import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSinglePet from './useSinglePet';

import SinglePetDetails from 'components/single-pet-details/SinglePetDetails';
import SinglePetTimeline from 'components/single-pet-timeline/SinglePetTimeline';

import { YEAR_FILTER_DATA } from 'constants/year';

import * as S from './components';

const SinglePet = () => {
  const [year, setYear] = useState(YEAR_FILTER_DATA[0]);

  const { id } = useParams();
  const { pet, timeline, loading } = useSinglePet(id, year.value);

  return (
    <S.SinglePetWrapper>
      <SinglePetDetails year={year} pet={pet} timeline={timeline} />
      <SinglePetTimeline
        YEAR_FILTER_DATA={YEAR_FILTER_DATA}
        year={year}
        setYear={setYear}
        timeline={timeline}
        loading={loading}
      />
    </S.SinglePetWrapper>
  );
};

export default SinglePet;
