import React, { useMemo } from 'react';
import { Skeleton, Input } from 'antd';
import css from '../ui/EditExternalId.module.css';
import { useTranslation, Trans } from 'react-i18next';

const useEditExternalIdSteps = ({ formik, guide }) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t('integration.edit_pet_form.edit_external_id_modal.first_step')
      },
      {
        title: (
          <div className={css.getReferenceIdStep}>
            <span>{t('integration.edit_pet_form.edit_external_id_modal.second_step')}</span>
            {guide ? (
              <img className={css.imagePlaceholder} src={guide}></img>
            ) : (
              <Skeleton.Image className={css.imagePlaceholder} />
            )}
          </div>
        )
      },
      {
        title: (
          <>
            <span className={css.externalIdTitle}>
              {t('integration.edit_pet_form.edit_external_id_modal.third_step')}
            </span>
            <Input
              name="externalId"
              value={formik.values.externalId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className={css.externalIdNote}>
              <Trans
                i18nKey={'integration.edit_pet_form.edit_external_id_modal.note'}
                values={{ note: 'Note:' }}
                components={{ span: <span /> }}
              />
            </div>
          </>
        )
      }
    ],
    [formik.values.externalId, t]
  );
};

export { useEditExternalIdSteps };
