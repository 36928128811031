import React, { useRef, useState } from 'react';
import * as S from './components';
import Header from 'components/header/Header';
import SideMenu from 'components/side-menu/SideMenu';

const Layout = ({ children, isHome }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [vhSize, setVhSize] = useState(() => window.innerHeight * 0.1);
  window.addEventListener('resize', () => {
    setVhSize(window.innerHeight * 0.1);
  });

  const burgerRef = useRef();

  return (
    <S.Container vhSize={vhSize} sidebarOpen={sidebarOpen}>
      <Header setSidebarOpen={setSidebarOpen} burgerRef={burgerRef} />
      <S.Main>
        {sidebarOpen && <S.Layer />}
        <SideMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} burgerRef={burgerRef} />
        <S.Content isHome={isHome}>{children}</S.Content>
      </S.Main>
    </S.Container>
  );
};

export default Layout;
