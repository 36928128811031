import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import Button from '../common/button/Button';

import { ReactComponent as Warning } from 'assets/icons/warning-triangle.svg';

import './style.scss';

const CancelAppointmentModal = ({ onclose, onConfirm, user }) => {
  const { t } = useTranslation();

  return (
    <Modal zIndex={1001} isOpen={true} onRequestClose={onclose}>
      <div className="cancel-appointment-modal">
        <h3>
          <Warning /> {t('new_appointment.cancel_appointment_title')}
        </h3>
        <p>
          You are about to cancel appointment for <strong>{user?.firstName} </strong>
          from your appointment list.
        </p>
        <p>{t('new_appointment.cancel_appointment_text')}</p>
        <div className="footer-wrapper">
          <Button
            label={t('new_appointment.yes_cancel')}
            className="cancel-appointment-btn"
            onClick={onConfirm}
          />
          <Button label={t('no')} color="primary" className="next-btn" onClick={onclose} />
        </div>
      </div>
    </Modal>
  );
};

export default CancelAppointmentModal;
