import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { getCurrencyIcon } from 'utils/helpers';
import { useMediaQuery } from 'react-responsive';
import useCheckout from './UseCheckout';
import usePayments from './usePayments';
import useMyPets from 'pages/my-pets/UseMyPets';
import { ReactComponent as GoBackIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as AlertCircle } from 'assets/icons/alert-circle.svg';
import { ReactComponent as PawIcon } from 'assets/icons/paw-orange.svg';
import { ReactComponent as DropDownButton } from 'assets/icons/arrow-down-gray.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { ReactComponent as Fail } from 'assets/icons/fail-circle.svg';

import * as S from './components';

import { PageTitle } from 'components/common/texts/components';
import { FormRow, InputWrapper } from 'components/complete-profile-form/components';
import Input from 'components/common/input/Input';
import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import PhoneInput from 'components/common/phone-input/PhoneInput';
import Checkbox from 'components/checkbox/Checkbox';
import MembershipPetCard from 'components/membership-pet-card/MembershipPetCard';
import VetCard from 'components/checkout-vet-card/CheckoutVetCard';
import { IconWrapper } from 'components/common/go-back/components';
import CheckoutDetailsPetCard from 'components/membership/checkout-details-pet-card/CheckoutDetailsPetCard';
import CheckoutDetailsPetsSlider from 'components/membership/checkout-details-pet-slider/CheckoutDetailsPetSlider';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import PaymentResponseMsg from 'components/payment-response-modal/PaymentResponseMsg';
import PetAcceptionControlModal from 'components/pet-acception-control-modal/PetAcceptionControlModal';
import PaymentMethod from 'components/payment-method/PaymentMethod';
import { SELECT_VET } from 'constants/client';
import useMembershipDetails from '../details/useMembershipDetails';
import AuthContext from '../../../contexts/AuthContext';

SwiperCore.use([Navigation]);

const Checkout = () => {
  const history = useHistory();
  const { id } = useParams();
  const { user: me, refetch: meRefetch } = useContext(AuthContext);
  const { selectedPets: selectedSubscriptionPets } = useMembershipDetails();

  const [personalInfo, setPersonalInfo] = useState(true);
  const [selectedPets, setSelectedPets] = useState(true);
  const [selectVet, setSelectVet] = useState(true);
  const [isPaymentResponseModalOpen, setIsPaymentResponseModalOpen] = useState(false);

  const { t } = useTranslation();
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  const isMobile = useMediaQuery({
    query: `(max-width: 480px)`
  });
  const { petToRemove, setPetToRemove } = useMyPets();

  const petName = petToRemove?.name;

  const { handleRemovePet, vet, subscriptionPets, priceData, loading, subscriptionType } =
    useCheckout(me, id);

  const {
    loading: fieldsLoading,
    submitForm,
    paymentMethods,
    error,
    setError,
    success,
    fieldsState,
    defaultProvider,
    isEmailSubscribed,
    finishedSuccessfully,
    setIsEmailSubscribed,
    isFinished,
    paymentSkipped,
    setPaymentSkipped
  } = usePayments(priceData?.price, id, me, priceData, vet, meRefetch);
  const togglePageSubMenu = (set) => {
    if (isMobile) {
      set((prev) => !prev);
    } else {
      set(true);
    }
  };

  const handleGoBack = () => {
    history.push(SELECT_VET.replace(':id', id));
  };

  useEffect(() => {
    (success || !!error?.length) && setIsPaymentResponseModalOpen(true);
  }, [error, success]);

  const CheckoutButton = () =>
    useMemo(() => {
      return fieldsLoading ? (
        <>
          {t('processing')}
          <S.Loader />
        </>
      ) : isFinished ? (
        finishedSuccessfully ? (
          <>
            {t('completed')}
            <Check />
          </>
        ) : (
          <>
            {t('failed')}
            <Fail />
          </>
        )
      ) : (
        t('checkout')
      );
    }, [fieldsLoading, isFinished, finishedSuccessfully]);

  const isOneTimeVisit = subscriptionType?.name === 'one_time';

  return (
    <>
      <PaymentResponseMsg
        handleCloseModal={() => {
          setIsPaymentResponseModalOpen(false);
        }}
        isOpen={isPaymentResponseModalOpen}
        error={error}
        setError={setError}
      />
      <LoaderWrapper isLoading={loading}>
        <S.Wrapper>
          <S.GoBackWrapper onClick={handleGoBack}>
            <S.IconWrapper>
              <GoBackIcon />
            </S.IconWrapper>
            <PageTitle>{t('checkout')}</PageTitle>
          </S.GoBackWrapper>

          <S.BodyWrapper>
            <S.CheckoutAbsoluteWrapper>
              <S.CheckoutDetailsWrapper>
                <S.CheckoutSubWrapper>
                  <S.PageSubTitle>{t('checkout.details')}</S.PageSubTitle>
                  {me.isAdmin && (
                    <FormRow style={{ marginBottom: '.9rem' }}>
                      <Checkbox
                        label="skip.payments"
                        isChecked={paymentSkipped}
                        handleChange={() => setPaymentSkipped((curr) => !curr)}
                      />
                    </FormRow>
                  )}
                  {subscriptionPets?.length > 2 && (
                    <S.ArrowsWrapper>
                      <S.Prev ref={prevRef}>
                        <IconWrapper>
                          <ArrowLeft />
                        </IconWrapper>
                      </S.Prev>
                      <S.Prev ref={nextRef}>
                        <IconWrapper rtl>
                          <ArrowLeft />
                        </IconWrapper>
                      </S.Prev>
                    </S.ArrowsWrapper>
                  )}
                </S.CheckoutSubWrapper>
                {subscriptionPets?.length > 2 ? (
                  <CheckoutDetailsPetsSlider
                    pets={subscriptionPets}
                    prevRef={prevRef}
                    nextRef={nextRef}
                  />
                ) : (
                  subscriptionPets?.map((pet) => {
                    return (
                      <div key={pet.uid}>
                        <CheckoutDetailsPetCard
                          isOneTimeVisit={isOneTimeVisit}
                          image={pet?.pet?.avatarUrl}
                          name={pet?.pet?.name}
                          type={pet?.pet?.type?.name}
                          price={pet?.plan?.price}
                          plan={pet?.plan}
                          currency={pet?.plan?.currency}
                        />
                      </div>
                    );
                  })
                )}
                <form onSubmit={submitForm}>
                  {paymentMethods.length ? (
                    <div>
                      {paymentMethods.map((p) => (
                        <PaymentMethod {...p} key={p.uid} />
                      ))}
                      {error && (
                        <S.ErrorWrapper>
                          <AlertCircle />
                          <S.ErrorText>{error}</S.ErrorText>
                        </S.ErrorWrapper>
                      )}
                    </div>
                  ) : (
                    <>
                      <S.PriceText margin={true}>{t('payment.method')}</S.PriceText>
                      <S.InputWrapper
                        id="card-number-container"
                        isFocused={fieldsState?.cardNumber.isFocused}
                        data-before="before"
                        data-bluesnap="ccn">
                        {defaultProvider !== 'bluesnap' && (
                          <S.Label
                            isFocused={fieldsState?.cardNumber.isFocused}
                            notEmpty={!fieldsState.cardNumber.isEmpty}>
                            {t('card.number')}
                          </S.Label>
                        )}
                      </S.InputWrapper>
                      <S.CvvAndDateWrapper>
                        <S.CVCWrapper>
                          <S.InputWrapper
                            id="card-cvc-container"
                            isFocused={fieldsState?.cvc.isFocused}
                            data-bluesnap="cvv">
                            {defaultProvider !== 'bluesnap' && (
                              <S.Label
                                isFocused={fieldsState?.cvc.isFocused}
                                notEmpty={!fieldsState.cvc.isEmpty}>
                                {t('cvc')}
                              </S.Label>
                            )}
                          </S.InputWrapper>
                        </S.CVCWrapper>
                        <div style={{ width: '100%' }}>
                          <S.InputWrapper
                            id="card-expiration-container"
                            isFocused={fieldsState?.cardExpiration.isFocused}
                            data-bluesnap="exp">
                            {defaultProvider !== 'bluesnap' && (
                              <S.Label
                                isFocused={fieldsState?.cardExpiration.isFocused}
                                notEmpty={!fieldsState.cardExpiration.isEmpty}>
                                {t('expiry.date.mm.yy')}
                              </S.Label>
                            )}
                          </S.InputWrapper>
                        </div>
                      </S.CvvAndDateWrapper>
                      {error && (
                        <S.ErrorWrapper>
                          <AlertCircle />
                          <S.ErrorText>{error}</S.ErrorText>
                        </S.ErrorWrapper>
                      )}
                    </>
                  )}

                  <S.FlexBox>
                    <S.PriceText>
                      {isOneTimeVisit ? t('one.time.visit') : t('price.per.month')}
                    </S.PriceText>
                    <S.PriceValueText>
                      {priceData &&
                        getCurrencyIcon(priceData?.currency) +
                          (priceData?.originalPrice).toFixed(2)}
                    </S.PriceValueText>
                  </S.FlexBox>

                  {!!priceData?.additionalFee && (
                    <S.FlexBox>
                      <S.PriceText>{t('additional.one.time.payment')}</S.PriceText>
                      <S.PriceValueText>
                        {priceData &&
                          getCurrencyIcon(priceData?.currency) +
                            (priceData?.additionalFee).toFixed(2)}
                      </S.PriceValueText>
                    </S.FlexBox>
                  )}
                  <S.Hr top />
                  <S.FlexBox>
                    <S.PriceText>
                      {t('discount')}
                      <div>
                        <S.PriceText colored>
                          {priceData && Math.round(priceData?.discountAmount * 100)}%
                          {priceData && priceData?.additionalDiscountAmount > 0 && (
                            <>
                              &nbsp; +{' '}
                              {priceData && Math.round(priceData?.additionalDiscountAmount * 100)}%
                            </>
                          )}
                        </S.PriceText>
                      </div>
                    </S.PriceText>
                    <S.PriceValueText orange>
                      {priceData &&
                        '-' +
                          getCurrencyIcon(priceData?.currency) +
                          (
                            (priceData?.additionalFee + priceData?.originalPrice) *
                            priceData?.discountAmount
                          ).toFixed(2)}
                    </S.PriceValueText>
                  </S.FlexBox>
                  <S.Hr />
                  <S.FlexBox>
                    <S.PriceText bold>{t('pay.now')}</S.PriceText>
                    <S.PriceValueText bold total>
                      {getCurrencyIcon(priceData?.currency)}
                      {priceData && (priceData?.price).toFixed(2)}
                    </S.PriceValueText>
                  </S.FlexBox>
                  <S.CheckoutButton
                    loading={fieldsLoading}
                    finishedSuccessfully={finishedSuccessfully}
                    isFinished={isFinished}
                    disabled={fieldsLoading}>
                    <CheckoutButton />
                  </S.CheckoutButton>
                </form>
              </S.CheckoutDetailsWrapper>
            </S.CheckoutAbsoluteWrapper>
            <S.Main>
              <S.CheckoutInfoWrapper>
                <S.PersonalInfoWrapper>
                  <S.PersonalInfoHead
                    onClick={() => togglePageSubMenu(setPersonalInfo)}
                    personalInfo={personalInfo}>
                    <S.PageSubTitle>{t('personal.info')}</S.PageSubTitle>
                    <DropDownButton width="49" height="34" />
                  </S.PersonalInfoHead>
                  {personalInfo && (
                    <S.PersonalInfo>
                      <FormRow>
                        <S.FullWidth>
                          <InputWrapper>
                            <Input disabled value={me.email} name="email" label={t('email')} />
                          </InputWrapper>
                        </S.FullWidth>
                      </FormRow>
                      <FormRow style={{ marginBottom: '.9rem' }}>
                        <Checkbox
                          label="keep.me.update.on.news.and.exclusive.offers"
                          isChecked={isEmailSubscribed}
                          handleChange={() => setIsEmailSubscribed((curr) => !curr)}
                        />
                      </FormRow>
                      <FormRow>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.firstName}
                            name="firstName"
                            label={t('firstName')}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.lastName}
                            name="lastName"
                            label={t('lastName')}
                          />
                        </InputWrapper>
                      </FormRow>
                      <FormRow>
                        <InputWrapper full>
                          <AddressAutocomplete disabled address={me.address} />
                        </InputWrapper>
                      </FormRow>
                      <FormRow>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.address?.country?.name}
                            name="address.country"
                            label={t('country')}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.address?.city}
                            name="address.city"
                            label={t('city')}
                          />
                        </InputWrapper>
                      </FormRow>
                      <FormRow>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.address?.zipCode}
                            name="address.zipCode"
                            label={t('postal.code')}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <PhoneInput
                            style={{ width: '100%' }}
                            disabled
                            label={t('phone.number.not.required')}
                            searchPlaceholder={t('phone.input.search.placeholder')}
                            value={`${me.phonePrefix}${me.phoneNumber}`}
                          />
                        </InputWrapper>
                      </FormRow>
                      <FormRow>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.address?.floor}
                            name="address.floor"
                            label={t('floor')}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            disabled
                            value={me.address?.apartment}
                            name="address.apartment"
                            label={t('apartment')}
                          />
                        </InputWrapper>
                      </FormRow>
                    </S.PersonalInfo>
                  )}
                </S.PersonalInfoWrapper>
                <div>
                  <S.SelectedPetsWrapper>
                    <S.SelectedPetsHead
                      selectedPets={selectedPets}
                      onClick={() => togglePageSubMenu(setSelectedPets)}>
                      <S.PageSubTitle>{t('selected.pets')}</S.PageSubTitle>
                      <DropDownButton width="49" height="34" />
                    </S.SelectedPetsHead>
                    {selectedPets && (
                      <S.SelectedPets>
                        <S.PetsWrapper>
                          {subscriptionPets?.map((pet, index) => {
                            return (
                              <S.MembershipPetCardWrapper key={index}>
                                <MembershipPetCard
                                  fullWidth={true}
                                  index={index}
                                  selectedPets={selectedSubscriptionPets}
                                  pet={pet.pet}
                                  plan={pet.plan}
                                  key={pet.uid}
                                  subPetId={pet.uid}
                                  isCheckout
                                  handleRemovePet={setPetToRemove}
                                  isSubscriptionOneTime={isOneTimeVisit}
                                />
                              </S.MembershipPetCardWrapper>
                            );
                          })}
                          {/* <AddAlergyButton
                  isModal={true}
                  type="button"
                  onClick={() => {}}
                >
                  <AddAlergyIcon src={PlusIcon} alt="plus" />
                  <AddAlergyLabel>{t('add.pets.from.list')}</AddAlergyLabel>
                </AddAlergyButton> */}
                        </S.PetsWrapper>
                      </S.SelectedPets>
                    )}
                  </S.SelectedPetsWrapper>
                  {vet && vet.user && (
                    <S.SelectedVetsWrapper>
                      <S.SelectedVetsHead
                        selectVet={selectVet}
                        onClick={() => togglePageSubMenu(setSelectVet)}>
                        <S.PageSubTitle>{t('selected.vet')}</S.PageSubTitle>
                        <DropDownButton width="49" height="34" />
                      </S.SelectedVetsHead>
                      {selectVet && (
                        <VetCard
                          languages={vet?.languages}
                          user={vet?.user}
                          image={vet?.user?.avatarUrl}
                          name={`${vet?.user?.firstName} ${vet?.user?.lastName}`}
                          title="good doctor"
                        />
                      )}
                    </S.SelectedVetsWrapper>
                  )}
                  {isMobile && (
                    <S.CheckoutButtonWrapper onClick={submitForm}>
                      <S.CheckoutButton
                        active
                        loading={fieldsLoading}
                        finishedSuccessfully={finishedSuccessfully}
                        isFinished={isFinished}
                        disabled={fieldsLoading}>
                        <CheckoutButton />
                      </S.CheckoutButton>
                    </S.CheckoutButtonWrapper>
                  )}
                </div>
              </S.CheckoutInfoWrapper>
            </S.Main>
          </S.BodyWrapper>
        </S.Wrapper>
        <PetAcceptionControlModal
          isOpen={!!petToRemove?.subPetId}
          submitHandler={() => {
            handleRemovePet(petToRemove?.subPetId);
            setPetToRemove(null);
          }}
          closeHandler={() => {
            setPetToRemove(null);
          }}
          data={petToRemove}
          onRequestClose={() => setPetToRemove(null)}
          caption={
            <Trans>
              Are you sure you want to remove <S.StyledText>{{ petName }}</S.StyledText> ?
            </Trans>
          }
          petNameCaption={`${petToRemove?.name} ?`}
          icon={<PawIcon />}
        />
      </LoaderWrapper>
    </>
  );
};

export default Checkout;
