import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DASHBOARD } from 'constants/client';

const PublicRoute = ({ component: Component, user, loading, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !user && !loading ? (
          Layout ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={DASHBOARD} />
        );
      }}
    />
  );
};

export default PublicRoute;
