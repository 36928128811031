import DogAvatar from 'assets/icons/dog-avatar.svg';
import OtherAvatar from 'assets/icons/other-avatar.svg';
import CatAvatar from 'assets/icons/cat-avatar.svg';
import DogImage from 'assets/images/dog-avatar.svg';
import OtherImage from 'assets/images/other-avatar.svg';
import CatImage from 'assets/images/cat-avatar.svg';

export const type = [
  {
    label: 'Dog',
    value: 'dog'
  },
  { label: 'Cat', value: 'cat' }
];
export const gender = [
  {
    label: 'Male',
    value: 'male'
  },
  { label: 'Female', value: 'female' }
];

export const furColor = [
  {
    label: 'Black',
    value: 'black'
  },
  { label: 'Brown', value: 'brown' }
];

export const weightUnit = [
  {
    label: 'kg',
    value: 'kg'
  },
  { label: 'lb', value: 'lb' }
];

export const isSterilized = [
  {
    label: 'Yes',
    value: true
  },
  { label: 'No', value: false }
];

export const isFullyVaccinated = [
  {
    label: 'Yes',
    value: true
  },
  { label: 'No', value: false }
];

export const petAvatars = {
  dog: DogAvatar,
  cat: CatAvatar,
  other: OtherAvatar
};

export const petImages = {
  dog: DogImage,
  cat: CatImage,
  other: OtherImage
};
