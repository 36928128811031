import React, { useState, useRef } from 'react';
import * as S from './components';
import Header from 'components/header/Header';
import SideMenu from 'components/side-menu/SideMenu';

const VetLayout = ({ children, isHome, isCustomers, disableMobilePadding, isAdmin }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [vhSize, setVhSize] = useState(() => window.innerHeight * 0.1);
  window.addEventListener('resize', () => {
    setVhSize(window.innerHeight * 0.1);
  });

  const burgerRef = useRef();

  return (
    <S.Container vhSize={vhSize} sidebarOpen={sidebarOpen}>
      <Header setSidebarOpen={setSidebarOpen} burgerRef={burgerRef} isFromVetRoute />
      <S.Main>
        {sidebarOpen && <S.Layer />}
        <SideMenu
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          isForVet
          burgerRef={burgerRef}
        />
        <S.ContentWrapper>
          <S.Content
            id="ID_LAYOUT_SCROLLABLE"
            isCustomers={isCustomers}
            isHome={isHome}
            disableMobilePadding={disableMobilePadding}>
            {children}
          </S.Content>
        </S.ContentWrapper>
      </S.Main>
    </S.Container>
  );
};

export default VetLayout;
