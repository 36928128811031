import React, { memo } from 'react';
import { Form } from 'antd';
import { Modal } from '_fsd/shared';
import EditClientAddressModal from '../edit-client-address-modal/EditClientAddressModal';
import ModalContent from './components/ModalContent';
import ModalHeader from './components/ModalHeader';
import ModalFooter from './components/ModalFooter';
import useNewAppointmentModal from './useNewAppointmentModal';
import { serializeSignUpFlow } from './serializers';
import './style.scss';
import PetSection from '../../../components/_new/PetSection/PetSection';
import css from './Modal.module.css';

const NewAppointmentModal = ({
  vet,
  current,
  setCurrent,
  changeStep,
  calendarDate,
  onRequestClose,
  selectedTimeslot,
  openNewClientModal,
  getAppointmentsByTimeslotUid
}) => {
  const {
    search,
    setSearch,
    clients,
    loading,
    refetchClients,
    selectedClient,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    setPetsToCreate,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    form,
    handleSignUp,
    fetchPetOwners,
    total,
    isSubmitting,
    nonAppPets
  } = useNewAppointmentModal(
    current,
    changeStep,
    vet,
    onRequestClose,
    setCurrent,
    getAppointmentsByTimeslotUid
  );

  const steps = [
    {
      content: (
        <ModalContent
          vet={vet}
          total={total}
          search={search}
          clients={clients}
          loading={loading}
          onClientSelect={onClientSelect}
          fetchPetOwners={fetchPetOwners}
          selectedClient={selectedClient}
          onClientEdit={onClientEdit}
        />
      )
    },
    {
      content: (
        <Form
          form={form}
          layout="vertical"
          disabled={isSubmitting}
          initialValues={{
            pets: [],
            isInformClient: true
          }}
          onFinish={(values) => {
            if (petList.length === 0) {
              form.setFields([
                {
                  name: 'pets',
                  errors: ['Select at least one pet']
                }
              ]);
            } else {
              handleSignUp(
                serializeSignUpFlow(
                  values,
                  selectedClient?.user,
                  petList,
                  calendarDate,
                  vet,
                  selectedTimeslot,
                  nonAppPets
                )
              );
            }
          }}>
          <PetSection
            setPetsToCreate={setPetsToCreate}
            client={selectedClient?.user}
            petList={petList}
            setPetList={setPetList}
            vet={vet}
            isVet
          />
          <Form.Item name="isInformClient" noStyle></Form.Item>
        </Form>
      )
    }
  ];

  return (
    <>
      <Modal
        isOpen={true}
        customHeader={
          <ModalHeader
            className={css.modalHeader}
            search={search}
            setSearch={setSearch}
            current={current}
            changeStep={changeStep}
            openNewClientModal={openNewClientModal}
            onRequestClose={onRequestClose}
          />
        }
        customFooter={
          <ModalFooter
            className={css.modalFooter}
            onRequestClose={onRequestClose}
            isNextButtonDisabled={isNextButtonDisabled}
            onNextClick={onNextClick}
            changeStep={changeStep}
            current={current}
            steps={steps}
            form={form}
            isLoading={loading || isSubmitting}
          />
        }
        onClose={onRequestClose}>
        <div className={css.modalContent}>{steps[current].content}</div>
      </Modal>
      {clientToEdit && (
        <EditClientAddressModal
          onRequestClose={() => setClientToEdit(null)}
          refetchClients={refetchClients}
          client={clientToEdit}
        />
      )}
    </>
  );
};

export default memo(NewAppointmentModal);
