import React, { useContext, useMemo } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import style from './Modal.module.css';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';
import cls from 'classnames';

const Modal = ({ title, children, onClose, className }) => (
  <div className={style.modal}>
    <div className={style.header}>
      <h3>{title}</h3>
      <CloseIcon onClick={() => onClose()} />
    </div>
    <div className={cls(style.content, className)}>{children}</div>
  </div>
);

const Container = (props) => {
  const { closeModal } = useContext(ScheduleAppointmentContext);
  return useMemo(() => <Modal onClose={closeModal} {...props} />, [props, closeModal]);
};

export default Container;
