import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DASHBOARD } from 'constants/client';

import Modal from 'components/common/modal/Modal';
import exit from 'assets/icons/close-gray.svg';
import paymentError from 'assets/icons/payment-error.svg';
import paymentSuccess from 'assets/icons/payment-success.svg';

import * as S from './components';

const PaymentResponseMsg = ({ isOpen, handleCloseModal, error, setError }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const clickHandler = () => {
    if (!!error?.length) {
      setError('');
      return handleCloseModal();
    } else {
      history.push(DASHBOARD);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={clickHandler}>
      <S.Wrapper>
        <S.Exit src={exit} onClick={clickHandler} />
        <S.MessageImage src={!!error?.length ? paymentError : paymentSuccess} />
        <S.Caption>
          {' '}
          {!!error?.length
            ? t("Payment didn't complete successfully")
            : t('Payment completed successfully')}
        </S.Caption>
        {error && <S.SubCaption>{error}</S.SubCaption>}
        <S.Button isError={!!error?.length} onClick={clickHandler}>
          {!!error?.length ? t('close') : t('ok')}
        </S.Button>
      </S.Wrapper>
    </Modal>
  );
};

export default PaymentResponseMsg;
