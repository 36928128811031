import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Tooltip } from 'antd';

import { InfoCircleFilled } from '@ant-design/icons';

import { VetStatus } from 'constants/enums';

import './style.scss';

const RadioInput = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className="radio-wrapper">
      <Form.Item label={t('status')}>
        <Tooltip title={t('status.text')}>
          <InfoCircleFilled className="info-icon address-info-icon" />
        </Tooltip>
        <Form.Item name={name}>
          <Radio.Group id="status" name="status" className="status">
            <Radio value={VetStatus.ACTIVE}>{t('available')}</Radio>
            <Radio value={VetStatus.OCCUPIED}>{t('occupied')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form.Item>
    </div>
  );
};

export default RadioInput;
