import React from 'react';
import css from './Timeslot.module.css';
import { TimeslotRouteSummary } from './Timeslot.route-summary';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const TimeslotInfoTooltip = ({ routeSummaryData }) => {
  return (
    <Tooltip
      overlayClassName={css.tooltip}
      title={<TimeslotRouteSummary routeSummaryData={routeSummaryData} />}
      placement={'bottom'}>
      <InfoCircleFilled className={css.info} style={{ fontSize: '12px' }} />
    </Tooltip>
  );
};
