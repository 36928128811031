import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 54rem;
  width: 100%;

  ${({ theme }) => theme.mobile`
    width: auto;
    padding: 0 3.2rem;
  `}
`;

export const HeadingSections = styled.div`
  margin-bottom: 2.9rem;
`;

export const Phone = styled.div`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.67;
  color: #f9b057;
  margin-left: 1rem;
  display: inline-flex;
  ${({ isRtl }) =>
    isRtl &&
    css`
      direction: rtl;
    `}
  ${({ theme }) => theme.mobile`
      font-size: 1.4rem;
    `}
`;

export const GoBackWrapper = styled.div`
  margin-bottom: 5.6rem;
`;
