import React from 'react';
import { LogoWrapper, LogoImg } from './components';

import KumbaLogo from 'common_repo/assets/icons/logo-white.png';

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoImg src={KumbaLogo} alt="Kumba Logo" />
    </LogoWrapper>
  );
};

export default Logo;
