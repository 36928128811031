import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;
export const PhoneInputWrapper = styled.div`
  & .react-tel-input .country-list .search-box {
    width: calc(100%- 0.6rem);
    padding-right: 4rem;
  }
`;

export const ResentText = styled.p`
  color: ${({ theme }) => theme.colors.errorRed};
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 24px;
  margin-top: 2rem;
`;

export const AgreementDescription = styled.span`
  display: inline-flex;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(138, 148, 166, 0.9);
  margin-top: 2rem;
  ${({ theme }) => theme.laptopS`
    display: contents;
  `}
`;

export const HighlightedText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(138, 148, 166, 0.9);
  margin: 0 0.4rem;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  ${({ theme, terms }) => theme.mobile`
      ${terms && `margin-left: 0`}
   `}
`;
