import { useChartData } from '_fsd/features/monthly-appointment-summary-chart';
import orderBy from 'lodash/orderBy.js';

const filterCurrentMonthData = (data) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  return data?.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate.getFullYear() === currentYear && itemDate.getMonth() === currentMonth;
  });
};

const countByCaregivers = (data, vets) => {
  if (!data || !vets) return [];
  const caregiverCounts = {};
  vets.forEach((vet) => {
    caregiverCounts[vet.user.uid] = {
      vetUser: vet.user,
      vet,
      count: 0,
      caregiver: `${vet.user.firstName} ${vet.user.lastName}`
    };
  });
  data?.forEach((entry) => {
    const vetUser = entry.vetUser;
    if (!caregiverCounts[vetUser.uid]) {
      caregiverCounts[vetUser.uid] = {
        count: 0,
        vetUser
      };
    }
    caregiverCounts[vetUser.uid].count += 1;
  });
  return orderBy(Object.values(caregiverCounts), ['count'], ['desc']);
};

export const useDashboardData = ({ data, vets }) => {
  const dataSource = orderBy(
    data?.map(({ date, uid, user, timeSlot }) => {
      return {
        uid,
        date,
        caregiver: `${timeSlot.vet.user.firstName} ${timeSlot.vet.user.lastName}`,
        client: `${user.firstName} ${user.lastName}`,
        vetUser: timeSlot.vet.user
      };
    }),
    ['date'],
    ['desc']
  );

  const monthlyData = filterCurrentMonthData(dataSource);
  const apptsByCaregiver = countByCaregivers(monthlyData, vets);
  const chartsData = useChartData(dataSource, vets);

  return {
    apptsByCaregiver,
    monthlyData,
    chartsData
  };
};
