import styled from 'styled-components';

export const DeleteIcon = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.4rem;
  display: none;

  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const AddIcon = styled.img`
  display: none;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Upload = styled.div`
  position: relative;

  &:hover {
    ${DeleteIcon} {
      display: unset;
    }

    ${AddIcon} {
      display: unset;
    }
  }
`;
