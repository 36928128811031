import styled, { css } from 'styled-components';

export const ErrorMessage = styled.span`
  font-family: inter, assistant;
  font-weight: 400;
  display: block;
  height: 1rem;
  color: ${({ theme }) => theme.colors.errorRed};
  font-size: 1.2rem;
  margin-top: 0.2rem;
  ${({ theme }) => theme.mobile`
    text-align: left;
    padding-right: 2.4rem;
  `}
  ${({ isForVet, isError }) =>
    isForVet &&
    css`
      display: ${isError ? 'block' : 'none'};
    `}
`;
