import styled from 'styled-components';

export const TimelineEmptyState = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f4f7;
`;
export const CalendarIcon = styled.img`
  height: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '10.6rem')};
  width: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '10.6rem')};
  ${({ theme }) => theme.laptopM`
    height: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '7.6rem')};
  `}
  ${({ theme }) => theme.laptop`
    height: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '10.6rem')};
  `}
`;
export const NoMeetingsYetText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.9rem;
  color: ${({ theme }) => theme.colors.black};
  white-space: pre-wrap;
`;
export const ChooseSubscriptionText = styled.h4`
  max-width: 16.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: rgba(50, 59, 75, 0.64);
  padding-bottom: 3rem;
`;
