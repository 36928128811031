import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-top: 13%;
  max-width: 59.2rem;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => theme.mobile`
    width: 100%;
    padding: 3.2rem;
    margin-top: unset;
  `}
`;

export const GoBackWrapper = styled.div`
  margin-bottom: 5.6rem;
  ${({ theme }) => theme.mobile`
    margin-bottom: 1.6rem;
  `}
`;
