import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'styled-components';

import Modal from 'components/common/modal/Modal';
import PlanCard from '../plan-card/PlanCard';

import * as S from './components';

const PlansModal = ({
  isOpen,
  setPlansModalOpen,
  updatePetPlan,
  selectedPlanUid,
  petName,
  theme,
  plans
}) => {
  const { t } = useTranslation();
  const [chosenPlan, setChosenPlan] = useState(() => selectedPlanUid);

  const chooseMembership = (id) => {
    setChosenPlan(id);
  };

  const handleSave = () => {
    if (chosenPlan) updatePetPlan(chosenPlan);

    setPlansModalOpen(false);
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px`
  });

  return (
    <Modal
      scrollable
      isMobile={isMobile}
      isOpen={isOpen}
      onRequestClose={() => setPlansModalOpen(false)}>
      <S.Wrapper>
        <S.Header>
          <S.Title>{t('choose.plan.for.specific.pet', { petName })}</S.Title>
        </S.Header>
        <S.PlansWrapper>
          {plans?.map((plan) => (
            <PlanCard
              key={plan.uid}
              plan={plan}
              chooseMembership={chooseMembership}
              isSelected={chosenPlan === plan.uid}
            />
          ))}
        </S.PlansWrapper>
        <S.ButtonsWrapper>
          <S.Button onClick={() => setPlansModalOpen(false)}>{t('cancel')}</S.Button>
          <S.Button save onClick={handleSave}>
            {t('save')}
          </S.Button>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </Modal>
  );
};

export default withTheme(PlansModal);
