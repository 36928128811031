import styled from 'styled-components';

export const VetWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dbe2ee;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-height: 17.2rem;
  padding: 1.6rem;
  display: flex;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
  `}
`;

export const VetInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  width: 100%;
  ${({ theme }) => theme.mobile`
    margin-left: 0;
  `}
`;

export const VetImage = styled.img`
  min-width: 14rem;
  height: 14rem;
  border-radius: 8px;
  object-fit: cover;
  ${({ theme }) => theme.mobile`
    min-width: 6rem;
    max-width: 6rem;
    height: 6rem;
  `}
`;

export const AvatarWithText = styled.div`
  min-width: 14rem;
  height: 14rem;
  border-radius: 0.8rem;
  object-fit: cover;
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.6rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mobile`
    min-width: 6rem;
    max-width: 6rem;
    height: 6rem;
  `}
`;

export const VetMainInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const VetName = styled.h2`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mobile`
    margin-left: 1rem;
  `}
`;

export const VetTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #8a94a6;
`;

export const LanguagesWrapper = styled.div`
  display: flex;
  margin: 1.2rem 0;
  flex-wrap: wrap;
`;

export const LanguageWrapper = styled.div`
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
`;

export const About = styled.p`
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: 400;
  color: #323b4bb8;
  line-height: 2.1rem;
  height: 100%;
  max-height: 11rem;
  max-width: 45rem;
  flex-wrap: wrap;
  display: inline-flex;
  overflow-wrap: anywhere;

  ${({ theme }) => theme.mobile`
    overflow: auto;
  `}

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
`;
