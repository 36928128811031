export const generateColors = (count, defaultColors) => {
  const colors = [];
  const saturation = 70;
  const lightness = 50;
  for (let i = 0; i < count; i++) {
    const hue = Math.round((360 / count) * i);
    colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
  }
  if (defaultColors) {
    return [...defaultColors, ...colors];
  }
  return colors;
};
