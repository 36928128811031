import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    height: unset;
    min-height: 100vh;
  `}
`;

export const SliderWrapper = styled.div`
  width: 42.7%;
  height: 100%;
  ${({ theme }) => theme.tablet`
    display: none;
  `}
  ${({ theme }) => theme.mobile`
    display:block;
    width:100%;
    height: 34.3rem;
  `}
`;

export const FormsContainer = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 10rem;
  ${({ theme }) => theme.mobile`
    width: 100%;
    padding: unset;
    border-radius: 1.6rem 1.6rem 0 0;
    box-shadow: 0 44px 65px 0 rgba(176, 183, 195, 0.19);
    z-index: 1;
    margin-top: -2rem;
    min-height: 40rem;  
  `}
`;

export const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 3.2rem;
  z-index: 5;
  ${({ theme }) => theme.mobile`
    top: 1.2rem;
  `}
`;

export const Line = styled.span`
  z-index: 2;
  border-top: 0.4rem solid rgba(196, 196, 196, 1);
  width: 4.5rem;
  margin: auto;
  display: none;
  ${({ theme }) => theme.mobile`
    display: block;
  `}
`;
