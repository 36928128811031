import React from 'react';
import ClientCard from './ClientCard';
import ClientsEmptyState from './ClientsEmptyState';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton } from 'antd';

const ModalContent = ({
  total,
  clients,
  vet,
  onClientSelect,
  selectedClient,
  loading,
  onClientEdit,
  fetchPetOwners
}) => {
  if (!clients.length && !loading) return <ClientsEmptyState vet={vet} />;

  const loadMoreData = () => {
    if (loading) {
      return;
    }

    fetchPetOwners();
  };

  return (
    <div className={`appointments-content-wrapper ${loading ? 'd-flex' : ''}`}>
      <LoaderWrapper isLoading={loading}>
        <div
          id="scrollableDiv"
          style={{
            overflow: 'auto'
          }}>
          <InfiniteScroll
            dataLength={clients.length}
            next={loadMoreData}
            hasMore={clients.length < total}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1
                }}
                active
              />
            }
            scrollableTarget="scrollableDiv">
            <List
              dataSource={clients}
              renderItem={(item) => (
                <ClientCard
                  key={item?.uid}
                  item={item}
                  onClientSelect={onClientSelect}
                  selectedClient={selectedClient}
                  onClientEdit={onClientEdit}
                />
              )}
            />
          </InfiniteScroll>
        </div>
      </LoaderWrapper>
    </div>
  );
};

export default ModalContent;
