import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { SELECT_VET, CHOOSE_PET } from 'constants/client';
import {
  calculateSubscriptionPriceQuery,
  getSubscriptionPetsQuery,
  getSubscriptionQuery,
  removeSubscriptionPetMutation
} from 'gql';
import { replaceRouteParams } from 'utils/helpers';

const useMembershipDetails = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { id } = match.params;

  const discount = useQuery(calculateSubscriptionPriceQuery, {
    variables: {
      record: {
        subscriptionId: match.params.id
      }
    }
  });

  const { data, loading } = useQuery(getSubscriptionPetsQuery, {
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const { data: subscriptionData } = useQuery(getSubscriptionQuery, {
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const [removePet] = useMutation(removeSubscriptionPetMutation, {
    refetchQueries: [
      {
        query: getSubscriptionPetsQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      },
      {
        query: calculateSubscriptionPriceQuery,
        variables: {
          record: {
            subscriptionId: match.params.id
          }
        }
      }
    ]
  });

  const handleRemovePet = async (uid) => {
    try {
      await removePet({
        variables: {
          record: {
            uid
          }
        }
      });
    } catch (err) {
      throw err;
    }
  };

  const submitHandler = () => {
    history.push(replaceRouteParams(SELECT_VET, 'id', id));
  };

  const goBackHandler = () => {
    history.push(replaceRouteParams(CHOOSE_PET, 'id', id));
  };

  return {
    selectedPets: data?.getSubscriptionPets && !loading ? data.getSubscriptionPets : [],
    discount: discount?.data?.calculateSubscriptionPrice || {},
    currency: discount?.data?.calculateSubscriptionPrice?.currency || {},
    isLoading: loading || discount?.loading,
    subscriptionData: subscriptionData?.getSubscription || {},
    handleRemovePet,
    submitHandler,
    goBackHandler
  };
};

export default useMembershipDetails;
