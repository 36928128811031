import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import cls from 'classnames';
import Modal from 'components/common/modal/Modal';
import textCss from 'styles/text.module.css';
import { ReactComponent as Warning } from 'assets/icons/warning-circle-red.svg';

import css from './TimeslotWarningModal.module.css';

const TimeslotWarningModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onRequestClose={onClose} noBorderRadius>
      <div className={css.content}>
        <div className={css.header}>
          <Warning /> <span className={textCss.h5}>{t('route.warn_title')}</span>
        </div>
        <div className={cls(css.desc, textCss.pRegular)}>
          <span>{t('route.desc')}</span>
          <ul>
            <li>{t('route.desc_opt_1')}</li>
            <li>{t('route.desc_opt_2')}</li>
          </ul>
        </div>
        <div className={css.footer}>
          <Button className={css.btnCancel} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type="primary" className={css.btnOk} onClick={onConfirm}>
            {t('route.unlock')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TimeslotWarningModal;
