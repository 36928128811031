import { gql, useQuery } from '@apollo/client';

export const useGetUserConcernsByVetUid = (vetUid, isAvailable, isVet) => {
  const { data, ...rest } = useQuery(query, {
    variables: {
      record: {
        vetUid
      }
    },
    skip: !vetUid
  });
  const filteredData = data?.getUserConcernsByVetUid?.filter((concern) => {
    return (isAvailable ? concern.isSelected : true) && (!isVet ? concern.isBookable : true);
  });

  return { data: filteredData, ...rest };
};

const query = gql`
  query GetUserConcernsByVetUid($record: GetUserConcernsByVetUidRecord!) {
    getUserConcernsByVetUid(record: $record) {
      uid
      itemValue
      duration
      isSelected
      isBookable
      concern {
        uid
        name
      }
    }
  }
`;
