import React, { useEffect } from 'react';
import { Popover } from 'antd';

import { ReactComponent as Info } from 'assets/images/info.svg';

import { Dot, AddressCheckItem, AddressCheckList } from './components';
import { useTranslation } from 'react-i18next';

const AddressChecklist = ({ formikAddress, setIsAddressValid }) => {
  const { t } = useTranslation();

  const error =
    (formikAddress?.countryCode === 'US' && !formikAddress?.zipCode?.length) ||
    !formikAddress?.countryCode?.length ||
    !formikAddress?.street?.length ||
    !formikAddress?.city?.length;

  useEffect(() => {
    setIsAddressValid(!error);
  }, [formikAddress]);

  return (
    <Popover
      content={
        <AddressCheckList>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.countryCode} />
            {t('country')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.city} />
            {t('city')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.street} />
            {t('street')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot
              isNotNeeded={formikAddress?.countryCode !== 'US'}
              missing={formikAddress?.countryCode === 'US' && !formikAddress?.zipCode}
            />
            {t('zipcode')}
          </AddressCheckItem>
        </AddressCheckList>
      }>
      <Info fill={error ? '#E44E61' : '#22A3C3'} />
    </Popover>
  );
};

export default AddressChecklist;
