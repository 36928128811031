import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useRouteMatch, useHistory } from 'react-router-dom';

import {
  getPetsByOwnerQuery,
  getSubscriptionPetsQuery,
  choosePetsForSubscriptionMutation,
  getSubscriptionQuery,
  calculateSubscriptionPriceQuery
} from 'gql';

import { MEMBERSHIP_DETAILS } from 'constants/client';
import { replaceRouteParams } from 'utils/helpers';

const useChoosePet = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { id } = match?.params;
  const [rendered, setRendered] = useState(false);
  const [selectedPets, setSelectedPets] = useState({});
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [isAddPetModalOpen, setIsAddPetModalOpen] = useState(false);
  const { data, previousData, loading } = useQuery(getPetsByOwnerQuery, {
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const { data: subscriptionPetsData, loading: subscriptionPetsDataLoading } = useQuery(
    getSubscriptionPetsQuery,
    {
      skip: id === ':id',
      fetchPolicy: 'network-only',
      variables: {
        record: {
          subscriptionId: id
        }
      }
    }
  );

  useEffect(() => {
    if (rendered) return;
    if (!loading && !subscriptionPetsDataLoading && data && subscriptionPetsData) {
      const subPetsArr = subscriptionPetsData?.getSubscriptionPets;
      const petsArr = data?.getPetsByOwner;
      if (subPetsArr?.length) {
        setSelectedPets(subPetsArr.reduce((acc, key) => ((acc[key?.pet?.uid] = true), acc), {}));
      } else {
        setSelectedPets(petsArr.reduce((acc, key) => ((acc[key?.uid] = true), acc), {}));
      }
      setRendered(true);
    }
  }, [subscriptionPetsDataLoading, subscriptionPetsData, loading, data, rendered]);

  useEffect(() => {
    if (data?.getPetsByOwner.length > previousData?.getPetsByOwner.length) {
      setSelectedPets({
        ...selectedPets,
        [data?.getPetsByOwner?.[0].uid]: true
      });
    }
  }, [data, previousData, setSelectedPets, loading]);

  const closeModal = () => {
    setCongratsModalOpen(false);
    setDiscountModalOpen(false);
  };

  const [addPetSubscription] = useMutation(choosePetsForSubscriptionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: calculateSubscriptionPriceQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      },
      {
        query: getSubscriptionPetsQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      },
      {
        query: getSubscriptionQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      }
    ]
  });

  const submitHandler = async () => {
    if (data?.getPetsByOwner.length > 1) {
      Object.keys(selectedPets).length == 1
        ? setDiscountModalOpen(true)
        : setCongratsModalOpen(true);
    }
    try {
      await addPetSubscription({
        variables: {
          record: {
            subscriptionId: id,
            petIds: Object.keys(selectedPets)
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const continueClickHandler = async () => {
    try {
      setDiscountModalOpen?.(false);
      const res = await addPetSubscription({
        variables: {
          record: {
            subscriptionId: id,
            petIds: Object.keys(selectedPets)
          }
        }
      });

      if (res.data?.choosePetsForSubscription?.length) {
        history.push(replaceRouteParams(MEMBERSHIP_DETAILS, 'id', match.params.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goBackHandler = () => {
    history.push('/');
  };

  return {
    data,
    loading,
    selectedPets,
    setSelectedPets,
    previousData,
    discountModalOpen,
    setDiscountModalOpen,
    congratsModalOpen,
    plansModalOpen,
    setPlansModalOpen,
    isAddPetModalOpen,
    setIsAddPetModalOpen,
    closeModal,
    submitHandler,
    goBackHandler,
    continueClickHandler
  };
};

export default useChoosePet;
