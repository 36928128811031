import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker as AntDatePicker, Form } from 'antd';

import './styles.css';

const DatePicker = ({ label, name, required, date, onChange }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: t('field.is.required') }]}>
      <AntDatePicker
        onChange={onChange ? onChange : () => void 0}
        style={{ width: '100%' }}
        picker="date"
        format={'YYYY-MM-DD'}
        defaultValue={date}
      />
    </Form.Item>
  );
};

export default memo(DatePicker);
