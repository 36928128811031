import React, { memo } from 'react';
import { Form, Input, Layout, Typography } from 'antd';

import PhoneInput from 'components/common/phone-input/PhoneInput';
import { PhoneInputWrapper } from 'components/sign-up-form/components';

import './style.css';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { Content } = Layout;

const PersonalDetails = ({ form, phoneNumberError, isForVet }) => {
  const { t } = useTranslation();
  return (
    <Content className="patient-details-wrapper personal-details-wrapper">
      <Title level={5} className="page-heading">
        {isForVet
          ? t('admin.recommender.scheduling_stepper.vet_personal_step_title')
          : t('admin.recommender.personal_details.add.information.about.yourself')}
      </Title>
      <Form.Item
        name="firstName"
        rules={[
          { required: true, whitespace: true },
          { max: 25, message: t('admin.too.much.symbols') }
        ]}
        label={t('admin.recommender.personal_details.firstName')}>
        <Input placeholder="Maria" maxLength={25} />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          { required: true, whitespace: true },
          { max: 25, message: t('admin.too.much.symbols') }
        ]}
        label={t('admin.recommender.personal_details.lastName')}>
        <Input placeholder="Morrison" maxLength={25} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, type: 'email', whitespace: true },
          { max: 254, message: t('admin.too.much.symbols') }
        ]}
        label={t('admin.recommender.personal_details.email')}>
        <Input placeholder="Mariamorrison@gmail.com" maxLength={254} />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[{ required: true }, { pattern: /^[0-9]{8,15}$/, message: 'Invalid number' }]}
        label={t('admin.recommender.personal_details.phone_number')}>
        <PhoneInputWrapper>
          <PhoneInput
            hideLabel
            searchPlaceholder={t('admin.phone.input.search.placeholder')}
            value={`${form.getFieldValue('phonePrefix')}${form.getFieldValue('phoneNumber')}`}
            onChange={(prefix, number) => {
              form.setFieldsValue({ phonePrefix: prefix, phoneNumber: number });
            }}
            isPublic
            error={phoneNumberError}
          />
        </PhoneInputWrapper>
      </Form.Item>
    </Content>
  );
};

export default memo(PersonalDetails);
