import { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { buySubscriptionMutation } from 'gql';
import { executeFbScript, executeGoogleScript } from 'utils/helpers';
import theme from 'styles/theme';
import AuthContext from '../contexts/AuthContext';

const {
  create,
  fields: { EXPIRATION, NUMBER, CVC }
} = window.PayMe;

const getProps = (placeholder) => ({
  styles: {
    base: {
      'font-size': '16px',
      // fontFamily: 'inter',
      // fontWeight: '400',
      color: theme.colors.black,
      '::placeholder': {
        color: '#B0B7C3',
        'font-size': '16px',
        'font-family': 'inter'
      }
    },
    invalid: {
      color: theme.colors.red,
      borderColor: theme.colors.red
    },
    valid: {
      color: theme.colors.black
    }
  },
  placeholder: ' '
});

const usePaymeProvider = (
  price,
  subscriptionId,
  me,
  data,
  setLoading,
  setError,
  setSuccess,
  priceData,
  vet
) => {
  const { refetch: meRefetch } = useContext(AuthContext);
  const instanceRef = useRef(null);
  const [isFinished, setIsFinished] = useState(false);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);

  const [buySubscription, { loading: buyingSubscription }] = useMutation(buySubscriptionMutation);
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

  const apiKey = isProduction
    ? vet?.paymeSeller?.sellerPublicKey
    : 'c264c69c-189f-49a3-8ed9-66115283fe11';

  const initFields = (setFieldsState, setLoading, setError) => {
    create(apiKey, {
      testMode: !isProduction
    })
      .then(function (instance) {
        const allFieldsReady = [];
        instanceRef.current = instance;
        const fields = instance.hostedFields();
        const cardNumber = fields.create(NUMBER, getProps(' '));
        const expiration = fields.create(EXPIRATION, getProps(' '));

        const cvc = fields.create(CVC, getProps(' '));

        allFieldsReady.push(cardNumber.mount('#card-number-container'));
        allFieldsReady.push(expiration.mount('#card-expiration-container'));
        allFieldsReady.push(cvc.mount('#card-cvc-container'));

        Promise.all(allFieldsReady).then(() => {
          setLoading(false);
        });
        [cardNumber, expiration, cvc].forEach((field) =>
          field.on('keyup', ({ field, isValid, isEmpty }) => {
            setError('');
            setFieldsState((curr) => ({
              ...curr,
              [field]: {
                ...curr[field],
                isValid,
                isEmpty
              }
            }));
          })
        );
        [cardNumber, expiration, cvc].forEach((field) =>
          field.on('focus', ({ field }) => {
            setFieldsState((curr) => ({
              ...curr,
              [field]: {
                ...curr[field],
                isFocused: true
              }
            }));
          })
        );

        [cardNumber, expiration, cvc].forEach((field) =>
          field.on('blur', ({ field }) => {
            setFieldsState((curr) => ({
              ...curr,
              [field]: {
                ...curr[field],
                isFocused: false
              }
            }));
          })
        );
      })
      .catch(function (error) {
        setLoading(false);
        setIsFinished(true);
        setFinishedSuccessfully(false);
        // Instantiation error occurs
      });
  };

  const submitForm = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!data?.paymentMethods?.length) {
      const sale = {
        payerFirstName: me?.firstName,
        payerLastName: me?.lastName,
        payerEmail: me?.email,
        payerPhone: `${me?.phonePrefix}${me?.phoneNumber}`,
        payerZipCode: me?.address?.zipCode,
        total: {
          label: '🚀 Kumba subscription',
          amount: {
            currency: 'ILS',
            value: price.toFixed(2)
          }
        }
      };
      instanceRef.current
        .tokenize(sale)
        .then(async (data) => {
          const { card, token } = data;
          await buySubscription({
            variables: {
              record: {
                subscriptionId,
                card: {
                  ...card,
                  token
                },
                saveCard: true
              }
            }
          });
          if (!me.isAdmin) {
            executeFbScript(priceData?.currency?.name, priceData?.currency?.price);
            executeGoogleScript(priceData?.currency?.name, priceData?.currency?.price);
          }

          setLoading(false);
          setFinishedSuccessfully(true);
          setIsFinished(true);
          await meRefetch();
          setSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          setFinishedSuccessfully(false);
          setIsFinished(true);
          const errorMessage = err?.payload?.status_error_details;
          setError([errorMessage]);
          console.log('errorrrrrr', err);
        });
    } else {
      await buySubscription({
        variables: {
          record: {
            subscriptionId,
            paymentMethod: data?.paymentMethods?.find((pm) => pm.isPrimary)?.uid
          }
        }
      });
      if (process.env.REACT_APP_NODE_ENV === 'production') {
        if (!me.isAdmin) {
          executeFbScript(priceData?.currency?.name, priceData?.currency?.price);
          executeGoogleScript(priceData?.currency?.name, priceData?.currency?.price);
        }
      }
      setLoading(false);
      setSuccess(true);
      setFinishedSuccessfully(true);
      setIsFinished(true);
      await meRefetch();
    }
  };

  return {
    initFields,
    submitForm,
    buyingSubscription,
    finishedSuccessfully,
    isFinished,
    buySubscription
  };
};

export default usePaymeProvider;
