import React, { useCallback, useState } from 'react';
import NewAppointmentModal from '../../../../pages/vet/new-appointment-modal/NewAppointmentModal';
import NewClientModal from '../../../../pages/vet/new-appointment-modal/NewClientModal';
import useCalendar from '../../../../pages/calendar/useCalendar';

export const NewAppModalWrapper = ({
  vet,
  calendarDate,
  selectedTimeslot,
  close,
  isNewAppointmentsModalOpen,
  refetchApps
}) => {
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const { sendRequestCode, checkExistingUser } = useCalendar();

  const [current, setCurrent] = useState(0);
  const changeStep = useCallback((step) => step < current && setCurrent(step), [current]);
  return (
    <>
      {isNewAppointmentsModalOpen && (
        <NewAppointmentModal
          vet={vet}
          current={current}
          changeStep={changeStep}
          setCurrent={setCurrent}
          calendarDate={calendarDate}
          selectedTimeslot={selectedTimeslot}
          openNewClientModal={() => {
            setIsNewClientModalOpen(true);
            close();
          }}
          onRequestClose={close}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
      {isNewClientModalOpen && (
        <NewClientModal
          vet={vet}
          calendarDate={calendarDate}
          sendRequestCode={sendRequestCode}
          selectedTimeslot={selectedTimeslot}
          checkExistingUser={checkExistingUser}
          selectedWorkingArea={selectedTimeslot}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            close();
          }}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
    </>
  );
};
