import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link } from 'react-router-dom';

import PhoneInput from 'components/common/phone-input/PhoneInput';
import Button from 'components/common/button/Button';
import useSignUpForm from './useSignUpForm';

import { SIGN_UP, TERMS, PRIVACY_POLICY } from 'constants/client';

import {
  ButtonWrapper,
  PhoneInputWrapper,
  ResentText,
  AgreementDescription,
  HighlightedText
} from './components';

const SignUpForm = ({ redirectUrl, isVet }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { values, touched, errors, isSubmitting, handleSubmit, setFieldValue } = useSignUpForm(
    redirectUrl,
    isVet
  );

  const handleEnter = (e) => {
    if (e?.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <PhoneInputWrapper>
        <PhoneInput
          hideLabel
          searchPlaceholder={t('phone.input.search.placeholder')}
          values={`${values.phonePrefix}${values.phoneNumber}`}
          onChange={(prefix, number) => {
            setFieldValue('phonePrefix', prefix);
            setFieldValue('phoneNumber', number);
          }}
          error={touched.phoneNumber && errors.phoneNumber}
        />
      </PhoneInputWrapper>
      {match?.path === SIGN_UP && (
        <AgreementDescription>
          {t('by.creating.account')}{' '}
          <Link to={TERMS} target="_blank">
            <HighlightedText terms>{t('terms.of.service')}</HighlightedText>
          </Link>
          {t('and')}
          <Link to={PRIVACY_POLICY} target="_blank">
            <HighlightedText>{t('privacy.policy')}</HighlightedText>
          </Link>
        </AgreementDescription>
      )}
      <ButtonWrapper>
        <Button type="submit" color="primary" disabled={isSubmitting}>
          {t('get.code')}
        </Button>
      </ButtonWrapper>
      <ResentText>{errors.serverError}</ResentText>
    </form>
  );
};

export default SignUpForm;
