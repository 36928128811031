import { generateMediaQuery } from 'common_repo/utils/helpers';

export const screenSizes = {
  mobileS: 320,
  mobile: 480,
  tablet: 768,
  tabletL: 962,
  tabletXl: 1080,
  laptopSm: 1250,
  laptopS: 1280,
  laptop: 1368,
  laptopM: 1440,
  desktopS: 1730,
  desktop: 1920
};

export const screenHeights = {
  laptopHeightS: 696,
  laptopHeightSm: 720,
  laptopHeightMs: 780,
  laptopHeightM: 860
};

export const colors = {
  primaryBlue: '#22A3C3',
  primaryOrange: '#FF9A05',
  secondaryGrape: '#585CE5',
  secondaryViolet: '#A68BFF',
  success: '#389E0D',
  error: '#FF4D4F',
  info: '#2F54EB',
  gray1: '#434343',
  gray2: '#595959',
  gray3: '#8C8C8C',
  gray4: '#BFBFBF',
  gray5: '#D9D9D9',
  gray6: '#F0F0F0',
  gray7: '#F5F5F5',
  gray8: '#FAFAFA'
};

export const maxHeightMedia = generateMediaQuery(screenHeights, 'max-height');

export const minWidthMedia = generateMediaQuery(screenSizes, 'min-width');

export default generateMediaQuery(screenSizes, 'max-width');
