import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import updateLocale from 'dayjs/plugin/updateLocale';

import {
  Wrapper,
  Avatar,
  PetName,
  PetAge,
  FlexWrapper,
  ShowMoreBtn,
  ShowMoreIcon,
  ActionButton,
  ExpandedActionsWrapper,
  NameAndAgeWrapper
} from './components';

import DotsIcon from 'assets/icons/dots-gray.svg';
import EditIcon from 'assets/icons/pencil-gray.svg';
import RemoveIcon from 'assets/icons/trash-gray.svg';
import { useTranslation } from 'react-i18next';
import { petAvatars } from 'constants/pet';
import useOutsideClickDetector from 'hooks/useOutsideClickDetector';

dayjs.extend(relativeTime);
// dayjs.extend(updateLocale);

// dayjs.updateLocale("en", {
//   name: "es",
//   weekdays: "Domingo_Lunes ...".split("_"),
//   weekdaysShort: "Sun_M".split("_"),
//   weekdaysMin: "Su_Mo".split("_"),
//   weekStart: 1,
//   yearStart: 4,
//   months: "Enero_Febrero ... ".split("_"),
//   monthsShort: "Jan_F".split("_"),
//   ordinal: (n) => `${n}º`,
//   formats: {
//     LTS: "h:mm:ss A",
//     LT: "h:mm A",
//     L: "MM/DD/YYYY",
//     LL: "MMMM D, YYYY",
//     LLL: "MMMM D, YYYY h:mm A",
//     LLLL: "dddd, MMMM D, YYYY h:mm A",
//     l: "D/M/YYYY",
//     ll: "D MMM, YYYY",
//     lll: "D MMM, YYYY h:mm A",
//     llll: "ddd, MMM D, YYYY h:mm A",
//   },
//   relativeTime: {
//     future: "in %s",
//     past: "%s old",
//     s: "a few seconds",
//     m: "1 minute",
//     mm: "%d minutes",
//     h: "an hour",
//     hh: "%d hours",
//     d: "1 day",
//     dd: "%d days",
//     M: "1 month",
//     MM: "%d months",
//     y: "1 year",
//     yy: "%d years",
//   },
//   meridiem: (hour, minute, isLowercase) => {
//     return hour > 12 ? "PM" : "AM";
//   },
// });

const PetCard = ({
  uid,
  avatarUrl,
  name,
  type,
  birthDate,
  editHandler,
  removeHandler,
  index,
  petQuantity
}) => {
  const { t } = useTranslation();
  const [actionsOpen, setActionsOpen] = useState(false);
  const moreInfoRef = useRef(null);

  useOutsideClickDetector(moreInfoRef, () => setActionsOpen(false));

  return (
    <Wrapper>
      <FlexWrapper>
        <Avatar src={avatarUrl || petAvatars[type?.name]} alt="avatar" hasAvatar={avatarUrl} />
        <NameAndAgeWrapper>
          <PetName>{name}</PetName>
          <PetAge>{dayjs(birthDate).from(new Date(), true) + ' old'}</PetAge>
        </NameAndAgeWrapper>
      </FlexWrapper>
      <ShowMoreBtn onClick={() => setActionsOpen(true)}>
        <ShowMoreIcon src={DotsIcon} alt="dots" />
      </ShowMoreBtn>
      {actionsOpen && (
        <ExpandedActionsWrapper index={index} ref={moreInfoRef}>
          <ActionButton
            icon={EditIcon}
            label={t('edit.pet')}
            submitHandler={() => {
              editHandler(uid);
              setActionsOpen(false);
            }}
          />
          {petQuantity > 1 && (
            <ActionButton
              icon={RemoveIcon}
              label={t('remove.pet')}
              submitHandler={() => {
                removeHandler({ uid, name });
                setActionsOpen(false);
              }}
            />
          )}
        </ExpandedActionsWrapper>
      )}
    </Wrapper>
  );
};

export default PetCard;
