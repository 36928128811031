import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { updatePersonalDetailsMutation } from 'gql';
import { UserPersonalDetailsScheme } from 'validations/validations';
import { DASHBOARD, VET_ROUTES } from 'constants/client';
import { Roles } from '../../constants/enums';
import AuthContext from '../../contexts/AuthContext';

const useCompleteProfileForm = () => {
  const { user: me, refetch } = useContext(AuthContext);
  const history = useHistory();
  const { phonePrefix, phoneNumber, firstName, lastName, email, address } = me;
  const [notWorkingAreaModalOpen, setNotWorkingAreaModalOpen] = useState(false);

  const [updatePersonalDetails, { loading }] = useMutation(updatePersonalDetailsMutation);

  const onSubmit = useCallback(
    async ({ firstName, lastName, email, address }, { setFieldError }) => {
      try {
        const { data } = await updatePersonalDetails({
          variables: {
            record: {
              firstName,
              lastName,
              email,
              address: {
                ...address,
                floor: +address.floor,
                apartment: `${address.apartment}`,
                houseNumber: +address.houseNumber
              }
            }
          }
        });
        await refetch();

        let redirect;

        if (data?.updateCustomerPersonalDetails?.role === Roles.VET) {
          redirect = VET_ROUTES.UPDATE_VET;
        } else {
          redirect = DASHBOARD;
        }
        history.push(redirect);
      } catch (err) {
        err.graphQLErrors.forEach((item) => {
          const errObj = item?.extensions?.exception?.response;
          setFieldError(errObj?.field, errObj?.error);
        });
      }
    },
    [updatePersonalDetails, history]
  );

  const initialValues = {
    phonePrefix: phonePrefix,
    phoneNumber: phoneNumber,
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    address: {
      countryCode: address?.country?.code || '',
      city: address?.city || '',
      street: address?.street || '',
      houseNumber: address?.houseNumber || '',
      zipCode: address?.zipCode || '',
      floor: address?.floor || '',
      apartment: address?.apartment || '',
      comment: address?.comment || '',
      description: address?.description || '',
      lng: address?.lng || '',
      lat: address?.lat || ''
    }
  };

  const { values, handleChange, handleSubmit, isSubmitting, touched, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
      validationSchema: UserPersonalDetailsScheme
    });

  return {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting: isSubmitting || loading,
    touched,
    errors,
    notWorkingAreaModalOpen,
    setNotWorkingAreaModalOpen
  };
};

export default useCompleteProfileForm;
