import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Radio, Typography, Checkbox, Tooltip, Form, Select, message } from 'antd';

import { InfoCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import Button from 'components/common/button/Button';
import DeleteServiceModal from './DeleteServiceModal';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import InputNumber from 'components/vet-components/vet-web-inputs/input-number/InputNumber';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-red-empty.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon-gray.svg';
import { useGetVetsForVetAdmin } from '_fsd/entities/vet/api/useGetVetsForVetAdmin';
import { useAssignUserConcernToVet } from '_fsd/entities/vet/api/useAssignUserConcernToVet';
import { useGetVetIdsByUserConcernUid } from '_fsd/entities/vet/api/useGetVetIdsByUserConcernUid';
import { cssColor as colors } from '_fsd/shared';

const { Title } = Typography;

const AddServiceModal = ({
  loadingCreateUserConcern,
  loadingRemoveUserConcern,
  onRequestClose,
  selectedService,
  handleCreateUserConcern,
  handleRemoveUserConcern
}) => {
  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();
  const { data: vetsForVetAdminData } = useGetVetsForVetAdmin();
  const vetsList = useMemo(
    () =>
      vetsForVetAdminData?.map((i) => ({
        value: i.uid,
        label: `${i.user.firstName} ${i.user.lastName}`
      })),
    [vetsForVetAdminData]
  );
  const { data: vetsForUserConcern } = useGetVetIdsByUserConcernUid(
    selectedService?.value || selectedService?.uid
  );
  const [assignConcernToVet] = useAssignUserConcernToVet();
  const [form] = Form.useForm();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [assignedVetsList, setAssignedVetsList] = useState([]);

  useEffect(() => {
    const initialAssignedVets = vetsList?.filter((i) =>
      vetsForUserConcern?.find((v) => v.uid === i.value)
    );
    setAssignedVetsList(initialAssignedVets?.map((i) => i.value));
  }, [vetsList, vetsForUserConcern]);

  const handleToggleDeleteModal = useCallback(() => {
    setIsDeleteModalVisible((prevState) => !prevState);
  }, []);

  const onServiceDelete = useCallback(() => {
    handleRemoveUserConcern(selectedService?.value || selectedService?.uid);
    onRequestClose();
  }, [selectedService]);

  const getHourAndMinute = useCallback((duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return {
      hours,
      minutes
    };
  }, []);

  useEffect(() => {
    if (selectedService?.value || selectedService?.itemValue) {
      form.setFieldsValue({
        itemValue: selectedService?.label ?? selectedService?.itemValue,
        isBookable: selectedService?.isBookable,
        isSelected: selectedService?.isSelected,
        hours: getHourAndMinute(selectedService?.duration)?.hours || 0,
        minutes: getHourAndMinute(selectedService?.duration)?.minutes || 0
      });
    }
  }, [selectedService]);

  const initialValues = {
    itemValue: '',
    hours: 0,
    minutes: 30,
    isSelected: true,
    isBookable: true
  };
  return (
    <Modal
      isOpen={true}
      isLaptop={!isMobile}
      isMobile={isMobile}
      autoHeight
      onRequestClose={onRequestClose}>
      <div className="new-service-modal-wrapper">
        <div className="new-service-modal__header">
          <Title level={1} className="new-service-modal__title">
            {t('new_service_modal.title')}
          </Title>
          <CloseIcon onClick={onRequestClose} />
        </div>
        <Form
          form={form}
          disabled={loadingCreateUserConcern || loadingRemoveUserConcern}
          onFinish={async (values) => {
            if (selectedService?.value || selectedService?.itemValue)
              values.uid = selectedService?.value || selectedService?.uid;
            await handleCreateUserConcern({
              ...values,
              duration: values?.hours * 60 + values?.minutes
            });
            try {
              assignConcernToVet({
                variables: {
                  record: {
                    vetIds: assignedVetsList,
                    userConcernId: values.uid
                  }
                }
              });
            } catch (err) {
              message.error(err.message);
            }
          }}
          initialValues={initialValues}>
          <div className="new-service-modal__content">
            <div className="new-service-modal__form-group">
              <Title className="new-service-modal__sub_title">
                {t('new_service_modal.service_name_label')}
                <Tooltip title={<div>{t('new_service_modal.name_tooltip_text')}</div>}>
                  <InfoCircleFilled className="address-info-icon" />
                </Tooltip>
              </Title>
              <Input
                placeholder={t('new_service_modal.service_name_placeholder')}
                name="itemValue"
                whitespace={true}
              />
            </div>
            <div className="new-service-modal__form-group">
              <Title className="new-service-modal__sub_title">
                {t('new_service_modal.service_duration_label')}
                <Tooltip title={<div>{t('new_service_modal.duration_tooltip_text_2')}</div>}>
                  <InfoCircleFilled className="address-info-icon" />
                </Tooltip>
              </Title>
              <div className="d-flex">
                <InputNumber
                  name="hours"
                  controls={true}
                  whitespace={true}
                  placeholder={t('new_service_modal.service_hours_placeholder')}
                  addOnAfter={<span className={colors.cBlue}>{t('new_service_modal.hours')}</span>}
                />
                <InputNumber
                  label={null}
                  name="minutes"
                  controls={true}
                  whitespace={true}
                  max={59}
                  placeholder={t('new_service_modal.service_minutes_placeholder')}
                  addOnAfter={
                    <span className={colors.cBlue}>{t('new_service_modal.minutes')}</span>
                  }
                />
              </div>
            </div>
            <div className="new-service-modal__form-group">
              <Title className="new-service-modal__sub_title">
                {t('new_service_modal.assign_to_caregivers')}
              </Title>
              <Select
                mode="multiple"
                size="medium"
                placeholder="Please select"
                onChange={(e) => setAssignedVetsList(e)}
                value={assignedVetsList}
                style={{
                  width: '100%'
                }}
                options={vetsList}
              />
            </div>
            <div className="new-service-modal__form-group">
              <Title className="new-service-modal__sub_title">
                {t('new_service_modal.service_availability_label')}
                <Tooltip title={<div>{t('new_service_modal.availability_tooltip_text')}</div>}>
                  <InfoCircleFilled className="address-info-icon" />
                </Tooltip>
              </Title>
              <Form.Item name="isSelected">
                <Radio.Group
                // onChange={}
                >
                  <Radio value={true}>{t('new_service_modal.available')}</Radio>
                  <Radio value={false}>{t('new_service_modal.not_available')}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="new-service-modal__form-group">
              <Title className="new-service-modal__sub_title">
                {t('new_service_modal.service_visibility_label')}
                <Tooltip title={<div>{t('new_service_modal.visibility_tooltip_text')}</div>}>
                  <InfoCircleFilled className="address-info-icon" />
                </Tooltip>
              </Title>
              <Form.Item name="isBookable" valuePropName="checked">
                <Checkbox>{t('new_service_modal.service_visibility_placeholder')}</Checkbox>
              </Form.Item>
            </div>
            {selectedService && (
              <div className="new-service-modal__form-group">
                <Title className="new-service-modal__sub_title">
                  {t('new_service_modal.delete_service')}
                </Title>
                <Button
                  type="button"
                  isForModal
                  color="#fff"
                  width="9.4rem"
                  height="3.2rem"
                  label={t('delete')}
                  icon={<TrashIcon />}
                  fontColor="#FF4B55"
                  border="1px solid #FF4B55"
                  onClick={handleToggleDeleteModal}
                  className="new-service-modal__delete-btn"
                />
              </div>
            )}
          </div>
          <div className="new-service-modal__footer">
            <Button
              disabled={loadingCreateUserConcern || loadingRemoveUserConcern}
              color="primary"
              type="submit"
              width="8rem"
              height="3.2rem"
              isForModal
              label={selectedService ? t('preferences.update') : t('preferences.add')}
            />
          </div>
        </Form>
      </div>
      {isDeleteModalVisible && (
        <DeleteServiceModal onRequestClose={handleToggleDeleteModal} onConfirm={onServiceDelete} />
      )}
    </Modal>
  );
};

export default AddServiceModal;
