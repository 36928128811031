import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { checkIfUserExistsQuery, requestCodeMutation, requestCodeOnEmailMutation } from '../../gql';
import { Roles } from '../../constants/enums';
import AuthContext from '../../contexts/AuthContext';
import { Form, message } from 'antd';

export const STEPS = {
  LOGIN: 'LOGIN',
  SCHEDULE: 'SCHEDULE'
};
export const MODALS = {
  ACC_DOESNT_EXIST: 'ACC_DOESNT_EXIST',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_PHONE: 'VERIFY_PHONE'
};

const ScheduleAppointmentContext = createContext({
  selectedVet: null,
  placeId: null,
  loading: false,
  isBooking: false,
  vets: [],
  errors: {},
  step: STEPS.LOGIN,
  closeModal: (clear) => {},
  setCurrentVet: () => {},
  sendRequestCode: () => {},
  checkExistingUser: () => {},
  verifyPhoneSuccessHandler: () => {},
  newClientHandler: () => {},
  setErrors: () => {}
});

const initialState = {
  errors: {},
  modal: null,
  modalContext: {},
  step: STEPS.LOGIN,
  loading: false,
  uiDisabled: false,
  phone: '',
  prefix: ''
};

export const ScheduleAppointmentContextProvider = ({ children }) => {
  const { user, setCodeSendAt, refetch } = useContext(AuthContext);
  const [checkIfUserExists] = useLazyQuery(checkIfUserExistsQuery, { fetchPolicy: 'network-only' });
  const [requestCode] = useMutation(requestCodeMutation);

  const [state, setStateRaw] = useState(initialState);
  const setState = useCallback(
    (obj) => setStateRaw((prev) => ({ ...prev, ...obj })),
    [setStateRaw]
  );

  const [form] = Form.useForm();

  const closeModal = useCallback((clear) => {
    const newState = { modal: null, modalContext: {} };
    if (clear) {
      newState.phone = '';
      newState.prefix = '';
    }
    setState(newState);
  }, []);

  // todo: requestCode
  const checkPhoneNumber = useCallback(
    async ({ phone, prefix }) => {
      const data = await checkIfUserExists({
        variables: { record: { email: '', phonePrefix: prefix, phoneNumber: phone } }
      });
      if (data.data?.checkIfUserExists?.phoneNumber) {
        requestCode({
          variables: {
            record: {
              phonePrefix: prefix,
              phoneNumber: phone,
              role: Roles.PET_OWNER
            }
          }
        })
          .then((data) => {
            setCodeSendAt(data.data?.requestCode?.codeSentAt);
            setState({ modal: MODALS.VERIFY_PHONE, phone, prefix });
          })
          .catch((err) => {
            /* todo: set error */
          });
      } else {
        setState({ modal: MODALS.ACC_DOESNT_EXIST, phone, prefix });
      }
    },
    [checkIfUserExists]
  );
  const verifyPhoneSuccessHandler = useCallback(() => {
    setState({
      modal: null,
      step: STEPS.SCHEDULE
    });
  }, []);

  const openModal = useCallback((modal, modalContext) => setState({ modal, modalContext }), []);

  const newClientHandler = useCallback(() => {
    requestCode({
      variables: {
        record: {
          phonePrefix: state.prefix,
          phoneNumber: state.phone,
          role: Roles.PET_OWNER
        }
      }
    })
      .then((data) => {
        setCodeSendAt(data.data?.requestCode?.codeSentAt);
        setState({ modal: MODALS.VERIFY_PHONE });
      })
      .catch((err) => {
        /* todo: set error */
      });
  }, [state.prefix, state.phone]);

  const setErrors = useCallback((errors) => setState({ errors }), []);

  const setStep = useCallback((step) => setState({ step }), []);

  const [requestCodeOnEmail] = useMutation(requestCodeOnEmailMutation);
  const handleReassignEmail = useCallback(() => {
    const email = form.getFieldValue('email');
    const fields = form.getFieldsValue();
    requestCodeOnEmail({ variables: { record: { email } } })
      .then(() => refetch())
      .then(() => {
        openModal(MODALS.VERIFY_EMAIL, { email });
      })
      .catch((err) => {
        message.error(`INTERNAL ERROR: ${err.message}`);
        console.error('claim email', err);
      })
      .finally(() => {
        form.setFieldsValue(fields);
      });
  }, [form]);

  useEffect(() => {
    if (user) {
      const firstName = form.getFieldValue('firstName');
      const lastName = form.getFieldValue('lastName');
      const newUser = { ...user };
      if (firstName && lastName) {
        delete newUser.firstName;
        delete newUser.lastName;
      }
      form.setFieldsValue(newUser);
    }
    if (user && user.role === Roles.PET_OWNER) {
      setState({ step: STEPS.SCHEDULE });
    }
  }, [user, form]);

  return (
    <ScheduleAppointmentContext.Provider
      value={{
        // ...values,
        ...state,
        form,
        setStep,
        openModal,
        closeModal,
        checkPhoneNumber,
        verifyPhoneSuccessHandler,
        newClientHandler,
        handleReassignEmail,
        setErrors,
        checkIfUserExists
      }}>
      {children}
    </ScheduleAppointmentContext.Provider>
  );
};

export default ScheduleAppointmentContext;
