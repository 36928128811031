import * as yup from 'yup';

export const clientSchema = yup.object({
  firstName: yup.string().required('The field is required'),
  lastName: yup.string().required('The field is required'),
  email: yup.string().email().required('The field is required'),
  phonePrefix: yup.string().required('The field is required'),
  phoneNumber: yup
    .string()
    .min(8, 'Please enter a valid phone number')
    .required('The field is required'),
  address: yup.string().required('The field is required'),
  addressObject: yup.object().required('The field is required')
});
