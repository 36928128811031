import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import Button from 'components/common/button/Button';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import { ReactComponent as WarningIcon } from 'assets/icons/warning-circle-red.svg';

const { Title } = Typography;

const DeleteServiceModal = ({ onRequestClose, onConfirm }) => {
  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      isLaptop={!isMobile}
      isMobile={isMobile}
      autoHeight>
      <div className="delete-service-modal">
        <div className="delete-service-modal__icon">
          <WarningIcon />
        </div>
        <Title className="delete-service-modal__title">
          {t('new_service_modal.delete_modal_title')}
        </Title>
        <Title className="delete-service-modal__sub-title">
          {t('new_service_modal.delete_modal_sub_title')}
        </Title>
        <div className="delete-service-modal__footer">
          <Button
            isForModal
            color="#fff"
            width="7.5rem"
            height="3.2rem"
            label={t('cancel')}
            onClick={onRequestClose}
            border="1px solid #D9D9D9"
            fontColor="rgba(0, 0, 0, 0.85)"
          />
          <Button
            isForModal
            width="7.5rem"
            color="#FF4B55"
            height="3.2rem"
            fontColor="#fff"
            onClick={onConfirm}
            label={t('delete')}
            border="1px solid #FF4B55"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteServiceModal;
