import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseTimeSlotHour } from 'utils/helpers';

import Button from 'components/common/button/Button';
import DatePicker from 'components/common/datepicker/DatePicker';
import Modal from 'components/common/modal/Modal';
import useDateFormat from 'common_repo/components/useDateFormat';

import * as S from 'components/vet-card/components';
import moment from 'moment-timezone';

const TimeSlotCalendar = ({
  setIsCalendarOpen,
  startDate,
  handleChangeDate,
  timeSlots,
  workingHours,
  handleSelectHour,
  selectedHour,
  handleSubmitCalendar,
  selectedDate
}) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormat();
  const [hasChanged, setHasChanged] = useState(false);
  const handleChange = (date) => {
    handleChangeDate(date);
    setHasChanged(true);
  };
  return (
    <Modal isOpen onRequestClose={() => setIsCalendarOpen(false)}>
      <S.DatePickerWrapper>
        <S.DatePickerHeader>
          <S.DatePickerTitle>{t('choose.date.of.visit')}</S.DatePickerTitle>
        </S.DatePickerHeader>
        <S.DatePickerContainer>
          <DatePicker
            label=""
            selected={
              hasChanged
                ? startDate
                : selectedDate
                  ? moment.tz(selectedDate, moment.tz.guess()).toDate()
                  : startDate
            }
            onChange={(date) => handleChange(date)}
            open
            minDate={new Date()}
            startYear={new Date().getFullYear()}
            endYear={2}
            hideHeader
            isForTimeSlot
            includeDates={Object.keys(timeSlots).map((date) => {
              return moment.tz(date, moment.tz.guess()).toDate();
            })}
          />

          {startDate && workingHours && !!workingHours.length && (
            <S.TimeSlotsWrapper>
              <S.TimeSlotsHeader>
                <S.TimeSlotTitle>{t('time.slots.for.date')}</S.TimeSlotTitle>
                <S.TimeSlotDate>{dateFormat(startDate)}</S.TimeSlotDate>
              </S.TimeSlotsHeader>
              <S.SlotsList>
                {workingHours
                  ?.filter((s) => !s?.full)
                  ?.map((slot) => {
                    return (
                      <S.SlotsListItem
                        key={slot.uid}
                        isActive
                        onClick={() => handleSelectHour(slot)}
                        isChosed={selectedHour && selectedHour.uid === slot.uid}>
                        {parseTimeSlotHour(slot.startTime)}:{parseTimeSlotHour(slot.endTime)}
                        <div className="caregiver-text">
                          <span>{`${slot?.vet?.user?.firstName} ${slot?.vet?.user?.lastName}`}</span>
                        </div>
                      </S.SlotsListItem>
                    );
                  })}
              </S.SlotsList>
            </S.TimeSlotsWrapper>
          )}

          {startDate && (
            <S.ButtonWrapper>
              <Button
                onClick={handleSubmitCalendar}
                disabled={!selectedHour}
                type="button"
                label={t('continue')}
                color={selectedHour ? 'primary' : 'disabled'}
              />
            </S.ButtonWrapper>
          )}
        </S.DatePickerContainer>
      </S.DatePickerWrapper>
    </Modal>
  );
};

export default TimeSlotCalendar;
