import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 43.9rem;
  height: 36.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
`;

export const Caption = styled.h2`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  color: #2d3363;
`;

export const SubCaption = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #9094ac;
  margin-top: 1.2rem;
`;

export const Description = styled.h3`
  margin-top: 3.2rem;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  color: #9094ac;
  max-width: 29rem;
  text-align: center;
  line-height: 2rem;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const SadIcon = styled.img`
  margin-bottom: 3.9rem;
  height: 11.2rem;
`;
