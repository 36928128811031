import React from 'react';
import Modal from 'components/common/modal/Modal';

const ModalOrComponent = ({ children, showModal, isOpen, onClose }) => {
  if (showModal) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        {children}
      </Modal>
    );
  }

  return children;
};

export default ModalOrComponent;
