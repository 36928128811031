import React, { memo } from 'react';

import { Select as AntSelect, Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Eye } from 'assets/icons/visibility.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';

import './styles.css';

const { Option } = AntSelect;

const Select = ({
  name,
  label,
  options,
  placeholder,
  required,
  hasVisibilitySign,
  mode,
  infoIcon,
  tooltipText,
  onSelect,
  onDeselect
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={
        <div>
          {label}
          {hasVisibilitySign && (
            <Tooltip title={tooltipText || t('info.will.be.visible')}>
              {infoIcon ? (
                <InfoIcon className="address-info-icon" />
              ) : (
                <Eye className="address-info-icon" />
              )}
            </Tooltip>
          )}
        </div>
      }
      rules={[{ required: required, message: t('field.is.required') }]}>
      <AntSelect
        placeholder={placeholder}
        mode={mode}
        onSelect={onSelect ? onSelect : () => {}}
        onDeselect={onDeselect ? onDeselect : () => {}}>
        {options?.map(({ value, displayValue }, index) => {
          return (
            <Option key={value + index} value={value}>
              {t(`${displayValue}`)}
            </Option>
          );
        })}
      </AntSelect>
    </Form.Item>
  );
};

export default memo(Select);
