import React from 'react';
import cls from 'classnames';
import css from './Col.module.css';

export const Col = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={cls(css.container, className)} {...props}>
      {children}
    </div>
  );
});
