import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SIGN_IN } from 'constants/client';

const PrivateRoute = ({ component: Component, user, isHome, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          Layout ? (
            <Layout isHome={isHome}>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={SIGN_IN} />
        );
      }}
    />
  );
};

export default PrivateRoute;
