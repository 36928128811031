import { getVetsAdminQuery } from 'gql';
import { useQuery } from '@apollo/client';

export const useClinicVets = ({ order, search, skip } = { search: '' }) => {
  const { data, ...rest } = useQuery(getVetsAdminQuery, {
    variables: {
      search,
      order
    },
    skip
  });
  return { data: data?.getVets, ...rest };
};
