export const helb = {
  common: {
    hello: 'שלום',
    'welcome.to.kumba': 'ברוכים הבאים לקומבה',
    'already.have.account?': 'יש לכם כבר חשבון?',
    'sign.in': 'התחברו',
    'sign.up.for.free': 'הירשמו בחינם',
    'phone.input.placeholder': 'מספר טלפון',
    'pet.name.input.placeholder': 'שם בע"ח*',
    'pet.type.input.placeholder': 'סוג בע"ח',
    'pet.gender.input.placeholder': 'מין בע"ח*',
    'pet.chip.number.input.placeholder': 'מספר שבב',
    'pet.medical.alerts.input.placeholder': 'התראות רפואיות',
    'pet.breed.input.placeholder': 'גזע',
    'pet.fur.color.placeholder': 'בחר צבע פרווה',
    'pet.food.type.input.placeholder': 'סוג מזון',
    'pet.weight.input.placeholder': 'משקל בע"ח',
    'pet.birthday.input.placeholder': 'תאריך לידה (משוער)*',
    'pet.avatar.input.placeholder': 'העלה תמונת בע"ח',
    'pet.behavioral.notes.input.placeholder': 'הערות התנהגות',
    'pet.sterilized.input.placeholder': 'מעוקרת/מסורס?',
    'go.back.button': 'חזרה',
    'advanced.information': 'פרטים נוספים',
    allergy: 'אלרגיה',
    'add.allergy': 'הוסף אלרגיה',
    continue: 'המשך',
    'get.code': 'שלחו לי קוד לנייד',
    'lets.get.started': 'בואו נתחיל',
    'add.your.pet': 'הוסף בע"ח חדש',
    'fillout.pet.information.registration':
      'אנו צריכים מספר פרטים אודות הכלב או החתול שלכם כדי שנוכל להתאים את התוכנית הטובה ביותר עבורם',
    'do.you.want.to.add.more.pets': 'תרצו להוסיף בעל חיים נוסף?',
    'are.you.sure.remove.pet': 'האם אתה בטוח שברצונך להסיר את',
    remove: 'הסר',
    yes: 'כן',
    no: 'לא',
    'phone.input.search.placeholder': 'חפש מדינות',
    'enter.your.phone.number': 'כדי לגשת לחשבון שלך יש להזין מספר טלפון אליו ישלח קוד אימות',
    'dont.have.account?': 'אין לכם עדיין חשבון?',
    'resend.new.code': 'שליחת קוד חדש',
    resend: 'שלח שוב',
    'verify.code': 'אימות קוד',
    'code.verification': 'אימות קוד',
    'enter.6.digit.code': 'אנא הזינו את הקוד בן 6 הספרות שנשלח למספר',
    'get.started': 'בואו נתחיל!',
    'fill.personal.info':
      'אנא הזינו כתובת מלאה כדי שנדע לאן להגיע (הכתובת שלכם גלויה אך ורק לוטרינר/ית)',
    'verification.code.sent': 'קוד האימות נשלח לטלפון שלך. תקף לשעה הקרובה',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    'phone.number': 'מספר טלפון*',
    'phone.number.not.required': 'מספר טלפון',
    email: 'דוא"ל',
    'email.tooltip.text': 'נדרשת כתובת דואר אלקטרוני/אימייל לטובת אימות החשבון',
    floor: 'קומה*',
    apartment: 'דירה*',
    comment: 'הערות לגביי הכתובת',
    country: 'מדינה',
    city: 'עיר',
    address: 'כתובת',
    'postal.code': 'מיקוד',
    'edit.pet': 'ערוך בע"ח',
    'remove.pet': 'הסרת בע"ח',
    'here.is.list.of.your.pets': 'כאן תוכלו להוסיף עוד בעלי חיים או לערוך את הפרטים שלהם',
    'my.pets': 'בעלי-החיים שלי',
    'add.new.pet': 'הוסף בע"ח חדש',
    cancel: 'ביטול',
    'edit.info.of.your.pet': 'ערוך מידע על בע"ח שלך',
    january: 'ינואר',
    february: 'פברואר',
    march: 'מרץ',
    april: 'אפריל',
    may: 'מאי',
    june: 'יוני',
    july: 'יולי',
    august: 'אוגוסט',
    september: 'ספטמבר',
    october: 'אוקטובר',
    november: 'נובמבר',
    december: 'דצמבר',
    calendar: 'לוח שנה',
    'your.conversations': "צ'אט",
    'search.placeholder': 'חפש ...',
    pets: 'בעלי-החיים שלי',
    home: 'בית',
    chat: "צ'אט",
    'chat.info': 'קבצים ששותפו',
    finance: 'כספים',
    settings: 'הגדרות',
    help: 'עזרה',
    profile: 'פרופיל',
    'log.out': 'יציאה',
    'help.center': 'Help center',
    'search.for': 'חפש',
    conversations: "צ'אט",
    'write.your.message': 'כתוב את ההודעה שלך ...',
    'read.only': 'לקריאה בלבד',
    'read.only.description': 'כדי לשלוח הודעה, עליך להפעיל תוכנית עם הווטרינר הזה',
    'search.in.conversations': "חפש בצ'אט",
    'no.media': 'אין מדיה משותפת',
    'no.files': 'אין קבצים משותפים',
    'shared.media': 'מדיה משותפת ({{count}})',
    'shared.files': 'קבצים משותפים ({{count}})',
    'no.messages.yet': 'אין עדיין הודעות',
    'choose.subscription.plan.and.chat.with.your.vet':
      "קנה מנוי עוד היום ותוכל להיוועץ עם הוטרינר שלך בצ'אט אונליין",
    'choose.plan': 'בחר תוכנית',
    'order.service': 'הצטרפו כחברי להקה!',
    'select.a.plan.than.works.for.you': 'הירשמו לקומבה או הזמינו ביקור חד פעמי',
    'yearly.membership': 'מנוי',
    'one.time.visit': 'ביקור חד פעמי',
    'access.to.all.our.services':
      'ביקורי בית – חיסונים, טיפולים מונעים ובדיקות שגרתיות אצלכם בסלון',
    'veterinarian.service': "צ'אט – התייעצו אונליין עם הוטרינר/ית שלכם ללא הגבלה",
    'free.consultation': 'הפניה למרפאות – בדיקה ללא עלות במרפאות השותפות שלנו',
    'order.now': 'הזמן עכשיו',
    'not.find.slot': 'מחפש זמן אחר?',
    'get.discount': 'ברכישה למספר בע"ח תקבל <1>{{getDiscount}}</1> בעת התשלום',
    congratulation: 'כבוד לבעלי מספר בע"ח!',
    'select.more.pets': 'הוספת בעלי חיים',
    'continue.anyway': 'המשך',
    'select.pets': 'בחירת בעל/י חיים',
    'choose.your.pets.to.continue': 'סמנו את בעלי החיים עבורם תרצו לרכוש מנוי',
    'membership.details': 'פרטי המנוי',
    'check.details.of.membership.before.continue': 'בדוק את פרטי החברות לפני שתמשיך',
    basic: 'כלב בוגר',
    change: 'שינוי',
    'selected.plan': 'תוכנית שנבחרה',
    'total.price': 'מחיר כולל',
    price: 'מחיר',
    'you.ve.got': 'אתה זכאי ל',
    discount: 'הנחה',
    'info.text':
      'זו התוכנית המומלצת ע" פ סוג, גיל והיסטוריית החיסונים של בעל החיים שלך ניתן לשנות תוכנית ע"י לחיצה על כפתור העריכה',
    checkout: 'סיום',
    'personal.info': 'מידע אישי',
    'keep.me.update.on.news.and.exclusive.offers': 'עדכן אותי לגבי חדשות ומבצעים בלעדיים',
    'add.pets.from.list': 'הוסף בע"ח מהרשימה',
    'selected.pets': 'בעלי-חיים שנבחרו',
    vet: 'וטרינר',
    vets: 'וטרינרים',
    'checkout.details': 'פרטי תשלום',
    'visit.details': 'פרטי ביקור',
    name: 'שם',
    type: 'סוג',
    gender: 'מין',
    age: 'גיל',
    'select.a.pet': 'בחירת בעל חיים',
    'select.a.vet': 'בחירת וטרינר',
    'choose.date.of.visit': 'בחר תאריך ביקור',
    'time.slots.for.date': 'משבצות זמן לתאריך',
    save: 'שמור',
    back: 'חזור',
    'choose.vet.and.a.time': 'בחר וטרינר וחלון זמן שנוח לך',
    'choose.vet.and.continue': 'ניתן לצפות בתורים גם בתצוגת לוח-שנה עבור כל וטרינר',
    'select.all': 'בחר הכל',
    'deselect.all': 'בטל בחירה',
    'fully.vaccinated': 'האם קיבל/ה יותר מחיסון אחד?*',
    'choose.plan.for.specific.pet': "אם פוצ'י הוא גור/ה יש לסמן גם את חבילת הגורים",
    'code.sent': 'הקוד נשלח',
    'invalid.file': 'סוג קובץ לא חוקי',
    'you.can.only.upload.images': 'ניתן להעלות רק תמונות',
    'image.must.be.smaller.than.10.mb': 'על התמונה להיות קטנה מ10MB',
    'you.will.be.able.to.resend.code.in': 'ניתן לקבל קוד חדש בעוד',
    'please.provide.right.code': 'הקוד אינו תקין. אנא נסו שוב',
    notifications: 'הודעות',
    'mark.all.as.read': 'סמן הכל כנקרא',
    today: 'היום',
    instructions: 'סיכום',
    earlier: 'מוקדם יותר',
    timeline: 'ציר זמן',
    'filter.by': 'סנן לפי',
    'all.years': 'כל השנים',
    'hey.you.are.here.now': 'היסטוריית ביקורים',
    'new.pet': 'הוספת בע"ח',
    'book.extra.visit': 'הזמנת ביקור נוסף',
    subscribe: 'רכישת מנוי',
    'no.meetings.yet': 'אין עדיין ביקורים',
    'choose.subscription.plan.or.schedule.a.visit': 'רכוש מנוי או הזמן ביקור',
    cta: 'רכישות מנוי',
    'extra.costs': 'עלויות נוספות',
    with: 'ע"י',
    missed: 'התפספס',
    'vet.info': 'פרטי וטרינר',
    biography: 'עליי',
    'weekly.time.slots': 'שעות עבודה',
    apply: 'המשך',
    'choose.time.slot.first': 'בחר חלון זמן',
    'covered.by.wellness.plan': 'כלול במנוי',
    'extra.visit': 'ביקור נוסף',
    'the.visit.is.too.soon':
      'תאריך הביקור קרוב מדי, לכן לא ניתן להזיזו. להזזה נסה ליצור קשר עם הוטרינר שלך',
    'reschedule.event': 'הזז את תאריך הביקור',
    'user.comment': 'הערות לוטרינר',
    'user.address': 'כתובת',
    'visit.information': 'פרטי ביקור',
    vaccines: 'חיסונים',
    'welcome.back': 'ברוך שובך',
    'medications.products.given': 'תרופות/מוצרים שניתנו',
    files: 'קבצים',
    description: 'תיאור',
    'given.to': 'ניתן ל',
    qty: 'כמות',
    total: 'סה"כ',
    'download.all': 'הורד הכל',
    'download.receipt': 'הורד קבלה',
    'you.dont.have.upcoming.meetings': 'אין ביקורים קרובים',
    'you.dont.have.past.meetings': 'אין ביקורים קודמים',
    'download.medical.record': 'הורד תיק רפואי',
    'wellness.plan': 'סוג מנוי',
    'profile.completed.on': 'הפרופיל שלם בכ-',
    weight: 'משקל',
    breed: 'גזע',
    'chip.number': 'מספר שבב',
    'copied.to.clipboard': 'הועתק',
    'spray.neuter': 'מעוקר / מסורס',
    'food.type': 'סוג מזון',
    'medical.alerts': 'התראות רפואיות',
    allergies: 'אלרגיות',
    note: 'הערות לגביי התנהגות',
    'book.a.visit': 'הזמן ביקור',
    'profile.details': 'פרטי משתמש',
    'deleting.your.pet.will.remove.all.pets.data.permanently.this.cannot.be.undone':
      'מחיקת בע"ח תסיר את כל הנתונים שלו/ה לצמיתות, לא ניתן יהיה לבטל פעולה זאת.',
    delete: 'מחק',
    confirm: 'אישור',
    'payment.method': 'אמצעי תשלום',
    dr: 'ד"ר',
    'client.name': 'שם הלקוח',
    'medical.file.for': 'תיק רפואי עבור',
    veterinarian: 'וטרינר',
    summary: 'סיכום',
    'kumba.application.ltd': 'קומבה אפיקיישן בע"מ',
    date: 'תאריך',
    'choose.pet': 'בחר בע"ח',
    'you.will.get': 'בעת התשלום תקבל',
    'on.second.pet.membership': 'עבור רכישה למספר בעלי-חיים',
    'selected.vet': 'וטרינר',
    'by.creating.account': 'הנני מסכים כי יצירת חשבון מהווה הסכמה עם',
    'terms.of.service': 'תנאי השירות',
    and: 'ו',
    'privacy.policy': 'מדיניות הפרטיות',
    visit: 'ביקור',
    'we.cant.give.you.service.for.now': 'מצטערים',
    'address.that.you.put.is.not.in.our.working.area': 'אין וטרינר שעובד באיזור מגורך',
    'we.are.growing.fast.and.will.become.available.in.more.regions.soon':
      'אנחנו גדלים מהר ונהיה זמינים באזורים נוספים בקרוב.',
    street: 'רחוב',
    'building.number': 'מספר בניין',
    'zip.code.for.us': 'מיקוד (ארה"ב בלבד)',
    close: 'סגור',
    ok: 'אוקיי',
    'home.visit': 'ביקור בית',
    'one.time.payment': 'תשלום חד-פעמי',
    'additional.one.time.payment': 'תוספת חד-פעמית',
    'puppies.need.procedures': 'גורים זקוקים למספר טיפולים ותשומת לב רבים יותר מהוטרינר',
    'kittens.need.procedures': 'גורים זקוקים למספר טיפולים ותשומת לב רבים יותר מהוטרינר',
    'additional.payment.info': 'זוהי תוספת תשלום חד-פעמית',
    processing: 'המתן',
    completed: 'הושלם',
    failed: 'נכשל. נסה שוב',
    new: 'חדש',
    'card.number': 'מספר כרטיס',
    cvc: 'cvv',
    'expiry.date.mm.yy': 'תאריך תפוגה MM/YY',
    year: 'שנה',
    'years.of.experience': '{{count}} שנות ניסיון',
    'the.appointments.rescheduled.later': 'ניתן לשנות את מועד התור מאוחר יותר',
    'price.per.month': 'תשלום חודשי',
    month: 'חודש',
    'per.month': 'חודש',
    'pay.now': 'שלם עכשיו',
    instuctions: 'סיכום',
    done: 'סיים',
    'add.comment.optional': 'הוסף הערה (אופציונלי)',
    'skip.payments': 'דלג על התשלום',
    'format.not.supported': 'פורמט זה איננו נתמך',
    'you.can.leave.comment': 'כתוב הערה כאן',
    clients: 'לקוחות',
    routes: 'סבבים',
    'home.address': 'כתובת ביתך',
    'start.point': 'כתובת תחילתל סבבים',
    'end.point': 'כתובת לסיום סבבים',
    upload: 'העלה',
    'first.name': 'שם פרטי',
    last_name: 'שם משפחה',
    social_number: 'מספר זהות',
    status: 'סטטוס:',
    active: 'זמין ללקוחות חדשים',
    inactive: 'משתמש כבוי',
    occupied: 'לא פנוי ללקוחות חדשים',
    license_number: 'מספר רישיון',
    main_commute_method: 'כלי תחבורה',
    car: 'רכב',
    bike: 'אופנוע',
    bicycle: 'אופניים',
    walk: 'הליכה',
    experience: 'שנות ניסיון',
    mobile_number: 'טלפון נייד',
    select_timezone: 'אזור זמן',
    building_number: 'מספר בית',
    zipcode: 'מיקוד (ארה"ב בלבד)',
    bank_code: "מס' בנק",
    bank_branch: "מס' סניף",
    swift: 'SWIFT BIC',
    bank_name: 'שם הבנק',
    bank_account: "מס' חשבון בנק",
    short_biography: 'תיאור קצר על עצמך',
    create_vendor: 'צור משתמש לסליקה',
    payment_provider: 'ספק תשלומים',
    seller_code: "מס' עוסק מורשה",
    social_issue_date: 'תאריך הנפקת ת.ז',
    bank_account_class: 'Bank Account Class',
    bank_account_type: 'סוג חשבון בנק',
    account_id: 'Account ID',
    bank_state: 'Bank State',
    bank_city: 'Bank City',
    'birth.date': 'תאריך לידה',
    male: 'זכר',
    female: 'נקבה',
    'check.address': "בדוק שדה 'כתובת'",
    submit: 'סיום',
    confirm_delete_timeslot: 'האם אתה בטוח שברצונך למחוק חלון זמן זה?',
    'financial-data': 'מידע פיננסי',
    inventory_default_setup: 'הגדרת ציוד למכירה בבית הלקוח',
    'non-working_areas': 'אזורים בהם אינך מעוניין לעבוד',
    'working.days': 'ימי/שעות עבודה',
    cards: 'Cards',
    active_subscriptions: 'מנויים פעילים',
    'updated.successfully': 'עודכן בהצלחה',
    'failed.to.update': 'אופס. משהו לא הצליח'
  }
};
