import React, { useState, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { getInitialLetters } from 'utils/helpers.js';

import ChooseAnotherVetModal from 'components/choose-another-vet-modal/ChooseAnotherVetModal.jsx';

import * as S from './components';
import theme from 'styles/theme';
import AuthContext from '../../../contexts/AuthContext';

const CalendarVet = ({ selectedVet, loading, isBooking, vets, setCurrentVet }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowingMore, setIsShowineMore] = useState(false);

  const shortenedBio = selectedVet?.user?.bio?.substring(0, 160);

  return (
    <S.VetInfoWrapper isBooking={isBooking}>
      {loading ? (
        <Skeleton.Avatar size="large" />
      ) : (
        <S.VetAvatarWrapper>
          {selectedVet?.user?.avatarUrl ? (
            <S.VetAvatar src={selectedVet?.user?.avatarUrl} />
          ) : (
            <S.VetInitials>
              {getInitialLetters(null, selectedVet?.user?.firstName, selectedVet?.user?.lastName)}
            </S.VetInitials>
          )}
        </S.VetAvatarWrapper>
      )}

      {loading ? (
        <Skeleton paragraph={{ rows: 3 }} />
      ) : (
        <>
          <S.VetName>{`${selectedVet?.user?.firstName} ${selectedVet?.user?.lastName}`}</S.VetName>
          {selectedVet?.experience && (
            <S.Experience>
              {selectedVet?.experience} {t('years.of.experience')}
            </S.Experience>
          )}
          <S.Languages>
            {selectedVet?.languages?.map((language) => (
              <S.Language key={language.uid}>{language?.name}</S.Language>
            ))}
          </S.Languages>
          {selectedVet?.user?.bio && (
            <>
              <S.AboutMeTitle>{t('about.me')}</S.AboutMeTitle>
              <S.AboutMe>
                {isMobile && (
                  <>
                    {isShowingMore || selectedVet?.user?.bio?.length <= 160
                      ? selectedVet?.user?.bio
                      : `${shortenedBio}...`}
                    <span
                      style={{ color: 'orange' }}
                      onClick={() => setIsShowineMore((isShowMore) => !isShowMore)}>
                      {selectedVet?.user?.bio?.length > 160 &&
                        (isShowingMore > 160 ? ' Show less' : ' Show more')}
                    </span>
                  </>
                )}
                {!isMobile && selectedVet?.user?.bio}
              </S.AboutMe>
            </>
          )}

          {user && isBooking && vets && !!vets?.length && vets?.length > 1 && (
            <S.ChooseAnotherVet onClick={() => setIsModalOpen(true)}>
              {t('choose.another.vet')}
            </S.ChooseAnotherVet>
          )}
        </>
      )}

      {isModalOpen && (
        <ChooseAnotherVetModal
          isOpen={true}
          onRequestClose={() => setIsModalOpen(false)}
          isSelectedVet={selectedVet}
          vets={vets}
          setCurrentVet={setCurrentVet}
        />
      )}
    </S.VetInfoWrapper>
  );
};

export default memo(CalendarVet);
