import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/client';

export const usePims = (variables = {}, options = {}) => {
  const { data, ...rest } = useQuery(query, {
    variables,
    fetchPolicy: 'no-cache',
    ...options
  });
  return { data: data?.pims, ...rest };
};

const query = gql`
  query pims {
    pims {
      uid
      name
      template
      createdAt
      updatedAt
    }
  }
`;
