import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { petAvatars } from 'constants/pet';

import Selector from 'components/common/selector/Selector';
import Checkbox from 'components/checkbox/Checkbox';
import FilterModal from './filter-modal/FilterModal';

import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import timelineIcon from 'assets/icons/timeline-icon.svg';

import * as S from './components';
import { useLocalizationContext } from 'common_repo';

const TimelineFilter = ({
  petsData,
  year,
  setYear,
  setPetsData,
  setSelectedPets,
  selectedPets,
  YEAR_FILTER_DATA
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { rtl: isRtl } = useLocalizationContext();

  const { t } = useTranslation();

  const handleChangeSelectedPets = (selectedPet) => {
    if (selectedPets.findIndex((p) => p.uid === selectedPet.uid) >= 0) {
      setSelectedPets([...selectedPets.filter((pet) => pet.uid !== selectedPet.uid)]);
      setPetsData([
        ...petsData.map((pet) => {
          return pet.uid === selectedPet.uid
            ? {
                ...pet,
                isChecked: false
              }
            : pet;
        })
      ]);
    } else {
      setSelectedPets([selectedPet, ...selectedPets]);
      setPetsData([
        ...petsData.map((pet) => {
          return pet.uid === selectedPet.uid
            ? {
                ...pet,
                isChecked: true
              }
            : pet;
        })
      ]);
    }
  };

  const formatPetOption = (props) => {
    const { isChecked, type, name } = props;

    return (
      <S.OptionWrapper onClick={() => handleChangeSelectedPets(props)}>
        <S.Avatar src={petAvatars[type?.name]} />
        <S.OptionLabelBox>
          <S.OptionName>{name}</S.OptionName>
          <S.CheckboxMobile>
            <Checkbox isChecked={isChecked} isForSelect={true} handleChange={() => {}} />
          </S.CheckboxMobile>
        </S.OptionLabelBox>
      </S.OptionWrapper>
    );
  };

  const MultiValueContainer = (props) => {
    const { name, type } = props?.data;
    return (
      <components.MultiValueContainer>
        <S.Value onClick={() => handleChangeSelectedPets(props?.data)}>
          <S.ValueWrapper>
            <S.Avatar src={petAvatars[type?.name]} />
            <S.ValueLabelBox>{name}</S.ValueLabelBox>
          </S.ValueWrapper>
        </S.Value>
      </components.MultiValueContainer>
    );
  };

  return (
    <S.Wrapper>
      <FilterModal
        isOpen={isFilterModalOpen}
        handleChangeYear={setYear}
        handleChangeSelectedPets={handleChangeSelectedPets}
        handleCloseModal={() => setIsFilterModalOpen(false)}
        YEAR_FILTER_DATA={YEAR_FILTER_DATA}
        pets={petsData}
        year={year}
        selectedPets={selectedPets}
        MultiValueContainer={MultiValueContainer}
        formatPetOption={formatPetOption}
      />
      <S.Flex>
        <S.TimelineIcon src={timelineIcon} alt="timeline" />
        <S.TimelineHeaderTitle>{t('timeline')}</S.TimelineHeaderTitle>
      </S.Flex>
      <S.TimelineFilterWrapper>
        <S.FilterByText>{t('filter.by')}</S.FilterByText>
        <S.YearSelectorWrapper>
          <Selector
            hideLabel
            onChange={setYear}
            options={YEAR_FILTER_DATA}
            value={year}
            isFilter
            isRtl={isRtl}
            getOptionLabel={(item) => t(item.label)}
          />
        </S.YearSelectorWrapper>
        <S.PetSelectorWrapper>
          <Selector
            placeholder={t('choose.pet')}
            value={selectedPets}
            options={petsData}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            hideLabel
            animateLabel={false}
            isClearable={false}
            isMulti
            formatOptionLabel={formatPetOption}
            valueContainer={{ MultiValueContainer }}
            isFilter
            isRtl={isRtl}
            customStyles={{
              valueContainer: (base) => ({
                ...base,
                flexWrap: 'no-wrap',
                overflowX: 'hidden'
              })
            }}
          />
        </S.PetSelectorWrapper>
      </S.TimelineFilterWrapper>
      <S.FilterIcon>
        <Filter onClick={() => setIsFilterModalOpen(true)} />
      </S.FilterIcon>
    </S.Wrapper>
  );
};

export default TimelineFilter;
