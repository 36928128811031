import React from 'react';
import cls from 'classnames';
import css from './IntegrationSuccessModal.module.css';
import { Button, cssText, Modal } from '_fsd/shared';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';

export const IntegrationSuccessModal = ({ closeModal, data }) => {
  const { t } = useTranslation();
  const { description } = data;

  return (
    <Modal
      isOpen
      onClose={closeModal}
      force
      onConfirm={closeModal}
      className={css.modalContainer}
      customHeader={() => null}
      customFooter={() => null}>
      <div className={css.container}>
        <CheckCircleOutlined className={css.icon} />
        <span className={cls(css.title, cssText.s16w5l24)}>{t('integration.title_success')}</span>
        <span className={cls(css.description, cssText.s14w4l22)}>{description}</span>
        <Button className={css.button} type="primary" onClick={closeModal}>
          {t('integration.button.ok')}
        </Button>
      </div>
    </Modal>
  );
};
