import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import theme from 'styles/theme';

import AddPetForm from 'components/add-pet-form/AddPetForm';
import Modal from 'components/common/modal/Modal';

import { Wrapper } from './components';

const PetModal = ({ isOpen, uid, handleCloseModal, isEdit }) => {
  const [advancedInfoOpen, setAdvancedInfoOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  const isMediumHeight = useMediaQuery({
    query: `(max-height: ${theme.screenHeights.laptopHeightSm}px)`
  });
  const isSmallHeight = useMediaQuery({
    query: `(max-height: ${theme.screenHeights.laptopHeightS}px)`
  });

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Create pet"
      onRequestClose={handleCloseModal}
      scrollable
      advancedInfoOpen={advancedInfoOpen}
      isMobile={isMobile}
      isPetModal
      isMediumHeight={isMediumHeight}
      isSmallHeight={isSmallHeight}>
      <Wrapper>
        <AddPetForm
          isEdit={isEdit}
          uid={uid}
          advancedInfoOpen={advancedInfoOpen}
          setAdvancedInfoOpen={setAdvancedInfoOpen}
          isModal
          handleCloseModal={handleCloseModal}
        />
      </Wrapper>
    </Modal>
  );
};

export default PetModal;
