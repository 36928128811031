import styled, { css } from 'styled-components';

import Checkmark from '../../../assets/icons/checkmark.svg';
import Search from '../../../assets/icons/search.svg';

export const Container = styled.div`
  width: 100%;
  & input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
  }

  .phone-input {
    color: #323b4b !important;
    font-family: inter, assistant;
    font-weight: 500;
    font-size: 1.6rem;
    border-radius: 0.8rem;
    transition: none !important;
    width: 100% !important;
    border-radius: 0.8rem;
    border-color: ${({ theme }) => theme.colors.gray};
    outline: none;
    height: 4.8rem;
    ${({ isForVet }) =>
      isForVet &&
      css`
        padding: 0.4rem 1.1rem;
        height: 3.3rem;
        font-size: 1.4rem;
      `}
    ${({ isPublic }) =>
      isPublic &&
      css`
        padding: 0.4rem 1.1rem;
        height: 3.2rem;
        font-size: 1.4rem;
        border-radius: 0.2rem;
        border-color: #d9d9d9;
      `}
    ${({ dropDownOpen }) =>
      dropDownOpen &&
      css`
        border-radius: 0.8rem 0.8rem 0 0;
        border-color: #ff9a05;
      `}
    :hover {
      border-color: ${({ theme }) => theme.colors.gray};
    }
    :focus {
      border-color: #ff9a05;
      box-shadow: none;
    }
    ${({ error }) =>
      error &&
      css`
        color: ${({ theme }) => theme.colors.errorRed} !important;
        border-color: ${({ theme }) => theme.colors.errorRed} !important;
      `}
  }

  .react-tel-input .selected-flag .arrow {
    top: 52% !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #555;
  }
  .react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 7px solid #555;
  }

  .special-label {
    font-size: 1.4rem;
    color: #b0b7c3;
    font-family: inter, assistant;
    font-weight: 500;
    ${({ hideLabel }) =>
      hideLabel &&
      css`
        display: none;
      `}
  }

  .country-list {
    margin: 0 !important;
    border-radius: 0 !important;
    z-index: 5;
  }

  .country-list > .highlight::after {
    position: absolute;
    content: '';
    background-image: url(${Checkmark});
    width: 2.4rem;
    height: 2.4rem;
    right: 2.7rem;
    top: 1.4rem;
  }

  .country-list > .search.search-class {
    position: sticky;
    top: 0;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray};
    padding-left: 5.3rem;
  }
  .country-list > .search.search-class::before {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    content: '';
    background-image: url(${Search});
    left: 1.3rem;
    top: 1.2rem;
  }

  .react-tel-input {
    z-index: 5;
    ${({ isRtl }) =>
      isRtl &&
      css`
        direction: rtl;
      `}
  }

  .selected-flag {
    padding: 0;
    padding-left: 11px !important;
    ${({ isRtl }) =>
      isRtl &&
      css`
        padding-left: unset !important;
        padding-right: 11px !important;
      `}
  }
  .selected-flag:hover {
    background-color: unset;
  }
  .react-tel-input .country-list {
    max-height: 21rem !important;
  }
  .react-tel-input .country-list .country {
    padding: 1.2rem 0;
    padding-left: 4.6rem !important;
    padding-right: 0.9rem !important;
    width: 100%;
  }
  .react-tel-input .country-list .flag {
    left: 13px !important;
  }

  .react-tel-input .selected-flag .arrow {
    left: 29px !important;
  }
  .react-tel-input .form-control {
    padding: 1.55rem;
    padding-right: 1.4rem;
    padding-left: 5.8rem;
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #f3f5f8 !important;
      `}
    ${({ isRtl }) =>
      isRtl &&
      css`
        padding-left: 1.4rem;
        padding-right: 5.8rem;
      `}
    ${({ isForVet }) =>
      isForVet &&
      css`
        padding: 0;
        padding-right: 1.4rem;
        padding-left: 5.8rem;
      `}
  }
  .react-tel-input .search-box {
    border: none !important;
  }
  .react-tel-input .search-box:hover {
    border: none;
  }

  .country-list .country:hover {
    background-color: #fbfcfd !important;
  }
  .country-list .country {
    border: 0.1rem solid ${({ theme }) => theme.colors.gray};
    border-top: none;
    border-left: none;
    border-right: none;
    overflow: hidden;
    height: 5.8rem;
    align-items: center;
    display: flex;
  }
  .country-list .country:last-child {
    border-bottom: none;
  }
  .country-list > .highlight {
    position: relative !important;
    border: 0.1rem solid #ff9a05;
    background-color: #fff !important;
  }
  .country-list::-webkit-scrollbar {
    display: none !important;
  }
  .country-list {
    scrollbar-width: none;

    border: 0.1rem solid ${({ theme }) => theme.colors.gray};
    border-radius: 0 0 0.8rem 0.8rem !important;
    width: 100% !important;
  }
  .react-tel-input .country-list .flag {
    top: 1.6rem;
  }
  .react-tel-input .flag-dropdown {
    width: ${({ dropDownOpen }) => (dropDownOpen ? '100%' : '0')};
    border: none;
  }

  .flag-dropdown.open {
    border: 1px solid #ff9a05;
    border-radius: 0.8rem 0.8rem 0rem 0rem;
  }

  .selected-flag.open {
    margin-left: 0.2rem;
  }
`;
