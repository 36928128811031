import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

import Input from 'components/common/input/Input';

import { ReactComponent as Info } from 'assets/icons/info.svg';

import * as S from './components';

const AddressAutocomplete = ({
  address,
  onSelect,
  error,
  disabled = false,
  isForVet,
  isForZipCode,
  isForCalendar,
  isForCompleteProfile,
  isAutoFocus = true
}) => {
  const [addr, setAddr] = useState(address?.description);
  const { t } = useTranslation();

  const handleSetPlaceId = (placeId) => {
    geocodeByPlaceId(placeId).then((res) => {
      getLatLng(res[0]).then(({ lat, lng }) => {
        const newAddress = {
          countryCode: '',
          city: '',
          street: '',
          houseNumber: '',
          zipCode: '',
          description: addr,
          lat: lat,
          lng: lng
        };
        if (isForZipCode) {
          newAddress.state = '';
        }

        for (const component of res[0].address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number': {
              newAddress.houseNumber = component.short_name;
              break;
            }
            case 'route': {
              newAddress.street = component.long_name;
              break;
            }
            case 'postal_code': {
              newAddress.zipCode = component.long_name;
              break;
            }
            case 'political': {
              newAddress.city = component.long_name;
              break;
            }
            case 'locality':
              newAddress.city = component.long_name;
              break;
            case 'country':
              newAddress.countryCode = component.short_name;
              break;
            case 'administrative_area_level_1':
              if (isForZipCode) newAddress.state = component.long_name;
              break;
            default: {
              break;
            }
          }
        }

        onSelect({ ...newAddress, placeId });
      });
    });
  };

  useEffect(() => {
    geocodeByAddress(addr).then((res) => {
      const placeId = res?.[0]?.place_id;
      handleSetPlaceId(placeId);
    });
  }, [addr]);

  return (
    <PlacesAutocomplete value={addr} debounce={300} onChange={setAddr} onSelect={setAddr}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <S.Wrapper>
          <Input
            autoFocus={isAutoFocus}
            id="addressInput"
            {...getInputProps({
              label: t('complete_profile_form.working_area'),
              flatBottom: !!suggestions.length,
              error,
              disabled,
              isForVet
            })}
            placeholder={!isAutoFocus ? 'E.g. 123 Main St., New York, NY 12345, US ' : null}
          />
          <S.CheckModal isForCalendar={isForCalendar} isForVet={isForVet}>
            <a data-tip data-for={'a'}>
              <S.AddressInfo>
                <Info fill={error ? '#E44E61' : '#8A94A6'} />
              </S.AddressInfo>
            </a>
            <ReactTooltip
              id={'a'}
              type="warning"
              backgroundColor="#323B4B"
              textColor="#fff"
              effect="solid"
              place={'top'}
              overridePosition={(place) => place}>
              {!isForCompleteProfile ? (
                <S.AddressCheckList>
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.houseNumber} isBlue={!error} />
                    {t('building.number')}
                  </S.AddressCheckItem>
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.street} isBlue={!error} />
                    {t('street')}
                  </S.AddressCheckItem>
                  {!isForZipCode && (
                    <S.AddressCheckItem>
                      <S.Dot missing={!address?.city} isBlue={!error} />
                      {t('city')}
                    </S.AddressCheckItem>
                  )}
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.countryCode} isBlue={!error} />
                    {t('country')}
                  </S.AddressCheckItem>
                  {!isForZipCode && (
                    <S.AddressCheckItem>
                      <S.Dot missing={!address?.state} isBlue={!error} />
                      {t('state')}
                    </S.AddressCheckItem>
                  )}
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.zipCode} isBlue={!error} />
                    {t('zip.code.for.us')}
                  </S.AddressCheckItem>
                </S.AddressCheckList>
              ) : (
                <S.AddressCheckList>
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.lat} isBlue={!error} />
                    {t('latitude')}
                  </S.AddressCheckItem>
                  <S.AddressCheckItem>
                    <S.Dot missing={!address?.lng} isBlue={!error} />
                    {t('longitude')}
                  </S.AddressCheckItem>
                </S.AddressCheckList>
              )}
            </ReactTooltip>
          </S.CheckModal>
          <S.DropDownContainer>
            {suggestions.map((suggestion) => {
              return (
                <S.Option key={suggestion.placeId} {...getSuggestionItemProps(suggestion)}>
                  <S.AddressDescription>{suggestion.description}</S.AddressDescription>
                </S.Option>
              );
            })}
          </S.DropDownContainer>
        </S.Wrapper>
      )}
    </PlacesAutocomplete>
  );
};

export default memo(AddressAutocomplete);
