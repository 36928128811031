import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './components';
import Checkbox from './Checkbox';
import MembershipTableRow from './membership-table-row/MembershipTableRow';
import { useQuery } from '@apollo/react-hooks';
import { getPetsByOwnerQuery } from 'gql';

const MembershipPetList = ({ data, selectedPets, setSelectedPets }) => {
  const { t } = useTranslation();

  const allCheckboxHandler = () => {
    !!Object.keys(selectedPets).length
      ? setSelectedPets({})
      : setSelectedPets(data.reduce((acc, key) => ((acc[key.uid] = true), acc), {}));
  };

  const { data: allPets } = useQuery(getPetsByOwnerQuery);
  const allPetsQuantity = allPets?.getPetsByOwner && Object.keys(allPets.getPetsByOwner).length;

  return (
    <S.Table>
      <S.Thead>
        <S.HeaderRow>
          <S.CheckboxAndNameWrapper>
            <S.RespSelectAllWrapper onClick={allCheckboxHandler}>
              <S.CheckboxWrapper>
                <Checkbox hasMinusSign={!!Object.keys(selectedPets).length} />
              </S.CheckboxWrapper>

              <S.SelectAllTextWrapper>
                {Object.keys(selectedPets).length === allPetsQuantity
                  ? t('deselect.all')
                  : t('select.all')}
              </S.SelectAllTextWrapper>
            </S.RespSelectAllWrapper>
            <S.NameHeaderWrapper>{t('name')}</S.NameHeaderWrapper>
          </S.CheckboxAndNameWrapper>
          <S.TypeWrapper>{t('type')}</S.TypeWrapper>
          <S.GenderWrapper>{t('gender')}</S.GenderWrapper>
          <S.AgeWrapper>{t('age')}</S.AgeWrapper>{' '}
        </S.HeaderRow>
      </S.Thead>

      <S.Tbody>
        {data.map(({ name, type, gender, birthDate, uid, avatarUrl }) => {
          return (
            <MembershipTableRow
              key={uid}
              uid={uid}
              name={name}
              type={type}
              gender={gender}
              birthDate={birthDate}
              avatarUrl={avatarUrl}
              selectedPets={selectedPets}
              setSelectedPets={setSelectedPets}
            />
          );
        })}
      </S.Tbody>
    </S.Table>
  );
};

export default MembershipPetList;
