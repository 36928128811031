import React, { useEffect, useMemo } from 'react';
import cls from 'classnames';
import text from 'styles/text.module.css';
import color from 'styles/colors.module.css';
import css from './PetEdit.module.css';
import { useFormik } from 'formik';
import { Input, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { getPetTypesQuery } from '../../../gql';
import { getOptions } from '../../../utils/helpers';
import { Button, ButtonColors, FormItem, Label } from '_fsd/shared';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';

export const petYup = (isVet) =>
  Yup.object({
    name: Yup.string().required('forms.pet.name'),
    type: Yup.object({
      value: isVet ? Yup.string() : Yup.string().required('forms.pet.type')
    }).required('forms.pet.type'),
    gender: Yup.object({
      value: isVet ? Yup.string() : Yup.string().required('forms.pet.gender')
    }).required('forms.pet.gender'),
    isSterilized: isVet
      ? Yup.string().oneOf(['unknown', 'neutered', 'no_neutered'])
      : Yup.string()
          .oneOf(['unknown', 'neutered', 'no_neutered'])
          .required('forms.pet.isSterilized'),
    birthDate: Yup.date(),
    year: Yup.number().min(0, 'forms.pet.age'),
    month: Yup.number().min(0, 'forms.pet.age'),
    day: Yup.number().min(0, 'forms.pet.age')
  });
export const petYupForClientList = Yup.object({
  name: Yup.string().required('forms.pet.name'),
  type: Yup.object({
    value: Yup.string().required('forms.pet.type')
  }).required('forms.pet.type'),
  gender: Yup.object({
    value: Yup.string()
  }),
  isSterilized: Yup.string().oneOf(['unknown', 'neutered', 'no_neutered']),
  birthDate: Yup.date(),
  year: Yup.number().min(0, 'forms.pet.age'),
  month: Yup.number().min(0, 'forms.pet.age'),
  day: Yup.number().min(0, 'forms.pet.age')
});

const petSchema = Yup.object({
  pet: petYup(false)
});

const PetEdit = (props) => {
  const { reqMap = {} } = props;
  const { data: typesData } = useQuery(getPetTypesQuery, { fetchPolicy: 'cache-first' });
  const types = useMemo(
    () =>
      typesData?.petTypes ? getOptions(typesData?.petTypes, { value: 'uid', label: 'name' }) : [],
    [typesData]
  );

  const { t } = useTranslation();

  const formik =
    props.formik ||
    useFormik({
      initialValues: {
        _id: uniqueId(),
        pet: {
          _id: uniqueId(),
          name: '',
          type: null,
          gender: null,
          isSterilized: undefined,
          age: null,
          year: '',
          month: '',
          day: ''
        }
      },
      validateOnChange: true,
      validationSchema: petSchema,
      onSubmit: props.onSubmit
    });
  const errors = {
    name: get(formik.touched, 'pet.name') && t(get(formik.errors, 'pet.name')),
    type: get(formik.touched, 'pet.type') && t(get(formik.errors, 'pet.type.value')),
    gender: get(formik.touched, 'pet.gender') && t(get(formik.errors, 'pet.gender.value')),
    isSterilized:
      get(formik.touched, 'pet.isSterilized') && t(get(formik.errors, 'pet.isSterilized')),
    year: get(formik.touched, 'pet.year') && t(get(formik.errors, 'pet.year')),
    month: get(formik.touched, 'pet.month') && t(get(formik.errors, 'pet.month')),
    day: get(formik.touched, 'pet.day') && t(get(formik.errors, 'pet.day'))
  };
  useEffect(() => {
    formik.setFieldValue('pet.update', true);
  }, []);

  return (
    <div className={css.container}>
      <FormItem label={`Enter pet’s name${reqMap.name ? '*' : ''}`} error={errors.name}>
        <Input
          onBlur={formik.handleBlur}
          status={errors.name ? 'error' : undefined}
          name="pet.name"
          value={formik.values.pet.name}
          onChange={formik.handleChange}
        />
      </FormItem>
      <div className={cls(css.row, css.flexColumns)}>
        <FormItem label={`Type${reqMap.name ? '*' : ''}`} error={errors.type}>
          <Select
            onBlur={formik.handleBlur}
            status={errors.type ? 'error' : undefined}
            style={{ width: '100%' }}
            name="pet.type"
            placeholder="Select type"
            value={formik.values.pet.type}
            onChange={(_, option) => formik.setFieldValue('pet.type', option)}
            options={types}
          />
        </FormItem>
        <FormItem label={'Sex'} error={errors.gender}>
          <Select
            onBlur={formik.handleBlur}
            status={errors.gender ? 'error' : undefined}
            style={{ width: '100%' }}
            placeholder="Select sex"
            name="pet.gender"
            value={formik.values.pet.gender}
            options={[{ value: 'male' }, { value: 'female' }]}
            onChange={(_, option) => formik.setFieldValue('pet.gender', option)}
          />
        </FormItem>
      </div>
      <Label>Reproductive status</Label>
      <FormItem error={errors.isSterilized}>
        <div className={cls(css.row, css.columns)}>
          <Button
            colorScheme={ButtonColors.BLUE_LIGHT}
            type={formik.values.pet.isSterilized === 'neutered' ? 'secondary' : 'default'}
            onClick={() => formik.setFieldValue('pet.isSterilized', 'neutered')}>
            Neutered
          </Button>
          <Button
            colorScheme={ButtonColors.BLUE_LIGHT}
            type={formik.values.pet.isSterilized === 'no_neutered' ? 'secondary' : 'default'}
            onClick={() => formik.setFieldValue('pet.isSterilized', 'no_neutered')}>
            Intact
          </Button>
          <Button
            colorScheme={ButtonColors.BLUE_LIGHT}
            type={formik.values.pet.isSterilized === 'unknown' ? 'secondary' : 'default'}
            onClick={() => formik.setFieldValue('pet.isSterilized', 'unknown')}>
            Unknown
          </Button>
        </div>
      </FormItem>

      <div className={cls(css.column)}>
        <Label>
          Age <span className={cls(text.pRegular, color.cGrayB8)}>(Optional)</span>
        </Label>
        <div className={cls(css.row, css.columns)}>
          <FormItem label={'Years'} error={errors.year} labelPosition={'bottomRight'}>
            <Input
              onBlur={formik.handleBlur}
              status={errors.year ? 'error' : undefined}
              type="number"
              name="pet.year"
              value={formik.values.pet.year}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem label={'Months'} error={errors.month} labelPosition={'bottomRight'}>
            <Input
              onBlur={formik.handleBlur}
              status={errors.month ? 'error' : undefined}
              type="number"
              name="pet.month"
              value={formik.values.pet.month}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem label={'Days'} error={errors.day} labelPosition={'bottomRight'}>
            <Input
              onBlur={formik.handleBlur}
              status={errors.day ? 'error' : undefined}
              type="number"
              name="pet.day"
              value={formik.values.pet.day}
              onChange={formik.handleChange}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default PetEdit;
