import React from 'react';
import css from './RemoveAppointmentPetContent.module.css';
import cls from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, ButtonColors } from '_fsd/shared';

const RemoveAppointmentPetContent = ({ appPet, onClose, onRemove }) => (
  <div className={css.column}>
    <div className={css.row}>
      <div className={cls(css.firstColumn, css.modalIcon)}>
        <InfoCircleOutlined />
      </div>
      <span className={css.modalTitle}>Are you sure you want to remove that pet?</span>
    </div>
    <div className={css.row}>
      <div className={css.firstColumn} />
      <span>{`${appPet.pet.name} will be removed from the appointment`}</span>
    </div>
    <div className={cls(css.row, css.modalButtons)}>
      <Button type="secondary" onClick={() => onClose(false)}>
        Cancel
      </Button>
      <Button type="primary" onClick={() => onRemove(appPet)} colorScheme={ButtonColors.ORANGE}>
        Remove
      </Button>
    </div>
  </div>
);

export default RemoveAppointmentPetContent;
