import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 2.4rem;
  background-color: #f5f6f9;
  ${({ theme }) => theme.mobile`
    padding: 0rem;
  `}
`;

export const Content = styled.div`
  max-width: 124rem;
  flex: 1;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 4.8rem;
  background-color: #fbfcfd;

  ${({ theme }) =>
    theme.mobile`
      max-width: 48rem;
      border-radius: 0;
    `}
  ${({ theme }) => theme.laptop`
    width: 100%;
    padding-bottom: 2.4rem;
    border-radius: 1.2rem 1.2rem 0.2rem 0.2rem;
  `};
`;
