import { useMutation } from '@apollo/client';

import {
  calculateSubscriptionPriceQuery,
  updatePlanForPetMutation,
  getSubscriptionPetsQuery,
  getSubscriptionQuery
} from 'gql';

import { useRouteMatch } from 'react-router';

export const useMembershipPetCard = () => {
  const match = useRouteMatch();

  const [updatePetPlan] = useMutation(updatePlanForPetMutation);

  const handleUpdatePetPlan = async (subscriptionPetUid, planUid) => {
    try {
      await updatePetPlan({
        variables: { record: { subscriptionPetUid, planUid } },
        refetchQueries: [
          {
            query: getSubscriptionPetsQuery,
            variables: {
              record: {
                subscriptionId: match.params.id
              }
            }
          },
          {
            query: calculateSubscriptionPriceQuery,
            variables: {
              record: {
                subscriptionId: match.params.id
              }
            }
          },
          {
            query: getSubscriptionQuery,
            variables: {
              record: {
                subscriptionId: match.params.id
              }
            }
          }
        ]
      });
    } catch (err) {
      throw err;
    }
  };

  return {
    handleUpdatePetPlan
  };
};
