import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { message, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { excludeTimeSlotMutation, toggleIsLockedMutation } from 'gql';

import { parseTimeSlotHour } from 'utils/helpers';
import TimeSlotWorkingAreas from 'components/timeslot-working-areas/TimeSlotWorkingAreas';
import { ActionColumn } from '../../../../_fsd/features';

const useTimeslots = (setUpdateTimeslotData, calendarDate, refetchAuthVet, setSelectedTimeslot) => {
  const { t } = useTranslation();
  const [excludeTimeSlot] = useMutation(excludeTimeSlotMutation);
  const [toggleIsLocked] = useMutation(toggleIsLockedMutation);

  const toggleIsLockedHandler = async (id) => {
    try {
      await toggleIsLocked({
        variables: {
          id
        }
      });
      message.success(t('route.lock_message'));
      refetchAuthVet();
    } catch (err) {
      console.error(err);
    }
  };
  const excludeTimeSlotHandler = async (timeSlotId) => {
    try {
      const res = await excludeTimeSlot({
        variables: {
          record: {
            timeSlotId,
            date: calendarDate
          }
        }
      });

      if (res.data?.excludeTimeSlot === 'success') {
        message.success(t('vet.timeslots.exclude.success'));
        refetchAuthVet();
      } else {
        message.error(t('vet.timeslots.exclude.error'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const timeslotsColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'timeslotHours',
      align: 'center',
      render: (text, record) => (
        <div className="hours-wrapper">{`${parseTimeSlotHour(
          record.startTime
        )} - ${parseTimeSlotHour(record.endTime)}`}</div>
      )
    },
    {
      title: 'Working Area',
      dataIndex: 'workingArea',
      key: 'timeslotWorkingHours',
      align: 'center',
      render: (text, record) => <TimeSlotWorkingAreas timeSlot={record} />
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'timeslotActions',
      align: 'center',
      render: (text, record) => {
        return (
          <ActionColumn
            record={record}
            refetchTimeslots={refetchAuthVet}
            toggleIsLockedHandler={toggleIsLockedHandler}
            setUpdateTimeslotData={setUpdateTimeslotData}
            excludeTimeSlotHandler={excludeTimeSlotHandler}
            setSelectedTimeslot={setSelectedTimeslot}
          />
        );
      }
    }
  ];

  return { timeslotsColumn, toggleIsLockedHandler, excludeTimeSlotHandler };
};

export default useTimeslots;
