import React, { memo, useEffect, useMemo } from 'react';
import { Calendar, Button, Row } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';

import { timeSlotsToDateObject } from 'common_repo/utils/helpers';

import './readyStyles.css';

const VetsCalendar = ({
  timeSlots,
  fullTimeSlots,
  setSelectedDateTimeSlots,
  setSelectedTimeslot,
  setCalendarDate,
  calendarDate,
  setAppointmentsData,
  showFullTimeSlots,
  showTimeSlotsInOtherArea
}) => {
  const dates = useMemo(() => {
    return timeSlotsToDateObject({
      timeslots: timeSlots,
      currenthMonth: null,
      fullTimeSlots: showFullTimeSlots ? [] : fullTimeSlots,
      isForReschedule: false,
      startDate: false,
      time: 14,
      isForAdminCalendar: showFullTimeSlots !== false,
      isForVet: showFullTimeSlots !== false
    });
  }, [timeSlots, showFullTimeSlots]);

  const setDateAndTimeSlots = (isFirstRender) => {
    let dateStr = dayjs(calendarDate).format('YYYY-MM-DD');
    if (isFirstRender) {
      const filteredDate = Object.keys(dates)
        .sort()
        .find((date) => date > dateStr);
      if (filteredDate) {
        dateStr = filteredDate;
        setCalendarDate(filteredDate);
      }
    }
    const slots = dates[dateStr];
    setSelectedDateTimeSlots(
      slots
        ? slots.map((slot) => {
            return {
              ...slot,
              key: slot.uid,
              isFull: fullTimeSlots.some((fullSlot) => fullSlot.timeSlotId === slot.uid) // TODO: change to take from timeslot object itself
            };
          })
        : []
    );
  };

  const onSelectDate = (date) => {
    if (calendarDate !== dayjs(date).format('YYYY-MM-DD')) {
      setSelectedTimeslot(null);
      setSelectedDateTimeSlots([]);
      setAppointmentsData([]);
      setCalendarDate(dayjs(date).format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    setDateAndTimeSlots();
  }, [calendarDate, showFullTimeSlots, showTimeSlotsInOtherArea, timeSlots]);

  useEffect(() => {
    setDateAndTimeSlots(true);
  }, []);

  return (
    <div className="vetsCalendarModal">
      <div className="calendar">
        <div className="site-calendar-demo-card">
          <Calendar
            value={moment(calendarDate)}
            fullscreen={false}
            headerRender={() => (
              <div>
                <Row justify="center">
                  <span className="calendar-header-title">
                    {dayjs(calendarDate).format('MMMM YYYY')}
                  </span>
                </Row>
                <Row justify="space-between">
                  <Button
                    className="calendar-header-arrows"
                    type="link"
                    onClick={() => onSelectDate(dayjs(calendarDate).add(-1, 'months'))}
                    icon={<ArrowLeftOutlined style={{ color: '#22A3C3' }} />}
                  />
                  <Button
                    type="link"
                    // block
                    onClick={() => onSelectDate(dayjs())}
                    style={{ color: '#22A3C3', overflow: 'hidden' }}>
                    Today
                  </Button>
                  <Button
                    type="link"
                    onClick={() => onSelectDate(dayjs(calendarDate).add(1, 'months'))}
                    icon={<ArrowRightOutlined style={{ color: '#22A3C3' }} />}
                  />
                </Row>
              </div>
            )}
            onSelect={onSelectDate}
            mode="month"
            className="calender-style"
            disabledDate={(current) => {
              return (
                current.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') &&
                !dates[current.format('YYYY-MM-DD')]?.length
              );
            }}
            dateFullCellRender={(current) => {
              const hasTimeSlots = dates[current.format('YYYY-MM-DD')];
              const today = current.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
              const past = current.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD');
              return (
                <span
                  className={`antd-cell ${
                    hasTimeSlots
                      ? past
                        ? 'cell-timeslots-past'
                        : 'cell-timeslots'
                      : 'cell-without-timeslot'
                  } ${today ? 'cell-today' : ''}`}>
                  {current.format('DD')}
                </span>
              );
            }}
            onPanelChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(VetsCalendar);
