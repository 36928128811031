import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Input } from 'antd';
import ButtonWithIcon from '../../../button-with-icon/ButtonWithIcon';
import { PropertyType } from '../../../../utils/enums';
import { ReactComponent as HomeIcon } from 'assets/icons/home-icon.svg';
import { ReactComponent as HomeDarkIcon } from 'assets/icons/home-dark.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/building-white.svg';
import { ReactComponent as BuildingDarkIcon } from 'assets/icons/building-dark.svg';

const AdditionalInfo = ({ propertyType, setPropertyType, isForVet }) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  return (
    <div className="additional-info-wrapper">
      <p className="additional-info">{t('property.type')}</p>
      <div className="property-type-wrapper">
        <ButtonWithIcon
          size="big"
          text={t('private.house')}
          active={propertyType === PropertyType.PrivateHome}
          onClick={() => setPropertyType(PropertyType.PrivateHome)}
          icon={propertyType === PropertyType.PrivateHome ? <HomeIcon /> : <HomeDarkIcon />}
        />
        <ButtonWithIcon
          size="medium"
          text={t('apartment')}
          active={propertyType === PropertyType.Apartment}
          onClick={() => setPropertyType(PropertyType.Apartment)}
          icon={propertyType === PropertyType.Apartment ? <BuildingIcon /> : <BuildingDarkIcon />}
        />
      </div>
      {propertyType === PropertyType.Apartment && (
        <Row gutter={12} className="property-details">
          <Col sm={12} md={12}>
            <Form.Item
              label={t('floor')}
              name="floor"
              rules={[{ required: propertyType === PropertyType.Apartment }, { whitespace: true }]}
              stringMode={true}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12} md={12}>
            <Form.Item
              label={t('flat')}
              name="apartment"
              rules={[
                { required: propertyType === PropertyType.Apartment },
                {
                  max: 5,
                  message: t('too.much.symbols')
                },
                { whitespace: true }
              ]}>
              <Input maxLength={5} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label={isForVet ? t('address_step.comment_title_for_vet') : t('comment')}
        name="userComment"
        rules={[{ max: 350, message: t('too.much.symbols') }]}>
        <TextArea
          rows={4}
          placeholder={
            isForVet ? t('scheduling_stepper.vet_special_instructions_text') : t('comment.text')
          }
          maxLength={350}
        />
      </Form.Item>
    </div>
  );
};

export default memo(AdditionalInfo);
