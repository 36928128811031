import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 75.8rem;
  padding: 6.8rem 8.4rem;
  ${({ theme }) => theme.laptopHeightS`
    padding: 4.8rem 6.4rem;
  `}
  ${({ theme }) => theme.laptop`
    padding: 4rem;
  `}
  ${({ theme }) => theme.mobile`
    width: 100%;
    padding: 3.2rem 1.6rem;
  `}
`;
