import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 12.8rem;
  margin-bottom: 2.45rem;
  background: rgba(50, 59, 75, 0.36);
  mix-blend-mode: multiply;
  border-radius: 8px;
  position: relative;

  ${({ theme }) => theme.laptop`
    height: 8.8rem;
  `}

  ${({ theme }) => theme.mobile`
    margin-bottom: 0rem;
    height: 25.9rem;
    justify-content: center;
    align-items: flex-start;
  `}
`;

export const PawsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 10.4rem;
  width: 12.6rem;
  z-index: 1;
  margin: 1.15rem 0 1.25rem 3.1rem;

  > div {
    bottom: 5.5rem;
    right: 7rem;
    position: absolute;

    ${({ theme }) => theme.laptop`
      bottom: 8rem;
      right: 8rem;
    `}
  }

  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

export const PawIcon = styled.img`
  width: 6.4rem;
  height: 6.4rem;
  top: 0;
  left: 0;
  position: absolute;

  ${({ theme }) => theme.laptop`
    width: 4.6rem;
    height: 4.6rem;
    top: 2rem;

    
  `}
`;

export const WelcomeBack = styled.img`
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TitleWrapper = styled.div`
  position: absolute;
  z-index: 1;
  margin-left: 15.5rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 2.9rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  ${({ theme }) => theme.laptop`
      font-size: 2.4rem;
      margin-left: 12.5rem;
  `}

  ${({ theme }) => theme.mobile`
      margin: 1.6rem 0rem;
      flex-direction: column-reverse;
      top: 6.5rem;
  `}

  > strong {
    font-weight: 700;
  }

  > svg {
    ${({ theme }) => theme.mobile`
      margin-left: unset;
      margin-bottom: 1.2rem;
    `}
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  > svg {
    margin-left: 2rem;
    ${({ theme }) => theme.mobile`
      margin-left: unset;
    `}
  }
`;
