import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { ReactComponent as Eye } from 'assets/icons/eye-blue.svg';
import { ReactComponent as EyeNonVisible } from 'assets/icons/eye-none-visible.svg';
import emptyAppointmentsImg from 'assets/icons/no-appointments.svg';
import { ReactComponent as Clock } from 'assets/icons/clock-blue.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-blue.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-white-line.svg';

const { Title } = Typography;

const PreferenceSupportedServices = ({ concerns, handleToggleNewServiceModal, isForVetAdmin }) => {
  const { t } = useTranslation();

  const titleComponent = useMemo(() => {
    return (
      <Title level={3} className="settings-text settings-sub_title">
        {t('preferences.services_title')}
      </Title>
    );
  }, []);

  const subTitleComponent = useMemo(() => {
    return (
      <Title level={4} className="settings-text notification-description">
        {isForVetAdmin
          ? t('preferences.services_vetAdmin_description')
          : t('preferences.services_description')}
      </Title>
    );
  }, []);

  const newServiceBtnComponent = useMemo(() => {
    return isForVetAdmin ? (
      <Button
        isForModal
        color="primary"
        width="15.7rem"
        height="3.2rem"
        icon={<PlusIcon />}
        onClick={handleToggleNewServiceModal}
        label={t('new_service_modal.button_text')}
        className="add-service-btn supported-services-btn"
      />
    ) : null;
  }, []);

  const servicesList = useMemo(() => {
    if (!concerns?.length) {
      return (
        <div className="empty-appointments">
          <img src={emptyAppointmentsImg} alt="no" />
          <p className="no-appointments-text">No Supported services </p>
          <Button
            color="primary"
            label={t('new_service_modal.button_text')}
            icon={<PlusIcon />}
            className="add-service-btn"
            onClick={handleToggleNewServiceModal}
          />
        </div>
      );
    }
    return (
      <div className="supported-services-list custom-scrollbar">
        {concerns?.map((concern) => {
          return (
            <div
              className={`supported-services-list__item ${!concern?.isSelected ? 'disabled-service-item' : ''}`}
              key={concern?.value}
              onClick={isForVetAdmin ? () => handleToggleNewServiceModal(concern) : null}>
              <div className="supported-services-list__wrapper align-items-center">
                <div className="supported-services-list__content">
                  <Title className="supported-services-list-item__title">
                    {concern?.label ?? concern?.itemValue}
                  </Title>
                  <div className="supported-services-list__details">
                    <div className="d-flex align-items-center">
                      <Clock className="services-clock-icon" />{' '}
                      <Title className="services-details__title services-details__time">
                        {concern?.duration || 0} {t('eta_tooltip.min')}
                      </Title>
                    </div>
                    <span className="services-details__line" />
                    <div className="d-flex align-items-center">
                      <span className="d-flex">
                        {concern?.isBookable ? (
                          <Eye className="services-eye-icon services-eye-icon__active" />
                        ) : (
                          <EyeNonVisible className="services-eye-icon" />
                        )}
                      </span>

                      <Title className="services-details__title services-details__bookable">
                        {concern?.isBookable
                          ? t('eta_tooltip.bookable')
                          : t('eta_tooltip.non_bookable')}
                      </Title>
                    </div>
                  </div>
                </div>
                {isForVetAdmin && (
                  <div className="supported-services-list__edit">
                    <EditIcon />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [concerns]);

  return (
    <>
      {titleComponent}
      {subTitleComponent}
      {Boolean(concerns?.length) && newServiceBtnComponent}
      {servicesList}
    </>
  );
};

export default PreferenceSupportedServices;
