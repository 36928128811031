import { useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  getPaymentMethodsQuery,
  getPaymentProviderByUserLocationQuery,
  updateEmailSubscriptionMutation
} from 'gql';
import useBluesnapProvider from 'services/useBluesnapProvider';
import usePaymeProvider from 'services/usePaymeProvider';

const usePayments = (price, id, me, priceData, vet, meRefetch) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [checkoutFormRendered, setCheckoutFormRendered] = useState(false);
  const [isEmailSubscribed, setIsEmailSubscribed] = useState(true);
  const [paymentSkipped, setPaymentSkipped] = useState(false);
  const [updateEmailSubscription] = useMutation(updateEmailSubscriptionMutation);

  const { data, loading: paymentMethodsLoading } = useQuery(getPaymentMethodsQuery, {
    fetchPolicy: 'no-cache'
  });

  const { data: paymentProviderData, loading: paymentProviderLoading } = useQuery(
    getPaymentProviderByUserLocationQuery
  );

  const {
    initFields: initBluesnapFields,
    submitForm: submitBluesnapForm,
    loading: bluesnapSubmitting,
    finishedSuccessfully,
    isFinished,
    buySubscription: buyBluesnapSubscription
  } = useBluesnapProvider(id, data?.paymentMethods, setError, setSuccess, priceData);

  const {
    initFields: initPaymeFields,
    submitForm: submitPaymeForm,
    buyingSubscription,
    isFinished: finished,
    buySubscription: buyPaymeSubscription
  } = usePaymeProvider(price, id, me, data, setLoading, setError, setSuccess, priceData, vet);

  const paymentOperations = useMemo(() => {
    if (!paymentProviderData?.provider) return;
    const obj = {
      bluesnap: {
        initFields: initBluesnapFields,
        submitHandler: submitBluesnapForm,
        buySubscription: buyBluesnapSubscription
      },
      payme: {
        initFields: initPaymeFields,
        submitHandler: submitPaymeForm,
        buySubscription: buyPaymeSubscription
      }
    };
    return obj[paymentProviderData?.provider?.name];
  }, [
    paymentProviderData,
    initBluesnapFields,
    buyBluesnapSubscription,
    buyPaymeSubscription,
    initPaymeFields,
    submitPaymeForm,
    submitBluesnapForm
  ]);

  const submitForm = async (e) => {
    e.preventDefault();
    if (me.isEmailSubscribed !== isEmailSubscribed) {
      updateEmailSubscription({
        variables: {
          record: { isEmailSubscribed }
        }
      });
    }
    if (me.isAdmin && paymentSkipped) {
      await paymentOperations?.buySubscription({
        variables: {
          record: { subscriptionId: id, skipPayment: paymentSkipped }
        }
      });
      setLoading(false);
      setSuccess(true);
      await meRefetch();
    } else {
      paymentOperations?.submitHandler(e);
    }
  };

  const [fieldsState, setFieldsState] = useState({
    cardNumber: {
      isFocused: false,
      isEmpty: true,
      isValid: false
    },
    cardExpiration: {
      isFocused: false,
      isEmpty: true,
      isValid: false
    },
    cvc: {
      isFocused: false,
      isEmpty: true,
      isValid: false
    }
  });

  useEffect(() => {
    if (data && !paymentProviderLoading && paymentProviderData && !checkoutFormRendered) {
      if (!data?.paymentMethods?.length) {
        paymentOperations?.initFields?.(setFieldsState, setLoading, setError);
      }
      setCheckoutFormRendered(true);
    }
  }, [data, paymentProviderLoading, paymentProviderData, checkoutFormRendered, paymentOperations]);

  return {
    loading: loading || buyingSubscription || bluesnapSubmitting,
    setLoading,
    setError,
    data,
    submitForm,
    paymentMethods: data?.paymentMethods || [],
    paymentMethodsLoading,
    error,
    success,
    fieldsState,
    inputsEmpty:
      fieldsState?.cardNumber?.isEmpty ||
      fieldsState?.cvc?.isEmpty ||
      fieldsState?.cardExpiration?.isEmpty,
    defaultProvider: paymentProviderData?.provider?.name,
    isEmailSubscribed,
    setIsEmailSubscribed,
    finishedSuccessfully: finishedSuccessfully || success,
    isFinished: isFinished || finished,
    paymentSkipped,
    setPaymentSkipped
  };
};

export default usePayments;
