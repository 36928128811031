import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${({ vhSize }) => `${vhSize}rem`};
  ${({ theme, vhSize }) => theme.mobile`
        overflow: unset;
        height: ${({ sidebarOpen }) => (sidebarOpen ? `${vhSize}rem` : 'unset')}
        position: relative;
    `}
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: calc(100% - 6.4rem);
  position: relative;
  background-color: #f5f6f9;
  ${({ theme }) => theme.mobile`
        height: calc(100% - 5.6rem);
        margin-top: 5.6rem;
    `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  flex: 1;
  position: relative;
  background-color: #f6f7fb;
  min-height: 28rem;
  margin: 2.4rem auto;
  padding: 2.4rem;
  ${({ theme }) => theme.mobile`
    width: 100%;
    height: 100%;
    padding : ${({ disableMobilePadding }) => (disableMobilePadding ? '0' : '3rem')};
    margin : ${({ disableMobilePadding }) => (disableMobilePadding ? '0' : '2.4rem auto')};
    ::-webkit-scrollbar {
      display: none;
    }
  `};
`;

export const Sidebar = styled.aside`
  width: 20rem;
  padding: 1.6rem 0 3.2rem;
  background-color: ${(props) => props.theme.colors.black};
`;

export const Layer = styled.div`
  ${({ theme }) => theme.mobile`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  z-index: 10;
  `}
`;
