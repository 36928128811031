import React from 'react';
import { Modal } from '_fsd/shared';
import { List, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUpsertExternalId } from '_fsd/entities/pet-integration';
import css from './EditExternalId.module.css';
import { useEditExternalIdSteps } from '../hooks';
import { useFormik } from 'formik';

export const EditExternalIdModal = ({ isOpen, onClose, petIntegration, integration, petId }) => {
  const { t } = useTranslation();
  const [upsertPetIntegrationExternalId] = useUpsertExternalId();
  const formik = useFormik({
    initialValues: { externalId: petIntegration?.externalId ?? '' },
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      const { externalId } = values;

      upsertPetIntegrationExternalId({
        variables: {
          record: {
            id: petIntegration?.uid,
            externalId,
            integrationId: integration.uid,
            petId
          }
        }
      })
        .then(() => {
          message.success(t('integration.edit_pet_form.edit_external_id_modal.success_message'));
          formikHelpers.setSubmitting(false);
          onClose();
        })
        .catch((err) => {
          message.error(err.message);
          formikHelpers.setSubmitting(false);
        });
    }
  });

  const editExternalIdListItems = useEditExternalIdSteps({
    formik,
    guide: integration.pimSettings?.pet.editExternalIdGuide
  });

  return (
    <Modal
      footerProps={{ displayCancel: false }}
      title={<span>{t('integration.edit_pet_form.edit_external_id_modal.title')}</span>}
      isOpen={isOpen}
      onClose={() => onClose()}
      onConfirm={formik.submitForm}>
      <List
        split={false}
        itemLayout="horizontal"
        dataSource={editExternalIdListItems}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <span className={css.editListCircle}>
                  <span>{index + 1}</span>
                </span>
              }
              title={item.title}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};
