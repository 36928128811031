import styled from 'styled-components';

export const Caption = styled.h2`
  font-family: inter, assistant;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  ${({ theme }) => theme.laptop`
    text-align: unset;
  `}

  ${({ theme }) => theme.mobile`
   text-align: left;
   
   `}
`;

export const Description = styled.p`
  color: #8a94a6;
  font-size: 1.6rem;
  line-height: 1.1;
  font-family: inter, assistant;
  font-weight: 400;
  margin-top: 0.8rem;
  text-align: center;

  ${({ theme }) => theme.mobile`
   text-align: left; 
   margin-top: 1.2rem;
   `}
`;
