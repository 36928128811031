import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'common_repo/assets/icons/logo-blue-beta.png';
import './style.scss';
import AuthContext from '../../../contexts/AuthContext';

const CopyRight = ({ isMobile }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  return (
    <div className={`copyright ${!user && isMobile ? 'mobile-copyright justify-flex-end' : ''}`}>
      <a
        href="https://kumba.pet"
        target="_blank"
        rel="noreferrer"
        className={`copyright-wrapper ${isMobile ? 'copyright-full-width' : ''}`}>
        <h3>{t('powered.by')}</h3>
        <img src={logo} alt="Kumba" />
      </a>
    </div>
  );
};

export default CopyRight;
