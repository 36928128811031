import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper, GoBackWrapper } from './components';
import AddPetForm from 'components/add-pet-form/AddPetForm';
import GoBack from 'components/common/go-back/GoBack';
import { COMPLETE_PROFILE, MY_PETS } from 'constants/client';
import AuthContext from '../../../contexts/AuthContext';

const AddPet = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [advancedInfoOpen, setAdvancedInfoOpen] = useState(false);

  const handleGoBack = () => {
    if (user?.hasPet) history.push(MY_PETS);
    else history.push(COMPLETE_PROFILE);
  };

  return (
    <Wrapper>
      <GoBackWrapper>
        <GoBack clickHandler={handleGoBack} />
      </GoBackWrapper>
      <AddPetForm advancedInfoOpen={advancedInfoOpen} setAdvancedInfoOpen={setAdvancedInfoOpen} />
    </Wrapper>
  );
};

export default AddPet;
