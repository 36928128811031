import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 56.4rem;
  background-color: #f5f6f9;
  font-family: inter, assistant;
  border-radius: 1.6rem;
  ${({ theme }) => theme.mobile`
    width: 31.2rem;
  `}
`;

export const Header = styled.div`
  height: 6.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: 4rem;
  padding-right: 2rem;
  border-radius: 1.6rem;
  ${({ theme }) => theme.mobile`
    height: 4.7rem;
    padding: 0 1.4rem 0 2.4rem;
  `}
`;

export const Body = styled.div`
  padding: 2.5rem 3.2rem 3.2rem 3.2rem;
  ${({ theme }) => theme.mobile`
    padding: 1.3rem 2rem 2rem 2rem
  `}
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 2rem;
  color: #323b4b;
  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
  `}
`;

export const Exit = styled.div`
  cursor: pointer;

  & svg {
    fill: #9094ac;
  }
`;

export const VetInfo = styled.div`
  height: 13.2rem;
  margin-bottom: 2.8rem;
  display: flex;
  ${({ theme }) => theme.mobile`
    flex-wrap: wrap;
  `}
`;

export const VetDetails = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  flex: 1;
`;

export const LanguageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  width: 90%;
  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const Experience = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: #323b4ba3;
  margin-bottom: 1rem;
`;

export const VetDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.2rem;
`;

export const VetName = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  color: #323b4b;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
`;

export const BookVisitButton = styled.button`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  background: #22a3c3;
  box-shadow: 0rem 1.2rem 2.3rem rgba(0, 0, 0, 0.03);
  border-radius: 0.6rem;
  height: 3.6rem;
  width: 12.9rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  ${({ theme }) => theme.mobile`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')}; 
  `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 27.2rem;
      height: 4.4rem;
      margin-top: 2.1rem;
    `}
`;

export const ChatAndName = styled.div`
  display: flex;
  align-items: center;
`;

export const Biography = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  max-height: 19.7rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2rem;
  border-radius: 1.2rem;
  white-space: pre-line;
  ${({ theme }) => theme.mobile`
    padding: 1.2rem 0.4rem 1.6rem 1.6rem;
  `}
`;

export const BiographyText = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: #323b4bb8;
  line-height: 2.1rem;
  margin-top: 0.8rem;
  overflow-y: auto;

  ${({ theme }) => theme.mobile`
    padding-right: 0.8rem;
  `}
  &::-webkit-scrollbar {
    width: ${({ theme }) => (theme.mobile ? '0.4rem' : '0.8rem')};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8a94a640;
    border-radius: 0.8rem;
  }
`;

export const TimeSlotsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2rem;
  border-radius: 1.2rem;
  ${({ theme }) => theme.mobile`
    padding: 1.1rem 1.6rem 1.5rem 1.6rem;
  `}
`;

export const TimeSlotsBody = styled.div`
  margin-top: ${({ theme }) => (theme.mobile ? '1.2rem' : '2.3rem')};
`;

export const SingleDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => (theme.mobile ? '1.2rem' : '1.6rem')};
`;

export const WeekdayText = styled.p`
  color: #777a95;
  font-size: ${({ theme }) => (theme.mobile ? '1.2rem' : '1.6rem')};
  font-weight: 400;
  ${({ theme }) => theme.mobile`
    margin-right: 2.4rem;
  `}
`;

export const TimeSlotsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const MoreTimeSlotsBox = styled.div`
  background-color: #dcf0f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  color: #22a3c3;
  font-size: 1.2rem;
  font-weight: 600;
  border: 0.05rem solid #acbcd4;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }) => theme.mobile`
      padding: 0.2rem 0.3rem;
      width: 2.2rem;
      height: 2.2rem;
  `}
`;

export const SingleTimeSlot = styled.div`
  background: rgba(219, 226, 238, 0.12);
  border: 0.05rem solid #acbcd4;
  box-sizing: border-box;
  border-radius: 0.8rem;
  width: 9.5rem;
  height: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #323b4bcc;
  ${({ theme }) => theme.mobile`
    width: 8.5rem;
    height: 2.2rem;
    font-size: 1.1rem;
    padding: 0.2rem 0.4rem;
    border-radius: 0.4rem;
  `}
`;

export const BoxTitle = styled.p`
  font-size: ${({ theme }) => (theme.mobile ? '1.4rem' : '1.6rem')};
  font-weight: 500;
  color: #323b4b;
`;

export const VetImage = styled.img`
  width: 13.2rem;
  height: 13.2rem;
  object-fit: cover;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  border: 0.8rem solid #eceff5;
  border-radius: 20rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-right: 2rem;
  ${({ theme }) => theme.mobile`
    width: 6.4rem;
    height: 6.4rem;
    border: 0.4rem solid #eceff5;
    margin-bottom: 1.2rem;
  `}
`;
export const AvatarWithText = styled.div`
  min-width: 13.2rem;
  height: 13.2rem;
  border-radius: 20rem;
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  margin-right: 2rem;
  align-items: center;
  justify-content: center;
  font-size: 3.6rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mobile`
     min-width: 5.6rem;
     height: 5.2rem;
     font-size: 2.6rem;
  `}
`;
export const ToolTipWrapper = styled.div`
  & div {
    font-family: inter, assistant;
    font-weight: 300;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;
    border-radius: ${({ theme }) => (theme.mobile ? '0.4rem' : '0.8rem')};
  }
`;
