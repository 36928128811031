import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 4.8rem;

  ${({ theme }) => theme.laptop`
    padding: 0 2.4rem;
    width: unset;
  `}

  ${({ theme }) => theme.mobile`
    padding: ${({ membership }) => (membership ? '0 2.4rem' : '0rem')};
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 35.6rem;
  height: 5.1rem;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const ActionButton = styled.button`
  width: 16.6rem;
  height: 5.1rem;
  background-color: ${({ disabled }) => (disabled ? '#DBE2EE' : '#FF9A05')};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  border-radius: 8px;
  font-family: inter, assistant;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ back }) =>
    back &&
    css`
      background-color: rgba(138, 148, 166, 1);
      cursor: pointer;
    `}

  ${({ theme }) => theme.mobile`
        &:first-child {
        margin-right: 1.2rem;
        }
        height: 5.2rem;

        flex: 1 15rem;
  `}
`;
