import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import theme from 'styles/theme';
import { buyBluesnapSubscriptionMutation, getPaymentFieldsTokenMutation } from 'gql';

import { executeFbScript, executeGoogleScript } from 'utils/helpers';
import AuthContext from '../contexts/AuthContext';

const bluesnapFieldNames = {
  ccn: 'cardNumber',
  exp: 'cardExpiration',
  cvv: 'cvc'
};

const useBluesnapProvider = (subscriptionId, paymentMethods, setError, setSuccess, priceData) => {
  const { refetch: meRefetch, user: me } = useContext(AuthContext);
  const { t } = useTranslation();

  const [pfToken, setPfToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);

  const [getPaymentFieldsToken] = useMutation(getPaymentFieldsTokenMutation);
  const [buySubscription] = useMutation(buyBluesnapSubscriptionMutation, {
    refetchQueries: ['me'],
    awaitRefetchQueries: true
  });

  const initFields = async (setFieldsState) => {
    const res = await getPaymentFieldsToken();
    const fieldsToken = res?.data?.getPaymentFieldsToken;
    let isEmpty = false;

    setPfToken(fieldsToken);
    if (fieldsToken) {
      const bsObj = {
        token: fieldsToken,
        onFieldEventHandler: {
          setupComplete: function () {
            console.warn('setupComplete');
          },
          threeDsChallengeExecuted: function () {
            console.warn('threeDsChallengeExecuted');
          },
          onFocus: function (tagId) {
            setFieldsState((curr) => {
              isEmpty = curr?.[bluesnapFieldNames?.[tagId]]?.isEmpty;
              return {
                ...curr,
                [bluesnapFieldNames[tagId]]: {
                  ...curr[bluesnapFieldNames[tagId]],
                  isFocused: true
                }
              };
            });
          },
          onBlur: function (tagId, ...rest) {
            setFieldsState((curr) => ({
              ...curr,
              [bluesnapFieldNames[tagId]]: {
                ...curr[bluesnapFieldNames[tagId]],
                isFocused: false
              }
            }));
          },
          onError: function (tagId, errorCode, errorDescription) {
            if ((errorCode && !isEmpty) || errorDescription === 'invalidInput') {
              console.log(errorCode, 'errorCode');
              setLoading(false);
              setIsFinished(true);
              setFinishedSuccessfully(false);
            }
          }, // Handle a change in validation

          /* errorCode returns:
                      "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                      "22013" --> "CC type is not supported by the merchant";
                      "14040" --> " Token is expired";
                      "14041" --> " Could not find token";
                      "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                      "400" --> "Session expired please refresh page to continue";
                      "403", "404", "500" --> "Internal server error please try again later";
                  */

          /* errorDescription is optional. Returns BlueSnap's standard error description */

          onType: function (tagId, cardType, cardData) {
            if (null != cardData) {
              console.log(cardData);
            }
          },
          onEnter: function (tagId) {},

          onValid: function (tagId, ...rest) {}
        },

        style: {
          ':focus': {
            color: theme.colors.black
          },
          input: {
            color: theme.colors.black,
            'font-size': '16px'
          },
          '.invalid': {
            color: 'red'
          }
        },
        ccnPlaceHolder: t('card.number'),
        cvvPlaceHolder: t('cvc'),
        expPlaceHolder: t('expiry.date.mm.yy')
      };
      window.bluesnap.hostedPaymentFieldsCreate(bsObj);
    }
  };

  const handleSubmit = async () => {
    const response = await buySubscription({
      variables: {
        record: {
          pfToken,
          subscriptionId
        }
      }
    });
    response?.data?.buyBluesnapSubscription === 'fail' ? setError(t('failed')) : setSuccess(true);

    if (response?.data?.buyBluesnapSubscription === 'success' && !me.isAdmin) {
      executeFbScript(priceData?.currency?.name, priceData?.currency?.price);
      executeGoogleScript(priceData?.currency?.name, priceData?.currency?.price);
    }

    await meRefetch();
    setLoading(false);
    setFinishedSuccessfully(true);
    setIsFinished(true);
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (paymentMethods?.length > 0) {
        await handleSubmit();
      } else {
        window.bluesnap.hostedPaymentFieldsSubmitData(async (callback) => {
          if (null != callback.cardData) {
            await handleSubmit();
          } else {
            const errorArray = callback.error;
            setIsFinished(true);
            setLoading(false);
            setFinishedSuccessfully(false);
            setError('error ');
            errorArray.forEach((err) => {
              console.log(
                `Received error: tagId= ${err.tagId}; errorCode=${err.errorCode}; errorDescription= ${err.errorDescription}`
              );
            });
          }
        });
      }
    } catch (err) {
      setFinishedSuccessfully(false);
      setIsFinished(true);
      setLoading(false);
      setError(err);
    }
  };

  return {
    initFields,
    submitForm,
    loading,
    finishedSuccessfully,
    isFinished,
    buySubscription
  };
};

export default useBluesnapProvider;
