import React, { useState } from 'react';
import { ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  ButtonColors,
  cssSpace,
  cssColor,
  cssText as text,
  cssText,
  Label,
  Modal
} from '_fsd/shared';
import css from './SaveModal.module.scss';
import cls from 'classnames';
import { Input, Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const SaveModal = ({ pushIsSavedSelectModal, onConfirm, onClose, error }) => {
  const [saveOption, setSaveOption] = useState('all'); // new || all
  const [newName, setNewName] = useState('');
  const [innerError, setInnerError] = useState('');
  const { t } = useTranslation();

  return (
    <Modal
      force
      icon={<ExclamationCircleOutlined />}
      title={
        pushIsSavedSelectModal
          ? t('settings_page.service_area.create_area_modal.save_modal_timeslot.title')
          : t('settings_page.service_area.create_area_modal.save_modal.title')
      }
      isOpen
      onConfirm={() => {
        setInnerError(false);
        if (saveOption === 'new') {
          if (newName) {
            onConfirm({ isSaved: false, newName });
          } else {
            setInnerError(true);
          }
        } else {
          onConfirm({ isSaved: true });
        }
      }}
      onClose={onClose}
      footerProps={{
        buttonConfirmProps: {
          colorScheme: ButtonColors.ORANGE
        },
        buttonCancelProps: {
          onClick: pushIsSavedSelectModal ? () => onConfirm({ isSaved: false }) : onClose
        },
        titleConfirm: pushIsSavedSelectModal
          ? t('settings_page.service_area.create_area_modal.save_modal_timeslot.button_confirm')
          : t('settings_page.service_area.create_area_modal.save_modal.button_confirm'),
        titleCancel: pushIsSavedSelectModal
          ? t('settings_page.service_area.create_area_modal.save_modal_timeslot.button_cancel')
          : t('settings_page.service_area.create_area_modal.save_modal.button_cancel')
      }}>
      <div className={css.saveModal}>
        {pushIsSavedSelectModal ? (
          t('settings_page.service_area.create_area_modal.save_modal_timeslot.content')
        ) : (
          <div className={css.saveModalContent}>
            <span className={cls(cssText.s14w4l22, cssColor.cCharterPrimary85)}>
              {t('settings_page.service_area.create_area_modal.save_modal.desc')}
            </span>
            <Radio.Group value={saveOption} onChange={(e) => setSaveOption(e.target.value)}>
              <div className={css.saveModalOptions}>
                <Radio value={'all'}>
                  <span className={cssText.s14w4l22} onClick={() => setSaveOption('all')}>
                    {t('settings_page.service_area.create_area_modal.save_modal.option_all_1')}
                    <strong>
                      {t('settings_page.service_area.create_area_modal.save_modal.option_all_2')}
                    </strong>
                    {t('settings_page.service_area.create_area_modal.save_modal.option_all_3')}
                  </span>
                </Radio>
                <Radio value={'new'}>
                  <span className={cssText.s14w4l22} onClick={() => setSaveOption('new')}>
                    {t('settings_page.service_area.create_area_modal.save_modal.option_new_1')}
                    <strong>
                      {t('settings_page.service_area.create_area_modal.save_modal.option_new_2')}
                    </strong>
                    {t('settings_page.service_area.create_area_modal.save_modal.option_new_3')}
                  </span>
                </Radio>
                {saveOption === 'new' && (
                  <div className={css.input}>
                    <Label className={cssText.s12w4l20}>
                      {t('settings_page.service_area.create_area_modal.save_modal.input_label')}
                      <Tooltip
                        overlayStyle={{ minWidth: 260 }}
                        className={text.s12w4l22}
                        title={t(
                          'settings_page.service_area.create_area_modal.save_modal.input_info'
                        )}>
                        <InfoCircleFilled className={cssSpace.ml8} />
                      </Tooltip>
                    </Label>
                    <Input
                      status={error || (innerError && 'error')}
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                    />
                    {(error || innerError) && (
                      <Label className={cssText.s12w4l20} type="error">
                        {error ||
                          t(
                            'settings_page.service_area.create_area_modal.save_modal.input_error_req'
                          )}
                      </Label>
                    )}
                  </div>
                )}
              </div>
            </Radio.Group>
          </div>
        )}
      </div>
    </Modal>
  );
};
