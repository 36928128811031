import { useQuery, useMutation } from '@apollo/client';
import {
  updateAppointmentUserComment,
  getSoldInventoriesForAppointmentRouteQuery,
  getAppointmentRoutesQuery,
  cancelVisitMutation
} from 'gql';
import { useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';

const useVisitModal = (appointmentId, onRequestClose, setIsCalendarOpen) => {
  const { user: me } = useContext(AuthContext);
  const [updateComment, { loading }] = useMutation(updateAppointmentUserComment, {
    refetchQueries: [
      {
        query: getAppointmentRoutesQuery,
        variables: {
          record: {
            date: 0,
            petIds: []
          }
        }
      }
    ]
  });

  const [cancelVisit] = useMutation(cancelVisitMutation, {
    refetchQueries: [
      {
        query: getAppointmentRoutesQuery,
        variables: {
          record: {
            date: 0,
            petIds: []
          }
        }
      }
    ]
  });

  const { data } = useQuery(getSoldInventoriesForAppointmentRouteQuery, {
    variables: {
      record: {
        appointmentRouteId: appointmentId
      }
    }
  });

  const updateUserComment = async (comment) => {
    try {
      await updateComment({
        variables: {
          record: {
            appointmentId: appointmentId,
            comment
          }
        },
        skip: !appointmentId
      });
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const removeVisit = async (appointmentRouteId, note) => {
    try {
      await cancelVisit({
        variables: {
          record: {
            appointmentRouteId: appointmentRouteId,
            userId: me?.uid,
            note: note
          }
        }
      });

      onRequestClose();
      setIsCalendarOpen(false);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  return {
    data: data?.getSoldInventoriesForAppointmentRoute,
    loading,
    updateUserComment,
    removeVisit
  };
};

export default useVisitModal;
