import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import Selector from 'components/common/selector/Selector';

import { ReactComponent as Close } from 'assets/icons/close-gray.svg';

import * as S from './components';

const FilterModal = ({
  handleCloseModal,
  isOpen,
  YEAR_FILTER_DATA,
  pets,
  selectedPets,
  formatPetOption,
  MultiValueContainer,
  year,
  handleChangeYear
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
      <S.FilterModal>
        <S.FilterHeaderWrapper>
          <S.FilterText>{t('filter.by')}</S.FilterText>
          <Close onClick={() => handleCloseModal(false)} />
        </S.FilterHeaderWrapper>
        <S.SelectorWrapper>
          <Selector
            hideLabel
            onChange={(value) => handleChangeYear(value)}
            options={YEAR_FILTER_DATA}
            value={year}
            isFilter
          />
          <Selector
            customStyles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 99 }),
              valueContainer: (base) => ({
                ...base,
                flexWrap: 'no-wrap',
                overflowX: 'hidden'
              })
            }}
            value={selectedPets}
            options={pets}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            hideLabel
            isMulti
            formatOptionLabel={formatPetOption}
            valueContainer={{ MultiValueContainer }}
            placeholder={t('choose.pet')}
            animateLabel={false}
            isFilter
            isMobile
          />
          <S.ApplyButton onClick={() => handleCloseModal(false)}>{t('apply')}</S.ApplyButton>
        </S.SelectorWrapper>
      </S.FilterModal>
    </Modal>
  );
};

export default FilterModal;
