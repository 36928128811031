import React from 'react';
import * as S from './components';
import Step from './step/Step';

import { MEMBERSHIP_STEPS } from 'constants/client';
import { useRouteMatch } from 'react-router-dom';
import { getStepStatus } from 'utils/helpers';

const Stepper = () => {
  const match = useRouteMatch();

  return (
    <S.StepperWrapper>
      {MEMBERSHIP_STEPS.map(({ text, route }, index) => (
        <Step
          key={index}
          stepNumber={index + 1}
          isFirstStep={index === 0}
          text={text}
          route={route}
          status={getStepStatus(route, index, match.path)}
        />
      ))}
    </S.StepperWrapper>
  );
};

export default Stepper;
