import React from 'react';
import { useTranslation } from 'react-i18next';

import PetAcceptionControlModal from 'components/pet-acception-control-modal/PetAcceptionControlModal';
import PetModal from 'components/pet-modal/PetModal';
import {
  PetsList,
  Wrapper,
  GoBackWrapper,
  PlusIcon,
  AddNewPetBtn,
  AddNewPetLabel,
  ContinueBtnWrapper
} from './components';
import { DASHBOARD } from 'constants/client';
import useMyPets from './UseMyPets';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-orange.svg';
import PetCard from 'components/pet-card/PetCard';
import GoBack from 'components/common/go-back/GoBack';
import Button from 'components/common/button/Button';
import PlusGreenIcon from 'assets/icons/plus-green.svg';
import { PageTitle, PageSubTitle } from 'components/common/texts/components';

const MyPets = () => {
  const {
    data,
    loading,
    history,
    handleRemovePet,
    handleEdit,
    petFormOpen,
    petId,
    handleCloseModal,
    petToRemove,
    setPetToRemove
  } = useMyPets();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <GoBackWrapper>
        <GoBack />
      </GoBackWrapper>
      <PageTitle>{t('my.pets')}</PageTitle>
      <PageSubTitle>{t('here.is.list.of.your.pets')}</PageSubTitle>
      {!loading && (
        <>
          <PetsList>
            {data?.getPetsByOwner?.map((pet, index) => {
              return (
                <PetCard
                  index={index}
                  {...pet}
                  key={pet.uid}
                  editHandler={handleEdit}
                  removeHandler={setPetToRemove}
                  petQuantity={data?.getPetsByOwner?.length}
                />
              );
            })}
            <AddNewPetBtn
              onClick={() => {
                history.replace({
                  ...history,
                  search: `petFormOpen=1`
                });
              }}>
              <PlusIcon src={PlusGreenIcon} alt="plus" />
              <AddNewPetLabel>{t('add.new.pet')}</AddNewPetLabel>
            </AddNewPetBtn>
          </PetsList>
          <ContinueBtnWrapper>
            <Button
              color="primary"
              onClick={() => history.push(DASHBOARD)}
              label={t('continue')}
              disabled={!data?.getPetsByOwner?.length}
            />
          </ContinueBtnWrapper>
        </>
      )}
      <PetModal isOpen={Boolean(+petFormOpen)} uid={petId} handleCloseModal={handleCloseModal} />
      <PetAcceptionControlModal
        isOpen={!!petToRemove?.uid}
        submitHandler={() => {
          handleRemovePet(petToRemove?.uid);
          setPetToRemove(null);
        }}
        closeHandler={() => {
          setPetToRemove(null);
        }}
        data={petToRemove}
        onRequestClose={() => setPetToRemove(null)}
        subCaption={`${t('are.you.sure.remove.pet')} ${petToRemove?.name} ?`}
        caption={`${t('remove')} ${petToRemove?.name} ?`}
        icon={<TrashIcon />}
      />
    </Wrapper>
  );
};

export default MyPets;
