import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePicker } from 'antd';
import moment from 'moment';

import Modal from 'components/common/modal/Modal';
import Button from '../common/button/Button';

import { ReactComponent as Clock } from 'assets/icons/clock-blue.svg';

import './style.scss';

const AppointmentEtaModal = ({ onclose, onConfirm, apptEtaStart, apptEtaEnd }) => {
  const [etaStart, setEtaStart] = useState(apptEtaStart || undefined);
  const [etaEnd, setEtaEnd] = useState(apptEtaEnd || undefined);
  const { t } = useTranslation();
  const savedFormat = 'HH:mm:ss';
  const visibleFormat = 'h:mm a';
  const defaultTime =
    etaStart && etaEnd ? [moment(etaStart, savedFormat), moment(etaEnd, savedFormat)] : undefined;

  const handleEditEta = (value) => {
    setEtaStart(moment(value?.[0]).format(savedFormat));
    setEtaEnd(moment(value?.[1]).format(savedFormat));
  };

  return (
    <Modal zIndex={1001} isOpen={true} onRequestClose={onclose}>
      <div className="appointment-eta-modal">
        <h3>
          <Clock /> {t('new_appointment.edit_eta')}
        </h3>
        <p> Changing this will affect the ETA that is being sent to the client </p>
        <div className="eta-input-wrapper">
          <TimePicker.RangePicker
            aria-label="start time"
            defaultValue={defaultTime}
            onChange={(value) => handleEditEta(value)}
            format={visibleFormat}
            allowClear={false}
            minuteStep={5}
          />
        </div>
        <div className="footer-wrapper">
          <Button label={t('cancel')} className="cancel-btn" onClick={onclose} />
          <Button
            label={t('done')}
            color="primary"
            className="next-btn"
            onClick={() => onConfirm(etaStart, etaEnd)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AppointmentEtaModal;
