import { useEffect, useState } from 'react';
import i18n from 'i18next';

const rtlLanguages = ['heb'];

const useLocalization = () => {
  const [locale, setLocale] = useState();

  const [rtl, setRtl] = useState(() => rtlLanguages.includes(locale?.language));

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setRtl(rtlLanguages.includes(lang));
    localStorage.setItem('lang', lang);
  };

  useEffect(() => {
    fetch('https://pro.ip-api.com/json?key=iR7DEn53096HfCm')
      .then((res) => res.json())
      .then((data) => {
        const loc = {
          country: data.country,
          countryCode: data.countryCode
        };
        setLocale(loc);
      })
      .catch((err) => {});
  }, []);

  return {
    ...locale,
    rtl,
    changeLanguage,
    lang: localStorage.getItem('lang') || 'en'
  };
};

export default useLocalization;
