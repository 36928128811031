import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 8rem;
  border: 1px solid rgba(172, 188, 212, 0.56);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  /*! @noflip */
  direction: ltr !important;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const CardMask = styled.span`
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 400;
  margin-left: 1.66rem;
  ${({ isHeb }) => (isHeb ? 'margin-right: 1.66rem' : 'margin-left: 1.66rem')}
`;

export const DeleteIconWrapper = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.75;
    `}
`;
