import React, { useState } from 'react';
import css from './AddressStepNew.module.css';
import { Button, isValidAddress } from '_fsd/shared';
import { RescheduleWrapper } from '_fsd/widgets';
import { AddressInput } from '_fsd/features';
import AdditionalInfo from './components/AdditionalInfo';
import { useTranslation } from 'react-i18next';

export const AddressStepNew = (props) => {
  const { t } = useTranslation();
  const {
    filters,
    timeslotActions,
    address,
    addressError,
    isForVet,
    onSelect,
    onChange,
    propertyType,
    setPropertyType,
    vet,
    setSelectedTimeslot
  } = props;
  const [showAddressAdditionalInfo, setShowAddressAdditionalInfo] = useState(false);

  return (
    <div className={css.container}>
      <span className={css.address}>Client address</span>
      <AddressInput
        address={address}
        addressError={addressError}
        isForVet={isForVet}
        onSelect={onSelect}
        onChange={onChange}
      />
      <Button
        colorScheme="orange"
        type="link"
        onClick={() =>
          setShowAddressAdditionalInfo((showAddressAdditionalInfo) => !showAddressAdditionalInfo)
        }>
        {t(`admin.recommender.address_details.${showAddressAdditionalInfo ? 'hide' : 'add'}`)}{' '}
        Additional Address Info
      </Button>

      {showAddressAdditionalInfo && (
        <AdditionalInfo
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          isForVet={isForVet}
        />
      )}
      {isValidAddress(address) ? (
        <RescheduleWrapper
          isVetAdmin
          defaultReschedule={false}
          filters={filters}
          timeslotActions={timeslotActions}
          theme="light"
          currentAddress={address}
          vetId={vet?.uid}
          displayFooter={false}
          displayHeader={false}
          close={() => {}}
        />
      ) : null}
    </div>
  );
};
