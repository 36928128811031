import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 9rem);
  width: 100%;
  padding-top: 2.2rem;
  background-color: #fcfcfd;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;

  ${({ theme }) => theme.laptop`
    height: 100%;
  `}

  ${({ theme }) => theme.mobile`
   padding: 0rem 2.4rem;
   width: 100vw;
   `}
`;

export const AddButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  ${({ theme }) => theme.laptop`
    width: 17.6rem;
    margin: unset;
  `}

  ${({ theme }) => theme.mobile`
   justify-content: unset;
   margin-top: 2.5rem;
   width: 100%;
   `}
`;

export const Main = styled.main`
  /* flex: 0 1 auto;
  height: 90%;
  overflow-y: hidden;
  padding-bottom: 7.2rem; */
  flex: 1;
  overflow-y: hidden;
  ${({ theme }) => theme.laptop`
    overflow-y: auto;
    margin-bottom : 3.2rem;
    ::-webkit-scrollbar {
      width: 0.6rem;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(138, 148, 166, 0.25);
      border-radius: 0.8rem;
    }
  `}
`;

export const ChoosePetsHeader = styled.header`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.laptop`
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2.4rem 0 2.4rem;
    align-items: center;
  `}
  ${({ theme }) => theme.mobile`
    padding: 0;  
    flex-direction: column;
  `}
`;

export const ChoosePetsTitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChoosePetsTextWrapper = styled.div`
  ${({ theme }) => theme.mobile`
   max-width: 23rem;`}
`;
