import { useQuery } from '@apollo/client';
import { getVetByUidQuery } from 'gql';

const useVetModal = (uid) => {
  const { data, loading } = useQuery(getVetByUidQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid
      }
    }
  });

  return {
    vet: data?.getVetByUid && !loading ? data?.getVetByUid : null,
    loading
  };
};

export default useVetModal;
