import React, { memo } from 'react';
import cls from 'classnames';
import { cssText, cssColor } from '_fsd/shared';
import css from './PetRow.module.css';
import { petImages } from 'constants/pet';
import capitalize from 'lodash/capitalize';
import { birthDayDateToNumbers } from 'utils/helpers';

const getAge = (date) => {
  if (date) {
    const { year, month, day } = birthDayDateToNumbers(date);
    if (year) return `${year}y `;
    if (month) return `${month}m `;
    if (day) return `${day}m `;
  }
  return null;
};

const PetRowC = ({ className, pet }) => {
  const age = getAge(pet.birthDate);
  const gender = capitalize(pet.gender);
  const type = capitalize(pet.type?.name || '');
  const description = [type, gender, age].filter((s) => s).join(' | ');
  return (
    <div className={cls(css.container, className)}>
      <div className={css.avatarWrapper}>
        <img
          className={css.avatar}
          src={pet?.avatarUrl || petImages[pet?.type?.name || 'other']}
          alt="avatar"
        />
      </div>
      <div className={css.pet}>
        <span className={cssText.s16h24w5}>{pet.name}</span>
        <span className={cls(cssText.s12h16w5, cssColor.cBlackO40)}>{description}</span>
      </div>
    </div>
  );
};

export const PetRow = memo(PetRowC);
