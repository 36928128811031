import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { petAvatars } from 'constants/pet';
import arrowDown from 'assets/icons/arrow-down-thin.svg';
import * as S from '../components';
import Checkbox from '../Checkbox';

const MembershipTableRow = ({
  name,
  type,
  gender,
  birthDate,
  uid,
  avatarUrl,
  selectedPets,
  setSelectedPets
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { t } = useTranslation();

  const [isPetDropdownOpen, setIsPetDropdownOpen] = useState(false);
  const petDropdownToggle = (e) => {
    e.stopPropagation();
    setIsPetDropdownOpen((prev) => !prev);
  };
  useEffect(() => {
    setIsPetDropdownOpen(false);
  }, [isMobile]);

  const checkboxHandler = (uid) => {
    if (selectedPets[uid]) {
      setSelectedPets((prev) => {
        delete prev[uid];
        return { ...prev };
      });
    } else {
      setSelectedPets((prev) => ({ ...prev, [uid]: true }));
    }
  };

  return (
    <S.Tr
      onClick={() => {
        checkboxHandler(uid);
      }}
      checked={selectedPets[uid]}
      isPetDropdownOpen={isPetDropdownOpen}>
      <S.WrapperForResponsive>
        <S.CheckboxAndNameWrapper>
          <S.CheckboxWrapper>
            <Checkbox checked={selectedPets[uid]} />
          </S.CheckboxWrapper>
          <S.NameWrapper>
            <S.ImageWrapper>
              {
                <S.Avatar
                  src={avatarUrl || petAvatars[type?.name]}
                  hasAvatar={avatarUrl}
                  alt="avatar"
                />
              }
            </S.ImageWrapper>
            <S.ResponsiveNameWrapper>
              <S.NameHeaderWrapper isMobile>{t('name')}</S.NameHeaderWrapper>
              <S.NameTextWrapper>{name}</S.NameTextWrapper>
            </S.ResponsiveNameWrapper>
          </S.NameWrapper>
        </S.CheckboxAndNameWrapper>

        <S.DropDownIconWrapper onClick={petDropdownToggle} isPetDropdownOpen={isPetDropdownOpen}>
          <S.DropDownIcon src={arrowDown} alt="dropdownIcon" />
        </S.DropDownIconWrapper>

        <S.TypeWrapper>{type?.label}</S.TypeWrapper>
        <S.GenderWrapper>{gender}</S.GenderWrapper>
        <S.AgeWrapper>{dayjs(birthDate).from(new Date(), true) + ' old'}</S.AgeWrapper>
      </S.WrapperForResponsive>

      {isPetDropdownOpen && (
        <S.ResponsiveHeadingsWrapper>
          <S.SingleHeadingWrapper>
            <S.RespFeaturesWrapper>{t('type')}</S.RespFeaturesWrapper>
            <S.TypeWrapper isMobile>{type.name}</S.TypeWrapper>
          </S.SingleHeadingWrapper>
          <S.SingleHeadingWrapper>
            <S.RespFeaturesWrapper>{t('gender')}</S.RespFeaturesWrapper>
            <S.GenderWrapper isMobile>{gender}</S.GenderWrapper>
          </S.SingleHeadingWrapper>
          <S.SingleHeadingWrapper>
            <S.RespFeaturesWrapper>{t('age')}</S.RespFeaturesWrapper>
            <S.AgeWrapper isMobile>{dayjs(birthDate).from(new Date(), true) + ' old'}</S.AgeWrapper>
          </S.SingleHeadingWrapper>
        </S.ResponsiveHeadingsWrapper>
      )}
    </S.Tr>
  );
};

export default MembershipTableRow;
