export const clientCommunicationConstants = {
  ENABLE_SMS_FIRST_REMINDER: 'ENABLE_SMS_FIRST_REMINDER',
  ENABLE_SMS_SECOND_REMINDER: 'ENABLE_SMS_SECOND_REMINDER',
  ENABLE_SMS_FINAL_ETA: 'ENABLE_SMS_FINAL_ETA',
  ENABLE_SMS_NEXT_CLIENT_ROUTE: 'ENABLE_SMS_NEXT_CLIENT_ROUTE',
  ENABLE_SMS_PET_VISIT_DONE: 'ENABLE_SMS_PET_VISIT_DONE',
  // ENABLE_SMS_VET_RESCHEDULE: 'ENABLE_SMS_VET_RESCHEDULE',
  ENABLE_SMS_WELCOME: 'ENABLE_SMS_WELCOME'
};

export const remindersConstants = {
  MESSAGE_CONTENT_FIRST_REMINDER: {
    key: 'MESSAGE_CONTENT_FIRST_REMINDER',
    parent: clientCommunicationConstants.ENABLE_SMS_FIRST_REMINDER
  },
  MESSAGE_CONTENT_SECOND_REMINDER: {
    key: 'MESSAGE_CONTENT_SECOND_REMINDER',
    parent: clientCommunicationConstants.ENABLE_SMS_SECOND_REMINDER
  },
  MESSAGE_CONTENT_FINAL_ETA: {
    key: 'MESSAGE_CONTENT_FINAL_ETA',
    parent: clientCommunicationConstants.ENABLE_SMS_FINAL_ETA
  },
  MESSAGE_CONTENT_NEXT_CLIENT_ROUTE: {
    key: 'MESSAGE_CONTENT_NEXT_CLIENT_ROUTE',
    parent: clientCommunicationConstants.ENABLE_SMS_NEXT_CLIENT_ROUTE
  },
  MESSAGE_CONTENT_PET_VISIT_DONE: {
    key: 'MESSAGE_CONTENT_PET_VISIT_DONE',
    parent: clientCommunicationConstants.ENABLE_SMS_PET_VISIT_DONE
  },
  MESSAGE_CONTENT_VET_RESCHEDULE: {
    key: 'MESSAGE_CONTENT_VET_RESCHEDULE',
    parent: clientCommunicationConstants.ENABLE_SMS_VET_RESCHEDULE
  },
  MESSAGE_CONTENT_VET_EARLY_FINISH: {
    key: 'MESSAGE_CONTENT_VET_EARLY_FINISH'
  },
  MESSAGE_CONTENT_WELCOME: {
    key: 'MESSAGE_CONTENT_WELCOME',
    parent: clientCommunicationConstants.ENABLE_SMS_WELCOME
  }
};

export const reminderKeys = {
  [remindersConstants.MESSAGE_CONTENT_FIRST_REMINDER.key]: [
    '{firstname}',
    '{pet_names}',
    '{vet_last_name}',
    '{date}',
    '{address}',
    '{time_slot_start}',
    '{time_slot_end}'
  ],
  [remindersConstants.MESSAGE_CONTENT_SECOND_REMINDER.key]: [
    '{firstname}',
    '{vet_last_name}',
    '{address}',
    '{time_slot_start}',
    '{time_slot_end}',
    '{pet_names}',
    '{vet_last_name}',
    '{date}'
  ],
  [remindersConstants.MESSAGE_CONTENT_FINAL_ETA.key]: [
    '{firstname}',
    '{vet_last_name}',
    '{date}',
    '{address}',
    '{eta_start}',
    '{eta_end}',
    '{pet_names}',
    '{pet_names}'
  ],
  [remindersConstants.MESSAGE_CONTENT_NEXT_CLIENT_ROUTE.key]: ['{vet_last_name}'],
  [remindersConstants.MESSAGE_CONTENT_PET_VISIT_DONE.key]: ['{firstname}'],
  [remindersConstants.MESSAGE_CONTENT_VET_RESCHEDULE.key]: [
    '{firstname}',
    '{pet_names}',
    '{new_timeslot_date}',
    '{timeslot_start_hour}',
    '{timeslot_end_time}'
  ],
  [remindersConstants.MESSAGE_CONTENT_VET_EARLY_FINISH]: [
    '{firstname}',
    '{pet_names}',
    '{vet_reason}'
  ],
  [remindersConstants.MESSAGE_CONTENT_WELCOME.key]: ['{first_name}']
};

export const clientsPerHourConstants = {
  ETA_WINDOW_SIZE: 'ETA_WINDOW_SIZE',
  DEFAULT_APPOINTMENT_DURATION: 'DEFAULT_APPOINTMENT_DURATION',
  FIRST_TIME_VISIT_ADDITIONAL_TIME: 'FIRST_TIME_VISIT_ADDITIONAL_TIME',
  CLIENTS_PER_HOUR: 'CLIENTS_PER_HOUR',
  DEFAULT_START_POINT: 'DEFAULT_START_POINT',
  DEFAULT_END_POINT: 'DEFAULT_END_POINT'
};

export const USER_CONCERN = 'USER_CONCERN';

export const preferenceTypeConstants = {
  REMINDERS: 'REMINDERS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  ANOTHER: 'ANOTHER'
};

export const preferenceTitlesAndDescriptions = {
  [clientCommunicationConstants.ENABLE_SMS_FIRST_REMINDER]: {
    title: 'First Reminder',
    description: 'Text message sent to clients one week before their appointment at 6:30 PM.'
  },
  [clientCommunicationConstants.ENABLE_SMS_SECOND_REMINDER]: {
    title: 'Second Reminder',
    description: `Text message sent to clients one day before their appointment at 10:30 AM, 
      with a reminder that it is their last opportunity to cancel or make changes online.`
  },
  [clientCommunicationConstants.ENABLE_SMS_FINAL_ETA]: {
    title: 'Final ETA',
    description:
      'Text message sent to clients one day before their appointment at 5:30 PM, with an estimated time of arrival.'
  },
  [clientCommunicationConstants.ENABLE_SMS_NEXT_CLIENT_ROUTE]: {
    title: 'On My Way',
    description:
      'Text message sent to clients when the previous appointment is finished and you select that you are on the way to them next.'
  },
  [clientCommunicationConstants.ENABLE_SMS_PET_VISIT_DONE]: {
    title: 'Appointment Follow Up',
    description:
      'Text message sent to clients after you mark their appointment completed. Embed a link here to get client feedback and reviews. '
  },
  [clientCommunicationConstants.ENABLE_SMS_VET_RESCHEDULE]: {
    title: 'Reschdule Appointment',
    description: 'Sent to customer by SMS when you are changing his appointment’s date'
  },
  [clientCommunicationConstants.ENABLE_SMS_WELCOME]: {
    title: 'Welcome Message',
    description: `Chat message in Kumba app/pet portal sent to clients when their account is created.`
  },
  [remindersConstants.MESSAGE_CONTENT_FIRST_REMINDER]: {
    title: 'Welcome Message',
    description:
      'Sent when a customer by application’s chat automatically when it is created. an email will be sent if message was not read by customer'
  },
  [clientsPerHourConstants.ETA_WINDOW_SIZE]: {
    title: 'ETA Window Size',
    description: 'The default size (In minutes) of the ETA time window sent to the customer'
  },
  [clientsPerHourConstants.FIRST_TIME_VISIT_ADDITIONAL_TIME]: {
    title: 'Additional time for first-time visits (by patient)',
    description:
      'This time will be added to the appointment  duration for each appointment that you see for the first time'
  },
  [clientsPerHourConstants.DEFAULT_APPOINTMENT_DURATION]: {
    title: 'Client Interaction Time',
    description:
      'The time required for greetings, setup and administrative tasks during each household visit. Excluding the time allocated for each treatment'
  },
  [clientsPerHourConstants.CLIENTS_PER_HOUR]: {
    title: 'Clients per hour',
    description: 'Number of clients you see per hour in average'
  },
  [clientsPerHourConstants.DEFAULT_START_POINT]: {
    title: 'Route Starting Point',
    description:
      "The default starting location for your routes (e.g. your office). This setting won't impact routes with already scheduled appointments."
  },
  [clientsPerHourConstants.DEFAULT_END_POINT]: {
    title: 'Route Ending Point',
    description:
      "The default final location for your routes (e.g. your home). This setting won't affect routes with already scheduled appointments."
  },
  [remindersConstants.MESSAGE_CONTENT_FIRST_REMINDER]: {
    title: 'First Reminder'
  },
  [remindersConstants.MESSAGE_CONTENT_SECOND_REMINDER]: {
    title: 'Second Reminder'
  },
  [remindersConstants.MESSAGE_CONTENT_FINAL_ETA]: {
    title: 'Final ETA'
  },
  [remindersConstants.MESSAGE_CONTENT_NEXT_CLIENT_ROUTE]: {
    title: 'On My Way'
  },
  [remindersConstants.MESSAGE_CONTENT_PET_VISIT_DONE]: {
    title: 'Appointment Follow Up'
  },
  [remindersConstants.MESSAGE_CONTENT_VET_RESCHEDULE]: {
    title: 'Reschedule Appointment'
  },
  [remindersConstants.MESSAGE_CONTENT_VET_EARLY_FINISH]: {
    title: 'Early finish'
  },
  [remindersConstants.MESSAGE_CONTENT_WELCOME]: {
    title: 'Welcome'
  }
};
