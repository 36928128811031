import styled from 'styled-components';

export const CardWrapper = styled.div`
  min-height: 6.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding: 0 1.6rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(219, 226, 238, 0.3);
  }

  ${({ theme }) => theme.laptop`
    padding-left: 0.4rem;
  `}
  ${({ theme }) => theme.laptopSm`
    padding-right: 0;
  `}
  ${({ theme }) => theme.mobile`
    padding-right: 1.6rem;
  `}
`;

export const PetImage = styled.img`
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 5.7rem;
  object-fit: cover;
  user-select: none;

  ${({ theme }) => theme.laptop`
    width: 4rem;
    height: 4rem;
  `}
  ${({ theme }) => theme.laptopSm`
    display : none;
  `}
  ${({ theme }) => theme.mobile`
     display : block;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2.1rem;
  max-width: 76%;
  user-select: none;

  ${({ theme }) => theme.laptop`
    margin-left: 1.6rem;
  `}
  ${({ theme }) => theme.laptopSm`
    margin-left: 0;
  `}

  ${({ theme }) => theme.mobile`
     max-width: 70%;
     margin-left: 1.6rem;
  `}
`;

export const NotificationMessage = styled.p`
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: ${({ isRead }) => (isRead ? '500' : '600')};
  color: #323b4b;
  line-height: 2.1rem;
  margin-bottom: 0.2rem;
  padding-top: 1rem;
  user-select: none;
  line-break: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.laptopSm`
    font-size: 1.2rem;
  `}
  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
  `}
`;

export const TimeText = styled.p`
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  color: #797f8a;
`;

export const ActivePoint = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.orange};
  position: absolute;
  right: 2rem;

  ${({ theme }) => theme.mobile`
    right: 0rem;
    `}
`;
