import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';

import { getTimeSlotTime } from 'utils/helpers';

import './style.scss';

const EtaTooltip = ({ appointment, isEtaExists, setSelectedAppointment, tooltipRef }) => {
  const { t } = useTranslation();
  const eta = isEtaExists(appointment);
  const { appointmentRoute } = appointment;
  return (
    <div className="eta-tooltip-wrapper">
      <div className="eta-content-wrapper">
        <div
          className={`eta-content ${!eta ? 'text-center' : ''}`}
          style={{ width: eta ? '40%' : '100%' }}>
          <p className="eta-content-title">{t('eta_tooltip.duration')}</p>
          <p className="eta-content-text">
            {appointmentRoute?.duration} {t('eta_tooltip.min')}
          </p>
        </div>
        {eta && (
          <div className="eta-content" style={{ width: '60%' }}>
            <p className="eta-content-title">{t('eta_tooltip.current_eta')}</p>
            <p className="eta-content-text">
              {getTimeSlotTime(appointmentRoute?.etaStart)} -{' '}
              {getTimeSlotTime(appointmentRoute?.etaEnd)}
              <EditOutlined
                onClick={() => {
                  setSelectedAppointment((prev) => ({ ...prev, eta: appointment }));
                }}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default EtaTooltip;
