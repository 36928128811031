import React from 'react';
import css from './SelectClinicMultipleVet.module.css';
import { Checkbox, getVetName } from '_fsd/shared';
import { MedicineBoxOutlined } from '@ant-design/icons';
import { User } from '_fsd/entities/user';
import { getSelectedLabel } from '../lib';
import { Select } from 'antd';

const Option = ({ vet, selected, onClick }) => (
  <div key={vet.uid} className={css.vetCheckbox} onClick={onClick}>
    <Checkbox checked={selected} />
    <User user={vet?.user} />
  </div>
);

const Selected = ({ text }) => {
  return (
    <div className={css.box}>
      <MedicineBoxOutlined />
      <span>{text}</span>
    </div>
  );
};

export const SelectClinicMultipleVet = ({ setSelectedVets, selectedVets, vets }) => {
  const options = (vets || []).map((v) => {
    const selected = selectedVets.some((s) => s.uid === v.uid);
    const onClick = (e) => {
      e.stopPropagation();
      if (selected) {
        setSelectedVets(selectedVets.filter((s) => s.uid !== v.uid));
      } else {
        setSelectedVets([...selectedVets, v]);
      }
    };
    return {
      value: v.uid,
      name: getVetName(v),
      label: <Option key={v.uid} vet={v} selected={selected} onClick={onClick} />
    };
  });
  const text = getSelectedLabel(selectedVets, vets);
  const selected = { value: 1, label: <Selected text={text} /> };
  return (
    <div className={css.container}>
      <Select
        className={css.select}
        value={selected}
        options={options}
        onSelect={() => {}}
        showSearch
        optionFilterProp={'name'}
      />
    </div>
  );
};
