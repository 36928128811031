import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import {
  CloseIconWrapper,
  Wrapper,
  IconBackground,
  Caption,
  SubCaption,
  ActionsWrapper,
  ButtonWrapper,
  IconWrapper
} from './components';
import Close from 'components/common/close/Close';
import Button from 'components/common/button/Button';

const PetAcceptionControlModal = ({
  isOpen,
  onRequestClose,
  icon,
  caption,
  subCaption,
  submitHandler,
  closeHandler
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Wrapper>
        <CloseIconWrapper>
          <Close clickHandler={onRequestClose} />
        </CloseIconWrapper>
        <IconBackground>
          <IconWrapper>{icon}</IconWrapper>
        </IconBackground>
        <Caption>{caption}</Caption>
        {subCaption && <SubCaption> {subCaption}</SubCaption>}
        <ActionsWrapper>
          <ButtonWrapper>
            <Button
              label={t('no')}
              fitted
              color="#ffffff"
              fontColor="#36475f"
              submitHandler={closeHandler}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button label={t('yes')} fitted color="primary" submitHandler={submitHandler} />
          </ButtonWrapper>
        </ActionsWrapper>
      </Wrapper>
    </Modal>
  );
};

export default PetAcceptionControlModal;
