import styled, { css } from 'styled-components';

export const PetModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 41.3rem;
  margin-top: 2.6rem;
  margin-bottom: 2.8rem;

  ${({ theme }) => theme.laptop`
    width: 41.4rem;
  `}

  ${({ theme }) => theme.mobile`
    width: 31.4rem;
  `}
`;

export const Exit = styled.div`
  display: flex;
  margin-left: 36.6rem;
  margin-right: 2rem;
  margin-top: 0.1rem;
  cursor: pointer;

  ${({ theme }) => theme.mobile`
    margin-left: 27rem;
  `}

  & svg {
    fill: #9094ac;
  }
`;

export const Title = styled.h2`
  font-family: inter, assistant;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
  color: rgba(45, 51, 99, 1);
  margin: 2.7rem 3.3rem 0 3.3rem;
  text-align: center;

  ${({ theme }) => theme.mobile`
    max-width: 30rem;
    text-align: center;
    margin-top: 3.7rem;
  `}
`;

export const Description = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1rem.8rem;
  color: rgba(144, 148, 172, 1);
  margin: 1.2rem 2.9rem 0 2.9rem;
  text-align: center;

  ${({ theme }) => theme.mobile`
    margin-top: 3.7rem;
    text-align: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35.8rem;
  height: 4.4rem;
  margin-top: 3.2rem;

  ${({ theme }) => theme.laptop`
    height: 4.9rem;
  `}

  ${({ theme }) => theme.mobile`
    width: 26.8rem;
    text-align: center;
    margin-top: 5rem;
    gap : 1rem;
  `}
`;

export const TextStyle = styled.strong`
  font-family: inter, assistant;
  font-weight: 700;
  ${({ congratulations }) =>
    congratulations &&
    css`
      font-family: inter, assistant;
      font-weight: 600;
      color: #24acb9;
    `}
`;
