import { gql, useMutation } from '@apollo/client';

export const useAssignUserConcernToVet = (vetIds, userConcernId) => {
  return useMutation(query, {
    variables: {
      record: {
        vetIds,
        userConcernId
      }
    }
  });
};

const query = gql`
  mutation assignUserConcernToVet($record: AssignUserConcernToVetRecord!) {
    assignUserConcernToVet(record: $record) {
      uid
    }
  }
`;
