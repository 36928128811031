import styled, { css } from 'styled-components';

export const ItemWrapper = styled.div`
  padding: 1.8rem 1rem;
  height: 6.4rem;
  width: unset;

  background: #ffffff;
  border: 0.1rem solid #dbe2ee;
  box-sizing: border-box;
  box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.03);
  border-radius: 0.8rem;
  width: 100%;
  max-width: ${({ singlePet }) => (singlePet ? '60.6rem' : 'auto')};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ missed }) =>
    missed &&
    css`
      background: linear-gradient(0deg, rgba(228, 78, 97, 0.04), rgba(228, 78, 97, 0.04)), #ffffff;
      border: 0.04rem solid #e44e61;
    `}

  ${({ theme, singlePet }) => theme.desktop`
     max-width: ${singlePet ? 'initial' : ''}; 
    `}

  ${({ theme }) => theme.minWidth.desktopS`
    width: 100%;
    ${({ singlePet }) =>
      !singlePet &&
      css`
        height: 9.6rem;
      `} 
    padding: ${({ singlePet }) => (singlePet ? '1.8rem 1.6rem' : '2rem 3.2rem 2.4rem 2.4rem')};
  `}
         

  ${({ theme }) => theme.mobile`
    height: 11.5rem;
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    position: relative;
    width: calc(100% - 1.9rem);
  `}
`;
export const VisitTypeIcon = styled.img`
  width: 2.8rem;
  height: 2.8rem;
  padding: 0.6rem;

  border-radius: 0.8rem;
  background-color: #acbcd4;

  ${({ theme }) => theme.minWidth.desktopS`
    width: ${({ singlePet }) => (singlePet ? '2.8rem' : '4.4rem')};
    height: ${({ singlePet }) => (singlePet ? '2.8rem' : '4.5rem')};
    padding: ${({ singlePet }) => (singlePet ? '0.6rem' : '1rem')};
  `}
`;

export const VisitType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.4rem;
  margin-left: 1.4rem;
  margin-right: 5rem;

  ${({ theme }) => theme.minWidth.desktopS`
    margin-left: 0.7rem;
    max-width : none;
  `}

  ${({ theme }) => theme.laptop`
    margin-right: 4rem;
  `}

  ${({ theme }) => theme.mobile`
     height: 2.1rem;
     margin-left: 1.6rem;
     margin-bottom: 0.45rem;
  `}
`;

export const TypeTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: ${({ isSelectedTimeline }) => (isSelectedTimeline ? '600' : '500')};
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #323b4b;
  width: 16.4rem;
  margin-left: 0.4rem;
  white-space: nowrap;

  font-size: 1.4rem;
  max-width: 14rem;
  line-height: 2.1rem;

  ${({ theme }) => theme.minWidth.desktopS`
    font-size: 1.6rem;
    max-width:none;
    line-height: 2.4rem;
  `}

  ${({ theme }) => theme.mobile`
     margin-left: 0rem;
     font-size: 1.4rem;
  `};
`;
export const PetName = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
`;

export const PetsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-right: 6.3rem;
  ${({ theme }) => theme.mobile`
  position: absolute;
  right: 1rem;
 
  `}
`;

export const Pets = styled.div`
  display: flex;
`;

export const Pet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  height: 2.4rem;
  left: 50.8rem;
  top: 38.2rem;
  background: #f3f5f9;
  border-radius: 0.4rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #59616e;
  margin-right: 0.8rem;

  ${({ theme }) => theme.mobile`
    width: 2rem;
    margin-right: 0rem;
    height: 2rem;
  `}
`;

export const Dots = styled.h4`
  width: 1.3rem;
  height: 1.6rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;

  ${({ theme }) => theme.mobile`
    // display: none;
    width: 2rem;
    height: 2rem;
    background: #89D2E466;
    color: #22A3C3;
    font-family: inter,assistant;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.4rem;
    border-radius: 0.4rem;
    user-select: none;

  `}
`;

export const PetImage = styled.img`
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 0.2rem;
  object-fit: cover;
  margin-right: 0.4rem;
`;
export const PetLetter = styled.div`
  margin-right: 0.4rem;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.colors.black};
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
`;

export const Line = styled.span`
  width: 0.1rem;
  height: 3.2rem;
  background: #dbe2ee;
`;

export const Vet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  max-width: ${({ singlePet }) => (singlePet ? '10rem' : '13.8rem')};
  height: 2.1rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  margin-left: 4.3rem;

  width: unset;
  ${({ theme }) => theme.minWidth.desktopS`
    width: ${({ singlePet }) => (singlePet ? 'auto' : '13.8rem')};
  `}

  ${({ theme }) => theme.mobile`
     height: 2.1rem;
     margin-left: 1.6rem;
     font-size: 1.1rem;
  `}
`;

export const Doctor = styled.div`
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.2rem;
`;

export const DoctorImage = styled.img`
  margin-right: 0.4rem;
  height: 2rem;
  width: 2rem;
  border-radius: 1.6rem;
  object-fit: cover;

  ${({ theme }) => theme.mobile`
     height: 1.6rem;
     width: 1.6rem;
  `}
`;

export const DoctorName = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 1.6rem;
  margin-right: 0.4rem;
  color: ${({ theme }) => theme.colors.black};
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
`;
export const DotctorFullName = styled.h3`
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0;
  max-width: 8rem;
  text-transform: capitalize;
  height: 2.2rem;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.darkGreen};
    color: ${({ theme }) => theme.colors.darkGreen};
  }
`;

export const ItemDetails = styled.div`
  height: 2.1rem;
  display: flex;
  align-items: center;
  margin-left: 6rem;
  > svg {
    margin-right: 0.6rem;
  }

  ${({ theme }) => theme.laptop`
    margin-left: 4.5rem;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 1rem;
    width: 100%;
    margin-left: 0rem;
    > svg {
      min-width: 1.2rem;
      height: 1.2rem;
    }
  `}
`;

export const Plan = styled.div`
  height: 2.1rem;
  width: 10rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #59616e;

  ${({ theme }) => theme.mobile`
   min-width: 7.8rem;
   margin-right: 1.5rem;
   font-size: 1.1rem;
  `}

  ${({ theme }) => theme.mobileS`
    margin-right: 0.5rem;
  `}
`;

export const ExtraCost = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 14rem;
  height: 2.1rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #59616e;
  > svg {
    margin-right: 0.6rem;
  }
  > strong {
    font-family: inter, assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2.1rem;
    margin-right: 0.5rem;
    ${({ theme }) => theme.mobile`
    font-size: 1.1rem;
    margin-right: 0.2rem;
    `}
  }

  ${({ theme }) => theme.mobile`
   font-size: 1.1rem;
   > svg {
      max-width: 1.1rem;
      height: 1rem;
    }
  `}
`;

export const ExtraCostWrapper = styled.p`
  ${({ theme }) => theme.mobile`
  white-space: nowrap;
  max-width: 2rem;
  text-overflow: ellipsis;
`}
`;

export const Status = styled.div`
  width: 4.2rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8rem;
  margin-left: 3.9rem;
  margin-right: 2.4rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: #e44e61;

  position: absolute;
  right: -2rem;
  top: 0.5rem;
  font-size: 1rem;

  ${({ theme }) => theme.minWidth.desktopS`
    font-size: 1.2rem;
    position: initial;
  `}

  ${({ theme }) => theme.minWidth.desktop`
    position: absolute;
  `}

  ${({ theme }) => theme.mobile`
    position:absolute;
    top: -2.3rem;
    right: -2.2rem;
  `}
`;

export const ArrowWrapper = styled.div`
  transform: ${({ isRtl }) => (isRtl ? 'rotate(90deg)' : 'rotate(-90deg)')};
  cursor: pointer;
  ${({ theme }) => theme.minWidth.desktopS`
    display: block;
  `}
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const VisitIcon = styled.img`
  background-color: #acbcd4;
  border-radius: 0.8rem;

  width: 2.8rem;
  height: 2.8rem;
  padding: 0.5rem;

  ${({ theme }) => theme.minWidth.desktopS`
    width: 4.4rem;
    height: 4.5rem;
    padding: 0.9rem;
  `}
`;

export const VetAndVisitWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    align-items: flex-start;
`};
`;

export const DetailsLine = styled.div`
  width: 0.1rem;
  height: 2rem;
  background: #dbe2ee;
  margin-right: 1.5rem;
  ${({ theme }) => theme.mobileS`
    margin-right: 1rem;
  `}
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 0.2rem;
  position: absolute;
  background-color: #f5f6f9;
  bottom: 4.2rem;
  display: none;

  ${({ theme }) => theme.mobile`
    display: block;
  `}
`;
