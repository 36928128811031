import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const ProfileText = styled.span`
  font-size: 2rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;
