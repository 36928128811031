import React, { memo, useContext } from 'react';
import Modal from './Modal';
import style from './ModalAccountNotFound.module.css';
import { Button } from 'antd';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';

const ModalAccountNotFound = ({ closeModal, newClientHandler }) => {
  return (
    <Modal title={'Account Not Found'} className={style.container}>
      <span className={style.description}>
        It&apos;s possible that the account is linked to another family member’s number.
      </span>
      <span className={style.title}>Please consider the following options:</span>
      <Button type="primary" className={style.buttonNew} onClick={newClientHandler}>
        Continue as new client
      </Button>
      <Button className={style.buttonBack} onClick={() => closeModal(true)}>
        Try another number
      </Button>
    </Modal>
  );
};

const Memoized = memo(ModalAccountNotFound);
const Container = () => {
  const { closeModal, newClientHandler } = useContext(ScheduleAppointmentContext);
  return <Memoized newClientHandler={newClientHandler} closeModal={closeModal} />;
};

export default Container;
