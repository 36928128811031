import styled from 'styled-components';

export const PageTitle = styled.h2`
  font-family: inter, assistant;
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 2rem;
  cursor: initial;
  ${({ theme }) => theme.laptop`
    margin-bottom: 0.8rem;
  `}
  ${({ theme }) => theme.mobile`
    font-size: 2rem;
  `}
`;

export const PageSubTitle = styled.h3`
  font-family: inter, assistant;
  font-size: 1.8rem;
  font-weight: 500;
  color: #8a94a6;
  margin-bottom: 3.2rem;
  ${({ theme }) => theme.laptop`
    margin-bottom: 2.4rem;
  `}
  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
  `}
`;
