import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

export const PetCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12.7rem;
  text-align: center;
  ${({ theme }) => theme.minWidth.desktopS`
    margin-right: 2rem;
    margin-left: 2rem;
  `}
  ${({ theme, isExtraVisitOpen }) => theme.mobile`
    display:flex;
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: unset;
    padding-right: 0.3rem;
    margin-bottom: 2.4rem;
    ${isExtraVisitOpen && 'margin-bottom: 6rem;'}
  `}
`;

export const CheckoutDetailsPetCard = styled(Link)`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0rem;
  cursor: pointer;

  ${({ theme }) => theme.minWidth.desktopS`
    margin-bottom: 1.5rem;
    `}

  ${({ theme }) => theme.mobile`
      max-width: 12.7rem;
      display:flex;
      align-items: center;
      margin-bottom: 0rem;
    `}
`;

export const CheckoutDetailsPetCardImage = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  padding: ${({ hasImage }) => (hasImage ? 0 : '.7rem')};
  background-color: ${({ hasImage }) => (hasImage ? 'none' : 'rgba(219, 226, 238, 1)')};
  object-fit: cover;
  border: 0.15rem solid rgba(255, 255, 255, 0.2);
  border-radius: 1.2rem;
  box-shadow: 0px 1.2rem 3.2rem -1.2rem rgba(45, 28, 28, 0.25);

  ${({ theme }) => theme.minWidth.desktopS`
    width: 7.2rem;
    height: 7.2rem;
  `}
`;

export const CheckoutDetailsPetCardName = styled.h3`
  font-family: inter, assistant;
  font-weight: 600;

  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0.8rem;
  font-size: 1.4rem;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 16rem;
  white-space: nowrap;

  ${({ theme }) => theme.minWidth.desktopS`
       margin-top: 1.3rem;
       font-size: 1.6rem;
   `}

  ${({ theme }) => theme.mobile`
    font-family: inter,assistant;
    font-size: 1.4rem;
    font-weight: 600;
    color: #323B4B;
    margin-top: 0rem;
    margin-left: 1.3rem;
    width: unset;
    `}
`;

export const VetWrapper = styled.div`
  background: ${({ isSubscribed, theme }) =>
    isSubscribed ? 'rgba(219, 226, 238, 0.4)' : theme.colors.orange};
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: ${({ isSubscribed }) => (isSubscribed ? 'space-between' : 'center')};
  position: relative;
  cursor: pointer;
  width: 12.7rem;
  height: 3.2rem;
  margin-top: 0.6rem;

  ${({ isRtl }) =>
    isRtl &&
    css`
      direction: ltr;
    `}

  ${({ theme }) => theme.minWidth.desktopS`
    width: 15.5rem;
    height: 4.4rem;
  `}

  ${({ theme }) => theme.mobile`
    width: 13.4rem;
    height: 3.6rem;
  `}
`;

export const VetInfo = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 13.5rem;
  ${({ theme }) => theme.desktopS`
    width: 10rem;
    `}
`;

export const VetImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border-radius: 1.6rem;
`;

export const VetName = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ isSubscribed, theme }) => (isSubscribed ? theme.colors.black : theme.colors.white)};
  width: 11.4rem;
  height: 2.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  padding-left: 0.4rem;
  text-transform: capitalize;
  ${({ isVet }) =>
    isVet &&
    css`
      :hover {
        border-bottom: 0.1rem solid
          ${({ isSubscribed, theme }) =>
            isSubscribed ? theme.colors.darkGray : theme.colors.white};
      }
    `}
  ${({ theme }) => theme.mobile`
    padding-right: 0.4rem;
  `}

  ${({ theme }) => theme.desktopS`
    width: 10.4rem;
  `}
`;

export const AvatarWithText = styled.div`
  min-width: 2.4rem;
  height: 2.4rem;
  padding-top: 0.3rem;
  padding-left: 0.2rem;
  background: rgba(219, 226, 238, 1);
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const VetArrowWrapper = styled.div`
  position: absolute;
  transform: rotate(90deg);
  right: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
  top: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${({ hasActiveSubscription, theme }) =>
      hasActiveSubscription ? theme.colors.white : 'rgba(255, 255, 255, 0.3)'};
  }
  border-radius: 0.4rem;

  ${({ theme }) => theme.desktopS`
    height: 1.6rem;
    width: 1.6rem;
  `}

  ${({ theme }) => theme.mobile`
    padding-left: unset;
  `}

  & svg {
    min-height: 0.9rem;
    min-width: 0.6rem;
    max-width: 0.6rem;
    transition: 0.2s ease-in-out;
    fill: ${({ theme }) => theme.colors.black};
    ${({ isExtraVisitOpen }) => isExtraVisitOpen && '  transform: rotate(180deg);'}
    ${({ hasActiveSubscription }) =>
      !hasActiveSubscription &&
      css`
        fill: ${({ theme }) => theme.colors.white};
      `}
    ${({ theme }) => theme.mobile`
        height: 1rem;
        width: 0.87rem;
      `}
  }
`;

export const Line = styled.div`
  position: absolute;
  right: 2.4rem;
  height: 50%;
  width: 0.1rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ExtraVisitWrapper = styled.a`
  background: #22a3c3;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  cursor: pointer;

  width: 12.7rem;
  height: 3.2rem;

  ${({ theme }) => theme.minWidth.desktopS`
    width: 15.5rem;
    height: 4.4rem;
  `}

  ${({ theme }) => theme.mobile`
    height: 3.6rem;
  `}
`;
export const ExtraVisitText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.white};
`;
