import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { petAvatars } from 'constants/pet';

const AvatarGroup = ({ pets }) => {
  if (!pets.length) return null;
  return (
    <Avatar.Group maxCount={2} maxStyle={{ color: '#fff', backgroundColor: '#22A3C3' }}>
      {pets.slice(0, 2).map((pet, i) => (
        <Tooltip placement="top" title={pet?.name} key={i}>
          <Avatar
            style={{ backgroundColor: '#000', padding: '.4rem' }}
            src={pet?.avatarUrl || petAvatars[pet?.type?.name] || petAvatars.other}
          />
        </Tooltip>
      ))}
      {pets.slice(2, pets.length).map((pet, i) => (
        <Tooltip placement="top" title={pet?.name} key={i}>
          <Avatar
            style={{ backgroundColor: '#000', padding: '.4rem' }}
            src={pet?.avatarUrl || petAvatars[pet?.type?.name] || petAvatars.other}
          />
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default AvatarGroup;
