import React from 'react';

import * as S from './components';

import SinglePetDetails from 'components/single-pet-details/SinglePetDetails';

const PetsPage = () => {
  return (
    <S.Wrapper>
      <SinglePetDetails />
    </S.Wrapper>
  );
};

export default PetsPage;
