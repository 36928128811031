import React from 'react';
import ContentLoader from 'react-content-loader';

const SinglePetDetailsLoader = ({ isMobile }) => {
  return (
    <ContentLoader
      speed={2}
      width={isMobile ? '127' : '170'}
      height={isMobile ? '127' : '157'}
      viewBox="0 0 100% 100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width={isMobile ? '127' : '170'}
        height={isMobile ? '127' : '157'}
      />
    </ContentLoader>
  );
};

export default SinglePetDetailsLoader;
