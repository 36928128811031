import React from 'react';

import Flag from 'components/common/flag/Flag';

import * as S from './components';

const Language = ({ language }) => {
  return (
    <>
      {language && (
        <S.Wrapper>
          <Flag country={language?.code.toLowerCase()} />
          <S.Name>{language?.name}</S.Name>
        </S.Wrapper>
      )}{' '}
    </>
  );
};

export default Language;
