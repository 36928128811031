import css from './TagEditable.module.css';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import useOutsideClickDetector from 'hooks/useOutsideClickDetector';

export const TagEditable = ({
  active,
  className,
  children,
  color,
  onChange,
  onDoubleClick,
  onClick,
  onClose,
  defaultName,
  onMouseEnter,
  onMouseLeave,
  hideColorBox = false,
  size = 'medium',
  focusWhenNameIsEmpty = false
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [editable, setEditable] = useState(focusWhenNameIsEmpty);
  const [name, setName] = useState('');
  const handleNewName = () => {
    if (editable && name) {
      onChange(name);
      setEditable(false);
    } else if (editable) {
      if (focusWhenNameIsEmpty && defaultName) {
        onChange(defaultName);
      }
      setEditable(false);
    }
  };
  useOutsideClickDetector(containerRef, handleNewName);
  useEffect(() => {
    if (inputRef?.current && focusWhenNameIsEmpty) {
      inputRef?.current.focus();
    }
  }, []);
  return (
    <div
      style={{ borderColor: color }}
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <div
        className={cls(css.tag, className, {
          [css.sm]: size === 'small',
          [css.md]: size === 'medium',
          [css.lg]: size === 'large'
        })}
        style={
          color && active
            ? {
                borderColor: color
              }
            : {}
        }
        onDoubleClick={
          onDoubleClick
            ? onDoubleClick
            : onChange
              ? (e) => {
                  e.stopPropagation();
                  setEditable(true);
                  setTimeout(() => inputRef?.current?.focus(), 0);
                }
              : undefined
        }
        onClick={onClick ? onClick : undefined}>
        {color && !hideColorBox ? (
          <div className={css.square} style={{ background: color }} />
        ) : null}
        {editable ? (
          <input
            ref={inputRef}
            onBlur={handleNewName}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleNewName();
              }
            }}
            defaultValue={children}
            className={css.input}
          />
        ) : (
          <span className={css.name}>{children}</span>
        )}
        {onClose ? (
          <div
            className={css.closeIcon}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}>
            <CloseOutlined />
          </div>
        ) : null}
      </div>
    </div>
  );
};
