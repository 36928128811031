export const SubscriptionStatus = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  FAILED_TO_PAY: 'failed_to_pay'
};

export const SubscriptionTypeName = {
  MONTHLY: 'monthly',
  ONE_TIME: 'one_time'
};

export const WorkingAreaType = {
  polygon: 'polygon',
  zipCode: 'zipCode'
};

export const PropertyType = {
  PrivateHome: 'private_home',
  Apartment: 'apartment'
};
