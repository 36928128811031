import React from 'react';
import { parseTimeSlotHour } from 'utils/helpers';
import useDateFormat from 'common_repo/components/useDateFormat';
import * as S from '../appointments/components';

const TimelineTimeBox = ({ isActive, visit, singlePet }) => {
  const { dateFormat } = useDateFormat();

  return (
    <S.TimelineTimeBox isActive={isActive} singlePet={singlePet}>
      <S.TimelineDateWrapper>
        <S.TimelineDateText>{dateFormat(visit?.route?.date)}</S.TimelineDateText>
        <S.TimelineTimeText>
          {parseTimeSlotHour(visit?.route?.timeSlot?.startTime)} -
          {parseTimeSlotHour(visit?.route?.timeSlot?.endTime)}
        </S.TimelineTimeText>
      </S.TimelineDateWrapper>
    </S.TimelineTimeBox>
  );
};

export default TimelineTimeBox;
