import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45.9rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  padding: 2rem 2rem 4rem 4.1rem;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      width: 44.8rem;
      height: 36.8rem;
      padding: 2rem 3.2rem 3.2rem 3.2rem;
    `}
  ${({ theme }) => theme.mobile`
    width: 31.4rem;
    padding: 1.2rem 0.9rem 3.2rem 2rem;
  `}
`;

export const Header = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 30.5rem;
  height: 15.3rem;
  margin-top: 2rem;
  display: flex;
  margin-left: auto;
  justify-content: center;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      width: unset;
    `}
  ${({ theme }) => theme.mobile`
    width: 13.4rem;
    margin-top: 3.3rem;
    height: 12.4rem;
    margin-left: 7.3rem;
    > svg {
      width: 13.4rem;
      height: 8.8rem;
    }
  `}
`;

export const Exit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.8rem;
  margin-left: auto;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      margin-top: unset;
      margin-left: 7.9rem;
    `}
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 2.1rem;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      padding-right: unset;
    `}
  ${({ theme }) => theme.mobile`
    padding: unset;
  `}
`;

export const MainTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2d3363;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      line-height: 2.8rem;
    `}
`;

export const HighlightedPetName = styled(MainTitle)`
  font-weight: 700;
  margin-left: 0.6rem;
  ${({ theme }) => theme.mobile`
    display: contents;
    text-transform: capitalize;
  `}
`;

export const MainText = styled.h5`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  color: #9094ac;
  width: 29rem;
  margin-top: 1.6rem;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      margin-top: unset;
      color: #2d3363;
      font-family: inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.8rem;
    `}
`;

export const Fotter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 2.3rem;
  margin-top: 3.2rem;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      margin-top: 4.8rem;
    `}
  ${({ theme }) => theme.mobile`
    margin-right: unset;
  `}
`;

export const Input = styled.input`
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid rgba(172, 188, 213, 0.56);
  box-sizing: border-box;
  box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.02);
  border-radius: 0.8rem;
  width: 24.5rem;
  height: 4.8rem;
  padding: 1.5rem 0 1.6rem 1.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #696e8c;
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.mobile`
    width: 15.2rem;
  `}
`;

export const Confirm = styled.button`
  width: 11.8rem;
  height: 4.8rem;
  background: ${({ theme }) => theme.colors.ash};
  border-radius: 0.8rem;
  border: none;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.errorRed};
  cursor: pointer;
  ${({ isForAppointment }) =>
    isForAppointment &&
    css`
      width: 12.7rem;
    `}
  ${({ theme }) => theme.mobile`
      width: 10.8rem;
  `}
`;
