import React from 'react';
import DP from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import useDateFormat from 'common_repo/components/useDateFormat';
import { useMediaQuery } from 'react-responsive';

import Selector from 'components/common/selector/Selector';
import Input from 'components/common/input/Input';
import css from './DatePicker.module.css';
import {
  Wrapper,
  DatePickerHeader,
  HeaderBottomWrapper,
  MonthSelectorWrapper,
  Calendar,
  SelectorWrapper,
  SelectedDate,
  Month,
  Arrow,
  CalendarHeaderWrapper
} from './components';

import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-orange.svg';
import { range, months } from 'utils/helpers';
import { useLocalizationContext } from 'common_repo';
import theme from 'styles/theme';

const DatePicker = ({
  value,
  label,
  error,
  isForTimeSlot,
  hideHeader,
  setIsCalendarOpen,
  startYear = 1900,
  endYear = 0,
  ...props
}) => {
  const { t } = useTranslation();
  const years = range(startYear, new Date().getFullYear() + endYear, 'desc');
  const { dateFormat } = useDateFormat();
  const { rtl: isRtl } = useLocalizationContext();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  return (
    <Wrapper isForTimeSlot={isForTimeSlot} className={css.container}>
      <DP
        {...props}
        calendarClassName="calendar"
        wrapperClassName="wrapper"
        popperPlacement={isMobile ? 'top-end' : ''}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
        customInput={<Input label={label} error={error} isCalendarInput hideKeyboardOnFocus />}
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {
          return (
            <DatePickerHeader isForTimeSlot={isForTimeSlot}>
              {!hideHeader && (
                <CalendarHeaderWrapper>
                  <Calendar>{t('calendar')}</Calendar>
                  <SelectedDate isRtl={isRtl}>{dateFormat(date)}</SelectedDate>
                </CalendarHeaderWrapper>
              )}
              <HeaderBottomWrapper>
                <MonthSelectorWrapper className={css.monthWrapper}>
                  <Arrow
                    right={isRtl}
                    type={'button'}
                    onClick={isRtl ? increaseMonth : decreaseMonth}
                    disabled={isRtl ? nextMonthButtonDisabled : prevMonthButtonDisabled}>
                    <LeftArrow />
                  </Arrow>
                  <Month>{months[new Date(date).getMonth()]}</Month>
                  <Arrow
                    right={!isRtl}
                    type={'button'}
                    onClick={isRtl ? decreaseMonth : increaseMonth}
                    disabled={isRtl ? prevMonthButtonDisabled : nextMonthButtonDisabled}>
                    <LeftArrow />
                  </Arrow>
                </MonthSelectorWrapper>
                <SelectorWrapper>
                  <Selector
                    value={{
                      label: new Date(date).getFullYear(),
                      value: new Date(date).getFullYear()
                    }}
                    options={years}
                    onChange={(item) => changeYear(item.value)}
                    hideLabel
                    noError
                    isForTimeSlot
                    isRtl={isRtl}
                  />
                </SelectorWrapper>
              </HeaderBottomWrapper>
            </DatePickerHeader>
          );
        }}
      />
    </Wrapper>
  );
};

export default DatePicker;
