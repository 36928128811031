import styled from 'styled-components';

export const PetTimelineWrapper = styled.div`
  width: 100%;
  background: #fafbfc;
  border-radius: 0.8rem;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  margin-top: 2.4rem;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
export const TimelineFilterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem 0.8rem 0 0;
  height: 5.6rem;
  padding: 1rem 1rem 1rem 2rem;
`;
export const TimelineFilterContainer = styled.div`
  width: ${({ hasTimelineAppointments }) =>
    hasTimelineAppointments ? '100%' : 'calc(100% - 37.4rem)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;
export const FilterByText = styled.span`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-left: 1.2rem;
  margin-right: 0.8rem;
  opacity: ${({ isTransparent }) => (isTransparent ? '0.5' : '1')};
  color: ${({ theme }) => theme.colors.black};
  ${({ theme, isTransparent }) => theme.mobile`
    display:${isTransparent && 'none'};
  `}
`;
export const YearSelectorWrapper = styled.div`
  width: 10.6rem;
  height: 3.6rem;
`;
