import styled, { css } from 'styled-components';

export const TimelineDataContainer = styled.div`
  background: ${({ singlePet }) => (singlePet ? '#FAFBFC' : '#f2f4f7')};
  position: relative;
  padding-right: ${({ singlePet }) => (singlePet ? '1.4rem' : '0')};
  display: ${({ singlePet }) => (singlePet ? 'flex' : 'block')};
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
    background-color: ${({ theme }) => theme.colors.white};
   `}
`;

export const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 11rem;
  padding: ${({ singlePet }) => (singlePet ? '0rem 2.4rem 0 3.2rem' : '0rem 2rem')};
  padding-right: 0rem;
  &:first-child {
    padding-top: 0.5rem;
    height: 11.5rem;
    ${({ theme }) => theme.desktopS`
      height: auto;
    `}
  }
  &:last-child {
    padding-bottom: 0.5rem;
    height: 11.5rem;
  }
  ${({ theme, singlePet }) => theme.laptop`
    height: ${singlePet ? '7.9rem' : '7.6rem'};
    ${
      singlePet &&
      css`
        padding-bottom: 0;
      `
    }
  `}

  ${({ theme }) => theme.desktopS`
    height: 7.4rem;
  `}

  ${({ theme }) => theme.mobile`
    height: 16rem;
    width: calc(100% - 1.6rem);
    align-items: flex-end;
    justify-content: flex-start;
    overflow-x: hidden;
    padding-bottom: 0.5rem;
  `}
`;
export const TimelineDataBox = styled.div`
  width: 100%;
  max-width: 87.4rem;
  height: 9.6rem;
  background: ${({ theme }) => theme.colors.white};
`;
export const TimelineDateWrapper = styled.div`
  width: 7.9rem;
  ${({ theme }) => theme.mobile`
    position: absolute;
    top: 18px;
    display: flex;
    align-items: center;
    width: unset;
    min-width: 16rem;
  `}
`;

export const HereBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ singlePet }) => (singlePet ? '2rem 1.075rem 2rem 2.4rem' : '2rem 0 2rem 2.4rem')};
  ${({ theme }) => theme.mobile`
    padding: 1.6rem 0 2.4rem 0;
    width: 100%;
  `}
  ${({ theme, singlePet }) => theme.laptop`
    ${
      singlePet &&
      css`
        padding: 1.2rem 0 1.6rem 2.4rem;
      `
    }
  `}
`;

export const HereBackground = styled.div`
  width: 100%;
  height: 0.8rem;
  background: #acbcd4;
  opacity: 0.1;
  border-radius: 0.4rem;
`;
export const HereText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #869dc0;
  white-space: nowrap;
  margin-left: 1rem;
  margin-right: ${({ singlePet }) => (singlePet ? '1.3rem' : '1.2rem')};
  ${({ theme, singlePet }) => theme.laptop`
    ${
      singlePet &&
      css`
        width: unset;
      `
    }
  `}
  ${({ theme }) => theme.mobile`
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  `}
`;

export const CalendarIcon = styled.img`
  height: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '10.6rem')};
  width: ${({ forUpcomming }) => (forUpcomming ? '6.4rem' : '10.6rem')};
  margin-top: ${({ forUpcomming }) => (forUpcomming ? 0 : '7.8rem')};

  ${({ theme }) => theme.laptop`
    width: 4rem;
    height: 4rem;
  `}
`;
export const EmptyUpCommingText = styled.h2`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.9rem;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 2rem;

  ${({ theme }) => theme.laptop`
    font-size: 1.4rem;
  `}
`;
export const EmptyUpComming = styled.div`
  padding-top: 2rem;
  height: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EmptyPast = styled.div`
  height: 29.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextCenter = styled.div`
  text-align: center;
`;
export const EmptyPastText = styled.h2`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.9rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const TimelineDateText = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: #323b4b;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.4rem;
  min-width: 7rem;
  ${({ theme }) => theme.mobile`
    margin-bottom: 0;
  `}
`;
export const TimelineTimeBox = styled.div`
  border-width: 0 0 0 0.1rem;
  border-radius: 0;
  border-style: dashed;
  border-color: ${({ isActive, theme }) => (isActive ? theme.colors.orange : '#acbcd4')};
  height: 100%;
  position: relative;
  padding-left: ${({ singlePet }) => (singlePet ? '2rem' : '1.2rem')};
  display: flex;
  flex-wrap: wrap;
  margin-right: 1.2rem;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background: ${({ isActive, theme }) => (isActive ? theme.colors.orange : '#acbcd4')};
    border: 0.1rem solid ${({ theme }) => theme.colors.white};
    left: -0.85rem;
    top: calc(50% - 0.9rem);
    ${({ theme }) => theme.mobile`
      top: calc(50% - 6rem);
    `}
  }

  ${({ theme }) => theme.laptop`
      margin-right: 1.2rem;
      padding-left: 1.2rem;
    `}

  ${({ theme }) => theme.mobile`
    height: 16rem;
    width: 1.7rem;
    padding-left: 1.7rem;
    margin-right: 0rem;
  `}
`;

export const TimelineTimeText = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: #323b4b;
  color: #59616e;
`;

export const PetTimelineContainer = styled.div`
  width: ${({ singlePet }) => (singlePet ? 'calc(100% - 35.9rem)' : '100%')};
  padding-right: 1.2rem;
  max-width: ${({ singlePet }) => (singlePet ? '77.8rem' : '100%')};
  height: ${({ singlePet }) => (singlePet ? '54.5rem' : 'calc(100vh - 62rem)')};
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
  scroll-behavior: smooth;
  background: ${({ singlePet }) => (singlePet ? ' #fafbfc' : 'transparent')};
  ${({ theme }) => theme.desktop`
    max-width: initial; 
    margin-right: unset;
    margin-left: unset;
  `}

  ${({ theme }) => theme.laptop`
    height: ${({ singlePet }) => !singlePet && 'calc(100vh - 48rem)'};
  `}

  ${({ theme, singlePet }) => theme.mobile`
    height: auto;
    width: ${singlePet && '100%'};
  `}

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #f2f4f7;
  }

  ::-webkit-scrollbar-thumb {
    background: #8a94a640;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f96;
  }
`;

export const SelectedTimeSlotWrapper = styled.div`
  width: 35rem;
  min-height: 46.4rem;
  margin-top: -4.2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  border-radius: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.ash};
  margin-bottom: 2rem;
`;
