import React from 'react';
import dayjs from 'dayjs';
import useDateFormat from 'common_repo/components/useDateFormat.js';

import * as S from './components';

import { parseTimeSlotHour, weekDayNumbers } from '../../utils/helpers';

const TimeSlot = ({ slot, setDate, isActive }) => {
  const { monthFormat } = useDateFormat();
  const isHeb = localStorage.getItem('lang') === 'heb';
  const formatDay = (day) => {
    const dateObject = new Date(dayjs().day(weekDayNumbers[day]));
    return monthFormat(dateObject);
  };

  const handleChooseDate = () => {
    setDate(slot, slot.date);
  };

  const { startTime, endTime, weekDay, date, full } = slot;

  return (
    <S.Wrapper onClick={full ? () => {} : handleChooseDate} isActive={isActive} disabled={full}>
      <S.Content>
        <S.Date isActive={isActive}>{date ? monthFormat(date) : formatDay(weekDay)}</S.Date>
        <S.Hours isActive={isActive}>
          {isHeb
            ? `${parseTimeSlotHour(endTime)}:${parseTimeSlotHour(startTime)}`
            : `${parseTimeSlotHour(startTime)}:${parseTimeSlotHour(endTime)}`}
        </S.Hours>
      </S.Content>
    </S.Wrapper>
  );
};

export default TimeSlot;
