import { gql, useQuery } from '@apollo/client';

export const useGetVetsForVetAdmin = () => {
  const { data, ...rest } = useQuery(query, {
    variables: {}
  });
  return { data: data?.getVetsForVetAdmin, ...rest };
};

const query = gql`
  query getVetsForVetAdmin {
    getVetsForVetAdmin {
      uid
      user {
        uid
        firstName
        lastName
      }
    }
  }
`;
