import React, { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { getInitialLetters } from 'utils/helpers.js';
import theme from 'styles/theme';

import CustomModal from 'components/common/modal/Modal';
import Biography from 'components/biography/Biography.jsx';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import * as S from './components.jsx';

const ChooseAnotherVetModal = ({ isOpen, onRequestClose, isSelectedVet, vets, setCurrentVet }) => {
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState(isSelectedVet);

  useEffect(() => {
    setIsSelected(isSelectedVet);
  }, [isSelectedVet]);

  const handleChoose = () => {
    setCurrentVet(isSelected);
    onRequestClose();
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.Title>
            {isMobile
              ? t('choose_another_vet_modal.choose.vet')
              : t('choose_another_vet_modal.choose.another.vet')}
          </S.Title>
          <S.CloseIcon>
            <CloseIcon onClick={onRequestClose} />
          </S.CloseIcon>
        </S.HeaderWrapper>
        <S.PleaseMessage>
          {t('choose_another_vet_modal.please.fill.information.about.pet')}
        </S.PleaseMessage>
        <S.VetList>
          {vets?.map(({ vet }) => {
            return (
              <S.VetCard
                key={vet?.uid}
                isSelected={isSelected?.uid === vet?.uid}
                onClick={() => setIsSelected(vet)}>
                {isSelected?.uid === vet?.uid && (
                  <S.Button>
                    <S.ButtonCircle />
                  </S.Button>
                )}
                <S.VetMainInfoWrapper>
                  <S.VetMain>
                    <S.VetAvatarWrapper>
                      {vet?.user?.avatarUrl ? (
                        <S.VetAvatar src={vet?.user?.avatarUrl} />
                      ) : (
                        <S.VetInitials>
                          {getInitialLetters(null, vet?.user?.firstName, vet?.user?.lastName)}
                        </S.VetInitials>
                      )}
                    </S.VetAvatarWrapper>
                    <S.VetInfo>
                      <S.Name>{`${vet?.user?.firstName} ${vet?.user?.lastName}`}</S.Name>
                      {vet?.experience && (
                        <S.Experience>
                          {vet?.experience} {t('years.of.experience')}
                        </S.Experience>
                      )}
                      <S.Languages>
                        {vet?.languages?.map((language) => (
                          <S.Language key={language.name}>{language?.name}</S.Language>
                        ))}
                      </S.Languages>
                    </S.VetInfo>
                  </S.VetMain>
                  {vet?.user?.bio && <Biography vet={vet} />}
                </S.VetMainInfoWrapper>
              </S.VetCard>
            );
          })}
        </S.VetList>
        <S.Footer>
          <S.ButtonsWrapper>
            <S.CancelButton onClick={onRequestClose}>{t('cancel')}</S.CancelButton>
            <S.ChooseButton onClick={handleChoose}>{t('choose')}</S.ChooseButton>
          </S.ButtonsWrapper>
        </S.Footer>
      </S.Wrapper>
    </CustomModal>
  );
};

export default memo(ChooseAnotherVetModal);
