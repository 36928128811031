import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 67.6rem;
  background: #f5f6f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.8rem;
  ${({ theme }) =>
    theme.mobile`
      width: 33.6rem;
    `}
  ${({ missed }) =>
    missed &&
    css`
      border-radius: 1.6rem;
    `}
`;

export const Header = styled.header`
  width: 100%;
  height: 6.4rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.6rem 1.6rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem;
  ${({ theme }) => theme.mobile`
    padding: 1.2rem 1.2rem 0.7rem 0.2rem;
    height: 4.7rem;
  `}
  ${({ missed }) =>
    missed &&
    css`
      background: linear-gradient(0deg, rgba(228, 78, 97, 0.08), rgba(228, 78, 97, 0.08)), #ffffff;
      border-bottom: 0.05rem solid rgba(228, 78, 97, 0.4);
      border-radius: 1.6rem 1.6rem 0 0;
    `}
`;

export const HeaderTitle = styled.h2`
  color: rgba(50, 59, 75, 1);
  font-family: inter, assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  margin-left: 2.2rem;
  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
    line-height: 1.8rem;
  `}
`;

export const ExitIcon = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Main = styled.main`
  padding: 3.2rem 3.2rem 2.4rem 3.2rem;
  ${({ theme }) => theme.mobile`
    padding: 1.6rem 1.2rem 1.2rem 1.2rem;
  `}
`;

export const Visit = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  margin-bottom: 1.2rem;
  padding: 2.4rem;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    padding: 1.6rem 1.6rem 2.4rem 1.4rem;
  `}
`;

export const VisitDetails = styled.div`
  width: 100%;
  height: 100%;
`;

export const VisitType = styled.div`
  width: 100%;
  height: 9.6rem;
  display: flex;
`;

export const ShareIconWrapper = styled.div`
  cursor: pointer;
`;

export const VisitTypeIcon = styled.img`
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.darkGray};
`;

export const VisitTypeDetails = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 1.6rem;
`;

export const Missed = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.errorRed};
  margin-left: 1.2rem;
`;

export const VisitTypeDetailsTitle = styled.title`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.7rem;
  display: flex;
  align-items: center;
  color: #323b4b;
  text-transform: capitalize;
`;

export const VisitTimesWrapper = styled.div`
  display: flex;
  margin-top: 0.4rem;
`;

export const VisitTime = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: #59616e;
  margin-right: 1.2rem;
`;

export const VetInfo = styled.div`
  width: 15.3rem;
  height: 18.7rem;
  margin-left: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: rgba(50, 59, 75, 0.5);
  ${({ theme }) => theme.mobile`
    height: unset;
    width: 100%;
    margin-left: unset;
  `}
`;

export const Vet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mobile`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: unset;
    align-items: center;
    padding: 1.1rem 1.5rem;
    background: rgba(245, 246, 249, 0.8);
    border-radius: 0.8rem;
    margin-top: 1.6rem;
  `}
`;

export const VetImage = styled.img`
  width: 13.4rem;
  height: 15.3rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  border-radius: 0.8rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-bottom: 1.1rem;
  object-fit: cover;
  ${({ theme }) => theme.mobile`
    width: 5.2rem;
    height: 5.6rem;
    margin-right: 1.3rem;
  `}
`;

export const VetDefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.4rem;
  height: 15.3rem;
  background-color: rgba(219, 226, 238, 1);
  font-size: 2.6rem;
  color: #ffffff;
  border-radius: 0.8rem;
  margin-bottom: 1.1rem;
  ${({ theme }) => theme.mobile`
    width: 5.2rem;
    height: 5.6rem;
    font-size: 2rem;
    margin-right: 1.3rem;
  `}
`;

export const VetInfoWrapper = styled.div`
  text-align: center;
`;

export const VetName = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
`;

export const ExtraVisit = styled.div`
  background: rgba(34, 163, 195, 0.12);
  border-radius: 0.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(34, 163, 195, 1);
  width: fit-content;
  padding: 0.7rem 1.2rem;
  height: 3.2rem;
  cursor: pointer;
  ${({ isMonthlySubscription }) =>
    isMonthlySubscription &&
    css`
      background-color: rgba(255, 154, 5, 0.12);
      color: rgba(255, 154, 5, 1);
    `}
`;

export const VisitContact = styled.div`
  display: flex;
  flex-direction: column;
  width: 38.2rem;
  margin-top: 2.1rem;
  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-top: 1.6rem;
  `}
`;

export const UserAddress = styled.div`
  width: 100%;
  height: 6.7rem;
  margin-bottom: 2.4rem;
  display: flex;
  ${({ theme }) => theme.mobile`
    justify-content: space-between;
  `}
`;

export const InfoIcon = styled.div`
  min-width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border: 0.04rem solid ${({ theme }) => theme.colors.ash};
  box-sizing: border-box;
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  ${({ theme }) => theme.mobile`
    margin-right: 1.2rem;
  `}
`;

export const VisitContactDetails = styled.div`
  width: 37rem;
  min-height: 6.4rem;
  margin-left: 1.2rem;
  position: relative;
  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-left: unset;
  `}
`;

export const DetailsTitle = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: rgba(50, 59, 75, 0.56);
  > svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.9rem;
  }
`;

export const AdressDetails = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #323b4b;
`;

export const UserComment = styled.div`
  width: 40.6rem;
  min-height: 6.7rem;
  display: flex;
  ${({ theme }) => theme.mobile`
    width: unset;
  `}
`;

export const Comment = styled.textarea`
  width: 37rem;
  min-height: 6.1rem;
  font-family: inter, assistant;
  font-style: italic;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  resize: none;
  border: none;
  background: ${({ theme }) => theme.colors.white};
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black};
  ::placeholder {
    font-size: 1.2rem;
    line-height: 2rem;
    color: rgba(50, 59, 75, 0.4);
  }
  :focus {
    color: ${({ theme }) => theme.colors.black};
    border-radius: 0.8rem;
  }
  ${({ theme }) => theme.mobile`
    width: 24.4rem;
  `}
  ${({ isPlaceHolder }) =>
    isPlaceHolder &&
    css`
      border: 0.1rem dashed ${({ theme }) => theme.colors.ash};
      border-radius: 0.8rem;
      box-sizing: border-box;
    `}
`;

export const PetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Pet = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  padding: 1.4rem 1.2rem 1.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  user-select: none;
  ${({ isActive }) =>
    isActive &&
    css`
      border: 0.05rem solid ${({ theme }) => theme.colors.ash};
      box-sizing: border-box;
      box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.03);
    `}
  ${({ theme }) => theme.mobile`
    padding: 1.2rem 0 0 0;
  `};
`;

export const PetHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.6rem;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
  }
  ${({ theme, isActive }) => theme.mobile`
     height: unset;
     padding-bottom: 1.2rem;
     padding-right: 2rem;
     padding-left: 1.6rem;
     border-bottom: ${isActive ? ' solid 0.1rem rgba(245, 246, 249, 1)  ' : 'unset'};
  `}
`;

export const ArrowWrapper = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  margin-left: 1.6rem;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  > svg {
    transition: 0.3s ease-in-out;
    ${({ isActive }) =>
      isActive &&
      css`
        transform: rotate(180deg);
      `}
  }
  :hover {
    background: #f2f4f7;
    border-radius: 0.6rem;
    ${({ vaccine }) =>
      vaccine &&
      css`
        background: unset;
        border-radius: unset;
      `}
  }
  ${({ vaccine }) =>
    vaccine &&
    css`
      display: none;
      ${({ theme }) => theme.mobile`
        display: flex;
        align-items: center;
        width: unset;
        height: unset;
        cursor: pointer;
        >svg{
          margin-left: 1.4rem;
        }
      `}
    `}
`;

export const PetImage = styled.img`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  object-fit: cover;
  background-color: #acbcd4;
  padding: 0.5rem;
`;

export const PetName = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.7rem;
  display: flex;
  color: rgba(50, 59, 75, 1);
  margin-left: 2rem;
`;

export const PetDetails = styled.div`
  width: 100%;
  padding: 3rem 1.6rem 1.8rem 0.8rem;
  ${({ theme }) => theme.mobile`
    padding: 0 0 1.6rem 0;
  `}
`;

export const PetDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mobile`
    padding: 0 1.5rem 0 1.6rem;
    margin-top: 1.6rem;
  `}
`;

export const PetDetailsHeader = styled.header`
  display: flex;
  align-items: center;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: rgba(50, 59, 75, 0.56);
`;

export const InstuctuinsText = styled.p`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 4.8rem;
`;

export const IconWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  margin-right: 2.4rem;
  ${({ theme }) => theme.mobile`
    margin-right: 1.3rem;
  `}
`;

export const Vaccines = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-left: 4.8rem;
  margin-top: 0.5rem;
  gap: 0.8rem;
  ${({ theme }) => theme.mobile`
    margin-left: 3.7rem;
  `}
`;

export const Vaccine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  height: 3.4rem;
  background: rgba(219, 226, 238, 0.12);
  border: 0.05rem solid ${({ theme }) => theme.colors.darkGray};
  box-sizing: border-box;
  border-radius: 0.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: rgba(50, 59, 75, 0.8);
`;

export const Medications = styled(Vaccines)``;
export const Medication = styled(Vaccine)``;

export const FilesHeader = styled(PetDetailsHeader)`
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.6rem 6rem 0 5.4rem;
  gap: 1.2rem 4.2rem;
`;

export const Receipt = styled.div`
  background-color: white;
  border-radius: 1.2rem;
  width: 100%;
  margin-top: 2.2rem;
  padding: 2.1rem 2.4rem 2.4rem 2.4rem;
`;

export const Receiptheader = styled.header`
  width: 100%;
  height: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
  }
`;

export const ReceiptIconWrapper = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 0.6rem;
`;

export const ReceiptTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 1.8rem;
`;

export const Download = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.6rem;
  padding: 0 1rem 0 0.8rem;
  cursor: pointer;
  > svg {
    margin-right: 0.2rem;
    fill: ${({ theme }) => theme.colors.darkGray};
  }
  :hover {
    background: #f2f4f7;
    border-radius: 0.6rem;
    > h4 {
      color: ${({ theme }) => theme.colors.black};
    }
    > svg {
      fill: ${({ theme }) => theme.colors.darkGreen};
    }
  }
  ${({ theme }) => theme.mobile`
    padding: 0 0.8rem 0 1.1rem;
  `}
`;
export const DownloadTitle = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.7rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: rgba(50, 59, 75, 0.56);
  margin-right: 1rem;
  ${({ theme }) => theme.mobile`
    display:none;
  `}
`;

export const ReceiptMain = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`;

export const ReceiptMainTitles = styled.div`
  display: flex;
  margin: 0 2.4rem;
  margin-bottom: 0.8rem;
  justify-content: space-between;
`;

export const ReceiptDescriptionTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: #8a94a6;
  margin-right: 11.9rem;
`;

export const ReceiptGivenTitle = styled(ReceiptDescriptionTitle)`
  margin-right: 5.6rem;
  ${({ theme }) => theme.mobile`
    margin: unset;
  `}
`;

export const ReceiptPriceTitle = styled(ReceiptDescriptionTitle)`
  margin-right: unset;
  margin-left: 5rem;
  ${({ theme }) => theme.mobile`
    margin: unset;
  `}
`;

export const ReceiptDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  ${({ theme }) => theme.mobile`
    border: 0.1rem solid #DBE2EE;
    box-sizing: border-box;
    box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.03);
    border-radius: 0.8rem;
  `}
`;

export const ReceiptDetails = styled.div`
  background: white;
  border: 0.1rem solid ${({ theme }) => theme.colors.ash};
  box-sizing: border-box;
  box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.03);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.8rem;
  padding: 1.3rem 1.2rem 1.3rem 2.4rem;
  display: flex;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    height: unset;
    padding: 1.2rem 0 0 0;
  `}
`;

export const DetailsMain = styled.div`
  ${({ theme }) => theme.mobile`
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1.6rem 2rem;
  `}
`;

export const VaccineName = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: #59616e;
  width: 16.7rem;
  border-right: 0.06rem solid rgba(172, 188, 212, 0.64);
  ${({ theme }) => theme.mobile`
    border-right: unset;
    border-bottom: ${({ isActive }) =>
      isActive ? '0.2rem solid rgba(245, 246, 249, 1)' : 'unset'};
    padding-bottom: 1.2rem;
    width: calc(100% - 3.2rem);
    padding: 0 0 1.2rem 0;
    margin: 0 1.6rem;
    justify-content: space-between;
  `}
`;

export const GivenTo = styled.div`
  > div {
    width: 11.8rem;
    height: 2.1rem;
    margin-left: 1.6rem;
    display: flex;
    align-self: center;
    align-items: center;
    ${({ theme }) => theme.mobile`
    align-items: center;
    justify-content: flex-end;
  `}
  }
  ${({ theme }) => theme.mobile`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
  `}
`;

export const GivenToImage = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  border-radius: 0.2rem;
  margin-right: 0.8rem;
  background-color: #acbcd4;
  padding: 0.1rem;
`;

export const GivenToName = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #59616e;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Price = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #59616e;
  width: 5.7rem;
  margin-left: 1.9rem;
  ${({ theme }) => theme.mobile`
    margin: unset;
    width: 100%;
    justify-content: space-between;
    margin-top: 1.6rem;
  `}
`;

export const Qty = styled(Price)`
  width: 6.2rem;
  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const Total = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #59616e;
  margin-left: 1.9rem;
  width: 6.9rem;
  margin-left: unset;
  padding-left: 2rem;
  ${({ theme }) => theme.mobile`
    margin: unset;
    width: 100%;
    justify-content: space-between;
    margin-top: 1.6rem;
    padding: unset;
  `}
`;

export const ReceiptFooter = styled.footer`
  background: #f5f6f9;
  border-radius: 0.8rem;
  width: 100%;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3rem;
  padding-right: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const TotalPrice = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(50, 59, 75, 0.6);
  margin-right: 1.9rem;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  position: relative;
`;

export const Reschedule = styled.h3`
  width: calc(50% - 0.5rem);
  text-align: center;
  cursor: pointer;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.7rem;
  position: relative;
  :hover {
    color: ${({ theme, rescheduleAvailable }) =>
      rescheduleAvailable ? '#22A3C3' : theme.colors.darkGray};
  }
  color: ${({ theme, rescheduleAvailable }) =>
    rescheduleAvailable ? 'rgba(50,59,75,1)' : theme.colors.darkGray};
`;

export const AlertMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 18.1rem;
  border-radius: 0.8rem;
`;

export const Loader = styled.div`
  border: 0.3rem solid ${({ theme }) => theme.colors.orange};
  border-top: 0.3rem solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  animation: spin 1s linear infinite;
  margin-left: 1.2rem;
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const ToolTipText = styled.p`
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  width: 21.3rem;
  top: -12rem;
  left: 5rem;
  padding: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: inter, assistant;
  font-weight: normal;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  border-radius: 0.8rem;
  z-index: 5;
  ::after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    background: ${({ theme }) => theme.colors.black};
    bottom: -0.5rem;
    transform: rotate(45deg);
    left: calc(50% - 0.5rem);
  }
`;

export const CancelAppointment = styled.h3`
  width: calc(50% - 0.5rem);
  text-align: center;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.45);
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  cursor: pointer;
  :hover {
    color: ${({ isDisabled }) => (isDisabled ? 'rgba(0, 0, 0, 0.45)' : 'rgba(0, 0, 0, 0.85)')};
  }
`;

export const Line = styled.span`
  width: 0.1rem;
  height: 2.4rem;
  background: ${({ theme }) => theme.colors.darkGray};
  opacity: 0.4;
  ${({ theme }) => theme.mobile`
      display : none;
  `}
`;
