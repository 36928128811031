import React from 'react';

import * as S from './components';

const DynamicAvatar = ({ w, h, fs, label, inSidebar }) => {
  return (
    <S.Wrapper w={w} h={h} inSidebar={inSidebar}>
      <S.Avatar>
        <S.Label fs={fs}>{label}</S.Label>
      </S.Avatar>
    </S.Wrapper>
  );
};

export default DynamicAvatar;
