import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 2.75rem 3.2rem 2.4rem;
  ${({ theme }) => theme.mobile`
    padding : 0rem;
  `}
`;
export const TimelineFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  flex-wrap: wrap;

  ${({ theme }) => theme.minWidth.desktopS`
    flex-wrap: nowrap;
  `}

  ${({ theme }) => theme.mobile`
    background: #F5F6F9;
    border-radius : 1.6rem 1.6rem 0 0;
  `}
`;
export const TimelineTabsWrapper = styled.div`
  width: 100%;
  display: none;
  ${({ theme }) => theme.mobile`
    display: flex;
    justify-content: space-between;
  `}
`;
export const TimelineTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 5.6rem;
  ${({ isActiveTab }) =>
    isActiveTab &&
    `
    &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    background: #f9b057;
    border-radius: .2rem .2rem 0 0;
    bottom: 0;
    max-width: 9.6rem;
  }
  `}
`;

export const TabContentWrapper = styled.div`
  transition: 0.3s ease-in-out;
  ${({ theme, isTabSwipedUp }) => theme.mobile`
  height: ${isTabSwipedUp ? 'calc(100vh - 12.8rem)' : 'calc(100vh - 27.5rem)'} ;
  background-color: #F5F6F9;
  box-shadow: 0px 4.4rem 6.5rem rgba(176, 183, 195, 0.19);
  border-radius: 1.6rem;
  padding: 0rem 1.5rem;
  margin-top: ${isTabSwipedUp ? '-18.7rem' : '-4rem'};
  z-index: 2;
  position: relative;
`};
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  left: 48%;
  top: -3.5rem;
  display: ${({ isTabSwipedUp }) => (isTabSwipedUp ? 'block' : 'none')};
  width: 4rem;
  height: 2rem;
`;
