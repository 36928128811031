import React, { memo, useEffect, useState } from 'react';
import { InitialsAvatar } from './InitialsAvatar';
import { Avatar } from './Avatar';
import { getInitialLetters } from 'utils/helpers';

const UserDynamicAvatarC = ({ user, size }) => {
  const [loaded, setLoaded] = useState(false);
  const letters = getInitialLetters(null, user.firstName, user.lastName);

  useEffect(() => {
    if (user.avatarUrl) {
      // eslint-disable-next-line no-undef
      const image = new Image();
      image.onload = function () {
        if (this.width > 0) {
          setLoaded(true);
        }
      };
      image.src = user.avatarUrl;
    } else {
      setLoaded(false);
    }
  }, [user]);
  return loaded ? (
    <Avatar img={user.avatarUrl} size={size} />
  ) : (
    <InitialsAvatar letters={letters} size={size} />
  );
};

export const UserDynamicAvatar = memo(UserDynamicAvatarC);
