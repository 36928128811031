import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  justify-content: space-between;
  padding-top: 2.6rem;
  overflow: hidden;
  ${({ theme }) => theme.laptop`
    padding: 2.4rem 0rem;
    width: 100%;
    
  `}

  ${({ theme }) => theme.mobile`
    padding: 2.4rem;
    width: 100%;
    overflow: auto;
  `}
`;

export const Main = styled.main`
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 2.6rem;
  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }

  ${({ theme }) => theme.laptopM`
    max-height: unset;
  `}

  ${({ theme }) => theme.mobile`
    max-height: unset;
    overflow:initial;
  `}
`;

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2rem;
  font-family: inter, assistant;
  font-weight: 600;
  padding: 0 2.4rem;
  ${({ theme }) => theme.mobile`
    padding: 0;
  
  `}
`;

export const PageSubTitle = styled.h3`
  color: #8a94a6;
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 400;
  margin-top: 0.6rem;
  padding: 0 2.4rem;
  ${({ theme }) => theme.mobile`
    padding: 0;
  `}
`;

export const VetsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.6rem;
  max-height: 100%;
  padding: 0 2.4rem;
  flex-wrap: wrap;
  ${({ theme }) => theme.laptop`
  max-height: unset;
  gap: 2.4rem;
  `}

  ${({ theme }) => theme.mobile`
    padding: 0;
    flex-direction: column;
  `}
`;

export const MessageWrapper = styled.div`
  width: 43.9rem;
  height: 36.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  ${({ theme }) => theme.mobile`
    width: 29rem;
  `}
`;

export const MessageCaption = styled.h2`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  color: #2d3363;
`;

export const MessageSubCaption = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #9094ac;
  margin-top: 1.2rem;
`;

export const MessageDescription = styled.h3`
  margin-top: 3.2rem;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  color: #9094ac;
  max-width: 29rem;
  text-align: center;
  line-height: 2rem;
`;

export const SadIcon = styled.img`
  margin-bottom: 3.9rem;
  height: 11.2rem;
`;
