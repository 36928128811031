import React from 'react';
import { useTranslation } from 'react-i18next';
import { getInitialLetters } from 'utils/helpers';

import { useLocalizationContext } from 'common_repo';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { petImages } from 'constants/pet.js';
import { visitIcon } from '../../../constants/visit';

import { ReactComponent as Arrow } from 'assets/icons/arrow-down-gray.svg';
// import {ReactComponent as Wallet} from 'assets/icons/wallet.svg';

import * as S from './components';

const TimelineContentBox = ({
  visit,
  route,
  pets,
  visitType = 'home',
  extraCosts = 0,
  missed = false,
  setVetUid,
  singlePet,
  setSelectedTimeSlot,
  isSelectedTimeline,
  isFutureTimeline,
  setIsVisitModalOpen
}) => {
  const { rtl } = useLocalizationContext();
  const { t } = useTranslation();
  const { vet } = route;

  const openVetModal = (e) => {
    e.stopPropagation();
    setVetUid(route?.vet?.uid, e);
  };

  const { isMobile, isLaptop } = mediaQueriesList();

  return (
    <S.ItemWrapper
      onClick={() => {
        setSelectedTimeSlot(visit);
        if (!singlePet) {
          setIsVisitModalOpen(true);
        }
      }}
      missed={missed}
      singlePet={singlePet}>
      <S.TopWrapper>
        <S.VisitTypeIcon src={visitIcon[visitType]} singlePet={singlePet} />
        <S.VetAndVisitWrapper>
          <S.VisitType>
            <S.TypeTitle isSelectedTimeline={isSelectedTimeline}>{t('home.visit')}</S.TypeTitle>
            {!singlePet && (
              <S.PetsWrapper>
                <S.Pets>
                  {!!pets?.length &&
                    pets.map((pet, i) => {
                      if (i <= 1) {
                        return (
                          <S.Pet key={pet.uid}>
                            {pet?.pet?.avatarUrl ? (
                              <S.PetImage src={pet?.pet?.avatarUrl} alt="pet" />
                            ) : (
                              <S.PetImage src={petImages[pet?.pet?.type?.name]} alt="pet" />
                            )}
                            {!isMobile && <S.PetName>{pet?.pet?.name}</S.PetName>}
                          </S.Pet>
                        );
                      }
                    })}
                </S.Pets>
                <S.Dots visible={pets.length > 2}>
                  {isMobile ? `+${pets.length - 1}` : '...'}
                </S.Dots>
              </S.PetsWrapper>
            )}
          </S.VisitType>
          {!isMobile && !singlePet && !isLaptop && <S.Line />}

          <S.Vet singlePet={singlePet}>
            {!singlePet && t('with')}
            <S.Doctor onClick={openVetModal}>
              {vet?.user?.avatarUrl ? (
                <S.DoctorImage src={vet?.user?.avatarUrl} alt="doctor" />
              ) : (
                <S.DoctorName>
                  {getInitialLetters(null, vet?.user?.firstName, vet?.user?.lastName)}
                </S.DoctorName>
              )}

              <S.DotctorFullName>
                {vet?.user?.firstName[0] + '. ' + vet?.user?.lastName}
              </S.DotctorFullName>
            </S.Doctor>
          </S.Vet>
        </S.VetAndVisitWrapper>
        {/* {!isFutureTimeline && (
          <S.ItemDetails>
            <S.ExtraCost>
              <Wallet />
              <strong>{extraCosts ? '$' + extraCosts : t('no')}</strong>
              {t('extra.costs')}
              <S.ExtraCostWrapper> </S.ExtraCostWrapper>
            </S.ExtraCost>
          </S.ItemDetails>
        )} */}
      </S.TopWrapper>
      {missed && <S.Status>{t('missed')} </S.Status>}
      {!isMobile && !singlePet && !isLaptop && (
        <S.ArrowWrapper isRtl={rtl}>
          <Arrow />
        </S.ArrowWrapper>
      )}
    </S.ItemWrapper>
  );
};

export default TimelineContentBox;
