import { useMemo } from 'react';
import { isAppointmentIntegrationError } from '_fsd/entities/integration';

export const useDatesWithIntegrationErrors = ({ dates, vetAdmin }) =>
  useMemo(() => {
    const datesWithIntegrationErrors = {};
    for (const [date, timeslots] of Object.entries(dates)) {
      const isAppointmentHasIntegrationError = timeslots.some((timeslot) => {
        return timeslot.appointments?.some((appointment) => {
          const route = timeslot.routes.find((route) =>
            route.appointmentRoutes.find((ap) => ap.uid === appointment.appointmentRouteId)
          );

          return isAppointmentIntegrationError({
            appointmentIntegrations: appointment.appointmentIntegrations,
            route,
            vetAdmin
          });
        });
      });
      datesWithIntegrationErrors[date] = {
        timeslots: [...timeslots],
        hasAnyIntegrationError: isAppointmentHasIntegrationError
      };
    }

    return datesWithIntegrationErrors;
  }, [dates]);
