import React from 'react';
import dayjs from 'dayjs';
import * as S from './components';
import useNotificationCard from './useNotificationCard';

const NotificationCard = ({ description, date, seen, avatarUrl, uid }) => {
  const { handleUpdateSeen } = useNotificationCard();
  return (
    <S.CardWrapper onClick={() => handleUpdateSeen(uid, !seen)}>
      {avatarUrl && <S.PetImage src={avatarUrl} />}
      <S.TextWrapper>
        <S.NotificationMessage isRead={seen}>{description}</S.NotificationMessage>
        <S.TimeText>{dayjs(date).format('MM/DD/YYYY hh:mm a')}</S.TimeText>
      </S.TextWrapper>
      {!seen && <S.ActivePoint />}
    </S.CardWrapper>
  );
};

export default NotificationCard;
