import React from 'react';
import css from './VetWithAppointmentCount.module.css';
import { UserDynamicAvatar } from '_fsd/shared';

export const VetWithAppointmentCount = ({ appt }) => {
  return (
    <div className={css.container}>
      <div className={css.flex}>
        <UserDynamicAvatar user={appt.vetUser} />
        <span>{appt.caregiver}</span>
      </div>
      <span>{appt.count}</span>
    </div>
  );
};
