import React from 'react';
import css from './File.module.css';
import { FileTextOutlined } from '@ant-design/icons';

const getShortFileName = (name) => {
  if (name.length > 15) {
    const [format] = name.split('.').slice(-1);
    return name.slice(0, 5) + '...' + format;
  }
  return name;
};

const File = ({ url }) => {
  const [fileName] = url.split('/').slice(-1);
  const v4 = new RegExp(
    /(_[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12})/
  );
  const name = fileName.replace(v4, '');
  return (
    <a className={css.container} href={url} target="_blank" rel="noreferrer">
      <FileTextOutlined />
      <span className={css.fileName} title={name}>
        {getShortFileName(name)}
      </span>
    </a>
  );
};

export default File;
