import styled from 'styled-components';

export const TimelineContent = styled.div`
  ${({ theme }) => theme.mobile`
     display : ${({ isActiveTab }) => (isActiveTab ? 'block' : 'none')};
       `}
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1rem 1rem 0.8rem 0.8rem;

  max-width: calc(100% - 2.4rem - 36rem);

  ${({ theme }) => theme.minWidth.desktopS`
    max-width: calc(100% - 2.4rem - 53.6rem);
    // max-height: 81.2rem;
  `}
  ${({ theme }) => theme.laptopS`
    max-width: calc(100% - 2.4rem - 30rem);
  `}
  ${({ theme }) => theme.laptopSm`
    max-width: calc(100% - 2.4rem - 22rem);
  `}

  ${({ theme }) => theme.mobile`
     max-width : 100%;
  `}
`;
