import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35.8rem;
  border: 0.1rem solid ${({ isSelected, theme }) => (isSelected ? theme.colors.orange : '#acbcd4')};
  border-radius: 1.2rem;
  padding: 2.4rem;
  padding-right: 0.5rem;
  max-width: 49%;
  margin-bottom: 2rem;

  ${({ theme }) => theme.laptop`
    height: 29.1rem;
    padding: 1.6rem;
    flex: 1;
    min-width: 40.6rem;
    margin-bottom: unset;
  `}

  ${({ theme }) => theme.mobile`
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    max-width:100%;
    width: 100%;
    margin-right:0;
    margin-bottom: 2rem;
    height: 43.6rem;
    min-width: unset;
    
  `}
`;
export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
     flex-direction: column;
  `}
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 19.5rem;
  overflow: hidden;

  ${({ theme }) => theme.laptop`
    max-height: 15.4rem;
    overflow-y: auto;
  `}

  ${({ theme }) => theme.mobile`
    overflow: hidden;
  `}

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
`;
export const Avatar = styled.img`
  width: 20.4rem;
  height: 19.2rem;
  border-radius: 0.8rem;
  margin-right: 2rem;
  object-fit: cover;
  ${({ theme }) => theme.laptop`
    height: 15.4rem;
  `}

  ${({ theme }) => theme.mobile`
     width: 5.6rem;
     height: 5.2rem;
  `}
`;
export const AvatarWithText = styled.div`
  width: 20.4rem;
  height: 19.2rem;
  border-radius: 0.8rem;
  margin-right: 2rem;
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.6rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.laptop`
    height: 15.4rem;
  `}

  ${({ theme }) => theme.mobile`
     width: 5.6rem;
     height: 5.2rem;
     font-size: 2.6rem;
  `}
`;

export const Name = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2rem;
  font-family: inter, assistant;
  font-weight: 600;
  ${({ theme }) => theme.mobile`
    display:none;
  `}
`;

export const ResponsiveName = styled.h3`
  display: none;
  font-family: inter, assistant;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.8rem;
  ${({ theme }) => theme.mobile`
    display:block;
  `}
`;

export const LanguagesWrapper = styled.div`
  display: flex;
  margin: 1.2rem 0;
  flex-wrap: wrap;
`;

export const LanguageWrapper = styled.div`
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
`;

export const About = styled.p`
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: 400;
  color: #323b4bb8;
  line-height: 2.1rem;
  height: 100%;
  max-height: 11rem;
  max-width: 45rem;
  flex-wrap: wrap;
  display: inline-flex;
  overflow-wrap: anywhere;

  ${({ theme }) => theme.laptop`
    overflow: unset;
  `}

  ${({ theme }) => theme.mobile`
    overflow: auto;
  `}

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
`;

export const TimeSlots = styled.div`
  margin: 1.8rem 0;
`;

export const MoreTimeSlots = styled.button`
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: inter, assistant;
  font-weight: 600;
  background: transparent;
  align-self: center;
  cursor: pointer;
  text-decoration: underline;
`;

export const ButtonWrapper = styled.div`
  padding: 0 3.6rem;
`;
export const DatePickerHeader = styled.div`
  padding: 2rem 3.3rem;
  background: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
`;
export const DatePickerTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const DatePickerContainer = styled.div`
  background: #f5f6f9;
`;
export const DatePickerWrapper = styled.div`
  position: relative;
  width: 42.4rem;
  min-height: 66.9rem;
  background: #f5f6f9;
  box-shadow: 0 0.4rem 6rem - 2rem rgba(5, 40, 72, 0.15);
  border-radius: 1.6rem;
  padding-bottom: 3.6rem;

  ${({ theme }) => theme.laptopHeightSm`
    min-height: unset;
  `}
  ${({ theme }) => theme.laptop`
    height: 66.9rem;
    padding-bottom: 3.2rem;
  `}
  ${({ theme }) => theme.mobile`
     width : 100%;
     height: auto;
  `}
  & .react-datepicker-wrapper {
    display: none;
  }

  & .react-datepicker-popper {
    transform: none !important;
    position: inherit !important;
    width: 42.4rem;
  }

  & .llmNWI,
  .kHmMYt .react-datepicker__header {
    background: rgb(245, 246, 249);

    > div {
      background: rgb(245, 246, 249);
    }
  }

  & .calendar {
    background: #f5f6f9 !important;
    border: none;
    padding-top: 0 !important;
  }

  & .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    color: #112950 !important;
    font-weight: 500;
    font-family: inter, assistant;
    background: ${({ theme }) => theme.colors.white} !important;
    border: 0.1rem solid ${({ theme }) => theme.colors.orange} !important;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      background: ${({ theme }) => theme.colors.orange};
      border-radius: 50%;
      bottom: -0.4rem;
      left: calc((3.6rem / 2) - 0.4rem);
    }
  }

  & .kHmMYt .react-datepicker__week .react-datepicker__day {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
    border: 0.05rem solid #e5eaf2;
  }

  & .kHmMYt .react-datepicker__day--disabled {
    background: #dbe2ee !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }

  & .kHmMYt .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.orange} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border: 0.05rem solid ${({ theme }) => theme.colors.orange} !important;
  }

  & .kHmMYt .react-datepicker__day-name {
    text-transform: uppercase;
  }
`;

export const TimeSlotsWrapper = styled.div`
  padding: 0 3.6rem 2.7rem;
  ${({ theme }) => theme.laptop`
     padding-bottom: 0;
  `}
`;
export const TimeSlotsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  ${({ theme }) => theme.laptop`
     margin-bottom: 1.6rem;
  `}
`;
export const TimeSlotTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: #2d3363;
  ${({ theme }) => theme.laptop`
     font-size: 1.6rem;
  `}
`;
export const TimeSlotDate = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: rgba(87, 92, 130, 0.72);
  ${({ theme }) => theme.laptop`
     font-size: 1.4rem;
  `}
`;
export const SlotsList = styled.div`
  display: flex;
  column-gap: 1.2rem;
  flex-wrap: wrap;
`;

export const SlotsListItem = styled.div`
  flex-direction: column;
  white-space: nowrap;
  width: 14.5rem;
  height: 5rem;
  background: ${({ theme, isChosed, disabled }) =>
    isChosed ? theme.colors.orange : disabled ? theme.colors.grey : theme.colors.white};
  border: 0.05rem solid #e5eaf2;
  box-sizing: border-box;
  border-radius: 0.8rem;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};
  color: ${({ theme, isActive, isChosed }) =>
    isChosed ? theme.colors.white : isActive ? theme.colors.orange : '#B4B8CD'};
`;
