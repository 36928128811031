import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  top: 5.4rem;
  width: 100%;
  z-index: 5;
  ${({ theme }) => theme.mobile`
    top: 5.1rem;
  `}
`;

export const Option = styled.div`
  padding: 0.5rem;
  display: flex;
  border: 1px solid #dbe2ee;
  align-items: center;
  color: #8a94a6;
  font-family: inter, assistant;
  font-weight: 600;
  background: #fff;
  font-size: 1.6rem;
  cursor: pointer;
  :hover {
    border-color: #ff9a05;
  }
  :last-child {
    border-radius: 0 0 0.8rem 0.8rem;
  }
  :first-child {
    border-top-color: #ff9a05;
  }
  ${({ theme }) => theme.mobile`
    font-family: inter,assistant;
    font-weight: 400;
    font-size: 1.3rem;
    height: 5.2rem;
  `}
`;

export const AddressDescription = styled.span``;

export const CheckModal = styled.div`
  position: absolute;
  width: 2rem;
  top: ${({ isForCalendar }) => (isForCalendar ? '.8rem' : '1.9rem')};
  right: 1.6rem;
  ${({ isForVet }) =>
    isForVet &&
    css`
      top: 0.9rem;
    `}
`;

export const AddressInfo = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddressCheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem 0;
  padding: 0.8rem 0;
`;

export const Dot = styled.span`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 2.4rem;
  background-color: ${({ missing, isBlue }) =>
    isBlue ? '#89d2e4' : missing ? '#E44E61' : '#41D77D'};
  margin-right: 0.8rem;
`;

export const AddressCheckItem = styled.li`
  display: flex;
  align-items: center;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
