import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../modal/Modal';
import exit from 'assets/icons/close-gray.svg';
import sadIcon from 'assets/icons/sad.png';

import * as S from './components';

const WarningModal = ({ isOpen, handleCloseModal, handleClickLeave }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
      <S.Wrapper>
        <S.Exit src={exit} onClick={handleCloseModal} />
        <S.MessageImage src={sadIcon} />
        <S.CaptionWrapper>
          <S.Caption> {t('all_info_will_be_lost')}</S.Caption>
          <S.Caption> {t('leave_the_page_question')}</S.Caption>
        </S.CaptionWrapper>

        <S.ButtonsWrapper>
          <S.Button onClick={handleCloseModal}>{t('cancel')}</S.Button>
          <S.Button active onClick={handleClickLeave}>
            {t('leave')}
          </S.Button>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </Modal>
  );
};

export default WarningModal;
