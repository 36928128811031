import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../modal/Modal';
import errorImage from 'assets/images/error-img.svg';
import exit from 'assets/icons/close-gray.svg';

import * as S from './components';

const ErrorModal = ({ isOpen, handleCloseModal, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
      <S.Wrapper>
        <S.Exit src={exit} onClick={handleCloseModal} />
        <S.MessageImage src={errorImage} />
        <S.Caption> {t('invalid.file')}</S.Caption>
        <S.SubCaption>{errorMessage}</S.SubCaption>
      </S.Wrapper>
    </Modal>
  );
};

export default ErrorModal;
