import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const PetName = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter, assistant, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  flex: 1;
  width: 100%;
  padding-left: 0px;
  padding-right: 10px;
  white-space: pre-wrap;
  opacity: 0.72;
  color: ${({ theme }) => theme.colors.white};
  ${({ withoutImage }) =>
    withoutImage
      ? 'padding-left: 34px'
      : `
      `};
  ${({ isPetActive }) => isPetActive && 'opacity: 1'};
  ${({ forPetowner }) =>
    forPetowner &&
    `
    font-size: 13px;
    line-height: 16px;
  `};
`;

export const PetAvatarWrapper = styled.div`
  margin-left: 0px;
  width: 16px;
  height: 16px;
  margin-left: 1px;
  margin-right: 13px;
  border-radius: 50%;
  display: flex;
  ${({ forPetowner }) =>
    forPetowner &&
    `
  margin-right: 18px;
  `}}

  `;

export const PetAvatar = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: ${({ isPetActive, theme }) =>
    isPetActive ? `0.1rem solid ${theme.colors.orange}` : '0.1rem solid rgba(255, 255, 255, 0.2)'};
  padding: ${({ hasAvatar }) => (hasAvatar ? '0.01rem' : '0.3rem')};
`;
export const SubMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
