import React, { useState, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { parseTimeSlotHour, getInitialLetters } from 'utils/helpers';
import useDateFormat from 'common_repo/components/useDateFormat';

import { ReactComponent as ShareIcon } from 'assets/icons/share-icon.svg';
import instructionsIcon from 'assets/icons/instuctions-icon.svg';
import visitDetailsIcon from 'assets/icons/visit-details.svg';
import medicationIcon from 'assets/icons/medication-icon.svg';
import vaccinesIcon from 'assets/icons/vaccine-icon.svg';
import commentIcon from 'assets/icons/comment-icon.svg';
import ClinicIcon from 'assets/icons/clinic-icon.svg';
import RemoteIcon from 'assets/icons/remote-icon.svg';
import locationIcon from 'assets/icons/location.svg';
import HomeIcon from 'assets/icons/home-icon.svg';
import arrow from 'assets/icons/arrow-down.svg';

import * as S from './components';

const VisitInfo = ({ visitType = 'home', selectedTimeSlot, setIsVisitModalOpen }) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormat();
  const match = useRouteMatch();
  const [isVisitDetailsOpen, setIsVisitDetailsOpen] = useState(false);

  const vet = selectedTimeSlot?.route?.vet?.user;
  const isHeb = localStorage.getItem('lang') === 'heb';

  const visitIcon = {
    home: HomeIcon,
    clinic: ClinicIcon,
    remote: RemoteIcon
  };

  const timeslotHour = !isHeb
    ? parseTimeSlotHour(selectedTimeSlot?.route?.timeSlot?.startTime) +
      '-' +
      parseTimeSlotHour(selectedTimeSlot?.route?.timeSlot?.endTime)
    : parseTimeSlotHour(selectedTimeSlot?.route?.timeSlot?.endTime) +
      '-' +
      parseTimeSlotHour(selectedTimeSlot?.route?.timeSlot?.startTime);

  const selectedPet = useMemo(() => {
    return selectedTimeSlot?.pets.find((curr) => {
      if (curr?.pet?.uid === match?.params?.id) {
        return curr?.pet;
      }
    });
  }, [selectedTimeSlot?.pets]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.LogoAndDetails>
          <S.GrayIcon large src={visitIcon[visitType]} alt="visit" />
          <S.Details>
            <S.Heading large>
              {visitType + ' ' + t('visit')}
              <div onClick={() => setIsVisitModalOpen(true)}>
                <ShareIcon />
              </div>
            </S.Heading>
            <S.Date>
              {dateFormat(selectedTimeSlot?.route?.date)} {timeslotHour}
            </S.Date>
          </S.Details>
        </S.LogoAndDetails>

        <S.VetWrapper>
          {vet?.avatarUrl ? (
            <S.VetImage src={vet?.avatarUrl} alt="vetavatar" />
          ) : (
            <S.VetDefaultAvatar>
              {getInitialLetters(null, vet?.firstName, vet?.lastName)}
            </S.VetDefaultAvatar>
          )}
          <S.VetName>{vet?.firstName[0] + '. ' + vet?.lastName}</S.VetName>
        </S.VetWrapper>
      </S.Header>

      <S.SingleDetail>
        <S.DetailImage src={locationIcon} alt="location" />
        <S.DetailsTextWrapper>
          <S.DetailsHeading>{t('user.address')}</S.DetailsHeading>
          <S.DetailsText>
            {selectedTimeSlot?.address?.houseNumber +
              ' ' +
              selectedTimeSlot?.address?.street +
              ', ' +
              selectedTimeSlot?.address?.city}
          </S.DetailsText>
        </S.DetailsTextWrapper>
      </S.SingleDetail>

      <S.SingleDetail style={{ display: 'none' }}>
        <S.DetailImage src={commentIcon} alt="comment" />
        <S.DetailsTextWrapper>
          <S.DetailsHeading>{t('user.comment')}</S.DetailsHeading>
          <S.DetailsText>
            <i>{selectedTimeSlot?.appointments?.[0]?.userComment || ''}</i>
          </S.DetailsText>
        </S.DetailsTextWrapper>
      </S.SingleDetail>
      <S.HorizontalLine />
      {(selectedPet?.summary ||
        selectedPet?.vaccines.length > 0 ||
        selectedPet?.soldInventories?.length > 0) && (
        <S.VisitDetails>
          <S.Flex>
            <S.Flex>
              <S.GrayIcon src={visitDetailsIcon} alt="visit" />
              <S.Heading>{t('visit.details')}</S.Heading>
            </S.Flex>
            <S.Arrow
              src={arrow}
              alt="arrow"
              isVisitDetailsOpen={isVisitDetailsOpen}
              onClick={() => {
                setIsVisitDetailsOpen((prev) => !prev);
              }}
            />
          </S.Flex>
          <S.VisitDetailsWrapper isVisitDetailsOpen={isVisitDetailsOpen}>
            {selectedPet?.summary && (
              <S.SingleDetail marginTop>
                <S.DetailImage src={instructionsIcon} alt="instructions" />
                <S.DetailsTextWrapper>
                  <S.DetailsHeading>{t('instructions')} </S.DetailsHeading>
                  <S.DetailsText>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedPet?.summary
                          .replaceAll('\\n', '<br/>')
                          .replaceAll('\n', '<br/>')
                      }}
                    />
                  </S.DetailsText>
                </S.DetailsTextWrapper>
              </S.SingleDetail>
            )}

            {selectedPet?.vaccines.length > 0 && (
              <S.SingleDetail>
                <S.DetailImage src={vaccinesIcon} alt="vaccines" />
                <S.DetailsTextWrapper>
                  <S.DetailsHeading>{t('vaccines')}</S.DetailsHeading>
                  <S.VaccinesWrapper>
                    {selectedPet?.vaccines?.map((vaccine) => (
                      <S.SingleVaccine key={vaccine?.uid}>{vaccine?.vaccine?.name}</S.SingleVaccine>
                    ))}
                  </S.VaccinesWrapper>
                </S.DetailsTextWrapper>
              </S.SingleDetail>
            )}
            {selectedPet?.soldInventories?.length > 0 && (
              <S.SingleDetail isMedications>
                <S.DetailImage src={medicationIcon} alt="medication" />
                <S.DetailsTextWrapper>
                  <S.DetailsHeading>{t('medications.products.given')}</S.DetailsHeading>
                  <S.VaccinesWrapper>
                    {selectedPet?.soldInventories?.map((inventory) => (
                      <S.SingleVaccine key={inventory?.uid}>
                        {inventory?.inventory?.name}
                      </S.SingleVaccine>
                    ))}
                  </S.VaccinesWrapper>
                </S.DetailsTextWrapper>
              </S.SingleDetail>
            )}
          </S.VisitDetailsWrapper>
        </S.VisitDetails>
      )}
    </S.Wrapper>
  );
};

export default memo(VisitInfo);
