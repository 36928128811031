import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './CustomTooltip.module.css';
import orderBy from 'lodash/orderBy.js';

export const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const totalValue = payload.reduce((sum, obj) => sum + obj.value, 0);
    const ordered = orderBy(payload, ['value'], ['desc']);
    return (
      <div className={css.customTooltip}>
        <span className={css.header}>
          <p className="label">{t('admin_dashboard.total_appointments_qty')}</p>
          <p className="label">{totalValue}</p>
        </span>
        {ordered.map((entry, index) => (
          <div key={`item-${index}`} className={css.flexBetween}>
            <div className={css.flex}>
              <div className={css.legend} style={{ backgroundColor: entry.color }}></div>
              <span>{entry.name}</span>
            </div>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};
