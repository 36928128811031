import { useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddPetValidationScheme } from 'validations/validations';
import { createPetMutation, getPetQuery, getPetTypesQuery } from 'gql';
import { useLocalizationContext } from 'common_repo';
import * as enums from 'constants/pet';
import useUpload from 'services/useUpload';

const getEnumValue = (name, value) => {
  if (['string', 'boolean'].includes(typeof value)) {
    const bla = enums[name]?.find((item) => item.value === value);
    return bla;
  }
  return '';
};

const UseAddPetForm = (uid, isModal, handleCloseModal) => {
  const { countryCode } = useLocalizationContext();
  const { uploadFile } = useUpload();

  const [newPetSuggestionModalOpen, setNewPetSuggestionModalOpen] = useState(false);

  const [createPet, { loading }] = useMutation(createPetMutation);
  const { data: petTypesData } = useQuery(getPetTypesQuery);

  const defaultWeightUnit = useMemo(() => {
    return enums.weightUnit[countryCode === 'US' ? 1 : 0];
  }, [countryCode]);

  const { data } = useQuery(getPetQuery, {
    skip: !uid,
    variables: {
      record: {
        uid
      }
    }
  });

  const existingPet = useMemo(() => {
    return data?.pet || {};
  }, [data]);

  const initialValues = useMemo(() => {
    return {
      type: existingPet?.type?.uid || null,
      gender: getEnumValue('gender', existingPet.gender),
      birthDate: existingPet.birthDate ? new Date(existingPet.birthDate) : '',
      name: existingPet.name || '',
      // picture: "",
      isFullyVaccinated: getEnumValue('isFullyVaccinated', existingPet.isFullyVaccinated),
      weight: existingPet.weight || '',
      weightUnit: getEnumValue('weightUnit', existingPet.weightUnit) || defaultWeightUnit,
      chipNumber: existingPet.chipNumber || '',
      breed: existingPet.breed || '',
      allergies: existingPet.allergies || [''],
      medicalAlerts: existingPet.medicalAlerts || '',
      behavioralNotes: existingPet.behavioralNotes || '',
      isSterilized: getEnumValue('isSterilized', existingPet.isSterilized),
      foodType: existingPet.foodType || '',
      avatar: existingPet.avatarUrl || ''
    };
  }, [existingPet, defaultWeightUnit]);

  const onSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true);
        const {
          type,
          gender,
          weightUnit,
          isSterilized,
          weight,
          avatar,
          chipNumber,
          breed,
          medicalAlerts,
          behavioralNotes,
          foodType
        } = values;

        let avatarUrl;

        let { allergies } = values;

        if (allergies.length) {
          allergies = allergies.filter((allergy) => allergy && allergy.trim());
        }

        if (avatar && typeof avatar !== 'string') {
          avatarUrl = await uploadFile(values.avatar);
        }

        const record = {
          ...values,
          allergies,
          type,
          gender: gender?.value,
          isFullyVaccinated: true,
          weight: +weight || null,
          weightUnit: weightUnit?.value,
          isSterilized: isSterilized?.value,
          avatarUrl,
          uid,
          chipNumber: chipNumber?.trim() || null,
          breed: breed?.trim() || null,
          medicalAlerts: medicalAlerts?.trim() || null,
          behavioralNotes: behavioralNotes?.trim() || null,
          foodType: foodType?.trim() || null
        };

        delete record.avatar;

        await createPet({
          variables: {
            record
          },
          refetchQueries: ['me', 'getPetsByOwner']
        });
        setSubmitting(false);
        if (isModal && !loading) {
          handleCloseModal?.();
        } else {
          setNewPetSuggestionModalOpen(true);
        }
      } catch (err) {
        const errors = {};
        console.log(err, 'err');
        err.graphQLErrors &&
          err.graphQLErrors.forEach((error) => {
            errors[error.message.field] = error.message.error;
          });
        setErrors(errors);
        setSubmitting(false);
      }
    },
    [createPet, isModal, handleCloseModal, uid, uploadFile]
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: AddPetValidationScheme,
    enableReinitialize: true
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    touched,
    errors,
    isSubmitting,
    resetForm
  } = formik;

  return {
    handleSubmit,
    handleChange,
    touched,
    errors,
    values,
    setFieldValue,
    formik,
    isSubmitting,
    newPetSuggestionModalOpen,
    setNewPetSuggestionModalOpen,
    resetForm,
    petTypes: petTypesData?.petTypes || []
  };
};

export default UseAddPetForm;
