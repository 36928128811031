import Stepper from 'components/stepper/Stepper';
import React from 'react';

import * as S from './components';

const MembershipLayout = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Content>
        <Stepper />
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default MembershipLayout;
