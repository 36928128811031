import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  top: 3.2rem;
  width: 100%;
  z-index: 5;
`;

export const Option = styled.div`
  padding: 1.44rem;
  display: flex;
  height: 0.64rem;
  border: 1px solid #dbe2ee;
  align-items: center;
  padding-left: 1.92rem;
  color: #818896;
  font-family: inter;
  font-weight: 500;
  background: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  :hover {
    border-color: #46abfe;
  }
  :last-child {
  }
  :first-child {
  }
`;

export const AddressDescription = styled.span``;

export const AddressCheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.3rem 0;
  padding: 1.3rem 0;
`;

export const Dot = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 3.84rem;
  background-color: ${({ missing, isNotNeeded }) =>
    isNotNeeded ? '#89d2e4' : missing ? '#E44E61' : '#41D77D'};
  margin-right: 0.8rem;
`;

export const AddressCheckItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.44rem;
  line-height: 1.3rem;
`;
