import React from 'react';
import { useLocalizationContext } from 'common_repo';
import Modal from 'react-modal';

import { customStyles } from './components';

const CustomModal = ({
  isOpen,
  noBorderRadius,
  onRequestClose,
  contentLabel,
  children,
  scrollable = false,
  isMobile = false,
  isLaptop,
  advancedInfoOpen,
  isSmallHeight,
  isMediumHeight,
  autoHeight,
  missed,
  withOverflow,
  fullSize,
  zIndex,
  custom = {}
}) => {
  const { rtl: isRtl } = useLocalizationContext();
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      style={customStyles(
        scrollable,
        isMobile,
        isLaptop,
        advancedInfoOpen,
        isRtl,
        isSmallHeight,
        isMediumHeight,
        missed,
        autoHeight,
        withOverflow,
        noBorderRadius,
        fullSize,
        zIndex,
        custom
      )}
      onRequestClose={onRequestClose}
      bodyOpenClassName="ReactModal__Body--open">
      {children}
    </Modal>
  );
};

export default CustomModal;
