import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import WelcomeBackImage from 'assets/images/welcome-back.png';
import WelcomeImageResponsive from 'assets/images/home-mobile.png';
import pawIcon from 'assets/icons/paw-icon.svg';
import { ReactComponent as Mustache } from 'assets/icons/mustache.svg';
import theme from 'styles/theme';
import * as S from './components';
import AuthContext from '../../../contexts/AuthContext';

const TimelineHeader = ({ isTabSwipedUp }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  return (
    <S.Header>
      <S.PawsWrapper>
        <S.PawIcon src={pawIcon} alt="paw" />
        <div>
          <S.PawIcon src={pawIcon} alt="paw" />
        </div>
      </S.PawsWrapper>
      {!isTabSwipedUp && (
        <S.TitleWrapper>
          <S.Title>
            <strong>{t('welcome.back')},</strong>&nbsp; {user?.firstName}!
            {!isMobile && <Mustache />}
          </S.Title>
          {isMobile && <Mustache />}
        </S.TitleWrapper>
      )}

      <S.WelcomeBack
        src={isMobile ? WelcomeImageResponsive : WelcomeBackImage}
        alt="welcome back"
      />
    </S.Header>
  );
};

export default TimelineHeader;
