import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import css from './WorkingAreaEditorPolygon.module.css';
import { Button, cssSpace, TagEditable } from '_fsd/shared';
import { PlusOutlined } from '@ant-design/icons';
import uniqueId from 'lodash/uniqueId';
import { getColor } from '../../admin-map-vets';
import mapStyle from '_fsd/app/map_styles.json';
import { createPortal } from 'react-dom';
import { AREA_TYPE, useFindWorkingAreas } from '../../../entities';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import { getZipArea } from '../../../entities/zip';

const { google } = window;

const getShapeCoordinates = (shape) => {
  return shape
    .getPath()
    .getArray()
    .map((item) => {
      return { lat: item.lat(), lng: item.lng() };
    });
};

const DEFAULT_CENTER = {
  lat: 38.1843034,
  lng: -120.7975979
};

const WithAreasList = ({ children, fullScreenMap, map }) => {
  if (fullScreenMap && map?.getDiv()?.children?.[0])
    return createPortal(
      <div className={css.fullScreenAreaList}>{children}</div>,
      map?.getDiv()?.children?.[0]
    );
  return <div className={cssSpace.mt16}>{children}</div>;
};

const AreasView = ({ form, editable, setEditable, onChangeName, onRemove, createPolygon, t }) => (
  <div className={css.areas}>
    <div className={css.row}>
      {form.values.polygons?.map((p, i) => (
        <TagEditable
          active={editable?.key === p.key}
          onChange={(name) => onChangeName(p, name)}
          color={getColor(i)}
          key={p.key}
          onClick={() => setEditable(p)}
          onClose={() => onRemove(p)}
          focusWhenNameIsEmpty={!p.name}
          defaultName={!p.name && p.key}>
          {p.name}
        </TagEditable>
      ))}
    </div>
    <Button icon={<PlusOutlined />} onClick={createPolygon}>
      {t('settings_page.service_area.create_area_modal.button_newArea')}
    </Button>
  </div>
);

export const WorkingAreaEditorPolygon = ({ form, defaultCenter = DEFAULT_CENTER }) => {
  const { t } = useTranslation();
  const [editable, setEditable] = useState(null);
  const [fullScreenMap, setFullScreenMap] = useState(false);

  const createPolygon = () => {
    setEditable('new_polygon');
  };
  const addPolygonToForm = (area) => {
    const key = uniqueId('Polygon ');
    const polygon = {
      key,
      name: '',
      area
    };
    form.setFieldValue('polygons', [...form.values.polygons, polygon]);
    setEditable(polygon);
  };
  const onRemove = (polygon) => {
    form.setFieldValue(
      'polygons',
      form.values.polygons.filter((p) => p.key !== polygon.key)
    );
  };
  const onChangeName = (polygon, name) => {
    form.setFieldValue(
      'polygons',
      form.values.polygons.map((p) => {
        if (p.key === polygon.key) {
          return { ...p, name };
        }
        return p;
      })
    );
  };

  const [mapRef, setMapRef] = useState(null);
  const polygonRef = useRef({});
  const inputRef = useRef(null);
  const listenersRef = useRef({});

  const onEdit = useCallback(
    (polygon) => {
      const ref = polygonRef.current[polygon.key];
      if (ref) {
        const area = ref
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });
        form.setFieldValue(
          'polygons',
          form.values.polygons?.map((p) => {
            if (p.key === polygon?.key) {
              return {
                ...p,
                area
              };
            }
            return p;
          })
        );
      }
    },
    [form.values.polygons]
  );

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon, p) => {
      polygonRef.current[p.key] = polygon;
      const path = polygon.getPath();
      listenersRef.current[p.key] = [
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      ];
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback((p) => {
    (listenersRef.current[p.key] || []).forEach((lis) => lis.remove());
    delete polygonRef.current[p.key];
  }, []);

  const onDrawComplete = (shape) => {
    const path = getShapeCoordinates(shape);
    google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
    addPolygonToForm(path);
  };

  const [showOtherAreas, setShowOtherAreas] = useState(true);

  return (
    <div className={css.container}>
      <input
        ref={inputRef}
        className={css.input}
        placeholder={t('settings_page.service_area.create_area_modal.placeholder_map_search')}
      />
      <GoogleMap
        mapContainerClassName={css.map}
        center={defaultCenter}
        zoom={7}
        onLoad={(map) => {
          // todo: move to separate function
          setMapRef(map);
          const bounds = new google.maps.LatLngBounds();
          form.values.polygons.forEach((p) => {
            p.area.forEach((a) => {
              bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
            });
          });
          if (form.values.polygons.length > 0) {
            map.panTo(bounds.getCenter());
            map.fitBounds(bounds, 50);
          }
          const input = inputRef.current;
          const autocomplete = new google.maps.places.Autocomplete(input, {
            fields: ['place_id', 'geometry', 'name']
          });

          autocomplete.bindTo('bounds', map);
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
          google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
            input.style.display = 'block';
          });
          const onBoundsChanged = () => {
            if (
              map.getDiv().children[0].clientHeight === window.innerHeight &&
              map.getDiv().children[0].clientWidth === window.innerWidth
            ) {
              setFullScreenMap(true);
            } else {
              setFullScreenMap(false);
            }
          };
          google.maps.event.addListener(map, 'bounds_changed', onBoundsChanged);
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
              return;
            }
            if (place.geometry.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setCenter(place.geometry.location);
              map.setZoom(10);
            }
          });
        }}
        // version="weekly"
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          styles: mapStyle,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.HYBRID,
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
              google.maps.MapTypeId.TERRAIN
            ],
            position: google.maps.ControlPosition.TOP_LEFT,
            style: google.maps.MapTypeControlStyle.DEFAULT
          }
        }}>
        <LoaderWrapper isLoading={!mapRef}>
          <DrawingManager
            onPolygonComplete={onDrawComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingModes: ['polygon', '']
              },
              polygonOptions: { editable: !!editable },
              drawingMode: editable === 'new_polygon' ? 'polygon' : ''
            }}
          />
          {form.values.polygons?.map((p, i) => (
            <Polygon
              options={{
                fillColor: getColor(i),
                fillOpacity: 0.12,
                strokeColor: getColor(i),
                strokeOpacity: 1,
                strokeWeight: 2
              }}
              key={p.key}
              editable={p.key === editable?.key}
              draggable={p.key === editable?.key}
              path={p.area}
              onClick={() => setEditable(p)}
              onMouseUp={() => editable?.key === p.key && onEdit(p)}
              onDragEnd={() => editable?.key === p.key && onEdit(p)}
              onLoad={(polygon) => onLoad(polygon, p)}
              onUnmount={onUnmount}
            />
          ))}
          <OtherAreas mapRef={mapRef} filterId={form.values.uid} showOtherAreas={showOtherAreas} />
        </LoaderWrapper>
      </GoogleMap>
      <WithShowOtherAreasButton
        fullScreenMap={fullScreenMap}
        map={mapRef}
        showOtherAreas={showOtherAreas}
        setShowOtherAreas={setShowOtherAreas}
      />
      <WithAreasList fullScreenMap={fullScreenMap} map={mapRef}>
        <AreasView
          form={form}
          editable={editable}
          setEditable={setEditable}
          onChangeName={onChangeName}
          onRemove={onRemove}
          createPolygon={createPolygon}
          t={t}
        />
      </WithAreasList>
    </div>
  );
};

const WithShowOtherAreasButton = ({ fullScreenMap, setShowOtherAreas, showOtherAreas, map }) => {
  const { t } = useTranslation();
  const text = showOtherAreas
    ? t(`settings_page.service_area.create_area_modal.button_hide_areas`)
    : t(`settings_page.service_area.create_area_modal.button_show_areas`);
  if (fullScreenMap) {
    return createPortal(
      <div className={css.otherAreasButtonFS}>
        <button className={css.gmapButton} onClick={() => setShowOtherAreas((p) => !p)}>{text}</button>
      </div>,
      map?.getDiv()?.children?.[0]
    );
  }
  return (
    <div className={css.otherAreasButton}>
      <button className={css.gmapButton} onClick={() => setShowOtherAreas((p) => !p)}>{text}</button>
    </div>
  );
};

const OtherAreas = React.memo(({ mapRef, filterId, showOtherAreas }) => {
  const { data } = useFindWorkingAreas({
    record: {
      name: ''
    },
    skip: 0,
    take: 1000,
    isSaved: true
  });
  const [zipPolygons, setZipPolygons] = useState([]);

  useEffect(() => {
    if (data?.data.length) {
      const zips = uniq(
        flatMap(
          data?.data
            .map((wa, i) => {
              if (wa.type === AREA_TYPE.ZIP) {
                return {
                  ...wa,
                  zipCodes: wa.zipCodes.map((z) => ({
                    index: i,
                    ...z
                  }))
                };
              }
              return wa;
            })
            .filter((z) => z.type === AREA_TYPE.ZIP),
          (a) => a.zipCodes.map((z) => ({ zip: z.zip, index: z.index }))
        )
      );
      if (zips.length) {
        const zipsCodes = zips.map((z) => z.zip);
        getZipArea(zipsCodes).then((res) => {
          const zipPolygonsObjects = res.map((r) => ({
            ...r,
            index: zips.find((z) => z.zip === r.zip)?.index
          }));
          setZipPolygons(zipPolygonsObjects);
        });
      }
    } else {
      setZipPolygons([]);
    }
  }, [data?.data, mapRef]);

  if (!data?.data?.length || !showOtherAreas) return null;
  return [
    ...flatten(
      data.data
        .filter((wa) => wa.uid !== filterId && wa.type === AREA_TYPE.POLYGON)
        .map((wa) => wa.polygons)
    ),
    ...zipPolygons
  ].map((p) => (
    <Polygon
      options={{
        fillColor: '#777777',
        fillOpacity: 0.12,
        strokeColor: '#919191',
        strokeOpacity: 1,
        strokeWeight: 1
      }}
      key={p.uid}
      editable={false}
      draggable={false}
      path={p.area}
    />
  ));
});
