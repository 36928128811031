import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { FormikProvider, FieldArray } from 'formik';

import theme from 'styles/theme';

import DatePicker from 'components/common/datepicker/DatePicker';

import { gender, isSterilized, weightUnit } from 'constants/pet';

import useAddPetForm from './useAddPetForm';

import {
  FormElementWrapper,
  FormWrapper,
  WeightUnitWrapper,
  AdvancedInformationBtn,
  AdvancedInformationLabel,
  AdvancedInformationIcon,
  FlexWrapper,
  AddAlergyIcon,
  AddAlergyButton,
  AddAlergyLabel,
  ContinueWrapper,
  TextareaWrapper,
  GradientLayer,
  ActionsWrapper,
  ActionsBtnWrapper,
  NameTypeWrapper,
  AdvancedInfoWrapper,
  RemoveAlergyButton,
  BlurredBackground
} from './components';

import Selector from 'components/common/selector/Selector';
import Input from 'components/common/input/Input';
import Button from 'components/common/button/Button';
import ArrowIcon from 'assets/icons/arrow-down.svg';
import PlusIcon from 'assets/icons/plus-green.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/close-gray.svg';
import TextArea from 'components/common/textarea/TextArea';
import PetAcceptionControlModal from 'components/pet-acception-control-modal/PetAcceptionControlModal';
import { PageTitle, PageSubTitle } from 'components/common/texts/components';
import { MY_PETS } from 'constants/client';
import { ReactComponent as PawIcon } from 'assets/icons/paw-orange.svg';
import Dropzone from 'components/common/dropzone/Dropzone';
import { useLocalizationContext } from 'common_repo';

const AddPetForm = ({
  uid,
  isModal,
  handleCloseModal,
  setAdvancedInfoOpen,
  advancedInfoOpen,
  isEdit
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const {
    handleSubmit,
    handleChange,
    touched,
    errors,
    values,
    setFieldValue,
    formik,
    isSubmitting,
    newPetSuggestionModalOpen,
    setNewPetSuggestionModalOpen,
    resetForm,
    petTypes,
    loading
  } = useAddPetForm(uid, isModal, handleCloseModal);
  const { rtl: isRtl } = useLocalizationContext();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptop}px)`
  });
  return (
    <>
      {isCalendarOpen && <BlurredBackground />}
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <FormWrapper isRtl={isRtl}>
            <div>
              <PageTitle>{t(uid ? 'edit.info.of.your.pet' : 'add.your.pet')}</PageTitle>
              <PageSubTitle>{t('fillout.pet.information.registration')}</PageSubTitle>
            </div>
            <FlexWrapper>
              <FormElementWrapper isAvatar>
                <Dropzone
                  nama="avatar"
                  value={values.avatar}
                  placeholder={t('pet.avatar.input.placeholder')}
                  onDrop={(files) => setFieldValue('avatar', files[0])}
                />
              </FormElementWrapper>
              <NameTypeWrapper>
                <FormElementWrapper>
                  <Input
                    type="text"
                    name="name"
                    label={t('pet.name.input.placeholder')}
                    onChange={handleChange}
                    value={values.name}
                    error={touched.name && errors.name && t(errors.name)}
                    maxLength={30}
                  />
                </FormElementWrapper>
                <FormElementWrapper>
                  <Selector
                    isRtl={isRtl}
                    placeholder={`${t('pet.type.input.placeholder')} *`}
                    onChange={(item) => {
                      setFieldValue('type', item?.uid);
                    }}
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.uid}
                    options={petTypes}
                    value={petTypes.find((t) => t.uid === values.type) || null}
                    error={touched.type && errors.type}
                    errorText={t(errors.type)}
                  />
                </FormElementWrapper>
              </NameTypeWrapper>
            </FlexWrapper>
            <FormElementWrapper>
              <Selector
                isRtl={isRtl}
                placeholder={t('pet.gender.input.placeholder')}
                onChange={(value) => {
                  setFieldValue('gender', value);
                }}
                options={gender}
                value={values.gender}
                error={touched.gender && errors.gender && errors.gender.value}
                errorText={t(errors?.gender?.value)}
              />
            </FormElementWrapper>
            <FormElementWrapper>
              <DatePicker
                selected={new Date(values.birthDate).getTime()}
                maxDate={new Date()}
                value={values.birthDate}
                label={t('pet.birthday.input.placeholder')}
                onChange={(date) => setFieldValue('birthDate', date)}
                error={touched.birthDate && errors.birthDate && t(errors.birthDate)}
                setIsCalendarOpen={setIsCalendarOpen}
              />
            </FormElementWrapper>
            {/* <VaccinatedLabel>{t('fully.vaccinated')}</VaccinatedLabel>*/}
            {/* <VaccinatedValuesWrapper>*/}
            {/*  <VaccinateButtonsWrapper>*/}
            {/*    {isFullyVaccinated.map((item) => (*/}
            {/*      <VaccinatedValue*/}
            {/*        isRtl={isRtl}*/}
            {/*        key={item.value}*/}
            {/*        onClick={() => setFieldValue('isFullyVaccinated', item)}*/}
            {/*        isSelected={values.isFullyVaccinated?.value === item.value}*/}
            {/*      >*/}
            {/*        {item.label}*/}
            {/*      </VaccinatedValue>*/}
            {/*    ))}{' '}*/}
            {/*  </VaccinateButtonsWrapper>*/}
            {/*  <ErrorMessage>*/}
            {/*    {touched.isFullyVaccinated &&*/}
            {/*      errors.isFullyVaccinated &&*/}
            {/*      t(errors.isFullyVaccinated.value)}*/}
            {/*  </ErrorMessage>*/}
            {/* </VaccinatedValuesWrapper>*/}

            <AdvancedInformationBtn
              type="button"
              onClick={() => setAdvancedInfoOpen((curr) => !curr)}>
              <AdvancedInformationLabel>{t('advanced.information')}</AdvancedInformationLabel>
              <AdvancedInformationIcon src={ArrowIcon} alt="arrow" isOpen={advancedInfoOpen} />
            </AdvancedInformationBtn>

            {advancedInfoOpen && (
              <AdvancedInfoWrapper>
                <FormElementWrapper>
                  <Input
                    type="text"
                    name="chipNumber"
                    label={t('pet.chip.number.input.placeholder')}
                    onChange={handleChange}
                    value={values.chipNumber}
                    maxLength={25}
                    error={touched.chipNumber && errors.chipNumber}
                    isChipNumber
                  />
                </FormElementWrapper>
                <FormElementWrapper>
                  <Input
                    type="text"
                    name="breed"
                    label={t('pet.breed.input.placeholder')}
                    onChange={handleChange}
                    value={values.breed}
                    error={touched.breed && errors.breed}
                  />
                </FormElementWrapper>
                <FormElementWrapper>
                  <Selector
                    isRtl={isRtl}
                    placeholder={t('pet.sterilized.input.placeholder')}
                    onChange={(value) => {
                      setFieldValue('isSterilized', value);
                    }}
                    options={isSterilized}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }}
                    value={values.isSterilized}
                    error={touched.isSterilized && errors.isSterilized}
                  />
                </FormElementWrapper>
                <FormElementWrapper>
                  <Input
                    type="text"
                    name="foodType"
                    label={t('pet.food.type.input.placeholder')}
                    onChange={handleChange}
                    value={values.foodType}
                    error={touched.foodType && errors.foodType}
                  />
                </FormElementWrapper>
                <FormElementWrapper>
                  <Input
                    type="text"
                    name="medicalAlerts"
                    label={t('pet.medical.alerts.input.placeholder')}
                    onChange={handleChange}
                    value={values.medicalAlerts}
                    error={touched.medicalAlerts && errors.medicalAlerts}
                    maxLength={50}
                  />
                </FormElementWrapper>
                <FormElementWrapper isWeightWrapper>
                  <Input
                    type="text"
                    name="weight"
                    label={t('pet.weight.input.placeholder')}
                    onChange={handleChange}
                    value={values.weight}
                    error={touched.weight && errors.weight}
                    isWeightInput
                  />
                  <WeightUnitWrapper>
                    <Selector
                      isRtl={isRtl}
                      placeholder=""
                      onChange={(value) => {
                        setFieldValue('weightUnit', value);
                      }}
                      options={weightUnit}
                      value={values.weightUnit || weightUnit[0]}
                      customStyles={{
                        control: () => ({
                          backgroundColor: '#ffffff',
                          display: 'flex',
                          height: isMobile ? '5.2rem' : isLaptop ? '5.1rem' : '5.8rem',
                          width: '100%',
                          border: `0.1rem solid ${
                            touched.weight && errors.weight ? theme.colors.red : theme.colors.gray
                          }`,
                          borderRadius: isRtl ? '0.8rem 0 0 0.8rem' : '0 0.8rem 0.8rem 0',
                          direction: 'rtl'
                        })
                      }}
                      error={touched.weight && !!errors.weight}
                    />
                  </WeightUnitWrapper>
                </FormElementWrapper>
                <TextareaWrapper>
                  <TextArea
                    type="text"
                    name="behavioralNotes"
                    label={t('pet.behavioral.notes.input.placeholder')}
                    onChange={handleChange}
                    value={values.behavioralNotes}
                    error={touched.behavioralNotes && errors.behavioralNotes}
                  />
                </TextareaWrapper>
                <FieldArray
                  name="allergies"
                  render={(arrayHelpers) => (
                    <div style={{ width: '100%' }}>
                      {values.allergies.map((_, index) => (
                        <FormElementWrapper hasRemoveIcon key={`allergy${index}`}>
                          <Input
                            label={`${t('allergy')} ${index + 1}`}
                            name={`allergies.${index}`}
                            value={values.allergies[index]}
                            onChange={handleChange}
                          />
                          <RemoveAlergyButton onClick={() => arrayHelpers.remove(index)}>
                            <RemoveIcon />
                          </RemoveAlergyButton>
                        </FormElementWrapper>
                      ))}
                      <AddAlergyButton
                        isModal={isModal}
                        type="button"
                        onClick={() => arrayHelpers.insert(values.allergies.length, '')}>
                        <AddAlergyIcon src={PlusIcon} alt="plus" />
                        <AddAlergyLabel>{t('add.allergy')}</AddAlergyLabel>
                      </AddAlergyButton>
                    </div>
                  )}
                />
              </AdvancedInfoWrapper>
            )}
            {isModal ? (
              <ActionsWrapper>
                <ActionsBtnWrapper>
                  <Button
                    type="button"
                    label={t('cancel')}
                    color="secondary"
                    submitHandler={handleCloseModal}
                  />
                </ActionsBtnWrapper>
                <ActionsBtnWrapper submitButton>
                  <Button
                    color="primary"
                    type="submit"
                    label={
                      isSubmitting
                        ? `${t('processing')} ...`
                        : isEdit
                          ? t('done')
                          : t('add.your.pet')
                    }
                    disabled={isSubmitting}
                  />
                </ActionsBtnWrapper>
              </ActionsWrapper>
            ) : (
              <ContinueWrapper formOpen={advancedInfoOpen} isModal={isModal}>
                <Button
                  type="submit"
                  label={t('continue')}
                  color="primary"
                  disabled={isSubmitting}
                />
                {advancedInfoOpen && <GradientLayer />}
              </ContinueWrapper>
            )}
          </FormWrapper>
        </form>
      </FormikProvider>
      <PetAcceptionControlModal
        isOpen={newPetSuggestionModalOpen}
        onRequestClose={() => {
          if (!loading) {
            setNewPetSuggestionModalOpen(false);
            history.replace(MY_PETS);
          }
        }}
        submitHandler={() => {
          resetForm();
          if (!loading) {
            setNewPetSuggestionModalOpen(false);
          }
        }}
        closeHandler={() => {
          setNewPetSuggestionModalOpen(false);
          history.replace(MY_PETS);
        }}
        caption={t('do.you.want.to.add.more.pets')}
        setNewPetSuggestionModalOpen={setNewPetSuggestionModalOpen}
        icon={<PawIcon />}
      />
    </>
  );
};

export default AddPetForm;
