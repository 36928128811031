import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import useCompleteProfileForm from './useCompleteProfileForm';
import Input from 'components/common/input/Input';
import Button from 'components/common/button/Button';
import NotWorkingAreaModal from 'components/not-working-area-modal/NotWorkingAreaModal';
import { Form, FormRow, InputWrapper } from './components';
import AuthContext from '../../contexts/AuthContext';

const CompleteProfileForm = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [addressHasChanged, setAddressHasChanged] = useState(false);

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    notWorkingAreaModalOpen,
    setNotWorkingAreaModalOpen
  } = useCompleteProfileForm();

  const onAddressSelect = useCallback((address) => {
    setFieldValue('address', { ...values.address, ...address });
    setAddressHasChanged((value) => (!value ? true : value));
  }, []);

  const addressCheck = useMemo(() => {
    return !!(addressHasChanged && (errors?.address?.lat || errors?.address?.lng));
  }, [addressHasChanged, errors.address]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <InputWrapper>
          <Input
            value={values.firstName}
            name="firstName"
            label={t('firstName')}
            onChange={handleChange}
            error={touched.firstName && errors.firstName}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            value={values.lastName}
            name="lastName"
            onChange={handleChange}
            label={t('lastName')}
            error={touched.lastName && errors.lastName}
          />
        </InputWrapper>
      </FormRow>
      <FormRow>
        <InputWrapper>
          <Input
            value={values.email}
            name="email"
            onChange={handleChange}
            label={t('email')}
            toolTipText={t('email.tooltip.text')}
            error={touched.email && errors.email}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            value={`${user?.phonePrefix}${user?.phoneNumber}`}
            label={t('phone.number')}
            disabled
            isNumeric
          />
        </InputWrapper>
      </FormRow>
      <FormRow>
        <InputWrapper full>
          <AddressAutocomplete
            address={values.address}
            onSelect={onAddressSelect}
            error={addressCheck}
            isForCompleteProfile
          />
        </InputWrapper>
        <NotWorkingAreaModal
          isOpen={notWorkingAreaModalOpen}
          onRequestClose={() => {
            setNotWorkingAreaModalOpen(false);
          }}
        />
      </FormRow>
      <FormRow>
        <Button type="submit" disabled={isSubmitting} color="primary" submitHandler={handleSubmit}>
          {t('continue')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default CompleteProfileForm;
