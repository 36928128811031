import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'antd';

import useUpdateVet from '../update-vet/useUpdateVet';
import SubmitButton from 'components/vet-components/vet-web-inputs/submit-button/SubmitBtn';
import VetPersonalInfo from '../vet-personal-info/VetPersonalInfo';
import VetProfessionalInfo from '../vet-professional-info/VetProfessionalInfo';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import * as S from './components';
import './styles.css';

const VetProfile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { formInitialValues, updateVet } = useUpdateVet();
  const { isTablet } = mediaQueriesList();

  const [vetImageUrl, setVetImageUrl] = useState('');

  useEffect(() => {
    form.resetFields();
  }, [formInitialValues]);

  const handleSubmit = (values) => {
    const formValues = { ...values };
    formValues.experience = formValues.experience?.toString() ?? '';
    formValues.endPoint = formValues.address;
    formValues.startPoint = formValues.address;
    formValues.avatarUrl = vetImageUrl;
    delete formValues.addressDescription;
    delete formValues.phoneNumberDisplay;
    if (formInitialValues.uid) {
      formValues.uid = formInitialValues.uid;
    }
    updateVet(formValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="vet-profile-form"
      requiredMark={false}
      onFinish={(values) => handleSubmit(values)}
      initialValues={formInitialValues}>
      {isTablet && (
        <Row justify="space-between">
          <Col>
            <S.ProfileText>{t('profile')}</S.ProfileText>
          </Col>
          <Col>
            <SubmitButton value={t('save')} onClick={form.submit} />
          </Col>
        </Row>
      )}

      <Row>
        <Col span={24}>
          <S.Content>
            <VetPersonalInfo
              form={form}
              formInitialValues={formInitialValues}
              vetImageUrl={vetImageUrl}
              setVetImageUrl={setVetImageUrl}
            />
            <VetProfessionalInfo form={form} />
          </S.Content>
        </Col>
      </Row>

      {!isTablet && (
        <Row justify="center" className="vet-profile-button-wrapper">
          <Col>
            <SubmitButton value={t('save')} onClick={form.submit} />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default VetProfile;
