import styled from 'styled-components';

export const CheckoutDetailsPetCard = styled.div`
  width: ${(props) => (props.isForSlider ? 'auto' : '100%')};
  height: ${(props) => (props.isForSlider ? 'auto' : '4.8rem')};
  padding: 0.8rem;
  border: ${(props) => (props.isForSlider ? 'none' : '.1rem solid #dbe2ee')};
  box-sizing: border-box;
  display: flex;
  ${({ isForSlider }) => isForSlider && 'flex-direction: column;'}
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mobile`
    padding: 0.8rem 2rem 0.8rem 1.2rem;
  `}
`;
export const CheckoutDetailsPetCardLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckoutDetailsPetCardImage = styled.img`
  width: ${(props) => (props.isForSlider ? '4.8rem' : '3.2rem')};
  height: ${(props) => (props.isForSlider ? '4.8rem' : '3.2rem')};
  padding: ${({ hasImage }) => (hasImage ? 0 : '.7rem')};
  background-color: ${({ hasImage }) => (hasImage ? 'none' : 'rgba(219, 226, 238, 1)')};
  object-fit: cover;
  border: 0.15rem solid rgba(255, 255, 255, 0.2);
  border-radius: 0.8rem;
`;
export const CheckoutDetailsPetCardName = styled.h3`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 1.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24rem;
`;
export const CheckoutDetailsPetCardPriceBox = styled.div`
  background: rgba(34, 163, 195, 0.1);
  border-radius: 0.8rem;
  padding: 0.4rem 0.6rem;
  text-align: center;
  margin-top: ${(props) => (props.isForSlider ? '.8rem' : '0')};
`;

export const CheckoutDetailsPetCardPrice = styled.span`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.darkGreen};

  ${({ isForSlider }) =>
    !isForSlider && 'display: flex; gap: 0.5rem; align-items: center; flex-wrap: nowrap;'}
`;

export const OneTimeText = styled.div`
  white-space: nowrap;
  color: #22a3c3;
  font-family: inter, assistant;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: -0.3rem;
  ${({ isVisible }) => !isVisible && 'display: none'}

  ${({ isForSlider }) =>
    !isForSlider && `font-weight: 400; font-size: 1.2rem; margin-top: 0.1rem; `}
`;

export const Bold = styled.p`
  font-size: 1.4rem;
`;
