import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { getPlansQuery } from 'gql';

const usePlansModal = (petType) => {
  const match = useRouteMatch();

  const { data } = useQuery(getPlansQuery, {
    variables: {
      record: {
        subscriptionId: match.params.id,
        petTypeId: petType?.uid
      }
    }
  });

  return {
    plans: data?.getPlans || []
  };
};

export default usePlansModal;
