import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useMembershipDetails from './useMembershipDetails';
import * as S from './components';
import MembershipPetCard from 'components/membership-pet-card/MembershipPetCard';
import useMyPets from 'pages/my-pets/UseMyPets';
import NavigationButtons from 'components/membership/navigation-button/NavigationButtons';
import { ReactComponent as PawIcon } from 'assets/icons/paw-orange.svg';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import theme from 'styles/theme';
import { ReactComponent as DiscountBadge } from 'assets/icons/discount-badge.svg';
import PetAcceptionControlModal from 'components/pet-acception-control-modal/PetAcceptionControlModal';

const MemberShipDetails = () => {
  const { t } = useTranslation();
  const {
    selectedPets,
    handleRemovePet,
    submitHandler,
    goBackHandler,
    discount,
    isLoading,
    subscriptionData
  } = useMembershipDetails();

  const percentage = `${Math.round(discount?.discountAmount * 100)}${
    discount?.additionalDiscountAmount > 0
      ? '% + ' + Math.round(discount?.additionalDiscountAmount * 100)
      : ''
  }`;

  const { petToRemove, setPetToRemove } = useMyPets();
  const petName = petToRemove?.name;

  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptop}px)`
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  const isSubscriptionOneTime = subscriptionData?.subscriptionType?.name === 'one_time';

  return (
    <LoaderWrapper isLoading={isLoading}>
      <S.Wrapper>
        <div>
          <S.Main>
            <S.TextWrapper>
              <div>
                <S.Title>
                  {isSubscriptionOneTime ? t('extra.visit') : t('membership.details')}
                </S.Title>
                <S.SubTitle isSubscriptionOneTime={isSubscriptionOneTime}>
                  {t('check.details.of.membership.before.continue')}
                </S.SubTitle>
              </div>
              {!!discount?.discountAmount && !isMobile && (
                <S.DiscountContainer>
                  <DiscountBadge />
                  <S.TextGray>{t('you.ve.got')}</S.TextGray>
                  <S.TextOrange>
                    <Trans> &nbsp; {{ percentage }}% discount</Trans>
                  </S.TextOrange>
                </S.DiscountContainer>
              )}
            </S.TextWrapper>
            <S.GrayLine />
            <S.PetsContainer>
              <S.PetsWrapper>
                {selectedPets?.map((subscriptionPet, index) => {
                  return (
                    <MembershipPetCard
                      selectedPets={selectedPets}
                      index={index}
                      {...subscriptionPet}
                      subPetId={subscriptionPet.uid}
                      key={subscriptionPet.uid}
                      handleRemovePet={setPetToRemove}
                      isSubscriptionOneTime={isSubscriptionOneTime}
                    />
                  );
                })}
              </S.PetsWrapper>
            </S.PetsContainer>
          </S.Main>
          <PetAcceptionControlModal
            isOpen={!!petToRemove?.subPetId}
            submitHandler={() => {
              handleRemovePet(petToRemove?.subPetId);
              setPetToRemove(null);
            }}
            closeHandler={() => {
              setPetToRemove(null);
            }}
            data={petToRemove}
            onRequestClose={() => setPetToRemove(null)}
            caption={
              <Trans>
                Are you sure you want to remove <S.StyledText>{{ petName }}</S.StyledText> ?
              </Trans>
            }
            icon={<PawIcon />}
          />
        </div>
      </S.Wrapper>
      <S.Footer>
        {isLaptop && !isMobile && <S.PetsWrapperShadow discount={discount?.discountAmount} />}
        <>
          {!isLaptop && <S.PetsWrapperShadow discount={discount?.discountAmount} />}
          {!!discount?.discountAmount && isMobile && (
            <S.DiscountContainer>
              <DiscountBadge />
              <S.TextGray>{t('you.ve.got')}</S.TextGray>
              <S.TextOrange>
                <Trans> &nbsp; {{ percentage }}% discount</Trans>
              </S.TextOrange>
            </S.DiscountContainer>
          )}
        </>
        {!isMobile && (
          <NavigationButtons
            membership
            disabled={!selectedPets.length}
            submitHandler={submitHandler}
            goBackHandler={goBackHandler}
          />
        )}
      </S.Footer>
      {isMobile && (
        <NavigationButtons
          membership
          disabled={!selectedPets.length}
          submitHandler={submitHandler}
          goBackHandler={goBackHandler}
        />
      )}
    </LoaderWrapper>
  );
};

export default MemberShipDetails;
