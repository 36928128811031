import { useMutation } from '@apollo/client';
import { updateAppointmentRouteEta } from 'gql';

const useEditAppointmentEta = () => {
  const [updatetETA] = useMutation(updateAppointmentRouteEta);

  const updateAppointmentETA = async (appointmentRouteId, etaStart, etaEnd) => {
    try {
      await updatetETA({
        variables: {
          data: {
            appointmentRouteId,
            etaStart,
            etaEnd
          }
        }
      });
    } catch (err) {
      console.log(err, 'error');
    }
  };
  return {
    updateAppointmentETA
  };
};

export default useEditAppointmentEta;
