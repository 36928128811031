import React from 'react';
import { useTranslation } from 'react-i18next';

import { petAvatars } from 'constants/pet';
import { getCurrencyIcon } from 'utils/helpers';
import * as S from './components';

const CheckoutDetailsPetCard = ({
  image,
  name,
  price,
  plan,
  currency,
  type,
  isForSlider = false,
  isOneTimeVisit
}) => {
  const { t } = useTranslation();
  return (
    <S.CheckoutDetailsPetCard isForSlider={isForSlider}>
      <S.CheckoutDetailsPetCardLeftSide>
        <S.CheckoutDetailsPetCardImage
          hasImage={!!image}
          isForSlider={isForSlider}
          src={image || petAvatars[type]}
        />
        {!isForSlider && <S.CheckoutDetailsPetCardName>{name}</S.CheckoutDetailsPetCardName>}
      </S.CheckoutDetailsPetCardLeftSide>
      <S.CheckoutDetailsPetCardPriceBox isForSlider={isForSlider}>
        <S.CheckoutDetailsPetCardPrice isForSlider={isForSlider}>
          <S.Bold>
            {getCurrencyIcon(currency)}
            {isOneTimeVisit ? price : `${price}/${t('per.month')}`}
          </S.Bold>
          {!!plan?.additionalFee && (
            <S.OneTimeText isVisible={plan?.additionalFee} isForSlider={isForSlider}>
              +{getCurrencyIcon(currency)}
              {plan?.additionalFee}{' '}
            </S.OneTimeText>
          )}
        </S.CheckoutDetailsPetCardPrice>
      </S.CheckoutDetailsPetCardPriceBox>
    </S.CheckoutDetailsPetCard>
  );
};

export default CheckoutDetailsPetCard;
