import { useHistory } from 'react-router';
import { useContext, useEffect } from 'react';
import { DASHBOARD } from 'constants/client';
import AuthContext from '../contexts/AuthContext';

const useActAsUser = () => {
  const { loading, setToken } = useContext(AuthContext);
  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    const userToken = location?.search?.split('userToken=')[1];
    if (userToken) {
      setToken(userToken, true);
      if (loading) {
        location.search = '';
        history.replace({ ...location });
      } else {
        setTimeout(() => {
          window.location.href = DASHBOARD;
        }, 600);
      }
    }
  }, [location.search]);

  return {};
};

export default useActAsUser;
