import { useQuery } from '@apollo/client';
import { getAppointmentsByDateQuery } from 'gql';

export const useAppointmentsByVetIds = ({ date, timeSlotId, vets, skip, fetchPolicy }) => {
  const { data, ...rest } = useQuery(getAppointmentsByDateQuery, {
    variables: {
      record: {
        date,
        vets,
        timeSlotId
      }
    },
    skip,
    fetchPolicy
  });
  return { data: data?.getAppointmentsByDate, ...rest };
};
