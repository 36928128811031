import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getPetOwnerByUidQuery, getPetsByUserQuery } from 'gql';
import { useEffect, useState } from 'react';

const useClientModal = (customerData) => {
  const [propertyType, setPropertyType] = useState(null);
  const { data } = useQuery(getPetOwnerByUidQuery, {
    variables: {
      record: {
        uid: customerData.uid
      }
    }
  });

  const { data: getPetsByUserData } = useQuery(getPetsByUserQuery, {
    variables: {
      record: {
        userId: customerData.userId
      }
    }
  });

  const serializePropertyType = {
    PrivateHome: 'private_home',
    Apartment: 'apartment'
  };

  useEffect(() => {
    if (data?.getPetOwnerById?.uid && !propertyType) {
      setPropertyType(
        serializePropertyType[data?.getPetOwnerById?.user?.address?.propertyType] ||
          serializePropertyType.PrivateHome
      );
    }
  }, [data]);

  const formInitialValues = useMemo(
    () => ({
      uid: data?.getPetOwnerById?.uid,
      firstName: data?.getPetOwnerById?.user?.firstName,
      lastName: data?.getPetOwnerById?.user?.lastName,
      avatarUrl: data?.getPetOwnerById?.user?.avatarUrl,
      email: data?.getPetOwnerById?.user?.email,
      floor: data?.getPetOwnerById?.user?.address?.floor,
      apartment: data?.getPetOwnerById?.user?.address?.apartment,
      phonePrefix: data?.getPetOwnerById?.user?.phonePrefix,
      phoneNumber: data?.getPetOwnerById?.user?.phoneNumber,
      phoneNumberDisplay: `${data?.getPetOwnerById?.user?.phonePrefix} ${data?.getPetOwnerById?.user?.phoneNumber}`,
      address: {
        countryCode: data?.getPetOwnerById?.user?.address?.country?.code,
        city: data?.getPetOwnerById?.user?.address?.city,
        street: data?.getPetOwnerById?.user?.address?.street,
        description: data?.getPetOwnerById?.user?.address?.description,
        zipCode: data?.getPetOwnerById?.user?.address?.zipCode,
        lat: data?.getPetOwnerById?.user?.address?.lat,
        lng: data?.getPetOwnerById?.user?.address?.lng,
        propertyType: data?.getPetOwnerById?.user?.address?.propertyType
      },
      comment: data?.getPetOwnerById?.user?.address?.comment,
      addressDescription: data?.getPetOwnerById?.user?.address?.description
    }),
    [data?.getPetOwnerById]
  );

  const petsData =
    getPetsByUserData?.getPetsByUser.map((pet) => ({
      ...pet,
      vetAdmin: customerData?.vetAdmin
    })) ?? [];

  return {
    petOwnerData: data?.getPetOwnerById?.user,
    formInitialValues,
    getPetsByUserData: [...petsData].reverse(),
    propertyType,
    setPropertyType
  };
};

export default useClientModal;
