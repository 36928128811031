import * as Yup from 'yup';

export const phoneValidationScheme = Yup.object().shape({
  prefix: Yup.string().required('error.input.phone.must_be_number'),
  phone: Yup.string()
    .required('error.input.phone.required')
    .matches(/^[0-9]+$/, 'phone.input.invalid_number')
    .min(8, 'phone.input.invalid_number')
    .max(15, 'phone.input.invalid_number')
});
