import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { MenuItemCommonStyles } from 'components/side-menu/components';

const NavItemCommonStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1.6rem;
  padding-right: 2rem;
`;

const SvgCommonStyles = css`
  svg {
    fill: ${({ theme, stroke }) => (stroke ? 'none' : theme.colors.white)};
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;

export const MenuItem = styled.li`
  height: 64px;
  ${MenuItemCommonStyles}

  & h2 {
    opacity: 0.64;
    color: ${({ theme }) => theme.colors.white};
    ${({ isForVet }) =>
      isForVet &&
      css`
        color: ${({ theme }) => theme.colors.white};
        margin-bottom: 0;
      `}
  }
  ${({ theme }) => theme.mobile`
  margin-bottom: 0rem;
  `}
`;

export const FlexItem = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 5px;

  ${NavItemCommonStyles}
  &.active svg, &.active path {
    fill: #ff9a05;
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.16);
  }
  &.active h2 {
    opacity: 1;
  }

  ${SvgCommonStyles}
`;

export const MenuItemButton = styled.button`
  ${({ isLogOut }) => isLogOut && `opacity: 0.72;`}
  color: inherit;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  ${({ isActive }) => isActive && 'background-color: rgba(0, 0, 0, 0.16)'};

  & h2 {
    ${({ isActive }) => isActive && 'opacity: 1'};
  }

  ${SvgCommonStyles}

  & svg {
    ${({ isActive, theme, stroke }) => isActive && (stroke ? `` : `fill: ${theme.colors.orange}`)};
    ${({ isActive, theme, stroke }) =>
      !isActive && (stroke ? `fill: none` : `fill: ${theme.colors.white}`)};
    ${({ forPetowner }) =>
      forPetowner &&
      `
      margin-right: 16px;
    `}
  }
  & svg path {
    ${({ isActive, theme, stroke }) =>
      isActive && (stroke ? `stroke: ${theme.colors.orange}` : `fill: ${theme.colors.orange}`)};
    ${({ isActive, theme, stroke }) =>
      !isActive && (stroke ? `stroke: ${theme.colors.white}` : `fill: ${theme.colors.white}`)};
  }

  ${NavItemCommonStyles}
`;
