import React, { memo, useCallback, useContext } from 'react';
import Modal from './Modal';
import VerifyCodeForm from '../../../components/verify-code-on-email-form/VerifyCodeForm';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';
import style from './ModalVerifyPhone.module.css';

const ModalVerifyEmail = ({ email, successHandler, closeModal }) => {
  return (
    <Modal title={'Please verify your Email'}>
      <span className={style.description}>
        Enter the 6-digit code that we have sent you via the email &nbsp;
        <span className={style.phone}>{email}</span>
      </span>
      <VerifyCodeForm
        successHandler={successHandler}
        email={email}
        setIsVerificationCodeSent={() => {}}
        onRequestClose={closeModal}
      />
    </Modal>
  );
};

const Memoized = memo(ModalVerifyEmail);
const Container = () => {
  const { modalContext, closeModal, setErrors } = useContext(ScheduleAppointmentContext);
  const successHandler = useCallback(() => {
    closeModal();
    setErrors({});
  }, []);
  return (
    <Memoized email={modalContext?.email} closeModal={closeModal} successHandler={successHandler} />
  );
};

export default Container;
