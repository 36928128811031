import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './components';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-orange.svg';

const AddPetButton = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <S.Wrapper onClick={handleClick}>
      <S.IconWrapper>
        <PlusIcon />
      </S.IconWrapper>
      <S.Label>{t('add.new.pet')}</S.Label>
    </S.Wrapper>
  );
};

export default AddPetButton;
