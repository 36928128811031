import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import theme from 'styles/theme';

import { ReactComponent as Percentage } from 'assets/icons/percentage-orange.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/close-gray.svg';

import CustomModal from 'components/common/modal/Modal';
import Button from 'components/common/button/Button';
import useChoosePetModal from './useChoosePetModal';

import * as S from './components';

export const ChoosePetModal = ({
  discountModalOpen,
  closeModal,
  congratsModalOpen,
  continueClickHandler
}) => {
  const { t } = useTranslation();
  const { discount, subscriptionType } = useChoosePetModal();
  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptop}px)`
  });

  const getDiscount = subscriptionType === 'one_time' ? '50% discount' : '10% discount';

  return (
    <CustomModal
      isOpen={discountModalOpen || congratsModalOpen}
      onRequestClose={closeModal}
      isLaptop={isLaptop}>
      <S.PetModal>
        <S.Exit onClick={closeModal}>
          <ExitIcon />
        </S.Exit>

        <Percentage fill={discountModalOpen ? 'rgba(254, 154, 6, 1)' : 'rgba(36, 172, 185, 1)'} />

        <S.Title>
          {discountModalOpen ? (
            <Trans i18nKey="get.discount">
              Get <S.TextStyle>{{ getDiscount }}</S.TextStyle> by adding more pet(s) to your
              purchase
            </Trans>
          ) : (
            t('congratulation')
          )}
        </S.Title>
        <S.Description>
          {!discountModalOpen && (
            <Trans>
              {t('you.will.get')}{' '}
              <S.TextStyle congratulations={congratsModalOpen}>{{ discount }}</S.TextStyle>{' '}
              {t('on.second.pet.membership')}
            </Trans>
          )}
        </S.Description>
        <S.ButtonsWrapper>
          <Button white={!discountModalOpen} submitHandler={closeModal} discount={true}>
            {discountModalOpen ? t('select.more.pets') : t('cancel')}
          </Button>
          <Button
            white={discountModalOpen}
            cyan={congratsModalOpen}
            congrats={!discountModalOpen}
            discount={true}
            submitHandler={continueClickHandler}>
            {discountModalOpen ? t('continue.anyway') : t('continue')}
          </Button>
        </S.ButtonsWrapper>
      </S.PetModal>
    </CustomModal>
  );
};
