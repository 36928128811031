import React from 'react';

import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import * as S from './components';

const NavigationButtons = ({ disabled, submitHandler, membership, tooltip, goBackHandler }) => {
  const { t } = useTranslation();
  return (
    <S.Wrapper membership={membership}>
      <S.ButtonsWrapper>
        <S.ActionButton back onClick={goBackHandler}>
          {t('back')}
        </S.ActionButton>
        {tooltip ? (
          <>
            <a data-tip data-for="infoText">
              <S.ActionButton disabled={disabled} onClick={submitHandler}>
                {t('continue')}
              </S.ActionButton>
            </a>
            <ReactTooltip
              id="infoText"
              type="warning"
              backgroundColor="#323B4B"
              textColor="#fff"
              effect="solid">
              {tooltip}
            </ReactTooltip>
          </>
        ) : (
          <S.ActionButton disabled={disabled} onClick={submitHandler}>
            {t('continue')}
          </S.ActionButton>
        )}
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default NavigationButtons;
