import styled from 'styled-components';

export const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2.4rem 4.7rem;
  border-bottom: 0.2rem solid rgb(222, 228, 238, 0.4);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.6rem 1.6rem 0 0;

  ${({ theme }) => theme.laptop`
    padding: 1.9rem 4.7rem;
  `}
  ${({ theme }) => theme.mobile`
    padding: 3rem 2.1rem;
    padding-bottom: 2.2rem;
    justify-content: space-between;
    width: 100vw;
    border-bottom: none;
    box-shadow: inset 0px 10px 30px -5px rgba(0, 0, 0, 0.1);
`}
`;
