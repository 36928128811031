import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import theme from 'styles/theme';

import { useMembershipPetCard } from './useMembershipPetCard';

import { ReactComponent as EditIcon } from 'assets/icons/pencil-gray.svg';
import { ReactComponent as PawIcon } from 'assets/icons/paw-orange.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon-gray.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-thin-gray.svg';
import { ReactComponent as TrashIconWhite } from 'assets/icons/trash-icon-white.svg';
import { petAvatars } from 'constants/pet';

import * as S from './components';

import PlansModal from 'components/membership/plans-modal/PlansModal';
import usePlansModal from 'components/membership/plans-modal/usePlansModal.js';
import { getCurrencyIcon } from 'utils/helpers';

const MembershipPetCard = ({
  subPetId,
  plan,
  fullWidth,
  handleRemovePet,
  pet,
  currency,
  isCheckout,
  isSubscriptionOneTime,
  selectedPets
}) => {
  const location = useLocation();
  const { plans } = usePlansModal(pet?.type);
  const { handleUpdatePetPlan } = useMembershipPetCard();
  const { t } = useTranslation();
  const [planModalIsOpen, setPlanModalIsOpen] = useState(false);
  const updatePetPlan = (planUid) => {
    handleUpdatePetPlan(subPetId, planUid);
  };
  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.desktop}px)`
  });
  const isLaptopSm = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptopSm}px)`
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  const isCheckoutPage = location.pathname.includes('checkout');
  const isCheckoutPageSmallScreen = isLaptopSm && isCheckoutPage;

  return (
    <>
      <S.Wrapper fullWidth={fullWidth} isCheckout={isCheckout}>
        <S.FlexWrapper>
          <S.ImageWrapper>
            <S.Avatar
              src={pet?.avatarUrl || petAvatars[pet.type?.name]}
              alt="avatar"
              hasAvatar={!pet?.avatarUrl}
            />
          </S.ImageWrapper>
          <S.ContentWrapper>
            <S.PetNameWrapper>
              <S.PetName>{pet.name}</S.PetName>
              {isLaptop && !isMobile && (
                <S.PriceContainer>
                  <S.PriceWrapper>
                    <S.PriceText>
                      {getCurrencyIcon(plan?.currency)}
                      {plan?.price}
                      {!isSubscriptionOneTime && <S.FontNormal>/{t('per.month')}</S.FontNormal>}
                      {isLaptopSm && isCheckoutPage && (
                        <S.FontSmall>
                          +{getCurrencyIcon(plan?.currency)}
                          {plan?.additionalFee}{' '}
                        </S.FontSmall>
                      )}
                    </S.PriceText>
                  </S.PriceWrapper>
                  {!!plan?.additionalFee && !isCheckoutPageSmallScreen && (
                    <S.OneTimeText isVisible={plan?.additionalFee}>
                      +{getCurrencyIcon(plan?.currency)}
                      {plan?.additionalFee} <S.FontNormal>one time payment </S.FontNormal>
                    </S.OneTimeText>
                  )}
                </S.PriceContainer>
              )}
              {selectedPets.length > 1 && !isCheckout && (
                <S.IconWrapper>
                  {isLaptop && !isMobile ? (
                    <TrashIconWhite
                      onClick={() => {
                        handleRemovePet({ subPetId, name: pet.name });
                      }}
                    />
                  ) : (
                    <TrashIcon
                      onClick={() => {
                        handleRemovePet({ subPetId, name: pet.name });
                      }}
                    />
                  )}
                </S.IconWrapper>
              )}
            </S.PetNameWrapper>
            <S.SelectedPlanWrapper isSubscriptionOneTime={isSubscriptionOneTime}>
              <S.SelectedPlan>{t('selected.plan')}</S.SelectedPlan>
              <S.ToolTopWrapper>
                <a data-tip data-for="infoText">
                  <InfoIcon fill="#8A94A6" />
                </a>
                <ReactTooltip
                  id="infoText"
                  type="warning"
                  backgroundColor="#323B4B"
                  textColor="#fff"
                  effect="solid">
                  <span>{t('info.text')}</span>
                </ReactTooltip>
              </S.ToolTopWrapper>
            </S.SelectedPlanWrapper>
            <S.PlanWrapper>
              <S.PlanText>{plan?.title}</S.PlanText>
              {plans?.length > 1 && !isSubscriptionOneTime && (
                <S.PlanEditWrapper onClick={() => setPlanModalIsOpen(true)}>
                  <S.ChangeText>{t('change')}</S.ChangeText>
                  <EditIcon />
                </S.PlanEditWrapper>
              )}
            </S.PlanWrapper>
            <S.FeaturesWrapper>
              {plan?.features?.map((feature, i) => {
                return (
                  <S.FeatureItem key={i}>
                    <PawIcon />
                    <S.FeatureTitle>{feature}</S.FeatureTitle>
                  </S.FeatureItem>
                );
              })}
            </S.FeaturesWrapper>
            {(!isLaptop || isMobile) && (
              <S.PriceContainer>
                <S.PriceWrapper>
                  <S.PriceText>
                    {`${getCurrencyIcon(plan?.currency)} ${plan?.price}`}
                    {!isSubscriptionOneTime && `/${t('month')}`}
                  </S.PriceText>
                </S.PriceWrapper>
                {!!plan?.additionalFee && (
                  <S.OneTimeText isVisible={plan?.additionalFee}>
                    +{getCurrencyIcon(plan?.currency)}
                    {plan?.additionalFee} <S.FontNormal>{t('one.time.payment')}</S.FontNormal>
                  </S.OneTimeText>
                )}
              </S.PriceContainer>
            )}
          </S.ContentWrapper>
        </S.FlexWrapper>
      </S.Wrapper>
      <PlansModal
        isOpen={planModalIsOpen}
        setPlansModalOpen={() => setPlanModalIsOpen(false)}
        updatePetPlan={updatePetPlan}
        selectedPlanUid={plan?.uid}
        petName={pet?.name}
        plans={plans}
      />
    </>
  );
};

export default MembershipPetCard;
