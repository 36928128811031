import React, { useCallback } from 'react';

import { MemoizedCalendarSteps } from 'components/calendar/steps/CalendarSteps';
import { Modal } from '_fsd/shared';

import './style.scss';
import { Form } from 'antd';

const NewClientModal = ({
  onRequestClose,
  vet,
  calendarDate,
  selectedTimeslot,
  sendRequestCode,
  checkExistingUser,
  getAppointmentsByTimeslotUid,
  selectedWorkingArea,
  goBack
}) => {
  const [form] = Form.useForm();

  const onFinishNewClient = useCallback((timeslotId, date) => {
    getAppointmentsByTimeslotUid(timeslotId, date);
    onRequestClose();
  }, []);

  return (
    <>
      <Modal
        customFooter={() => null}
        customHeader={() => null}
        isOpen={true}
        onClose={onRequestClose}>
        <MemoizedCalendarSteps
          form={form}
          vet={vet}
          isForVet
          calendarDate={calendarDate}
          selectedTimeslot={selectedTimeslot}
          sendRequestCode={sendRequestCode}
          checkExistingUser={checkExistingUser}
          onFinishNewClient={onFinishNewClient}
          selectedWorkingArea={selectedWorkingArea}
          onRequestClose={onRequestClose}
          goBack={goBack}
        />
      </Modal>
    </>
  );
};

export default NewClientModal;
