import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ isForPreference }) => (isForPreference ? 'calc(100% - 200px)' : '100%')};
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: ${({ isForPreference }) => (isForPreference ? '1rem' : '0')};
`;

export const DropDownContainer = styled.div`
  position: absolute;
  top: 5.7rem;
  width: 96%;
  z-index: 5;
  height: auto;

  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const Option = styled.div`
  padding: 0.5rem 1.2rem;
  display: flex;
  height: 0.8rem;
  height: auto;
  align-items: center;
  padding-left: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  font-family: inter, assistant;
  font-weight: 400;
  background: #fff;
  font-size: 1.3rem;
  line-height: 2.2rem;
  cursor: pointer;

  :hover {
    background-color: rgba(245, 245, 245, 1);
  }
  :last-child {
  }
  :first-child {
  }
`;

export const AddressDescription = styled.span``;

export const AddressCheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem;
`;

export const Dot = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 2.4rem;
  background-color: ${({ missing, isNotNeeded }) =>
    isNotNeeded ? '#89d2e4' : missing ? '#E44E61' : '#41D77D'};
  margin-right: 0.8rem;
`;

export const AddressCheckItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1rem;
`;

export const GoogleWrapper = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  padding-right: 1.2rem;
`;

export const GoogleImage = styled.img`
  object-fit: contain;
`;
