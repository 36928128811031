import React, { useState, useContext } from 'react';

export const WizardContext = React.createContext({});
export const useWizardContext = () => useContext(WizardContext);

const WizardProvider = ({ children }) => {
  const [wizardIsOpen, setWizardIsOpen] = useState(false);

  const openWizard = () => {
    setWizardIsOpen(true);
  };

  const closeWizard = () => {
    setWizardIsOpen(false);
  };

  const data = {
    wizardIsOpen,
    openWizard,
    closeWizard
  };

  return <WizardContext.Provider value={data}>{children}</WizardContext.Provider>;
};

export default WizardProvider;
