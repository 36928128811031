import { useLocalizationContext } from 'common_repo';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './components';

const Checkbox = ({ isChecked, label, handleChange, isForSelect }) => {
  const { t } = useTranslation();
  const { rtl: isRtl } = useLocalizationContext();

  return (
    <S.Label isForSelect={isForSelect}>
      {label && <S.LabelText onClick={handleChange}>{t(label)}</S.LabelText>}
      <S.Input type="checkbox" checked={isChecked} onChange={handleChange} />
      <S.Checkmark isForSelect={isForSelect} onClick={handleChange} isRtl={isRtl} />
    </S.Label>
  );
};

export default Checkbox;
