import { createVetMutation, getAuthVetQuery } from 'gql';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { message } from 'antd';
import moment from 'moment';

import { VetCommuteMethodArr } from 'constants/enums';

const useUpdateVet = () => {
  const { t } = useTranslation();

  const [createVet] = useMutation(createVetMutation, {
    refetchQueries: ['getAuthVet'],
    awaitRefetchQueries: true
  });

  const { data } = useQuery(getAuthVetQuery, {
    fetchPolicy: 'no-cache'
  });

  const updateVet = async (vetData) => {
    try {
      await createVet({
        variables: {
          record: {
            ...vetData
          }
        }
      });
      message.success(t('updated.successfully'));
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || t('failed.to.update'), 10);
    }
  };

  const formInitialValues = useMemo(() => {
    return {
      uid: data?.getAuthVet?.uid,
      firstName: data?.getAuthVet?.user?.firstName,
      lastName: data?.getAuthVet?.user?.lastName,
      avatarUrl: data?.getAuthVet?.user?.avatarUrl,
      email: data?.getAuthVet?.user?.email,
      gender: data?.getAuthVet?.gender,
      licenseNumber: data?.getAuthVet?.licenseNumber,
      experience: data?.getAuthVet?.experience,
      mainCommuteMethod:
        data?.getAuthVet?.commuteMethod?.toLowerCase() || VetCommuteMethodArr[0].value,
      bio: data?.getAuthVet?.user?.bio,
      status: data?.getAuthVet?.status?.toLowerCase(),
      languageIds: data?.getAuthVet?.languages?.map((item) => item.uid),
      birthDate: data?.getAuthVet?.birthDate ? moment(data?.getAuthVet?.birthDate) : null,
      phonePrefix: data?.getAuthVet?.user?.phonePrefix,
      phoneNumber: data?.getAuthVet?.user?.phoneNumber,
      phoneNumberDisplay: `${data?.getAuthVet?.user?.phonePrefix} ${data?.getAuthVet?.user?.phoneNumber}`,
      address: {
        countryCode: data?.getAuthVet?.startPoint?.country?.code,
        city: data?.getAuthVet?.startPoint?.city,
        street: data?.getAuthVet?.startPoint?.street,
        description: data?.getAuthVet?.startPoint?.description,
        zipCode: data?.getAuthVet?.startPoint?.zipCode,
        lat: data?.getAuthVet?.startPoint?.lat,
        lng: data?.getAuthVet?.startPoint?.lng
      },
      addressDescription: data?.getAuthVet?.startPoint?.description
    };
  }, [data]);

  return {
    updateVet,
    getVetByUidData: data?.getAuthVet,
    formInitialValues
  };
};

export default useUpdateVet;
