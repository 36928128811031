import React from 'react';
import EmptyStateIcon from 'assets/icons/clients-empty-state.svg';
import { useTranslation } from 'react-i18next';

const ClientsEmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className="clients-empty-state">
      <h3>{t('admin.recommender.new_appointment.empty_state_title')}</h3>
      <p>{t('admin.recommender.new_appointment.empty_state_description')}</p>
      <img src={EmptyStateIcon} alt="" />
    </div>
  );
};

export default ClientsEmptyState;
