import { INTEGRATION_ENTITY_STATUS } from '_fsd/entities';
import { useMemo } from 'react';

const useSyncStatusMenu = ({ t }) => {
  return useMemo(() => [
    {
      key: '*',
      label: t('customers.sync-status.filter.menu.all')
    },
    {
      key: INTEGRATION_ENTITY_STATUS.SYNCED,
      label: t('customers.sync-status.filter.menu.synced')
    },
    {
      key: INTEGRATION_ENTITY_STATUS.ERROR,
      label: t('customers.sync-status.filter.menu.error')
    }
  ]);
};

export default useSyncStatusMenu;
