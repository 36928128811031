export const TOKEN_KEY = 'token';

const getToken = () => sessionStorage.getItem(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY);

const setToken = (token, toSessionStorage) => {
  if (toSessionStorage) {
    sessionStorage.setItem(TOKEN_KEY, token);
  } else {
    localStorage.setItem(TOKEN_KEY, token);
  }
};

const clearToken = () => {
  if (sessionStorage.getItem(TOKEN_KEY)) {
    sessionStorage.removeItem(TOKEN_KEY);
  } else {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export default {
  getToken,
  setToken,
  clearToken
};
