import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: inter, assistant;
  font-weight: 400;
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid rgba(172, 188, 212, 0.56);
  &:focus-within {
    border-color: #ff9a05;
    ${({ flatBottom }) =>
      flatBottom &&
      css`
        border-radius: 0.8rem 0.8rem 0 0;
      `}
  }
  ${({ error }) =>
    error &&
    css`
      border-color: #ff563f !important;
    `}
`;

export const InputComponent = styled.input`
  height: 5.6rem;
  outline: none;
  border-radius: 0.8rem;
  padding: 2rem 2.4rem;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  outline: none;
  width: 100%;
  ${({ disabled, isCalendarInput }) =>
    disabled &&
    !isCalendarInput &&
    css`
      background: #f2f4f7;
      opacity: 0.43;
    `}

  ${({ isCalendarInput, theme }) =>
    isCalendarInput &&
    css`
      cursor: pointer;
      background: ${theme.colors.white};
    `}

  ${({ theme }) => theme.laptop`
    height: 4.9rem;
  `}
  
  ${({ theme }) => theme.mobile`
      height: 5.1rem;
    `}
    ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-right: 5rem;
    `}
    ${({ isRtl, isNumeric, isWeightInput }) =>
    isRtl &&
    css`
      direction: ${isNumeric || isWeightInput ? 'rtl' : 'ltr'};
      padding-right: ${isNumeric ? '2.4rem' : isWeightInput ? '21.4rem' : '12.6rem'};
    `}
  ${({ isChipNumber }) =>
    isChipNumber &&
    css`
      max-width: 32.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      ${({ theme }) => theme.mobile`
        max-width: 34.8rem;
      `}
    `}
  ${({ isForVet }) =>
    isForVet &&
    css`
      padding: 0.4rem 1.1rem;
      height: unset;
    `}
`;

export const Label = styled.label`
  position: absolute;
  z-index: 3;
  width: fit-content;
  height: 2rem;
  top: calc(50% - 1rem);
  left: 2.4rem;
  padding: 0 0.4rem;
  user-select: none;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: left;
  color: #b0b7c3;
  pointer-events: none;
  transition: 0.3s ease-in-out;

  ${({ value }) =>
    value &&
    css`
      top: -1rem;
      left: 2rem;
      font-size: 1.4rem;
    `}
  ${InputComponent}:focus ~ & {
    top: -1rem;
    left: 2rem;
    color: #ff9a05;
    font-size: 1.4rem;
  }
  &::before {
    content: '';
    width: 100%;
    height: 56%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: -1;
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #fafafc !important;
      `}
    ${({ disabled }) =>
      disabled &&
      css`
        &::before {
          background-color: #fafafc !important;
        }
      `}
  }
  ${({ error }) =>
    error &&
    css`
      color: #ff563f !important;
    `}
  ${({ isCalendarInput }) =>
    isCalendarInput &&
    css`
      ${({ theme }) => theme.laptopS`
        width: 19rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        `}
      ${({ theme }) => theme.laptopSm`
    width: 17rem;
    `}
    ${({ theme }) => theme.tablet`
      width: unset;
      `}
    ${({ theme }) => theme.mobileS`
        width: 17rem;
        `}
    `}
`;

export const IconWrapper = styled.div`
  /* pointer-events: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1.9rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  cursor: pointer;
  color: #b0b7c3;
  z-index: 3;
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `}
  ${InputComponent}:focus ~ & {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const ToolTipText = styled.p`
  display: none;
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  width: 20rem;
  left: -8.8rem;
  bottom: 3.2rem;
  padding: 1rem 1.5rem;
  color: #fff;
  font-family: inter, assistant;
  font-weight: 400;
  border-radius: 0.8rem;
  z-index: 5;
  ${IconWrapper}:hover & {
    display: flex;
  }
  ::after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    background: ${({ theme }) => theme.colors.black};
    bottom: -0.5rem;
    transform: rotate(45deg);
    left: calc(50% - 0.5rem);
    ${({ theme }) => theme.mobile`
        left: calc(85% - 0.5rem);
  `}
  }

  ${({ theme }) => theme.mobile`
    left: -15.8rem;
  `}
`;

export const CalendarWrapper = styled.div`
  height: 5.6rem;
  outline: none;
  border-radius: 0.8rem;
  padding: 2rem 2.4rem;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  outline: none;
  width: 100%;
  padding-right: 5rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #f2f4f7;
      opacity: 0.43;
    `}

  ${({ theme }) => theme.laptop`
    height: 4.9rem;
  `}
  
  ${({ theme }) => theme.mobile`
    height: 5.1rem;
  `}
`;
export const CalendarInput = styled.input`
  border: none;
`;
