import styled from 'styled-components';

export const OneTimeText = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkGreen};
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: right;
  margin-bottom: 0.8rem;
  display: flex;
  align-self: flex-end;
  margin-right: 1rem;
  ${({ isVisible }) => !isVisible && 'visibility: hidden'};

  & svg {
    margin-left: 0.4rem;
  }
`;

export const FontNormal = styled.span`
  font-weight: 400;
  margin-left: 0.2rem;

  ${({ small }) => small && 'font-size: 1.6rem'}
`;

export const ToolTopWrapper = styled.div`
  white-space: normal;
  text-align: left;

  & div {
    width: 20.2rem;
    font-family: inter, assistant;
    font-weight: 300;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
  }
  & a {
    display: flex;
    align-items: center;
  }

  & span {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
`;

export const TooltipText = styled.div`
  width: 15rem;
  height: 10rem;
  word-wrap: break-word;
`;

export const Plan = styled.li`
  width: 24.3rem;
  height: 32.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  border: 0.1rem solid
    ${({ isSelected, theme }) => (isSelected ? theme.colors.orange : theme.colors.ash)};
  box-sizing: border-box;
  border-radius: 0.8rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  ${({ theme }) => theme.mobile`
    width : 100%;
    height: auto;
    margin-bottom : 2.4rem;
  `}
`;

export const PlanHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.2rem;
  border-bottom: 0.05rem solid
    ${({ isSelected, theme }) => (isSelected ? theme.colors.orange : '#c4c4c4')};
`;

export const PlanTiTle = styled.h2`
  font-family: inter, assistant;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '500')};
  font-size: 2rem;
  line-height: 2.9rem;
  color: ${({ isSelected }) => (isSelected ? 'rgba(50, 59, 75, 1)' : 'rgba(50, 59, 75, 0.64)')};
  margin: 2rem 0 1.3rem 3.1rem;
`;

export const ChoosePlan = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.4rem;
  height: 2.4rem;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ isSelected }) =>
    isSelected ? '0.1rem solid #ff9a05' : '0.1rem solid rgba(138, 148, 166, 0.4)'};
  box-sizing: border-box;
  border-radius: 2rem;
  margin: 2rem 2rem 1.8rem 0;
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 2rem;
  background: #ff9a05;
  border: 1px solid #ff9a05;
  box-sizing: border-box;
`;

export const Info = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 15.8rem;
  margin: 1.6rem 1.7rem 1.7rem 1.6rem;
`;

export const Feature = styled.li`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  :first-child {
    margin-top: 0;
  }
  > svg {
    min-height: 1.2rem;
    min-width: 1.2rem;
  }
`;

export const FeatureTitle = styled.h4`
  margin-left: 0.8rem;
  font-size: 1.3rem;
  font-family: inter, assistant;
  font-weight: normal;
  line-height: 2.1rem;
  color: ${({ isSelected }) => (isSelected ? 'rgba(50, 59, 75, 1)' : 'rgba(138, 148, 166, 0.8)')};
`;

export const Price = styled.div`
  color: rgba(124, 130, 140, 1);
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 1.5rem 1.6rem 16.5rem;
  ${({ theme }) => theme.mobile`
    text-align : right;
    margin-bottom : 1.6rem;
  `}
`;

export const PerMonthWrapper = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 0.2rem;
`;
