import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';
import mapStyle from '_fsd/app/map_styles.json';

import { serializeAddressDescription } from 'serializers/signUpFlow.serializer';
import { PropertyType } from 'utils/enums';
import { Form } from 'antd';

import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import AdditionalInfo from 'components/calendar/steps/address/AdditionalInfo';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';

import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import locationIconForMap from 'assets/icons/location-filled.svg';

import useEditAppointmentAddressModal from './useEditAppointmentAddressModal';

import './style.scss';

const { google } = window;

const EditAppointmentAddressModal = ({
  appointment,
  onRequestClose,
  getAppointmentsByTimeslotUid
}) => {
  const { isMobile } = mediaQueriesList();

  const [address, setAddress] = useState({});
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 38.1843034,
    lng: -120.7975979
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 38.1843034,
    lng: -120.7975979
  });

  const { updateAddress } = useEditAppointmentAddressModal();

  const { t } = useTranslation();

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    setAddress({
      ...value,
      description
    });
  }, []);

  const [form] = Form.useForm();

  const __mapLoaded = (map) => {
    map.setOptions({
      styles: mapStyle
    });
  };

  useEffect(() => {
    if (appointment) {
      const appAddress = appointment?.appointmentRoute?.address;

      const coords = {
        lat: appAddress?.lat,
        lng: appAddress?.lng
      };
      setAddress(appAddress);
      setPropertyType(PropertyType[appAddress?.propertyType]);
      setDefaultCenter(coords);
      setMarkerPosition(coords);
    }
  }, [appointment]);

  const initialValues = {
    address: appointment?.appointmentRoute?.address?.description,
    floor: appointment?.appointmentRoute?.address?.floor
      ? String(appointment?.appointmentRoute?.address?.floor)
      : '',
    apartment: appointment?.appointmentRoute?.address?.apartment,
    userComment: appointment?.appointmentRoute?.address?.comment
  };

  return (
    <Modal
      zIndex={1001}
      isOpen={true}
      isLaptop={!isMobile}
      isMobile={isMobile}
      onRequestClose={onRequestClose}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values) => {
          const data = {
            uid: appointment?.appointmentRoute?.uid,
            address: {
              ...values,
              ...address,
              propertyType,
              houseNumber: Number(address?.houseNumber),
              floor: Number(values?.floor) || Number(appointment?.user?.address?.floor),
              comment: values.userComment,
              apartment: values.apartment
            }
          };

          delete data?.address?.address;
          delete data?.address?.userComment;

          updateAddress(data).then(async () => {
            await getAppointmentsByTimeslotUid();
            onRequestClose();
          });
        }}>
        <div className="edit-appointment-address-wrapper address-step">
          <div className="edit-appointment-address__header">
            <LocationIcon />
            <h2>{t('edit_appointment_address.title')}</h2>
          </div>
          <Form.Item
            name="address"
            className="address-field"
            rules={[{ required: true }, { max: 150 }]}>
            <div className="address-field">
              <h3>{t('edit.address')}</h3>
              <AddressAutocomplete
                onSelect={onSelect}
                address={address}
                isForZipCode
                isForCalendar
                isForCompleteProfile={true}
                // error={address && !!Object.keys(address).length && addressError}
              />
            </div>
          </Form.Item>

          <AdditionalInfo propertyType={propertyType} setPropertyType={setPropertyType} />

          <h3>{t('on.the.map')}</h3>
          <GoogleMap
            mapContainerClassName="App-map"
            center={defaultCenter}
            mapContainerStyle={{
              height: 300
            }}
            onLoad={(map) => __mapLoaded(map)}
            zoom={13}
            onZoomChanged={() => {
              const input = document.getElementById('addressInput');
              input.blur();
            }}
            version="weekly"
            options={{
              fullscreenControl: false,
              streetView: true,
              streetViewControl: false,
              mapTypeControl: false,
              zoomControl: false,
              styles: mapStyle
            }}>
            <Marker
              key="marker_1"
              position={markerPosition}
              icon={{
                url: locationIconForMap,
                scaledSize: new google.maps.Size(37, 37)
              }}
            />
          </GoogleMap>

          <div className="edit-appointment-address__footer">
            <Button
              label={t('cancel')}
              onClick={onRequestClose}
              isForModal
              color="#fff"
              fontColor="#323B4B"
              border="1px solid #22A3C3"
              width="75px"
              height="32px"
            />
            <Button
              label={t('done')}
              type="submit"
              isForModal
              color="darkGreen"
              fontColor="#fff"
              width="65px"
              height="32px"
              style={{ marginLeft: '1.6rem' }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditAppointmentAddressModal;
