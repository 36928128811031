import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import SadSmile from 'assets/icons/sad-smile.svg';

import './style.scss';

const LimitReachedWarning = () => {
  const { t } = useTranslation();
  return (
    <div className="limit-reached-wrapper">
      <div className="limit-reached-box">
        <img src={SadSmile} />
        <h3>{t('vet_routes.passed_your_plan')}</h3>
        <h4>
          {t('vet_routes.view_schedule')} <span>{t('vet_routes.upgrade_your_plan')}</span>
        </h4>
        <a
          href="mailto:info@kumba.pet?subject=I want to upgrade my plana Kumba"
          target="_blank"
          rel="noreferrer">
          {t('vet_routes.upgrade_plan')}
        </a>
      </div>
    </div>
  );
};

export default memo(LimitReachedWarning);
