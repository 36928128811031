import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Polygon } from '@react-google-maps/api';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import css from './WorkingAreasMapView.module.css';
import { attachSearchToGMap, Button, cssText, generateColors, Tag, TagEditable } from '_fsd/shared';
import { AREA_TYPE } from '_fsd/entities/working-area';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import { getZipArea } from '_fsd/entities/zip';
import mapStyle from '_fsd/app/map_styles.json';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { EditOutlined } from '@ant-design/icons';

const { google } = window;

const DEFAULT_CENTER = {
  lat: 38.1843034,
  lng: -120.7975979
};

const DEFAULT_AREA_NAME = 'Area';
const TAG_COUNT = 10;

const getHoveredColorProps = (hovered, areas, colors) => {
  if (hovered.length) {
    const area = hovered.find((h) => areas.some((a) => a.uid === h.uid));
    if (area) {
      return {
        polygon: {
          fillColor: colors[area.index],
          strokeColor: colors[area.index]
        },
        tag: {
          active: true
        }
      };
    } else {
      return {
        polygon: {
          fillColor: '#777777',
          fillOpacity: 0.12,
          strokeColor: '#919191',
          strokeOpacity: 1,
          strokeWeight: 1
        },
        tag: {
          color: '#777777',
          disabled: true
        }
      };
    }
  }
  return {
    polygon: {},
    tag: {}
  };
};

export const fitBounds = (map, areas, zipPolygons) => {
  const bounds = new google.maps.LatLngBounds();
  let boundsChanged = false;
  areas?.forEach((area) =>
    area.polygons.forEach((p) => {
      p.area.forEach((a) => {
        boundsChanged = true;
        bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
      });
    })
  );
  zipPolygons?.forEach((zipCode) =>
    zipCode.polygons.forEach((p) => {
      p.forEach((a) => {
        boundsChanged = true;
        bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
      });
    })
  );
  if (boundsChanged) {
    map.panTo(bounds.getCenter());
    map.fitBounds(bounds, 50);
  }
};

export const WorkingAreasMapView = ({
  data: dataRaw,
  loading,
  handleClickArea,
  disableControls,
  mapClass,
  containerClass,
  defaultCenter = DEFAULT_CENTER
}) => {
  const [showMore, setShowMore] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const data = useMemo(() => {
    return dataRaw.map((e, i) => ({ ...e, index: i }));
  }, [dataRaw, loading]);

  const [selectedAreas, setSelectedAreas] = useState({ areas: [], position: null });

  const [zipPolygons, setZipPolygons] = useState([]);

  useEffect(() => {
    if (mapRef && data && zipPolygons && !loading) {
      fitBounds(mapRef, data, zipPolygons);
    }
  }, [data, zipPolygons, loading, mapRef]);

  useEffect(() => {
    if (data?.length) {
      const zips = uniq(
        flatMap(
          data
            .map((wa, i) => {
              if (wa.type === AREA_TYPE.ZIP) {
                return {
                  ...wa,
                  zipCodes: wa.zipCodes.map((z) => ({
                    index: i,
                    ...z
                  }))
                };
              }
              return wa;
            })
            .filter((z) => z.type === AREA_TYPE.ZIP),
          (a) => a.zipCodes.map((z) => ({ zip: z.zip, index: z.index }))
        )
      );
      if (zips.length) {
        const zipsCodes = zips.map((z) => z.zip);
        getZipArea(zipsCodes).then((res) => {
          const zipPolygonsObjects = res.map((r) => ({
            ...r,
            areas: data.filter(
              (wa) => wa.type === AREA_TYPE.ZIP && wa.zipCodes.some((z) => z.zip === r.zip)
            ),
            index: zips.find((z) => z.zip === r.zip)?.index
          }));
          setZipPolygons(zipPolygonsObjects);
        });
      }
    } else {
      setZipPolygons([]);
    }
  }, [data]);

  const colors = useMemo(() => {
    if (data?.length > 0) {
      return generateColors(data.length);
    }
    return [];
  }, [data]);

  const [hovered, setHovered] = useState([]);
  const handleMouseOver = (areas) => {
    setHovered(areas);
  };
  const handleMouseOut = (areas) => {
    setHovered((prev) => prev.filter((p) => !areas.some((a) => a.uid === p.uid)));
  };

  return (
    <div className={cls(css.container, containerClass)}>
      {!disableControls && (
        <input
          ref={inputRef}
          className={css.input}
          placeholder={t('settings_page.service_area.create_area_modal.placeholder_map_search')}
        />
      )}
      <GoogleMap
        colors
        mapContainerClassName={cls(css.map, mapClass)}
        center={defaultCenter}
        zoom={7}
        onLoad={(map) => {
          setMapRef(map);
          fitBounds(map, data);
          attachSearchToGMap(inputRef, map, disableControls);
        }}
        version="weekly"
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          styles: mapStyle
        }}>
        <LoaderWrapper isLoading={!mapRef}>
          {data?.map((area) => {
            if (area.type === AREA_TYPE.POLYGON) {
              return area.polygons?.map((p, i) => (
                <Polygon
                  onMouseOver={() => !disableControls && handleMouseOver([area])}
                  onMouseOut={() => !disableControls && handleMouseOut([area])}
                  onClick={() =>
                    !disableControls && setSelectedAreas({ areas: [area], position: p.area[0] })
                  }
                  options={{
                    fillColor: colors[area.index],
                    fillOpacity: 0.12,
                    strokeColor: colors[area.index],
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    ...getHoveredColorProps(hovered, [area], colors).polygon
                  }}
                  key={area.uid + p.uid}
                  path={p.area}
                />
              ));
            }
            return null;
          })}
          {zipPolygons?.map((zip) => {
            return zip.polygons.map((p, index) => (
              <Polygon
                onMouseOver={() => !disableControls && handleMouseOver(zip.areas)}
                onMouseOut={() => !disableControls && handleMouseOut(zip.areas)}
                options={{
                  fillColor: colors[zip.index],
                  fillOpacity: 0.12,
                  strokeColor: colors[zip.index],
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  ...getHoveredColorProps(hovered, zip.areas, colors).polygon
                }}
                key={index}
                onClick={() => setSelectedAreas({ areas: zip.areas, position: p[0] })}
                path={p}
              />
            ));
          })}
        </LoaderWrapper>
        {selectedAreas.areas?.length && (
          <InfoWindow
            options={{
              maxWidth: 400,
              minWidth: 400,
              maxHeight: 700
            }}
            position={selectedAreas.position}
            onCloseClick={() => setSelectedAreas({})}>
            <div className={css.popup}>
              <span className={cls(css.popupTitle, cssText.s16w5l24)}>
                {t('settings_page.service_area.areas.map_popup_title')}
              </span>
              <div className={css.popupContainer}>
                {selectedAreas.areas.map((a) => (
                  <div key={a.uid} className={css.popupArea}>
                    <div className={css.header}>
                      <div className={css.area}>
                        <div className={css.square} style={{ background: colors[a.index] }} />
                        <span className={cssText.s14w5l22}>{a.name}</span>
                      </div>
                      <Button
                        type="text"
                        size="small"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setSelectedAreas({});
                          handleClickArea(a);
                        }}
                      />
                    </div>
                    <div className={css.polygons}>
                      <span className={cssText.s12w4l20}>
                        {t('settings_page.service_area.areas.map_popup_polygons_title')}
                      </span>
                      <div className={css.polygonsContainer}>
                        {a.polygons?.map((p) => (
                          <Tag gray key={p.uid}>
                            {p.name}
                          </Tag>
                        ))}
                        {a.zipCodes?.map((p) => (
                          <Tag gray key={p.uid}>
                            {p.zip}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      {!disableControls && data?.length && (
        <div className={css.areas}>
          {data.slice(0, showMore ? undefined : TAG_COUNT).map((area) => (
            <TagEditable
              onMouseEnter={() => handleMouseOver([area])}
              onMouseLeave={() => handleMouseOut([area])}
              color={colors[area.index]}
              {...getHoveredColorProps(hovered, [area], colors).tag}
              key={area.uid}
              onClick={() => handleClickArea(area)}>
              {area.name || DEFAULT_AREA_NAME}
            </TagEditable>
          ))}
          {data?.length > TAG_COUNT && (
            <Button onClick={() => setShowMore((p) => !p)}>
              {!showMore
                ? t('settings_page.service_area.areas.button.more', {
                    count: data.length - TAG_COUNT
                  })
                : t('settings_page.service_area.areas.button.less')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
