import { useQuery, useMutation } from '@apollo/client';
import { CHECKOUT } from 'constants/client';

import {
  chooseVetForSubscriptionMutation,
  getVetsQuery,
  getAppointmentQuery,
  getTimeQuery
} from 'gql';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { replaceRouteParams } from 'utils/helpers';

import { MEMBERSHIP_DETAILS } from 'constants/client';

const useSelectVet = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { id } = match.params;

  const time = useQuery(getTimeQuery, {
    fetchPolicy: 'no-cache'
  });

  const { data, loading } = useQuery(getVetsQuery, {
    fetchPolicy: 'no-cache'
  });

  const appointment = useQuery(getAppointmentQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const submitHandler = async (timeSlotId, appointmentDate) => {
    try {
      await chooseVet({
        variables: {
          record: {
            timeSlotId,
            appointmentDate,
            subscriptionId: id
          }
        }
      });
      history.push(replaceRouteParams(CHECKOUT, 'id', id));
    } catch (err) {
      throw err;
    }
  };

  const goBackHandler = () => {
    history.push(replaceRouteParams(MEMBERSHIP_DETAILS, 'id', id));
  };

  const [chooseVet] = useMutation(chooseVetForSubscriptionMutation, {
    refetchQueries: [
      {
        query: getVetsQuery
      }
    ]
  });

  return {
    vets: data?.getVetsInUserArea && !loading ? data.getVetsInUserArea : [],
    loading,
    submitHandler,
    selectedAppointment: appointment?.data ? appointment?.data?.getAppointment : null,
    time: time?.getTime || 14,
    goBackHandler
  };
};

export default useSelectVet;
