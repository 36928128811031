import React from 'react';

import { CloseIconWrapper } from './components';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

const Close = ({ clickHandler }) => {
  return (
    <CloseIconWrapper onClick={clickHandler}>
      <CloseIcon />
    </CloseIconWrapper>
  );
};

export default Close;
