import React from 'react';
import { useTranslation } from 'react-i18next';

import VerifyCodeForm from 'components/verify-code-form/VerifyCodeForm.jsx';

import CustomModal from 'components/common/modal/Modal';

import * as S from './component.jsx';

import { ReactComponent as ExitIcon } from 'assets/icons/close-gray.svg';

const CodeVerifyModal = ({
  isOpen,
  onRequestClose,
  phonePrefix,
  phoneNumber,
  setIsVerificationCodeSent,
  isPhoneNumberExists
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Wrapper>
        <S.Header>
          <S.Title>{t('verify_code.title')} </S.Title>
          <ExitIcon onClick={onRequestClose} />
        </S.Header>
        <S.Main>
          <S.MainTitle>
            {t('verify_code.description')}{' '}
            {phoneNumber && <S.PhoneNumber>{phonePrefix + ' ' + phoneNumber}</S.PhoneNumber>}
          </S.MainTitle>
          <VerifyCodeForm
            isForModal
            isPhoneNumberExists={isPhoneNumberExists}
            pPrefix={phonePrefix}
            pNumber={phoneNumber}
            setIsVerificationCodeSent={setIsVerificationCodeSent}
            onRequestClose={onRequestClose}
          />
        </S.Main>
      </S.Wrapper>
    </CustomModal>
  );
};

export default CodeVerifyModal;
