import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useLocalizationContext } from 'common_repo';

import { ReactComponent as GoBackIcon } from 'assets/icons/arrow-left.svg';
import * as S from './components';

const GoBack = ({ clickHandler }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { rtl } = useLocalizationContext();
  return (
    <>
      {!!(history.length > 1 || clickHandler) && (
        <S.Wrapper onClick={clickHandler || history.goBack}>
          <S.IconWrapper rtl={rtl}>
            <GoBackIcon />
          </S.IconWrapper>
          <S.Label>{t('go.back.button')}</S.Label>
        </S.Wrapper>
      )}
    </>
  );
};

export default GoBack;
