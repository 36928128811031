import React from 'react';

export const AttachmentIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.46056 8.3821L9.25549 1.58718C10.4271 0.415604 12.3266 0.415602 13.4981 1.58718C14.6613 2.75035 14.6696 4.63104 13.5232 5.8045L6.70331 12.6248C5.92226 13.4058 4.65593 13.4058 3.87488 12.6248C3.09383 11.8437 3.09383 10.5774 3.87488 9.79632L7.64612 6.02509C7.90647 5.76474 8.32858 5.76474 8.58893 6.02509C8.84928 6.28544 8.84928 6.70755 8.58893 6.9679L4.81769 10.7391C4.55734 10.9995 4.55734 11.4216 4.81769 11.6819C5.07804 11.9423 5.50015 11.9423 5.7605 11.6819L12.5554 4.88707C13.2063 4.23619 13.2062 3.18086 12.5553 2.52998C11.9044 1.87911 10.8492 1.87911 10.1983 2.52999L3.40341 9.32487C2.36201 10.3663 2.36208 12.0548 3.40348 13.0962C4.44488 14.1376 6.13332 14.1376 7.17472 13.0962L10.946 9.32494C11.2063 9.0646 11.6284 9.0646 11.8888 9.32494C12.1491 9.58529 12.1491 10.0074 11.8888 10.2678L8.11753 14.039C6.55543 15.6011 4.02277 15.6011 2.46067 14.039C0.898587 12.4769 0.898502 9.9442 2.46056 8.3821Z"
      fill="#36517E"
    />
  </svg>
);
