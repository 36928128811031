import { useQuery, gql } from '@apollo/client';

export const useVetAdminByUserId = (variables = {}, options = {}) => {
  const { data, ...rest } = useQuery(query, { variables, ...options });
  return { data: data?.findVetAdminByUserId, ...rest };
};

const query = gql`
  query findByVetAdminUserId($record: FindVetAdminByUserIdRecord!) {
    findVetAdminByUserId(record: $record) {
      uid
      integrations {
        status
        uid
        pimSettings
      }
    }
  }
`;
