import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Tooltip } from 'antd';

import { ReactComponent as Eye } from 'assets/icons/visibility.svg';
import './styles.css';
const { TextArea: AntTextArea } = Input;

const TextArea = ({ label, name, placeholder, hasVisibilitySign, required, limit }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required,
          message: t('form.required')
        },
        {
          max: limit,
          message: t('too.much.symbols', { max: limit })
        }
      ]}
      label={
        <div>
          {label}
          {hasVisibilitySign && (
            <Tooltip title={t('info.will.be.visible')}>
              <Eye />
            </Tooltip>
          )}
        </div>
      }
      labelCol={{ span: 24 }}>
      <AntTextArea
        placeholder={placeholder}
        maxLength={limit}
        autoSize={{ minRows: 5, maxRows: 5 }}
      />
    </Form.Item>
  );
};

export default memo(TextArea);
