import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import theme from 'styles/theme';
import { DASHBOARD } from 'constants/client';
import TimelineHeader from 'components/timeline/header/TimelineHeader';
import Timeline from 'components/timeline/content/Timeline';
import Notifications from 'components/timeline/notifications/notification-component/Notifications';
import VetModal from './vet-modal/VetModal';
import { ReactComponent as CalendarIcon } from 'assets/images/timeline-tabs/calendar.svg';
import { ReactComponent as CalendarIconActive } from 'assets/images/timeline-tabs/calendar-active.svg';
import { ReactComponent as PowIcon } from 'assets/images/timeline-tabs/pow.svg';
import { ReactComponent as PowIconActive } from 'assets/images/timeline-tabs/pow-active.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/timeline-tabs/notification.svg';
import { ReactComponent as NotificationIconActive } from 'assets/images/timeline-tabs/notification-active.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-down-white.svg';

import * as S from './components';

const activeTabEnum = {
  timeline: 'timeline',
  pets: 'pets',
  notifications: 'notifications'
};

const Dashboard = () => {
  const [vetUid, setVetUid] = useState(null);

  const [activeTab, setActiveTab] = useState(activeTabEnum.timeline);

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme?.screenSizes?.mobile}px)`
  });

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    window.location.href = `${DASHBOARD}#${tab}`;
  };

  useEffect(() => {
    const tab = window.location.href.split('#')[1];
    if (tab) setActiveTab(activeTabEnum[tab]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isTabSwipedUp, setIsTabSwipedUp] = useState(false);

  const handleSetVetUid = (value, e) => {
    e.stopPropagation();
    setVetUid(value);
  };

  return (
    <S.Wrapper isNotification={activeTab === activeTabEnum.notifications}>
      <TimelineHeader isTabSwipedUp={isTabSwipedUp} />
      <S.TabContentWrapper
        isTabSwipedUp={isTabSwipedUp}
        onClick={() => {
          if (isMobile) {
            setIsTabSwipedUp(true);
          }
        }}>
        <S.TimelineTabsWrapper>
          <S.ArrowWrapper
            onClick={(e) => {
              e.stopPropagation();
              setIsTabSwipedUp(false);
            }}
            isTabSwipedUp={isTabSwipedUp}>
            <Arrow />
          </S.ArrowWrapper>
          <S.TimelineTab
            onClick={() => handleChangeTab(activeTabEnum.timeline)}
            isActiveTab={activeTab === activeTabEnum.timeline}>
            {activeTab === activeTabEnum.timeline ? <CalendarIconActive /> : <CalendarIcon />}
          </S.TimelineTab>
          <S.TimelineTab
            isActiveTab={activeTab === activeTabEnum.pets}
            onClick={() => handleChangeTab(activeTabEnum.pets)}>
            {activeTab === activeTabEnum.pets ? <PowIconActive /> : <PowIcon />}
          </S.TimelineTab>

          <S.TimelineTab
            isActiveTab={activeTab === activeTabEnum.notifications}
            onClick={() => handleChangeTab(activeTabEnum.notifications)}>
            {activeTab === activeTabEnum.notifications ? (
              <NotificationIconActive />
            ) : (
              <NotificationIcon />
            )}
          </S.TimelineTab>
        </S.TimelineTabsWrapper>
        {vetUid && (
          <VetModal vetId={vetUid} isOpen={true} handleCloseModal={() => setVetUid(null)} />
        )}
        <S.TimelineFlexWrapper>
          {activeTab !== activeTabEnum.notifications && (
            <Timeline
              activeTabEnum={activeTabEnum}
              activeTab={activeTab}
              setVetUid={handleSetVetUid}
            />
          )}
          <Notifications isActiveTab={activeTab === activeTabEnum.notifications} />
        </S.TimelineFlexWrapper>
      </S.TabContentWrapper>
    </S.Wrapper>
  );
};

export default Dashboard;
