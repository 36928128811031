import moment from 'moment/moment';

export const getAge = (birthDate) => {
  const now = moment();
  const years = now.diff(birthDate, 'years');
  const months = now.diff(birthDate, 'months') % 12;
  let res = '';

  if (years != 0) res = res + years + 'y ';
  if (months != 0) res = res + months + 'm';

  return res;
};
