import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';

import TimelinePetCard from '../pet-card/TimelinePetCard';

import * as S from './components';

import PetModal from 'components/pet-modal/PetModal';
import { IconWrapper } from 'components/common/go-back/components';

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-green.svg';

import myPetsIcon from 'assets/icons/mypets-icon.svg';
import { useLocalizationContext } from 'common_repo';
import theme from 'styles/theme';

const TimelinePets = ({ isActiveTab, pets }) => {
  const { t } = useTranslation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [isPetModalOpen, setIsPetModalOpen] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const { rtl: isRtl } = useLocalizationContext();

  const isLaptopSm = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptopSm}px)`
  });

  const isLaptopm = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptopM}px)`
  });

  return (
    <S.TimelinePetsContainer isActiveTab={isActiveTab}>
      <S.PetsHead>
        <S.TitleAndIconWrapper>
          <S.PetsIcon src={myPetsIcon} alt="timelineIcon" />
          <S.PetsHeadTitle>{t('my.pets')}</S.PetsHeadTitle>
        </S.TitleAndIconWrapper>

        <S.NewPetTitle onClick={() => setIsPetModalOpen(true)}>
          <PlusIcon /> {t('new.pet')}
        </S.NewPetTitle>
      </S.PetsHead>
      {!isMobile && (
        <S.PetsSlider isRtl={isRtl}>
          <S.Prev ref={prevRef} rtl={isRtl}>
            <IconWrapper>
              <ArrowLeft />
            </IconWrapper>
          </S.Prev>
          <S.Prev ref={nextRef} rtl={!isRtl}>
            <IconWrapper rtl>
              <ArrowLeft />
            </IconWrapper>
          </S.Prev>
          <ReactSwiper
            slidesPerView={isLaptopSm ? 3 : isLaptopm ? 4 : 5}
            spaceBetween={20}
            navigation={{
              prevEl: prevRef.current ? prevRef.current : undefined,
              nextEl: nextRef.current ? nextRef.current : undefined
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.update();
            }}>
            {pets?.map((pet) => {
              return (
                <SwiperSlide key={pet.uid}>
                  <TimelinePetCard
                    id={pet.uid}
                    image={pet.avatarUrl}
                    name={pet.name}
                    price={pet.price}
                    type={pet.type?.name}
                    hasActiveSubscription={pet?.activeSubscription}
                    vet={pet?.activeSubscription?.vet?.user}
                    vetId={pet?.activeSubscription?.vet?.uid}
                  />
                </SwiperSlide>
              );
            })}
          </ReactSwiper>
        </S.PetsSlider>
      )}

      {isMobile && (
        <S.MobilePetsWrapper>
          {pets?.map((pet) => {
            return (
              <TimelinePetCard
                key={pet.uid}
                id={pet.uid}
                image={pet.avatarUrl}
                name={pet.name}
                price={pet.price}
                type={pet.type?.name}
                hasActiveSubscription={pet?.activeSubscription}
                vet={pet?.activeSubscription?.vet?.user}
                vetId={pet?.activeSubscription?.vet?.uid}
              />
            );
          })}
        </S.MobilePetsWrapper>
      )}
      <PetModal isOpen={isPetModalOpen} handleCloseModal={() => setIsPetModalOpen(false)} />
    </S.TimelinePetsContainer>
  );
};

export default TimelinePets;
