import React, { memo } from 'react';
import cls from 'classnames';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  IntegrationIcon,
  IntegrationStatus,
  INTEGRATION_MODAL,
  INTEGRATION_STATUS
} from '_fsd/entities/integration';
import css from './IntegrationItem.module.css';

const Integration = ({ data, onClick, openModal }) => {
  const {
    pim: { name },
    icon,
    bgColor,
    status,
    lastUpdate,
    messages
  } = data;
  const { t } = useTranslation();

  return (
    <div
      className={css.item}
      onClick={() =>
        openModal({
          type: INTEGRATION_MODAL.CREDENTIALS_EDITOR,
          context: { integration: data, pim: data.pim }
        })
      }>
      <div className={css.header}>
        <div className={css.integration}>
          <IntegrationIcon icon={icon} bgColor={bgColor} />
          <span className={css.text}>{name}</span>
        </div>
        <div>
          <IntegrationStatus status={status} messages={messages} />
        </div>
      </div>
      <div className={cls(css.content)}>
        <span>{t('integration.label.last_update')}</span>
        <div className={css.row}>
          <span>{moment(lastUpdate).format('DD/MM/YYYY hh:mm A')}</span>
          <SyncOutlined className={css.iconOutlined} />
        </div>
      </div>
      <div className={cls(css.footer)}>
        <Button
          type="default"
          disabled
          className={css.button}
          onClick={(e) => {
            e.stopPropagation();
            // openModal({
            //   type: INTEGRATION_MODAL.DISABLE,
            //   context: { id: data.id, integration: data }
            // });
          }}>
          {t('integration.button.disable')}
        </Button>
      </div>
    </div>
  );
};

const IntegrationDisabled = ({ data, openModal }) => {
  const {
    pim: { name },
    icon,
    bgColor
  } = data;
  return (
    <div
      className={css.item}
      onClick={() => {
        openModal({
          type: INTEGRATION_MODAL.CREDENTIALS_EDITOR,
          context: { integration: data, pim: data.pim }
        });
      }}>
      <div className={css.itemDisabled}>
        <IntegrationIcon icon={icon} bgColor={bgColor} disabled />
        <span>{name}</span>
      </div>
    </div>
  );
};

const MemoizedItem = memo(Integration);
const MemoizedItemDisabled = memo(IntegrationDisabled);

export const IntegrationItem = (props) => {
  if (props.disabled || props.data.status === INTEGRATION_STATUS.DISABLED) {
    return <MemoizedItemDisabled {...props} />;
  }
  return <MemoizedItem {...props} />;
};
