import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import VetCard from 'components/vet-card/VetCard';

import * as S from './components';
import useSelectVet from './useSelectVet';

import NavigationButtons from 'components/membership/navigation-button/NavigationButtons';

import SadIcon from 'assets/icons/sad.png';

const SelectVet = () => {
  const { vets, submitHandler, selectedAppointment, loading, goBackHandler, time } = useSelectVet();
  const [timeSlotId, setTimeSlotId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();

  const [selectedVetUid, setSelectedVetUid] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedAppointment?.uid) {
      setAppointmentDate(selectedAppointment.date);
      setTimeSlotId(selectedAppointment?.timeSlot?.uid);
    }
  }, [selectedAppointment]);

  const submitCalendar = (timeSlotId, appointmentDate) => {
    setTimeSlotId(timeSlotId);
    setAppointmentDate(appointmentDate);
  };

  return (
    <LoaderWrapper isLoading={loading}>
      <S.Wrapper>
        {vets.length > 0 ? (
          <S.Main>
            <S.PageTitle>{t('choose.vet.and.a.time')}</S.PageTitle>
            <S.PageSubTitle>{t('choose.vet.and.continue')}</S.PageSubTitle>
            <S.PageSubTitle>{t('the.appointments.rescheduled.later')}</S.PageSubTitle>
            <S.VetsWrapper>
              {vets.map((vet) => (
                <VetCard
                  key={vet.uid}
                  {...vet}
                  submitCalendar={submitCalendar}
                  selectedAppointment={selectedAppointment}
                  timeSlotId={timeSlotId}
                  selectedVetUid={selectedVetUid}
                  setSelectedVetUid={setSelectedVetUid}
                  time={time}
                />
              ))}
            </S.VetsWrapper>
          </S.Main>
        ) : (
          <S.MessageWrapper>
            <S.SadIcon alt="sad" src={SadIcon} />
            <S.MessageCaption>{t('we.cant.give.you.service.for.now')}</S.MessageCaption>
            <S.MessageSubCaption>
              {t('address.that.you.put.is.not.in.our.working.area')}
            </S.MessageSubCaption>
            <S.MessageDescription>
              {t('we.are.growing.fast.and.will.become.available.in.more.regions.soon')}
            </S.MessageDescription>
          </S.MessageWrapper>
        )}
        <NavigationButtons
          tooltip={timeSlotId ? false : t('choose.time.slot.first')}
          disabled={!timeSlotId}
          submitHandler={() => submitHandler(timeSlotId, appointmentDate)}
          goBackHandler={goBackHandler}
        />
      </S.Wrapper>
    </LoaderWrapper>
  );
};

export default SelectVet;
