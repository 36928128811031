import { useQuery } from '@apollo/client';
import { getPetQuery, getAppointmentRoutesQuery } from 'gql';

const useSinglePet = (uid, year) => {
  const pet = useQuery(getPetQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid
      }
    }
  });

  const { data, loading } = useQuery(getAppointmentRoutesQuery, {
    fetchPolicy: 'network-only',
    variables: {
      record: {
        date: year || 0,
        petIds: [uid]
      }
    }
  });

  return {
    timeline: data?.getAppointmentRoutes && !loading ? data.getAppointmentRoutes : [],
    pet: pet?.data?.pet && !pet?.loading ? pet?.data.pet : null,
    loading: loading || pet?.loading
  };
};

export default useSinglePet;
