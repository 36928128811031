import * as React from 'react';
import { Breadcrumb } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES, VET_ADMIN_ROUTES_SLUG, VET_ROUTES } from '../../constants/client';
import { Roles } from '../../constants/enums';
import { useMemo } from 'react';

const map = {
  /* pet owner */
  [ROUTES.BOOK]: 'schedule_book',
  /* vet */
  [VET_ROUTES.UPDATE_VET]: 'vet_profile',
  [VET_ROUTES.CUSTOMERS]: 'vet_clients',
  [VET_ROUTES.ROUTES]: 'vet_routes',
  [VET_ROUTES.UPDATE_VET]: 'vet_profile',
  [VET_ROUTES.CUSTOMERS]: 'vet_clients',
  [VET_ROUTES.ROUTES]: 'vet_routes',
  /* admin */
  [VET_ADMIN_ROUTES_SLUG._CLINIC]: 'clinic',
  [VET_ADMIN_ROUTES_SLUG._SETTINGS]: 'settings',
  [VET_ADMIN_ROUTES_SLUG.SCHEDULE]: 'clinic_schedule',
  [VET_ADMIN_ROUTES_SLUG.CLIENTS]: 'clinic_clients',
  [VET_ADMIN_ROUTES_SLUG.CAREGIVERS]: 'clinic_vets',
  [VET_ADMIN_ROUTES_SLUG.APP_MANAGER]: 'clinic_apptmanager',
  [VET_ADMIN_ROUTES_SLUG.SERVICE_AREA]: 'clinic_areas',
  [VET_ADMIN_ROUTES_SLUG.INTEGRATIONS]: 'clinic_integrations',
  [VET_ADMIN_ROUTES_SLUG.PLANS_AND_USAGE]: 'clinic_plans',
  [VET_ADMIN_ROUTES_SLUG.SUPPORTED_SERVICES]: 'clinic_services'
};
const getTranslatedPath = (t, key) => {
  const mapKey = '/' + key;
  return map[mapKey] ? t(map[mapKey]) : key.replace(/_/gm, ' ');
};

const BreadCrumb = ({ role }) => {
  const match = useRouteMatch();
  const { t } = useTranslation('breadcrumb');

  const replacedItems = useMemo(() => {
    const activeItem = Object.values(ROUTES).find((path) => path === match.path);
    const items = [];
    if (role !== Roles.VET_ADMIN) {
      items.push(t('home'));
    }
    if (activeItem === ROUTES.BOOK) {
      items.push(t(map[ROUTES.BOOK]));
      return items;
    }
    activeItem
      .split('/')
      .filter((f) => f)
      .forEach((key) => items.push(getTranslatedPath(t, key)));
    return items;
  }, [role, match.path]);

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumb>
        {replacedItems.map((key) => (
          <Breadcrumb.Item key={key}>{key}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumb;
