import React, { useState, useCallback } from 'react';
import css from './Wrapper.module.css';
import cls from 'classnames';
import { Button, cssText as text } from '_fsd/shared';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { Reschedule } from './Reschedule';
import { Recommender } from './Recommender';
import { useFilters } from './useFilters';
import { useSelectedTimeslot } from './useSelectedTimeslot.js';

export const RescheduleWrapper = (props) => {
  const {
    filters: parentFilters,
    timeslotActions: parentTimeslotActions,
    theme,
    displayFooter = true,
    displayHeader = true,
    selectedTimeslot,
    defaultTimeslot,
    appointment,
    currentAddress,
    vetId,
    close,
    defaultReschedule = true,
    isVetAdmin,
    appointments
  } = props;
  const [isReschedule, _setIsReschedule] = useState(defaultReschedule);
  const setIsReschedule = useCallback((value) => {
    _setIsReschedule(value);
  }, []);

  const filters = useFilters({
    vetId,
    displayTSNotInAreaDef: isVetAdmin,
    displayVetsDef: isVetAdmin
  });
  const timeslotActions = useSelectedTimeslot({
    defaultTimeslot
  });

  return (
    <div className={cls(css.modal, { [css.themeLight]: theme === 'light' })}>
      <div className={css.row}>
        <Button
          className={css.btnLeft}
          type={isReschedule ? 'secondary' : 'default'}
          colorScheme="blue"
          onClick={() => setIsReschedule(true)}>
          Calendar view
        </Button>
        <Button
          className={css.btnRight}
          type={!isReschedule ? 'secondary' : 'default'}
          colorScheme="blue"
          onClick={() => setIsReschedule(false)}>
          Smart scheduling view
        </Button>
      </div>
      {isReschedule ? (
        <Reschedule
          theme={theme}
          isVetAdmin={isVetAdmin}
          displayFooter={displayFooter}
          selectedTimeslot={selectedTimeslot}
          currentAddress={currentAddress}
          appointment={appointment}
          vetId={vetId}
          close={close}
          timeslotActions={parentTimeslotActions || timeslotActions}
          filters={parentFilters || filters}
        />
      ) : (
        <Recommender
          theme={theme}
          isVetAdmin={isVetAdmin}
          displayFooter={displayFooter}
          selectedTimeslot={selectedTimeslot}
          currentAddress={currentAddress}
          appointment={appointment}
          vetId={vetId}
          close={close}
          timeslotActions={parentTimeslotActions || timeslotActions}
          filters={parentFilters || filters}
          appointments={appointments}
        />
      )}
    </div>
  );
};
