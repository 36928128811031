import { getVetsAdminQuery } from 'gql';
import { useQuery } from '@apollo/client';

export const useVets = (props) => {
  const { data, ...rest } = useQuery(getVetsAdminQuery, {
    variables: {
      search: ''
    }
  });
  return { data: data?.getVets, ...rest };
};
