import React from 'react';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxMinus } from 'assets/icons/checkbox-minus.svg';

const Checkbox = ({ checked, handleClick, hasMinusSign }) => {
  return (
    <>
      {hasMinusSign ? (
        <CheckboxMinus onClick={handleClick} />
      ) : checked ? (
        <CheckboxChecked />
      ) : (
        <CheckboxUnchecked onClick={handleClick} />
      )}
    </>
  );
};

export default Checkbox;
