import React, { useMemo, useState } from 'react';
import { Button, Form, Layout, Tooltip, Checkbox } from 'antd';
import MediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { useTranslation } from 'react-i18next';

function StepperFooter({
  current,
  phoneNumberError,
  setPhoneNumberError,
  addressError,
  selectedDate,
  selectedTimeslot,
  errorMessage,
  steps,
  changeStep,
  form,
  isLoading,
  auth,
  isSubmitting,
  isBooking,
  vetIsOccupied,
  ignoreFloorAppartment,
  isForVet,
  goBack
}) {
  const { isMobile } = MediaQueriesList();
  const { t } = useTranslation();
  const [isInformClient, setIsInformClient] = useState(true);

  const { Content } = Layout;

  const renderButtonText = useMemo(() => {
    if (current === steps.length - 2) {
      if (auth?.me) return 'finish';
      else return 'next';
    }
    if (!auth?.me && current === steps.length - 1) return 'finish';
    return 'next';
  }, [current, auth]);

  return (
    <div className="footer-wrapper">
      <Content className="text-right">
      {current === steps.length - 1 ? (
        <div
          onClick={() => {
            form.setFieldsValue({ isInformClient: !isInformClient });
            setIsInformClient(!isInformClient);
          }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginRight: 20,
            fontSize: 14
          }}>
          <Checkbox checked={isInformClient} />
          <span>{t('inform_client_by_email')}</span>
        </div>
      ) : null}
        <Form.Item noStyle shouldUpdate={true} htmlFor="submit">
          {({ getFieldsError, getFieldsValue }) => {
            const isDisabled = () => {
              switch (current) {
                case 0:
                  const firstStepValues = getFieldsValue(['floor', 'apartment']);
                  const firstStepIsFilled =
                    Object.keys(firstStepValues).filter((value) => firstStepValues[value])
                      .length === 2;
                  const errorOnFirstStep =
                    getFieldsError(['floor', 'apartment']).filter(({ errors }) => errors.length)
                      .length > 0;

                  if (ignoreFloorAppartment) {
                    return vetIsOccupied || !selectedTimeslot?.uid || addressError;
                  }

                  return (
                    !firstStepIsFilled ||
                    errorOnFirstStep ||
                    vetIsOccupied ||
                    !selectedTimeslot?.uid ||
                    addressError
                  );
                  break;
                case 1:
                  const pet = getFieldsValue(['pets'])['pets'];
                  return !pet.length;
                  break;

                case 2:
                  const phoneError = getFieldsError(['phoneNumber'])[0].errors.length;
                  if (Boolean(phoneError) !== phoneNumberError) {
                    setPhoneNumberError(!phoneNumberError);
                  }
                  const thirdStepValues = getFieldsValue([
                    'firstName',
                    'lastName',
                    'email',
                    'phoneNumber'
                  ]);
                  const thirdStepIsFilled =
                    Object.keys(thirdStepValues).filter((value) => thirdStepValues[value])
                      .length === 4;
                  const errorsOnThirdStep =
                    getFieldsError(['firstName', 'lastName', 'email', 'phoneNumber']).filter(
                      ({ errors }) => errors.length
                    ).length > 0;
                  return !thirdStepIsFilled || errorsOnThirdStep;
                  break;

                default:
                  return addressError || !selectedDate || !selectedTimeslot?.uid;
              }
            };
            const buttonIsDisabled = isLoading || isDisabled() || errorMessage;
            return (
              <div className="stepper-footer">
                {errorMessage && <span className="error-message">{errorMessage}</span>}
                {isForVet && current === 0 && (
                  <Button
                    type="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={goBack}
                    className="back-btn">
                    {t('back')}
                  </Button>
                )}
                {current !== 0 && (
                  <Button
                    type="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => changeStep(current - 1)}
                    className="back-btn">
                    {t('back')}
                  </Button>
                )}
                {buttonIsDisabled ? (
                  <Tooltip
                    trigger={'hover'}
                    placement="topRight"
                    title={
                      <span>
                        {t(
                          current === 0
                            ? !selectedTimeslot?.uid
                              ? 'please.select.timeslot'
                              : 'recommender.patient_details.please.fill.fields'
                            : 'recommender.patient_details.please.fill.fields'
                        )}
                      </span>
                    }>
                    <Button type="primary" disabled={true}>
                      {t(renderButtonText)}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button type="primary" disabled={isSubmitting} onClick={() => form.submit()}>
                    {t(renderButtonText)}
                  </Button>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Content>
    </div>
  );
}

export default StepperFooter;
