import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { getAuthVetQuery, getAppointmentsByDateQuery, changeVisitOrderMutation } from 'gql';
import dayjs from 'dayjs';

const UseRoutes = (routeId, skip) => {
  const [calendarDate, setCalendarDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [mobileAppointmentsModalIsOpen, setMobileAppointmentsModalIsOpen] = useState(false);
  const [appointmentAddressToEdit, setAppointmentAddressToEdit] = useState(false);

  const { data, loading, refetch } = useQuery(getAuthVetQuery, {
    fetchPolicy: 'no-cache',
    skip: skip
  });

  const [changeVisitOrder] = useMutation(changeVisitOrderMutation);
  // const [updateRoute] = useMutation(updateRouteMutation);

  const changeVisitOrderHandler = async (appointmentRouteOrder) => {
    await changeVisitOrder({
      variables: {
        record: {
          routeId,
          appointmentRouteOrder
        }
      }
    });

    await getAppointmentsByTimeslotUid(selectedTimeslot, calendarDate);
  };

  const [getAppointmentsByDate] = useLazyQuery(getAppointmentsByDateQuery, {
    fetchPolicy: 'network-only'
  });

  const getAppointmentsByTimeslotUid = async (timeSlotId, date) => {
    try {
      await getAppointmentsByDate({
        variables: {
          record: {
            timeSlotId,
            date
          }
        },
        fetchPolicy: 'network-only'
      }).then((res) => {
        setAppointmentsData(res?.data?.getAppointmentsByDate || []);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedTimeslot && calendarDate) {
      getAppointmentsByTimeslotUid(selectedTimeslot, calendarDate);
    }
  }, [selectedTimeslot, calendarDate]);

  const spotCalendarDate = useCallback((date) => {
    setCalendarDate(dayjs(date).format('YYYY-MM-DD'));
  }, []);

  return {
    vet: data?.getAuthVet && !loading ? data?.getAuthVet : null,
    loading,
    setCalendarDate,
    calendarDate,
    selectedDateTimeSlots,
    setSelectedDateTimeSlots,
    setSelectedTimeslot,
    appointmentsData,
    setAppointmentsData,
    refetchAuthVet: refetch,
    spotCalendarDate,
    changeVisitOrderHandler,
    selectedTimeslot,
    getAppointmentsByTimeslotUid,
    mobileAppointmentsModalIsOpen,
    setMobileAppointmentsModalIsOpen,
    appointmentAddressToEdit,
    setAppointmentAddressToEdit
  };
};

export default UseRoutes;
