export const VetStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OCCUPIED: 'occupied',
  LIMIT_REACHED: 'limit_reached'
};

export const VetCommuteMethod = {
  DRIVING: 'driving',
  BICYCLING: 'bicycling',
  WALKING: 'walking'
};

export const BankAccountType = {
  SAVING: 'SAVING',
  CHECKING: 'CHECKING'
};

export const BankAccountClass = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
  INTERNATIONAL: 'INTERNATIONAL'
};
export const Roles = {
  VET: 'Vet',
  PET_OWNER: 'PetOwner',
  ADMIN: 'Admin',
  VETS_ADMIN: 'VetsAdmin'
};
