import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DASHBOARD, VET_ROUTES } from 'constants/client';
import Logo from 'common_repo/assets/icons/logo-blue-beta.png';
import NotFoundSvg from 'common_repo/assets/icons/404.svg';
import Button from 'components/common/button/Button';
import { Roles } from 'constants/enums';
import './style.scss';
import AuthContext from '../../contexts/AuthContext';

const NotFound = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const redirect =
    user?.role?.toLowerCase() === Roles.VET?.toLowerCase() ? VET_ROUTES.ROUTES : DASHBOARD;
  return (
    <div className="not-found-wrapper">
      <img src={Logo} alt="" />
      <div className="not-found-content">
        <div>
          <h3>{t('not_found.title')}</h3>
          <img src={NotFoundSvg} alt="" className="not-found-img" />
          <h4>{t('not_found.error_message')}</h4>
          <p>{t('not_found.instruction')}</p>
          <Link to={redirect}>
            <Button label={t('not_found.button_text')} color="primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
