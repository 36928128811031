import React from 'react';
import { useTranslation } from 'react-i18next';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import { getInitialLetters, getBookingUrl } from 'utils/helpers';

import Modal from 'components/common/modal/Modal';
import Language from 'components/language/Language';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

import { ReactComponent as ExitButton } from 'assets/icons/close-gray.svg';
import * as S from './components';
import useVetModal from './useVetModal';

const VetModal = ({ handleCloseModal, isOpen, vetId }) => {
  const { t } = useTranslation();
  const { isMobile } = mediaQueriesList();

  const { vet, loading } = useVetModal(vetId);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
      <S.Wrapper>
        <LoaderWrapper isLoading={loading || !vetId}>
          <S.Header>
            <S.ModalTitle>{t('info')}</S.ModalTitle>
            <S.Exit onClick={handleCloseModal}>
              <ExitButton />
            </S.Exit>
          </S.Header>
          <S.Body>
            <S.VetInfo>
              {vet?.user?.avatarUrl ? (
                <S.VetImage src={vet?.user?.avatarUrl} />
              ) : (
                <S.AvatarWithText>
                  {getInitialLetters(null, vet?.user?.firstName, vet?.user?.lastName)}
                </S.AvatarWithText>
              )}
              <S.VetDetails>
                <S.VetDetailsHeader>
                  <S.ChatAndName>
                    <S.VetName>
                      {vet?.user?.firstName} {vet?.user?.lastName}
                    </S.VetName>
                  </S.ChatAndName>
                  <a href={getBookingUrl()}>
                    <S.BookVisitButton>{t('book.a.visit')} </S.BookVisitButton>
                  </a>
                </S.VetDetailsHeader>
                {vet?.experience && (
                  <S.Experience>
                    {t('years.of.experience', {
                      count: vet?.experience
                    })}
                  </S.Experience>
                )}
                {!isMobile && !!vet?.languages && (
                  <S.LanguageWrapper>
                    {vet.languages.map((lang) => {
                      return <Language language={lang} key={lang.uid} />;
                    })}
                  </S.LanguageWrapper>
                )}
              </S.VetDetails>

              {isMobile && !!vet?.languages && (
                <S.LanguageWrapper>
                  {vet.languages.map((lang) => {
                    return <Language language={lang} key={lang.uid} />;
                  })}
                </S.LanguageWrapper>
              )}
            </S.VetInfo>

            {vet?.user?.bio && (
              <S.Biography>
                <S.BoxTitle>{t('biography')} </S.BoxTitle>
                <S.BiographyText>{vet.user.bio}</S.BiographyText>
              </S.Biography>
            )}

            <a href={getBookingUrl()}>
              <S.BookVisitButton isMobile>{t('book.a.visit')}</S.BookVisitButton>
            </a>
          </S.Body>
        </LoaderWrapper>
      </S.Wrapper>
    </Modal>
  );
};

export default VetModal;
