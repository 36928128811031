import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  transition: 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  ${({ isRtl }) =>
    isRtl &&
    css`
      direction: ltr;
    `}
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
`;

export const AdvancedInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FormElementWrapper = styled.div`
  flex-basis: 48.6%;
  position: relative;
  ${({ theme }) => theme.laptop`
    margin-bottom: 0.6rem;
  `}
  ${({ isAvatar }) =>
    isAvatar &&
    css`
      height: 14rem;
      overflow: hidden;
      margin-bottom: 2.4rem;
      margin-right: 1.6rem;
      ${({ theme }) => theme.laptop`
        height: 11.4rem;
        margin-bottom: unset;
      `}
    `}
  ${({ isWeightWrapper, hasRemoveIcon }) =>
    isWeightWrapper ||
    (hasRemoveIcon &&
      css`
        position: relative;
      `)}
  margin-bottom: 1.2rem;
  ${({ theme, isAvatar }) => theme.mobile`
    margin-right: unset;
    width: 100%;
    flex-basis: 100%;
    ${
      isAvatar &&
      `height: 14.3rem;
       margin-bottom: 1.6rem;
    `
    }
    `}
`;

export const NameTypeWrapper = styled.div`
  flex: 1;
  ${({ theme }) => theme.mobile`
  width: 100%;
`}
`;

export const TextareaWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mobile`
    margin-bottom: 1.2rem;
  `}
`;

export const WeightUnitWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 10.2rem;
`;

export const AdvancedInformationBtn = styled.button`
  outline: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  cursor: pointer;
  flex-basis: 100%;
`;

export const AdvancedInformationLabel = styled.h3`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
  `}
`;

export const AdvancedInformationIcon = styled.img`
  margin-left: 0.8rem;
  width: 1.3rem;
  height: 0.7rem;
  transition: 0.3s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
    `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const VaccinateButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const VaccinatedValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.6rem;
  > span {
    flex-basis: 100%;
  }
`;

export const VaccinatedLabel = styled.h3`
  opacity: 0.8;
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.43;
  margin-bottom: 0.8rem;
  flex-basis: 100%;

  ${({ isSelected }) => isSelected}
`;

export const VaccinatedValue = styled.div`
  cursor: pointer;
  width: 13.7rem;
  height: 3.4rem;
  border: 0.1rem solid;
  ${({ theme }) => theme.mobile`
    width: 48%;
  `}
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.orange : theme.colors.gray};
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.orange : '#b0b7c3')};
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: 400;
  border-radius: 0.4rem;
  margin-right: 1rem;
`;

export const AddAlergyButton = styled.button`
  width: 100%;
  height: 5.8rem;
  border-radius: 0.8rem;
  border: dashed 0.1rem ${({ theme }) => theme.colors.green};
  background-color: #ffffff;
  margin-bottom: ${({ isModal }) => (isModal ? '2.4rem' : '18rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mobile`
    margin-bottom: 2.4rem;
    height: 5.2rem;
  `};
`;

export const AddAlergyLabel = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.green};
`;

export const AddAlergyIcon = styled.img`
  width: 2.4rem;
  height: auto;
  margin-right: 0.8rem;
`;

export const ContinueWrapper = styled.div`
  width: 100%;
  ${({ formOpen }) =>
    formOpen &&
    css`
      height: 15.8rem;
      z-index: 4;
      width: 100%;
      background-color: #f2f4f7;
    `}
  ${({ theme }) => theme.mobile`
      height: unset;
      position: unset;
      bottom: unset;
      z-index: unset;
      width: 100%;
      background-color: unset;
    `}
`;

export const GradientLayer = styled.div`
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(242, 243, 247, 0), #f2f3f7);
  width: 100%;
  height: 4rem;
  top: -4rem;
  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mobile`
    flex-direction: column-reverse;
  `}
`;

export const ActionsBtnWrapper = styled.div`
  width: 48%;
  ${({ theme }) => theme.mobile`
    width: 100%;
    >button {
      height: 4.9rem;
    }
  `}
  ${({ submitButton }) =>
    submitButton &&
    css`
      ${({ theme }) => theme.mobile`
        margin-bottom: 1.5rem;
      `}
    `}
`;

export const RemoveAlergyButton = styled.div`
  position: absolute;
  top: 2.2rem;
  right: 1.7rem;
  display: none;
  cursor: pointer;

  ${({ theme }) => theme.mobile`
    top: 2rem;
    
  `}

  ${FormElementWrapper}:hover & {
    display: block;
  }

  & svg {
    fill: ${({ theme }) => theme.colors.orange};
  }
`;

export const BlurredBackground = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: none;

  ${({ theme }) => theme.mobile`
    display: block;
  `}
`;
