import React, { useMemo, useState } from 'react';
import css from './PhoneInput.module.css';
import CountryIcon from '../CountryIcon/CountryIcon';
import { Select, Input } from 'antd';
import countries from '../../country-phones.json';
import { phone } from 'phone';
import { PatternFormat } from 'react-number-format';

const PhoneInput = ({ onBlur, onChange, code, number, disabled, error }) => {
  const [selectedCountry, setCountry] = useState('US');
  const options = countries.map((c) => ({
    label: (
      <span className={css.rowLabel}>
        <CountryIcon countryCode={c.code} /> {`${c.dial_code}`}
      </span>
    ),
    value: c.name,
    country: c.name,
    code: c.dial_code,
    countryCode: c.code,
    icon: (
      <span className={css.rowLabel}>
        <CountryIcon countryCode={c.code} /> {`${c.dial_code}`}
      </span>
    )
  }));

  const value = useMemo(() => {
    const res = phone(`${code}${number}`);
    if (res.isValid) {
      setCountry(res.countryIso2);
      return options.find((opt) => opt.countryCode === res.countryIso2);
    } else {
      return options.find((opt) => opt.code === code && opt.countryCode === selectedCountry);
    }
  }, [code, number]);

  return (
    <div className={css.container}>
      <div className={css.row}>
        <Select
          disabled={disabled}
          className={css.select}
          style={{ width: 185 }}
          showSearch
          value={value}
          onChange={(_, v) => {
            onChange(v.code, number);
            setCountry(v.countryCode);
          }}
          filterOption={(input, option) => {
            if (option.code.includes(input)) return true;
            return option.country.toLowerCase().includes(input.toLowerCase());
          }}
          options={options}
          optionLabelProp={'icon'}
        />
        <PatternFormat
          onBlur={onBlur}
          disabled={disabled}
          placeholder="(123) 456-7890"
          className={css.input}
          value={number}
          customInput={Input}
          format={number?.length > 6 ? '(###) ###-######' : '(###) ### ######'}
          status={error ? 'error' : undefined}
          onValueChange={(values) => {
            onChange(code, values.value);
          }}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
