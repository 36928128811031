import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  /* border: 0.1rem dashed ${({ theme }) => theme.colors.gray}; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background:
    linear-gradient(to right, #d0d9e7 51%, #fff 0%) top/19.5px 1px repeat-x,
    /* top */ linear-gradient(#d0d9e7 61%, #fff 0%) right/1px 18px repeat-y,
    /* right */ linear-gradient(to right, #d0d9e7 51%, #fff 0%) bottom/19.5px 1px repeat-x,
    /* bottom */ linear-gradient(#d0d9e7 61%, #fff 0%) left/1px 18px repeat-y; /* left */
  background-color: #ffffff;
  overflow: hidden;
  ${({ theme }) => theme.mobile`
    border: 0.1rem dashed ${({ theme }) => theme.colors.gray};
    background: unset;
  `}
`;

export const DropzoneLabel = styled.h2`
  color: #b0b7c3;
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: 400;
`;

export const DropzoneIcon = styled.img`
  width: 4.4rem;
  height: 4.4rem;
  margin-bottom: 1.7rem;
`;

export const UploadedImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
