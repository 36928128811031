import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import Button from 'components/common/button/Button';

const ModalFooter = ({
  className,
  isNextButtonDisabled,
  onNextClick,
  current,
  steps,
  onRequestClose,
  changeStep,
  form,
  isLoading
}) => {
  const { t } = useTranslation();
  const [isInformClient, setIsInformClient] = useState(true);

  const getNextButtonLabel = useMemo(() => {
    if (current === steps.length - 1) {
      return t('finish');
    }
    return t('next');
  }, [current, steps]);

  const getBackButtonLabel = useMemo(() => {
    if (current === steps.length - 1) {
      return t('back');
    }
    return t('cancel');
  }, [current, steps]);

  const handleSubmitBackBtn = useCallback(() => {
    if (current === steps.length - 1) changeStep(current - 1);
    else onRequestClose();
  }, [current, steps]);

  return (
    <div className={`appointments-footer-wrapper ${className}`}>
      {current === steps.length - 1 && (
        <div
          className="app-footer-inform"
          onClick={() => {
            form.setFieldsValue({ isInformClient: !isInformClient });
            setIsInformClient(!isInformClient);
          }}>
          <Checkbox checked={isInformClient} />
          <span className="checkbox-text">{t('inform_client_by_email')}</span>
        </div>
      )}
      <Button label={getBackButtonLabel} className="cancel-btn" onClick={handleSubmitBackBtn} />
      <Button
        label={getNextButtonLabel}
        loading={isLoading}
        color="primary"
        className={`next-btn ${isNextButtonDisabled ? 'disabled' : ''}`}
        disabled={isNextButtonDisabled || isLoading}
        onClick={onNextClick}
      />
    </div>
  );
};

export default ModalFooter;
