import { useQuery } from '@apollo/client';
import { gql } from 'graphql.macro';

export const useAppointmentsByVetAdmin = ({ startDate, endDate }) => {
  const { data, ...rest } = useQuery(query, {
    variables: {
      data: {
        filter: {
          startDate,
          endDate
        }
      }
    },
    fetchPolicy: 'network-only'
  });

  return {
    data: data?.getAppointmentsByVetAdmin,
    ...rest
  };
};

const query = gql`
  query getAppointmentsByVetAdmin($data: GetAppointmentsByVetAdminRecord!) {
    getAppointmentsByVetAdmin(record: $data) {
      uid
      date
      user {
        uid
        firstName
        lastName
        role
      }
      timeSlot {
        uid
        vet {
          uid
          user {
            uid
            firstName
            lastName
            role
          }
        }
      }
    }
  }
`;
