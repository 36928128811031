import React from 'react';
import { useTranslation } from 'react-i18next';

import NotificationCard from '../notification-card/NotificationCard';
import notificationsIcon from 'assets/icons/notifications-icon.svg';
import * as S from './components';
import useNotifications from './useNotifications';

const Notifications = ({ isActiveTab }) => {
  const { t } = useTranslation();

  const { isSetSeenAllDisabled, data, markAllAsRead } = useNotifications();
  return (
    <S.Wrapper isActiveTab={isActiveTab}>
      <S.Header>
        <S.IconAndTitleWrapper>
          <S.NotificationsIcon src={notificationsIcon} alt="notifications" />
          <S.Title>{t('notifications')}</S.Title>
        </S.IconAndTitleWrapper>
        <S.MarkReadButton onClick={markAllAsRead} disabled={isSetSeenAllDisabled}>
          {t('mark.all.as.read')}
        </S.MarkReadButton>
      </S.Header>
      <S.Body>
        {!!data?.new?.length && (
          <>
            <S.Period hasBiggerMargin>{t('new')} </S.Period>
            {data?.new?.map((item) => (
              <NotificationCard key={item.uid} date={item?.createdAt} {...item} />
            ))}
          </>
        )}
        {!!data?.earlier?.length && (
          <>
            <S.Period hasBiggerMargin>{t('earlier')} </S.Period>
            {data?.earlier?.map((item) => (
              <NotificationCard key={item.uid} date={item?.createdAt} {...item} />
            ))}
          </>
        )}
      </S.Body>
    </S.Wrapper>
  );
};

export default Notifications;
