import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocalizationContext } from 'common_repo';

import VerifyCodeForm from 'components/verify-code-form/VerifyCodeForm';
import { PageTitle, PageSubTitle } from 'components/common/texts/components';
import GoBack from 'components/common/go-back/GoBack';

import { Wrapper, HeadingSections, Phone, GoBackWrapper } from './components';
import { SIGN_IN } from 'constants/client';
import AuthContext from '../../contexts/AuthContext';

const VerifyCode = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user: me } = useContext(AuthContext);
  const { rtl: isRtl } = useLocalizationContext();
  return (
    <Wrapper>
      <HeadingSections>
        <GoBackWrapper>
          <GoBack clickHandler={() => history.replace(SIGN_IN)} />
        </GoBackWrapper>
        <PageTitle>{t('code.verification')}</PageTitle>
        <PageSubTitle style={{ display: 'inline' }}>{t('enter.6.digit.code')}</PageSubTitle>
        <Phone isRtl={isRtl}>{`${me?.phonePrefix || ''} ${me?.phoneNumber || ''}`}</Phone>
      </HeadingSections>
      <VerifyCodeForm />
    </Wrapper>
  );
};

export default VerifyCode;
