import { useState, useCallback } from 'react';
import moment from 'moment/moment.js';

export const useSelectedTimeslot = ({ defaultTimeslot, handleTimeslot }) => {
  const [activeTimeslot, _setActiveTimeslot] = useState(defaultTimeslot);
  const [calendarDate, _setCalendarDate] = useState(
    defaultTimeslot?.date ? moment(defaultTimeslot?.date) : moment()
  );
  const setActiveTimeslot = useCallback((value) => {
    _setActiveTimeslot(value);
    handleTimeslot && handleTimeslot(value);
  }, []);
  const setCalendarDate = (date) => {
    _setCalendarDate(date);
    setActiveTimeslot();
  };

  return {
    activeTimeslot,
    defaultTimeslot,
    setActiveTimeslot,
    calendarDate,
    setCalendarDate
  };
};
