import React from 'react';
import DP from 'react-datepicker';
import startCase from 'lodash/startCase';
import Selector from 'components/common/selector/Selector';
import Input from 'components/common/input/Input';

import useDateFormat from 'common_repo/components/useDateFormat';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-blue.svg';
import { range, months } from 'utils/helpers';
import { useLocalizationContext } from 'common_repo';
import css from './Calendar.module.css';
import cls from 'classnames';

export const Calendar = ({
  value,
  label,
  theme = 'black',
  error,
  hideHeader,
  setIsCalendarOpen = () => {},
  startYear = 1900,
  endYear = 0,
  className,
  ...props
}) => {
  const years = range(new Date().getFullYear(), new Date().getFullYear() + 2, 'asc');
  const { dateFormat } = useDateFormat();
  const { rtl: isRtl } = useLocalizationContext();

  return (
    <div
      className={cls(css.wrapper, className, {
        [css.themeLight]: theme === 'light',
        [css.themeBlack]: theme === 'black'
      })}>
      <DP
        {...props}
        calendarClassName={css.calendar}
        wrapperClassName={css.section}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
        customInput={
          <Input
            className={css.input}
            label={label}
            error={error}
            isCalendarInput
            hideKeyboardOnFocus
          />
        }
        useWeekdaysShort
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {
          return (
            <div className={css.dp_header}>
              {!hideHeader && (
                <div className={css.calendar_header_wrapper}>
                  <div className={css.calendar}>Calendar</div>
                  <div className={css.selectedDate}>{dateFormat(date)}</div>
                </div>
              )}
              <div className={css.headerBottomWrapper}>
                <div className={css.monthSelectorWrapper}>
                  <button
                    className={cls(css.arrow, { [css.arrowDisabled]: prevMonthButtonDisabled })}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      decreaseMonth(e);
                    }}
                    disabled={prevMonthButtonDisabled}>
                    <LeftArrow />
                  </button>
                  <div className={css.month}>{startCase(months[new Date(date).getMonth()])}</div>
                  <button
                    className={cls(css.arrow, css.arrowRight, {
                      [css.arrowDisabled]: nextMonthButtonDisabled
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      increaseMonth(e);
                    }}
                    disabled={nextMonthButtonDisabled}>
                    <LeftArrow />
                  </button>
                </div>
                <div className={css.selectorWrapper}>
                  <Selector
                    value={{
                      label: new Date(date).getFullYear(),
                      value: new Date(date).getFullYear()
                    }}
                    options={years}
                    onChange={(item) => changeYear(item.value)}
                    hideLabel
                    noError
                    isForTimeSlot
                    isRtl={isRtl}
                  />
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
