export const RouteStatus = {
  REGISTER: 'REGISTER',
  ADJUSTMENT: 'ADJUSTMENT',
  READY: 'READY',
  UPCOMING: 'UPCOMING',
  LOCKED: 'LOCKED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED'
};
