import React from 'react';
import * as S from './components';
import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg';

const isHeb = localStorage.getItem('lang');

const PaymentMethod = ({ cardMask }) => (
  <S.Wrapper>
    <S.Flex>
      <MastercardIcon />
      <S.CardMask isHeb={isHeb}>{cardMask}</S.CardMask>
    </S.Flex>
  </S.Wrapper>
);
export default PaymentMethod;
