import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  border-radius: 0.8rem;
  padding: 1.8rem 3.1rem 2.9rem 2.7rem;
  ${({ theme }) => theme.mobile`
    height: unset;
    flex-direction: column;
    padding: 2rem 2.4rem 3.6rem 2.4rem;
    margin-bottom: 1.2rem;
    margin-top: 1.4rem;
    border-radius: 0;
    padding-bottom: ${({ openDetails }) => (openDetails ? '4.2rem' : '3.6rem')};
    `}
`;

export const ProfileHeaderTitle = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 18px;
  color: #323b4b;
  letter-spacing: 0.110377px;
`;
export const ProfileDetailsHeader = styled.header`
  display: none;
  ${({ theme }) => theme.mobile`
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 2.4rem;
      margin-bottom: 1.8rem;
      letter-spacing: 0.011rem;
      color: ${({ theme }) => theme.colors.black};
      > svg {
        margin-right: -0.8rem;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        ${({ openDetails }) =>
          openDetails &&
          css`
            transform: rotate(180deg);
          `}
      }
  `}
`;

export const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.2rem;
  ${({ theme }) => theme.mobile`
    justify-content: unset;
    flex-direction: row;
    `}
`;

export const PetAvatar = styled.img`
  width: 17rem;
  height: 15.7rem;
  object-fit: cover;
  border-radius: 0.8rem;
  ${({ theme }) => theme.mobile`
    min-width: 12.7rem;
    height: 12.7rem;
  `}
`;

export const SubscribeButton = styled.button`
  width: 13.4rem;
  height: 3.6rem;
  background: #ff9a05;
  border-radius: 0.8rem;
  border: none;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 1.2rem;
`;

export const SubscribeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mobile`
    margin-bottom: 1.1rem;
    align-items: unset;
  `}
`;

export const WellnessPlan = styled.div`
  display: flex;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.orange};
  text-transform: capitalize;
  ${({ theme }) => theme.mobile`
    margin-bottom: 0.5rem;
  `}
`;

export const VetImage = styled.img`
  margin-right: 0.4rem;
`;

export const VetAvatarByName = styled.div`
  margin-right: 0.4rem;
  height: 2rem;
  width: 2rem;
  border-radius: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  background-color: rgba(219, 226, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
`;

export const VetInfo = styled.div`
  display: flex;
  width: 15.4rem;
  height: 3.6rem;
  padding: 0.7rem 0.7rem 0.8rem 1.4rem;
  background: rgba(219, 226, 238, 0.4);
  border-radius: 0.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  > h2 {
    :hover {
      border-bottom: 0.1rem solid ${({ theme }) => theme.colors.darkGray};
    }
  }
  ${({ theme }) => theme.mobile`
    height: 2.8rem;
    width: 15.1rem;
  `}
`;

export const PetDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2.4rem;
  ${({ theme }) => theme.mobile`
    margin-left: 0;
  `}
`;

export const PetDetailsHeader = styled.header`
  display: flex;
  align-items: center;
  height: 3.2rem;
  margin-bottom: 1.1rem;
  ${({ isPetAvatarsHeader }) =>
    isPetAvatarsHeader &&
    css`
      margin-bottom: 0;
      height: unset;
    `}
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    height: unset;
    align-items: unset;
    margin-left: 3.4rem;
    margin-bottom: 0;
    margin-right: 1.7rem;
  `}
`;

export const PetCommonInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.8rem;
`;

export const PetName = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  max-width: 12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  ${({ theme }) => theme.mobile`
    max-width: 10.8rem;
    margin-right: 1.2rem;
  `}
`;

export const DotsWrapper = styled.div`
  min-width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  position: relative;
  > svg {
    cursor: pointer;
  }
  ${({ theme }) => theme.mobile`
    min-width: unset;
    max-width: 1.6rem;
  `}
`;

export const Settings = styled.div`
  position: absolute;
  top: 2.3rem;
  left: -0.5rem;
  width: 23.7rem;
  // height: 9.85rem;
  border: 0.05rem solid #dae1ed;
  box-sizing: border-box;
  box-shadow: 0 0.8rem 4.5rem rgba(5, 40, 72, 0.08);
  border-radius: 1rem;
  background-color: white;
  margin-top: 0.6rem;
  padding: 0.5rem 1.2rem 1rem 0.5rem;
  z-index: 1;
  :after {
    content: '';
    position: absolute;
    left: 1rem;
    top: -0.4rem;
    width: 0.65rem;
    height: 0.65rem;
    border-left: 0.05rem solid #dae1ed;
    border-top: 0.05rem solid #dae1ed;
    background-color: white;
    transform: rotate(45deg);
    border-radius: 0.1rem;
  }
  ${({ theme }) => theme.mobile`
    border: none;
    height: 15.9rem;
    width: 17.3rem;
    padding: 1.5rem 1.4rem 1.2rem 1.6rem;
    left: -8.5rem;
    :after{
      left: 8.2rem;
      border: none;
    }
  `}
`;

export const Setting = styled.div`
  width: 100%;
  height: 4.1rem;
  padding: 0.8rem 0 0.9rem 1.3rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5c6874;
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    min-width: 1.9rem;
    min-height: 1.9rem;
    margin-right: 1.5rem;
  }
  ${({ theme }) => theme.mobile`
    height: 4.4rem;
    padding: 0.8rem 0 0.2rem 0.2rem;
    > svg {
      margin-right: 1.4rem;
    }
  `}
`;

export const Triangle = styled.div`
  position: absolute;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1.5rem solid white;
`;

export const PencilWrapper = styled.div`
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 1.2rem;
  > svg {
    width: 1.667rem;
    height: 1.667rem;
    fill: #687a9e;
  }
`;

export const BarWrapper = styled.div`
  ${({ theme }) => theme.mobile`
    display: flex;
    flex-direction:column;
    width: 14.8rem;
    height: 3.6rem;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.2rem;
  `}
`;

export const ProfileCompleteBar = styled.div`
  width: 12.4rem;
  height: 0.6rem;
  background: #f5f6f9;
  border-radius: 0.8rem;
  margin-left: 2.5rem;
  ${({ theme }) => theme.mobile`
    height: 1rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-bottom: 0.8rem;
  `}
`;

export const Bar = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 0.6rem;
  background: #f9b057;
  border-radius: 0.8rem;
  ${({ theme }) => theme.mobile`
    height: 1rem;
  `}
`;

export const ProfileComplete = styled.div`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: rgba(50, 59, 75, 0.8);
  margin-left: 1rem;
  ${({ theme }) => theme.mobile`
    margin-left: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
  `}
`;

export const PetDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.2rem;
  margin-bottom: 3rem;
  ${({ theme }) => theme.mobile`
    margin-top: 2rem;
    height: unset;
  `}
`;

export const Detail = styled.div`
  width: 12.4rem;
  height: 4.4rem;
  background: #fafbfc;
  border-radius: 0.8rem;
  padding: 0.5rem 0.8rem 0.5rem 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ copy }) =>
    copy &&
    css`
      position: relative;
    `}
  ${({ theme }) => theme.mobile`
    width: 15rem;
  `}
`;

export const DetailTitle = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #8a94a6;
  margin-bottom: 0.1rem;
`;

export const ChipHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > svg {
    cursor: pointer;
  }
`;

export const DetailText = styled.h5`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: inline-block;
  width: 9.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
`;

export const MedicalInfoWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 8.1rem;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    margin-bottom: 2.4rem;
    gap: 2rem;
  `}
`;

export const MedicalInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const YellowDot = styled.span`
  background: #f9b057;
  border: 0.1rem solid white;
  box-sizing: border-box;
  box-shadow: 0 0.2rem 1.6rem rgba(0, 0, 0, 0.12);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.4rem;
`;

export const MedicalInfoTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
`;

export const MedicalAlertsText = styled.h4`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ isForAllergy }) => !isForAllergy && 'margin-left: 1.2rem'};
`;

export const Allergies = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

export const Allergy = styled.div`
  background: rgba(219, 226, 238, 0.12);
  border: 0.05rem solid rgba(172, 188, 212, 0.8);
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 0.6rem 1.2rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: inline-block;
  text-align: center;
  color: rgba(50, 59, 75, 0.8);
  max-width: 30rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const VisitWrapper = styled.div`
  display: flex;
  width: 27.2rem;
  height: 22.7rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  ${({ theme }) => theme.mobile`
    height: 13rem;
    width: 100%;
  `}
`;

export const Details = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
  `}
`;

export const ExtraVisitButton = styled.button`
  width: 13.4rem;
  height: 3.6rem;
  background: #22a3c3;
  border-radius: 0.8rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  color: white;
  border: none;
  cursor: pointer;
`;

export const Note = styled.div`
  border: 0.1rem dashed #dbe2ee;
  box-sizing: border-box;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(45, 28, 28, 0.25);
  border-radius: 0.8rem;
  width: 27.2rem;
  height: 17.9rem;
  padding: 0.9rem 0.6rem 1.9rem 1.9rem;
  ${({ theme }) => theme.mobile`
    height: 13rem;
    width: 100%;
  `}
`;

export const NoteHeader = styled.header`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: rgba(50, 59, 75, 0.8);
`;

export const NoteText = styled.div`
  font-family: inter, assistant;
  font-style: italic;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 1.1rem;
  overflow-wrap: break-word;
  overflow-y: auto;
  height: 12.2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-right: 1.3rem;
  ::-webkit-scrollbar {
    width: 0.4rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(138, 148, 166, 0.25);
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #d0d9e765;
  }
  ${({ theme }) => theme.mobile`
    height: calc(100% - 2.7rem);  
  `}
`;

export const CopiedAlert = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -0.2rem 3.2rem rgba(0, 0, 0, 0.04);
  border-radius: 0.8rem;
  width: 21.5rem;
  height: 4.8rem;
  left: 13.4rem;
  padding: 1.4rem 0 1.4rem 2rem;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  > svg {
    margin-right: 0.8rem;
  }
  :after {
    content: ' ';
    position: absolute;
    transform: rotate(45deg);
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${({ theme }) => theme.colors.white};
    bottom: 50%;
    left: -0.3rem;
  }
`;
