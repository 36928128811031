import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { DropzoneIcon, DropzoneWrapper, DropzoneLabel, UploadedImg } from './components';
import CameraAddIcon from 'assets/icons/camera-add.svg';
import ErrorModal from '../error-modal/ErrorModal';
import { useTranslation } from 'react-i18next';

const Dropzone = ({
  name,
  value,
  onDrop,
  placeholder,
  multiple = false,
  accept = 'image/*',
  maxSize = 10000000
}) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  useEffect(() => {
    if (fileRejections[0]?.errors?.length) {
      setErrorModalOpen(true);
    }

    switch (fileRejections[0]?.errors[0]?.code) {
      case 'file-too-large':
        setErrorMessage(t('image.must.be.smaller.than.10.mb'));
        break;
      case 'file-invalid-type':
        setErrorMessage(t('you.can.only.upload.images'));
        break;
      default:
        setErrorMessage(fileRejections[0]?.errors[0]?.message);
    }
  }, [fileRejections]);

  return (
    <>
      <ErrorModal
        isOpen={errorModalOpen}
        handleCloseModal={() => {
          setErrorModalOpen(false);
        }}
        errorMessage={errorMessage}
      />
      <DropzoneWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        {value ? (
          <UploadedImg
            src={typeof value === 'string' ? value : URL.createObjectURL(value)}
            alt={name}
          />
        ) : (
          <>
            <DropzoneIcon src={CameraAddIcon} alt="camera add" />
            <DropzoneLabel>{placeholder}</DropzoneLabel>
          </>
        )}
      </DropzoneWrapper>
    </>
  );
};

export default Dropzone;
