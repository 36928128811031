import React from 'react';
import { Form, Button } from 'antd';

import './styles.css';

const SubmitButton = ({ value, disabled, onClick }) => {
  return (
    <Form.Item className="submit-btn">
      <Button type="primary" onClick={onClick} disabled={disabled}>
        {value}
      </Button>
    </Form.Item>
  );
};

export default SubmitButton;
