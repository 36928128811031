import React from 'react';
import css from './Timeslot.module.css';
import cls from 'classnames';
import { cssText as text } from '_fsd/shared';

export const TimeslotCalendar = ({ timeslot, onClick, active, disabled }) => {
  const name = `${timeslot.vet.user.firstName} ${timeslot.vet.user.lastName}`;
  return (
    <div
      className={cls(text.s12w5l18fI, css.timeslot, {
        [css.selected]: active,
        [css.disabled]: disabled
      })}
      onClick={() => !disabled && onClick(timeslot)}>
      <span>{`${timeslot.startTime.slice(0, -3)} - ${timeslot.endTime.slice(0, -3)}`}</span>
      <span className={css.name} title={name}>
        {name}
      </span>
    </div>
  );
};
