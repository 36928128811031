import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './components';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { replaceRouteParams } from 'utils/helpers';

const Step = ({ stepNumber, isFirstStep, text, status, route }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const stepClicKHandler = () => {
    status !== 'passive' && history.push(replaceRouteParams(route, 'id', match.params.id));
  };

  return (
    <>
      {!isFirstStep && <S.SpaceLine status={status} />}

      <S.CircleAndTextWrapper onClick={stepClicKHandler} status={status}>
        <S.StepCircle status={status}>{status === 'done' ? <TickIcon /> : stepNumber}</S.StepCircle>

        <S.TextWrapper status={status}>{t(text)}</S.TextWrapper>
      </S.CircleAndTextWrapper>
    </>
  );
};

export default Step;
