import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputNumber as AntInput, Tooltip } from 'antd';

import { ReactComponent as Eye } from 'assets/icons/visibility.svg';
import './styles.css';

const InputNumber = ({
  label,
  name,
  placeholder,
  required,
  hasVisibilitySign,
  controls,
  max,
  min,
  addOnAfter
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      rules={[{ required, message: t('field.is.required') }]}
      label={
        label || hasVisibilitySign ? (
          <>
            {label}
            {hasVisibilitySign && (
              <Tooltip title={t('info.will.be.visible')}>
                <Eye />
              </Tooltip>
            )}
          </>
        ) : null
      }
      name={name}>
      <AntInput
        max={max || null}
        min={min ?? null}
        size="middle"
        controls={controls}
        type="number"
        placeholder={placeholder}
        style={{ width: '100%' }}
        addonAfter={addOnAfter}
      />
    </Form.Item>
  );
};

export default memo(InputNumber);
