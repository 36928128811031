import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-right: 3rem;

  .swiper-wrapper {
    z-index: 0;
  }

  .swiper-container {
    width: 100% !important;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-button-disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

export const Next = styled.div`
  cursor: pointer;
  margin-left: 1rem;
  position: absolute;
  z-index: 100;
  right: 0;
`;
export const Prev = styled.div`
  cursor: pointer;
`;
