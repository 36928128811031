import styled, { css } from 'styled-components';

export const Table = styled.div`
  width: 100%;
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
  padding: 0 4.8rem;
  max-height: 60%;
  ${({ theme }) => theme.laptop`
    max-height: max-content;
    padding: 0 2.4rem;
    margin-top: 3.2rem;
  `}
  ${({ theme }) => theme.mobile`
    padding : 0;
  `}
`;

export const Thead = styled.div``;

export const Tbody = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => theme.laptop`
    overflow-y: hidden;
  `}
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.7rem;
  color: #8a94a6;
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 400;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mobile`
    margin-bottom: 0;
  `}
`;

export const Tr = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 0.1rem solid #dbe2ee;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  height: 9.2rem;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  transition: height 0.3s;

  ${({ theme }) => theme.laptop`
    height: 7.2rem;
    :hover {
    border: 0.1rem solid #ff9a05;
    }
    cursor: default;
  `}

  ${({ theme }) =>
    !theme.mobile`
    &:hover {
    border: 0.1rem solid #ff9a05;
    background: rgba(255, 255, 255, 0.05);
  }
  `}

  ${({ checked }) => checked && 'background: rgba(255, 154, 5, 0.05); border: 1px solid #FF9A05;'}

  ${({ theme, isPetDropdownOpen }) =>
    theme.mobile`
    margin-bottom: 2.4rem;
    ${
      isPetDropdownOpen &&
      `height: 22.8rem; 
      justify-content: flex-start;  
      padding-bottom: 0.5rem;
       padding-top: 0rem`
    }

  `}
`;

export const Td = styled.div`
  width: 1rem;
`;

export const CheckboxWrapper = styled.div`
  width: 1rem;
  margin-left: 2.4rem;
  margin-right: 10.6rem;

  & svg {
    cursor: pointer;
  }

  ${({ theme }) => theme.mobile`
    margin-right: 2rem;
  `}
`;
export const NameWrapper = styled.div`
  max-width: 16rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ImageWrapper = styled.div`
  margin-right: 1.8rem;
`;

export const Avatar = styled.img`
  background-color: #dbe2ee;
  object-fit: cover;
  width: 5.6rem;
  height: 5.6rem;
  user-select: none;
  border-radius: 0.8rem;
  ${({ hasAvatar }) =>
    !hasAvatar &&
    css`
      padding: 1.2rem 0;
      object-fit: contain;
    `}
  ${({ theme }) => theme.laptop`
    width: 4rem;
    height: 4rem;
  `}
`;

export const NameHeaderWrapper = styled.div`
  width: 16rem;
  user-select: none;
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  ${({ theme, isMobile }) => theme.mobile`
    font-weight: 400;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    ${!isMobile ? 'display: none' : 'display: block'}   
    `};
`;

export const TypeWrapper = styled.div`
  width: 1rem;
  text-transform: capitalize;
  white-space: nowrap;
  user-select: none;

  ${({ theme, isMobile }) => theme.mobile`
    margin-right: 4rem;
    display: ${isMobile ? 'block' : 'none'};
   
   `}
`;

export const GenderWrapper = styled.div`
  width: 1rem;
  text-transform: capitalize;
  white-space: nowrap;
  user-select: none;

  ${({ theme, isMobile }) => theme.mobile`
    margin-right: 4rem;
    display: ${isMobile ? 'block' : 'none'};
   `}
`;

export const AgeWrapper = styled.div`
  width: 1rem;
  margin-right: 15rem;
  white-space: nowrap;
  text-transform: capitalize;
  user-select: none;

  ${({ theme, isMobile }) => theme.mobile`
      margin-right: 4rem;
      display: ${isMobile ? 'block' : 'none'};
   `}
`;

export const CheckboxAndNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => theme.mobile`
    margin-left: -0.7rem;
    margin-top: -1.4rem;
   
   `}
`;

export const NameTextWrapper = styled.div`
  width: 16rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ResponsiveNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
  justify-content: space-between;
`;

export const DropDownIcon = styled.img`
  width: 2.4rem;
  cursor: pointer;
`;

export const DropDownIconWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  transition: 0.3s ease-in-out;

  ${({ isPetDropdownOpen }) => isPetDropdownOpen && 'transform: rotate(180deg)'};

  ${({ theme }) => theme.mobile`
     display: flex;
  `}
`;

export const WrapperForResponsive = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mobile`
    margin-top: 1rem;
  `}
`;

export const ResponsiveHeadingsWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const SingleHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  width: 14rem;
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mobile`
    padding-left: 1.1rem;
    margin-top: 1.1rem;
    margin-bottom: 2.2rem;
  `}
`;

export const RespFeaturesWrapper = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: #8a94a6;
`;

export const SelectAllTextWrapper = styled.div`
  margin-top: 0.1rem;
  margin-left: 0.3rem;
  display: none;

  ${({ theme }) => theme.mobile`
    display: block;
  `}
`;

export const RespSelectAllWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
