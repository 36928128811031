import React, { useEffect, useState } from 'react';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { cssText, ButtonColors, Button, Calendar } from '_fsd/shared';
import css from './VetApptManagerPage.module.css';
import { useTimeslotsByVets } from '_fsd/entities/timeslot';
import { getTimeslotsInDate, Modal } from '_fsd/shared';
import moment from 'moment/moment';
import { VetTimeslotWrapper } from '../../../widgets/vet-timeslots';
import { ClinicVetsMap } from '../../../widgets/clinic-vets-map';
import { useAppointmentsByVetIds } from '_fsd/entities/appointment';
import { SelectClinicMultipleVet } from '_fsd/features/admin-select-multiple-vet';
import { useClinicVets } from '_fsd/entities/vet-admin';
import { AppWrapper } from './AppWrapper';
import { SmartScheduleWrapper } from './SmartScheduleWrapper';
import { Skeleton } from 'antd';
import { useDatesWithIntegrationErrors } from '../lib';

export const VetApptManagerPage = (props) => {
  const [view, setView] = useState('timeslots');
  const [vetsMap, setVetsMap] = useState();
  const { data: vets = [], loading: vetsLoading } = useClinicVets();
  const [selectedVets, setSelectedVets] = useState([]);
  useEffect(() => {
    if (vets?.length) {
      setSelectedVets(vets);
    }
  }, [vets]);

  const [isSmartScheduleOpen, setIsSmartScheduleOpen] = useState(false);

  const {
    data: timeslots,
    refetch: refetchTimeslots,
    loading
  } = useTimeslotsByVets({
    vetIds: selectedVets.map((v) => v.uid)
  });
  const [activeTimeslot, setActiveTimeslot] = useState();

  useEffect(() => {
    if (activeTimeslot) {
      if (!view) {
        setView('timeslots');
      }
    }
    if (!activeTimeslot) {
      setView('timeslots');
    }
  }, [activeTimeslot]);
  const vet = activeTimeslot?.vet;
  const [dates, setDates] = useState({});
  const [calendarDate, _setCalendarDate] = useState(moment());
  const timeslotActions = {
    calendarDate,
    setCalendarDate: _setCalendarDate
  };
  const timeslotsInDate = getTimeslotsInDate({
    timeslots,
    timeslotActions,
    setDates,
    filters: { displayFullTS: true },
    startDate: moment('2000-01-01'),
    isForAdminCalendar: true
  });
  const appRoute = activeTimeslot?.routes[0]?.appointmentRoutes[0];
  const route = activeTimeslot?.routes[0];
  const appsFilters = {
    date: calendarDate.format('YYYY-MM-DD'),
    skip: !vet?.uid || !activeTimeslot?.uid,
    fetchPolicy: 'network-only'
  };
  const { data: apps, refetch: refetchApps } = useAppointmentsByVetIds({
    vets: [vet?.uid],
    ...appsFilters,
    timeSlotId: view === 'timeslots' ? activeTimeslot?.uid : undefined
  });

  const [vetAdmin] = vets?.[0]?.vetAdmins ?? [];
  const datesWithIntegrationErrors = useDatesWithIntegrationErrors({ dates, vetAdmin });
  return (
    <Skeleton loading={vetsLoading || loading} active>
      <div className={css.container}>
        <div className={css.header}>
          <div className={css.headerCaregivers}>
            <span className={cssText.s16h24w5}>Caregivers</span>
            <SelectClinicMultipleVet
              setSelectedVets={setSelectedVets}
              selectedVets={selectedVets}
              vets={vets}
            />
          </div>
          <Button
            size="medium"
            disabled={!selectedVets.length}
            type="primary"
            colorScheme={ButtonColors.ORANGE}
            onClick={() => setIsSmartScheduleOpen(true)}
            icon={<PlusOutlined />}>
            Smart Scheduling
          </Button>
        </div>

        <div className={css.timeslotsWrapper}>
          <div className={css.calendar}>
            <Calendar
              className={css.calendarWidth}
              theme="light"
              open
              hideHeader
              holidays={Object.keys(dates).map((date) => ({ date: `${date} 12:00:00` }))}
              endYear={2}
              startYear={new Date().getFullYear()}
              selected={timeslotActions.calendarDate.toDate()}
              onChange={(value) => {
                timeslotActions.setCalendarDate(moment(value));
                setActiveTimeslot();
              }}
              renderDayContents={(day, date) => {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                const dayWithTimeslots = datesWithIntegrationErrors[formattedDate];

                return dayWithTimeslots?.hasAnyIntegrationError ? (
                  <div className={css.dateContainer}>
                    <span>{day}</span>
                    <ExclamationCircleFilled className={css.warningIcon} />
                  </div>
                ) : (
                  day
                );
              }}
            />
          </div>
          <div className={css.timeSlots}>
            <VetTimeslotWrapper
              refetchTimeslots={refetchTimeslots}
              view={view}
              setView={setView}
              route={appRoute}
              vet={vet}
              calendarDate={calendarDate}
              setCalendarDate={timeslotActions.setCalendarDate}
              setVetsMap={setVetsMap}
              timeslots={timeslotsInDate}
              setActiveTimeslot={setActiveTimeslot}
              vetAdmin={vetAdmin}
            />
          </div>
        </div>
        <div className={css.apps}>
          <AppWrapper
            refetchApps={refetchApps}
            setIsSmartScheduleOpen={setIsSmartScheduleOpen}
            view={view}
            apps={apps}
            activeTimeslot={activeTimeslot}
            vet={activeTimeslot?.vet}
            route={route}
            appRoute={appRoute}
            calendarDate={calendarDate}
            vetAdmin={vetAdmin}
          />
        </div>
        {vetsMap && (
          <Modal
            title="Day preview"
            isOpen={vetsMap}
            onClose={() => setVetsMap()}
            customFooter={() => null}>
            <ClinicVetsMap
              appsFilters={appsFilters}
              vetsMap={vetsMap}
              onClose={() => setVetsMap()}
            />
          </Modal>
        )}
        <SmartScheduleWrapper
          vets={vets}
          setCalendarDate={timeslotActions.setCalendarDate}
          isSmartScheduleOpen={isSmartScheduleOpen}
          setIsSmartScheduleOpen={setIsSmartScheduleOpen}
          refetchApps={() => {}}
        />
      </div>
    </Skeleton>
  );
};
