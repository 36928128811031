import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  padding: 1.6rem 3.2rem;
  max-height: 100rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);

  ${({ theme }) => theme.tabletL`
    padding: 2rem;
  `};

  ${({ isDropdownOpen }) =>
    !isDropdownOpen && `max-height: 6.4rem; display:flex; flex-direction: column`};
`;

export const Heading = styled.h3`
  font-size: 1.6rem;
  color: #000000d9;
  margin-bottom: 1.6rem;
`;

export const VisibilityInfo = styled.p`
  color: #434343;
  opacity: 0.75;
  font-size: 1.2rem;
  font-weight: 400;

  ${({ isDropdownOpen }) => !isDropdownOpen && `display: none;`};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mobile`
    // flex-direction: column;
    // align-items: flex-start;
    margin-bottom: 2rem;
  `};
`;

export const Content = styled.div`
  ${({ isDropdownOpen }) => !isDropdownOpen && `opacity: 0;`};
`;

export const EyeIcon = styled.img``;

export const DropdownIcon = styled.img`
  cursor: pointer;
  width: 2.1rem;
  height: 2.1rem;
  display: none;
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg) ${({ isDropdownOpen }) => isDropdownOpen && `transform: rotate(0deg);`};

  ${({ theme }) => theme.tabletL`
    display: block;
  `}
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.tabletL`
  flex-direction: column;
  align-items: flex-start;
  `};
`;
