import React, { memo, useContext, useEffect } from 'react';
import { Dropdown } from 'antd';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import CalendarVet from 'components/calendar/vet/CalendarVet';
import CalendarSteps from 'components/calendar/steps/CalendarSteps';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import CopyRight from 'components/calendar/copyright/CopyRight';
import BreadCrumb from 'components/breadcrumb/BreadCrumb';
import useCalendar from './useCalendar';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as LogOutImage } from 'assets/icons/logout-rounded.svg';
import './style.scss';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/AuthContext';
import ScheduleAppointmentContext, {
  MODALS,
  ScheduleAppointmentContextProvider,
  STEPS
} from './ScheduleAppointmentContext';
import SectionUserLogin from './components/SectionUserLogin';
import Modal from '../../components/common/modal/Modal';
import ModalAccountNotFound from './components/ModalAccountNotFound';
import ModalVerifyPhone from './components/ModalVerifyPhone';
import ModalVerifyEmail from './components/ModalVerifyEmail';

const MODALS_MAP = {
  [MODALS.ACC_DOESNT_EXIST]: () => <ModalAccountNotFound />,
  [MODALS.VERIFY_PHONE]: (vet) => <ModalVerifyPhone vet={vet} />,
  [MODALS.VERIFY_EMAIL]: () => <ModalVerifyEmail />
};

const LogOutDropDown = ({ t, logout }) => (
  <div className="log-out-wrapper" onClick={() => logout(false, true)}>
    <LogOutImage /> <h3>{t('log.out')}</h3>
  </div>
);

const Header = ({ t, user, logout }) => {
  return user ? (
    <div className="auth-user-wrapper">
      <h3>{t('welcome.back')},</h3>
      {user?.avatarUrl && <img src={user?.avatarUrl} alt="avatar" />}
      <h4>{user?.firstName}</h4>
      <Dropdown
        overlay={<LogOutDropDown logout={logout} t={t} />}
        trigger={['click']}
        placement="bottomRight">
        <a onClick={(e) => e.preventDefault()}>
          <ArrowDown />
        </a>
      </Dropdown>
    </div>
  ) : (
    ''
  );
};

const Calendar = () => {
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const { isMobile } = mediaQueriesList();

  const {
    selectedVet,
    loading,
    sendRequestCode,
    isBooking,
    vets,
    setCurrentVet,
    checkExistingUser,
    placeId
  } = useCalendar();

  const { step, setStep, closeModal, modal } = useContext(ScheduleAppointmentContext);

  useEffect(() => {
    if (user) {
      setStep(STEPS.SCHEDULE);
    } else {
      setStep(STEPS.LOGIN);
    }
  }, [user]);

  return (
    <>
      <LoaderWrapper isLoading={loading}>
        <div className="calendar-page">
          <div className={`calendar-wrapper ${isBooking ? 'booking' : ''}`}>
            {isMobile && isBooking && <BreadCrumb />}
            {!isBooking && (
              <div className="calendar-header-wrapper">
                <h3 className="vet-side-title">{t('schedule.an.appointment.with')}</h3>
                <Header t={t} user={user} logout={() => logout({ reload: true })} />
              </div>
            )}
            <div className="calendar-content-wrapper">
              <CalendarVet
                auth={user}
                selectedVet={selectedVet}
                loading={loading}
                isBooking={isBooking}
                vets={vets}
                setCurrentVet={setCurrentVet}
              />
              {step === STEPS.LOGIN && <SectionUserLogin vet={selectedVet} />}
              {step === STEPS.SCHEDULE && (
                <CalendarSteps
                  vet={selectedVet}
                  placeId={placeId}
                  sendRequestCode={sendRequestCode}
                  auth={user}
                  isBooking={isBooking}
                  checkExistingUser={checkExistingUser}
                />
              )}
            </div>
          </div>

          {isMobile && !isBooking && <CopyRight isMobile={isMobile} auth={user} />}
        </div>
      </LoaderWrapper>
      <Modal
        isOpen={!!modal}
        onRequestClose={() => closeModal()}
        isMobile={isMobile}
        isLaptop={!isMobile}>
        {modal && MODALS_MAP[modal](selectedVet)}
      </Modal>
    </>
  );
};

const MemoizedCalendar = memo(Calendar);

const Container = (props) => {
  return (
    <ScheduleAppointmentContextProvider>
      <MemoizedCalendar {...props} />
    </ScheduleAppointmentContextProvider>
  );
};

export default Container;
