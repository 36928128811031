import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 29.4rem;
  height: 5.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 154, 5, 0.1);
  outline: none;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;

  ${({ theme }) => theme.laptop`
    width: 17.6rem;
    height: 4.4rem;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
    height: 4.9rem;
  `}
`;

export const Label = styled.h2`
  color: ${({ theme }) => theme.colors.orange};
  font-size: 1.8rem;
  line-height: 1.22;
  font-family: inter, assistant;
  font-weight: 400;
`;

export const IconWrapper = styled.div`
  margin-top: 0.4rem;
  margin-right: 1.2rem;
`;
