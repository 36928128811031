import styled from 'styled-components';

export const Container = styled.div`
  max-width: 59.2rem;
  width: 100%;
  padding: 5rem 0;
  /* overflow-y: auto; */
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => theme.mobile`
    width: 100%;
    padding:  3.2rem;
    border-radius: 1.6rem 1.6rem 0 0;
  `};
`;
