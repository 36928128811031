import styled, { css } from 'styled-components';

import { Label as L } from 'components/common/input/components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const TextareaComponent = styled.textarea`
  width: 100%;
  min-height: 10rem;
  border-radius: 0.8rem;
  padding: 2rem 2.5rem;
  resize: none;
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  border: 0.1rem solid rgba(172, 188, 212, 0.56);
  outline: none;
  &:focus {
    border-color: #ff9a05;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: #ff563f !important;
    `}

  //safari-fix
  -webkit-appearance: none;
`;

export const Label = styled(L)`
  top: 1.9rem;
  left: 2.4rem;
  ${TextareaComponent}:focus ~ & {
    top: -1rem;
    left: 2rem;
    color: #ff9a05;
    font-size: 1.4rem;
  }
  ${({ value }) =>
    value &&
    css`
      top: -1rem;
      left: 2rem;
      font-size: 1.4rem;
    `}
`;
