import React from 'react';

const Flag = ({ width = 20, height = 12, country }) => (
  <img
    src={`https://flagcdn.com/w${width}/${country}.png`}
    srcSet={`https://flagcdn.com/w${width * 2}/${country.toLowerCase()}.png 2x`}
    width={width}
    height={height}
    alt={country}
  />
);

export default Flag;
