import React, { useState, useCallback, useEffect } from 'react';
import { serializeAddressDescription } from 'serializers/signUpFlow.serializer';
import { PropertyType } from 'utils/enums';
import { Form } from 'antd';
import AddressAutocomplete from './address-autocomplete/AddressAutocomplete';
import AdditionalInfo from '../../calendar/steps/address/components/AdditionalInfo';
import { ButtonColors, Modal } from '_fsd/shared';
import useUpdateUser from '../../useUpdateUser';
import './style.css';
import { useTranslation } from 'react-i18next';

const EditClientAddressModal = ({ client, onRequestClose, refetchClients }) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState({});
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [isSubmitting, setSubmitting] = useState(false);

  const { updateUser } = useUpdateUser(refetchClients, onRequestClose);

  const onChange = useCallback((value) => {
    setAddress({
      description: value
    });
  }, []);

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    setAddress({
      ...value,
      description
    });
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (client) {
      setAddress(client?.address);
      setPropertyType(PropertyType[client.address?.propertyType]);
    }
  }, []);

  const initialValues = {
    address: client?.address?.description,
    floor: String(client?.address?.floor),
    apartment: client?.address?.apartment,
    userComment: client?.address?.comment
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => onRequestClose()}
      title={t('admin.recommender.edit_address.title')}
      hideClose
      footerProps={{
        buttonCancelProps: {
          disabled: isSubmitting,
          children: t('back'),
          type: 'default'
        },
        buttonConfirmProps: {
          disabled: isSubmitting,
          children: t('save'),
          type: 'primary',
          colorScheme: ButtonColors.ORANGE,
          onClick: () => {
            form.submit();
          }
        }
      }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values) => {
          setSubmitting(true);
          const data = {
            email: client?.email,
            lastName: client?.lastName,
            firstName: client?.firstName,
            address: {
              ...values,
              ...address,
              propertyType,
              houseNumber: Number(address?.houseNumber)
            },
            floor: Number(values?.floor) || Number(client?.address?.floor),
            comment: values.userComment,
            apartment: values.apartment
          };
          delete data.address.country;
          delete data.address.address;
          delete data.address.__typename;
          delete data.address.userComment;
          updateUser(data, client?.uid).finally(() => setSubmitting(false));
        }}>
        <div className="new-edit-clients-modal-wrapper">
          <Form.Item
            name="address"
            className="address-field"
            rules={[{ required: true }, { max: 150 }]}>
            <div className="address-field">
              <h2>{t('admin.recommender.edit_address.lbl_address')}</h2>
              <AddressAutocomplete
                onChange={onChange}
                onSelect={onSelect}
                address={address}
                isForZipCode
                isForCalendar
                isForCompleteProfile={true}
                // error={address && !!Object.keys(address).length && addressError}
              />
            </div>
          </Form.Item>

          <AdditionalInfo propertyType={propertyType} setPropertyType={setPropertyType} />
        </div>
      </Form>
    </Modal>
  );
};

export default EditClientAddressModal;
