import React, { useLayoutEffect, useState, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './components.jsx';

const Biography = ({ vet }) => {
  const { t } = useTranslation();
  const [isOverflow, setIsOverflow] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  const biographyRef = useRef();

  const handleShowMoreText = (e) => {
    e.stopPropagation();
    setShowFullText((prev) => !prev);
  };

  useLayoutEffect(() => {
    setIsOverflow(biographyRef?.current?.offsetHeight < biographyRef?.current?.scrollHeight);
  }, [biographyRef]);

  return (
    <S.BiographyWrapper>
      <S.BiographyTitle>{t('short.biography')}</S.BiographyTitle>
      <S.Biography ref={biographyRef} showFullText={showFullText}>
        {vet?.user?.bio}
      </S.Biography>
      {isOverflow && (
        <S.SeeMore onClick={(e) => handleShowMoreText(e)}>
          {showFullText ? t('see.less') : t('see.more')}
        </S.SeeMore>
      )}
    </S.BiographyWrapper>
  );
};

export default memo(Biography);
