import { useQuery, useMutation } from '@apollo/client';
import {
  getSubscriptionQuery,
  calculateSubscriptionPriceQuery,
  removeSubscriptionPetMutation
} from 'gql';

const useCheckout = (me, id) => {
  const { data: priceData } = useQuery(calculateSubscriptionPriceQuery, {
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const { data, loading } = useQuery(getSubscriptionQuery, {
    fetchPolicy: 'network-only',
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const [removePet] = useMutation(removeSubscriptionPetMutation, {
    refetchQueries: [
      {
        query: getSubscriptionQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      },
      {
        query: calculateSubscriptionPriceQuery,
        variables: {
          record: {
            subscriptionId: id
          }
        }
      }
    ]
  });

  const handleRemovePet = async (uid) => {
    try {
      await removePet({
        variables: {
          record: {
            uid
          }
        }
      });
    } catch (err) {
      throw err;
    }
  };

  return {
    handleRemovePet,
    loading,
    vet: data?.getSubscription?.vet || null,
    subscriptionPets: data?.getSubscription?.subscriptionPets || null,
    priceData: priceData?.calculateSubscriptionPrice,
    subscriptionType: data?.getSubscription?.subscriptionType
  };
};

export default useCheckout;
