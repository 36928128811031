import React, { useState } from 'react';
import { Typography, Input } from 'antd';

import Modal from 'components/common/modal/Modal';
import Button from 'components/common/button/Button';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import './style.scss';
import useAppointments from './useAppointments';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { TextArea } = Input;

const EditAppointmentDetailsModal = ({ onRequestClose, data, onConfirm }) => {
  const [itemValue, setItemValue] = useState(data?.value || '');

  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();

  const { updateAppUserComment, updateAppVetComment } = useAppointments();

  const contentSwitch = {
    clientComment: {
      title: 'edit_client_details.client_comment.title',
      subTitle: 'edit_client_details.client_comment.subTitle',
      placeHolder: 'edit_client_details.client_comment.placeHolder',
      type: 'clientComment',
      onSubmit: updateAppUserComment
    },
    vetComment: {
      title: 'edit_client_details.vet_comment.title',
      subTitle: 'edit_client_details.vet_comment.subTitle',
      placeHolder: 'edit_client_details.vet_comment.placeHolder',
      type: 'vetComment',
      onSubmit: updateAppVetComment
    }
  };

  const { type } = data;

  const onSubmit = () => {
    contentSwitch[type]?.onSubmit(data?.uid, itemValue).then(() => {
      onConfirm();
    });
  };

  return (
    <Modal
      zIndex={1001}
      isOpen={true}
      onRequestClose={onRequestClose}
      isLaptop={!isMobile}
      isMobile={isMobile}>
      <div className="edit-appointment-details">
        <Title level={1}>{t(contentSwitch[type]?.title)}</Title>
        <Title level={2}>{t(contentSwitch[type]?.subTitle)}</Title>
        <TextArea
          placeholder={t(contentSwitch[type]?.placeHolder)}
          value={itemValue}
          onChange={(e) => setItemValue(e.target.value)}
        />
        <div className="edit-appointment-footer">
          <Button
            border="1px solid #FF9A05"
            width="8.1rem"
            height="4rem"
            color="#fff"
            fontColor="#323B4B"
            isForModal
            onClick={onRequestClose}>
            {t('cancel')}
          </Button>
          <Button color="primary" width="6.7rem" height="4rem" isForModal onClick={onSubmit}>
            {t('save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditAppointmentDetailsModal;
