import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ w }) => w || '100%'};
  height: ${({ h }) => h || '100%'};
  border-radius: 50%;
  background-color: rgba(138, 148, 166, 0.16);
  padding: ${({ inSidebar }) => (inSidebar ? '0.6rem' : '0.4rem')};
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const Label = styled.h2`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: ${({ fs }) => fs || '1.6rem'};
  color: #f8f9fa;
  line-height: 1.5;
  margin-bottom: 0;
`;
