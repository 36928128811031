import React from 'react';
import { EditOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { SubscriptionStatus, SubscriptionTypeName } from 'utils/enums';

import './customers-styles.css';

export const useCustomersColumns = (setCustomerData) => {
  const { t } = useTranslation();

  const handlePetStatus = (subscription) => {
    if (subscription?.subscriptionType?.name) {
      // if subscription is monthly return subscription status
      if (subscription?.subscriptionType?.name === SubscriptionTypeName.MONTHLY) {
        return subscription?.status;
      } else {
        // if pet has active one-time return ACTIVE status
        // if not return empty
        return subscription?.status === SubscriptionStatus.ACTIVE ? subscription?.status : '';
      }
    }
  };

  // const compareMoments = (m1, m2) => {
  //   if (!m1?.isValid() && !m2?.isValid()) {
  //     return null;
  //   }
  //
  //   if (m1?.isValid() && !m2?.isValid()) {
  //     return 1;
  //   }
  //
  //   if (!m1?.isValid() && m2?.isValid()) {
  //     return -1;
  //   }
  //
  //   if (m1?.isValid() && m2?.isValid()) {
  //     return m1.diff(m2);
  //   }
  // };

  return [
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate'
      // sorter: {
      //   compare: (a, b) => a.country - b.country,
      //   multiple: 1,
      // },
    },
    {
      title: t('customers.client.name'),
      dataIndex: 'fullName',
      key: 'fullName'
      // sorter: {
      //   compare: (a, b) => a.fullName - b.fullName,
      //   multiple: 2,
      // },
    },

    {
      title: t('customers.contact.info'),
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      className: 'contacts-column',
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              <div className="single-popover">
                <PhoneOutlined className="popover-icon" />
                {record.phone}
              </div>
              <div className="single-popover">
                <MailOutlined className="popover-icon" />
                {record.email}
              </div>
              <div className="location-icon">
                <EnvironmentOutlined className="popover-icon" />
                {record.city}
              </div>
              <div className="popover-location-text">{record.street}</div>
            </div>
          }>
          <div style={{ cursor: 'pointer' }}>@</div>
        </Popover>
      )
    },

    {
      title: t('customers.pet.amount'),
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      // sorter: {
      //   compare: (a, b) => a.petAmount - b.petAmount,
      //   multiple: 1,
      // },
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              {!!record?.pets?.length &&
                record?.pets.map((pet) => {
                  return (
                    <div className="single-popover" key={pet.uid}>
                      <div className="popover-address">{t('name')}:</div>
                      {pet.name}
                      {pet?.activeSubscription && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <div className="popover-address">{t('status')}:</div>
                          {handlePetStatus(pet?.activeSubscription)}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          }>
          <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
        </Popover>
      )
    },

    {
      title: t('customers.next.appointment.date'),
      dataIndex: 'nextAppointmentDate',
      align: 'center',
      key: 'nextAppointmentDate',
      // sorter: {
      //   compare: (a, b) => {
      //     const nexApptRes = compareMoments(a?.nextAppointmentDate, b?.nextAppointmentDate);
      //     if (nexApptRes !== null) return nexApptRes;
      //
      //     const nextEstRes = compareMoments(a?.nextAppointmentEstimation, b?.nextAppointmentEstimation);
      //     if (nextEstRes !== null) return nextEstRes;
      //
      //     return 0;
      //   },
      //   multiple: 1,
      // },
      render: (text, record) => {
        let dateString;

        if (record?.nextAppointmentDate?.isValid()) {
          dateString = record.nextAppointmentDate.format(t('date-time-format.full-date'));
        } else if (record?.nextAppointmentEstimation?.isValid()) {
          dateString =
            t('customers.next.estimated-short') +
            ' ' +
            record.nextAppointmentEstimation.format(t('date-time-format.full-date'));
        } else {
          dateString = t('customers.next.no-appointments');
        }

        return <div>{dateString}</div>;
      }
    },

    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="action-buttons-wrapper">
          <div>
            <EditOutlined
              className="action-button"
              onClick={() => {
                setCustomerData(record);
              }}
            />
          </div>
        </div>
      )
    }
  ];
};

export default useCustomersColumns;
