import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    width: 41.2rem;
    padding-top: 0;
    z-index: 10;
  }

  .react-datepicker__header {
    background: ${({ theme, isForTimeSlot }) => (isForTimeSlot ? '#f5f6f9' : theme.colors.white)};
    border: none;
    padding: 4px;
  }

  .calendar {
    display: flex;
    justify-content: center;
    border: ${({ isForTimeSlot }) =>
      isForTimeSlot ? 'none' : '1px solid rgba(172, 188, 212, 0.56)'};
    background-color: #fff;
    width: 41.2rem;
    padding: 2.8rem 3.2rem;
    /* box-shadow: 0 4px 60px -20px rgba(5, 40, 72, 0.15); */
    border-radius: 0.8rem;
    user-select: none;
  }

  .wrapper {
    width: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    font-family: inter, assistant;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .react-datepicker__day-name {
    color: #b4b8cd;
    font-size: 1.2rem;
  }

  .react-datepicker__week .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    background: ${({ theme, isForTimeSlot }) => (isForTimeSlot ? theme.colors.white : '#F8FAFD')};
    border-radius: 0.8rem;
    font-size: 1.4rem;
    color: ${({ theme, isForTimeSlot }) => (isForTimeSlot ? theme.colors.orange : '#9094AC')};
    border: ${({ isForTimeSlot }) => (isForTimeSlot ? '.05rem solid #e5eaf2' : 'none')};
  }

  .react-datepicker__day:hover {
    color: #ff9a05 !important;
    border: 1px solid #ff9a05;
    background: rgba(255, 154, 5, 0.07) !important;
  }

  .react-datepicker__day--selected {
    color: ${({ theme, isForTimeSlot }) =>
      isForTimeSlot ? theme.colors.white : theme.colors.orange} !important;
    border: ${({ isForTimeSlot }) =>
      isForTimeSlot ? '1px solid #ff9a05' : '1px solid #FF9A05'} !important;
    background: ${({ theme, isForTimeSlot }) =>
      isForTimeSlot ? theme.colors.orange : 'rgba(255, 154, 5, 0.07)'} !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.75 !important;
    background: ${({ isForTimeSlot }) => (isForTimeSlot ? '#dbe2ee' : '#F8FAFD')} !important;
    color: ${({ theme, isForTimeSlot }) =>
      isForTimeSlot ? theme.colors.white : '#9094AC'} !important;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    height: 22.8rem;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day--disabled:hover {
    border: none;
    color: #9094ac !important;
    background-color: #f8fafd !important;
  }

  ${({ theme }) =>
    theme.mobile`
      display: flex;
      justify-content: center;
      .calendar,
      .react-datepicker-popper {
        /* transform: none; */
        width: 100% !important;
      }
      .calendar {
        margin: 0;
        margin-bottom: 1rem;
        padding: 2.6rem 1.7rem;
      }
    `}
`;

export const DatePickerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, isForTimeSlot }) => (isForTimeSlot ? '#f5f6f9' : theme.colors.white)};
  margin-bottom: 1.8rem;
`;

export const CalendarHeaderWrapper = styled.div``;

export const Calendar = styled.h3`
  color: #2d3363;
  font-size: 2rem;
  font-family: inter, assistant;
  font-weight: 600;
`;

export const SelectedDate = styled.div`
  color: #f9b057;
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 700;
  ${({ isRtl }) =>
    isRtl &&
    css`
      direction: rtl;
    `}
`;

export const SelectorWrapper = styled.div`
  width: 9.4rem;
`;

export const Month = styled.span`
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
`;

export const Arrow = styled.button`
  color: #ff9a05;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
  ${({ right }) =>
    right &&
    css`
      transform: rotate(180deg);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.35;
      cursor: initial;
    `}
`;

export const MonthSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
  ${({ theme }) => theme.mobile`
    width: 14rem;
  `}
`;

export const HeaderBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.8rem;
  ${({ theme }) => theme.laptop`
    margin-top: 2.4rem;
  `}
`;
