import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/Modal';
import { Caption, SubCaption, CloseIconWrapper, Description, Wrapper } from './components';
import Close from 'components/common/close/Close';
import { SadIcon } from './components';
import Icon from 'assets/icons/sad.png';

const NotWorkingAreaModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Wrapper>
        <CloseIconWrapper>
          <Close clickHandler={onRequestClose} />
        </CloseIconWrapper>
        <SadIcon alt="sad" src={Icon} />
        <Caption>{t('we.cant.give.you.service.for.now')}</Caption>
        <SubCaption>{t('address.that.you.put.is.not.in.our.working.area')}</SubCaption>
        <Description>
          {t('we.are.growing.fast.and.will.become.available.in.more.regions.soon')}
        </Description>
      </Wrapper>
    </Modal>
  );
};

export default NotWorkingAreaModal;
