import React from 'react';
import { getVetName } from '_fsd/shared';
import { Popover } from 'antd';
import css from '../ui/Vets.column.module.css';
import { WorkingAreaType } from 'utils/enums';
import { parseTimeSlotHour } from '../../../../utils/helpers';

const getAreaName = (timeslot) => {
  const wa = timeslot.workingAreas[0];
  if (wa?.type === WorkingAreaType.polygon) {
    return wa?.polygon?.name;
  }
  return wa?.zipCode?.zip;
};
const getTimeslotName = (timeslot) => {
  return timeslot.name;
};

export const useVets = (props) => {
  const columns = [
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      align: 'center',
      render: (text, record) => <div>{getVetName(record)}</div>
    },
    {
      title: 'Timeslots',
      dataIndex: 'timeslots',
      key: 'timeslots',
      align: 'center',
      render: (text, record) => {
        const timeslots = record.timeslots?.map((t) => (
          <div className={css.row} key={t.uid}>
            <span>{getTimeslotName(t)}</span>
            <span>{`${parseTimeSlotHour(t.startTime)} - ${parseTimeSlotHour(t.endTime)}`}</span>
          </div>
        ));
        return (
          <Popover
            overlayClassName={css.popover}
            placement={'bottom'}
            content={<div className={css.column}>{timeslots}</div>}>
            <div>{record.timeslots?.length}</div>
          </Popover>
        );
      }
    }
  ];
  return { columns };
};
