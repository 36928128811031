import { useMutation } from '@apollo/client';
import { updateVisitDurationMutation } from 'gql';

const useAppointmentDuration = () => {
  const [updateDuration] = useMutation(updateVisitDurationMutation);

  const updateVisitDuration = async (appointmentRouteId, duration) => {
    try {
      await updateDuration({
        variables: {
          record: {
            appointmentRouteId,
            duration
          }
        }
      });
    } catch (err) {
      console.log(err, 'error');
    }
  };
  return {
    updateVisitDuration
  };
};

export default useAppointmentDuration;
