import React from 'react';
import cls from 'classnames';
import css from './InitialsAvatar.module.css';

export const InitialsAvatar = ({ letters, size = 'md' }) => (
  <div className={cls(css.container, css[size])}>
    <div className={css.bg}>
      <span>{letters}</span>
    </div>
  </div>
);
