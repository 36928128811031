import styled from 'styled-components';

export const PetsList = styled.div`
  display: flex;
  width: 100%;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.4rem;
  background-color: inherit;
  max-height: 44.8rem;
  overflow-x: hidden;
  overflow-y: auto;
  ${({ theme }) => theme.laptopHeightS`
    max-height: 38.8rem;
    margin-bottom: 1.5rem;
  `}
  ${({ theme }) => theme.mobile`
    grid-template-columns: 1fr;
    padding-right: unset;
    max-height: unset;
    overflow-y: unset;
    overflow-x: unset;
  `}

  ::-webkit-scrollbar {
    width: 0.4rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #8a94a640;
    border-radius: 1rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10rem;
  overflow: hidden;
  ${({ theme }) => theme.laptopHeightS`
    padding-top: 2%;
  `}
  ${({ theme }) => theme.mobile`
    padding: 3.2rem;
    width: 100%;
  `}
`;

export const GoBackWrapper = styled.div`
  margin-bottom: 5.6rem;
  ${({ theme }) => theme.laptopHeightS`
    margin-bottom: 2.6rem;
  `}
  ${({ theme }) => theme.mobile`
    margin-bottom: 1.6rem;
  `}
`;

export const AddNewPetBtn = styled.div`
  height: 20rem;
  border-radius: 0.8rem;
  border: 0.2rem dashed ${({ theme }) => theme.colors.green};
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2.4rem;
  ${({ theme }) => theme.desktop`
    width: 100%;
  `}
  ${({ theme }) => theme.mobile`
    height: 13.2rem;
  `}
`;

export const PlusIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
`;

export const AddNewPetLabel = styled.h2`
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.8rem;
  font-family: inter, assistant;
  font-weight: 400;
`;

export const ContinueBtnWrapper = styled.div`
  width: calc(100% - 2rem);
  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;
