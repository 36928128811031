import React from 'react';

export const PetIntegrationLink = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor">
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H11C11.5523 3 12 2.55228 12 2C12 1.44772 11.5523 1 11 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V13C23 12.4477 22.5523 12 22 12C21.4477 12 21 12.4477 21 13V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4Z"
      fill="currentColor"
    />
    <path
      d="M16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H19.5858L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L21 4.41421V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V2C23 1.44772 22.5523 1 22 1H16Z"
      fill="currentColor"
    />
  </svg>
);
