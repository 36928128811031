import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { updateAppointmentRouteAddressMutation } from 'gql';

const useEditAppointmentAddressModal = () => {
  const { t } = useTranslation();

  const [updateAppointmentAddress] = useMutation(updateAppointmentRouteAddressMutation);

  const updateAddress = async (values) => {
    try {
      await updateAppointmentAddress({
        variables: {
          record: values
        },
        refetchQueries: ['getAppointmentsByDate']
      });
      message.success(t('updated.successfully'));
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || t('failed.to.update'), 10);
    }
  };

  return { updateAddress };
};

export default useEditAppointmentAddressModal;
