import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { getPetOwnersQuery, signUpFlowMutation } from 'gql';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/AuthContext';

const useNewAppointmentModal = (
  current,
  changeStep,
  vet,
  onRequestClose,
  setCurrent,
  getAppointmentsByTimeslotUid
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);

  const [selectedClient, setSelectedClient] = useState(null);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [petList, setPetList] = useState([]);
  const [petsToCreate, setPetsToCreate] = useState([]);
  const [nonAppPets, setNonAppPets] = useState([]);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [getPetOwners] = useLazyQuery(getPetOwnersQuery);

  const fetchPetOwners = (getNewData) => {
    try {
      return getPetOwners({
        variables: {
          record: {
            page: getNewData ? 1 : page,
            search,
            take: 10,
            countries: [],
            userId: vet?.user?.uid,
            skipAppointmentPets: true
          }
        },
        skip: !user?.role,
        fetchPolicy: 'no-cache'
      }).then((body) => {
        setPage(body.data.getPetOwners?.page + 1);
        if (search.length || getNewData) {
          setClients(body?.data?.getPetOwners?.petOwners);
        } else {
          setClients([...clients, ...body.data.getPetOwners.petOwners]);
        }
        setTotal(body.data.getPetOwners.total);
      });
    } catch (e) {
      console.log(e);
      setClients([]);
    }
  };

  const [signUp] = useMutation(signUpFlowMutation);

  // Reset selected client if clients list changes, and It's not available in the new list
  useEffect(() => {
    if (selectedClient?.uid && clients.length) {
      const client = clients.find((client) => client.user?.uid === selectedClient?.user?.uid);
      if (!client) setSelectedClient(null);
    }
  }, [selectedClient, clients]);

  useEffect(() => {
    fetchPetOwners().then(() => setLoading(false));
  }, []);

  useEffect(async () => {
    await fetchPetOwners(true).then(() => setLoading(false));
  }, [search]);

  const isNextButtonDisabled = useMemo(() => {
    return (
      !clients?.length || loading || !selectedClient || (petList.length === 0 && current === 1)
    );
  }, [clients, loading, selectedClient, petList, current]);

  const onClientSelect = (client) => {
    setSelectedClient(client);
    form.resetFields(['pets', 'concern', 'condition']);
    setPetList([]);
    setPetsToCreate([]);
  };

  const handleSignUp = useCallback(async (values) => {
    // setSubmitting(true);
    try {
      const res = await signUp({
        variables: {
          record: values
        },
        refetchQueries: ['getAppointmentsByDate']
      });

      if (res?.data?.signUpFlow) {
        message.success(t('new_appointment.success_message'));
        getAppointmentsByTimeslotUid(values?.timeslotId, values?.date);
        onRequestClose();
        setCurrent(0);
      }
    } catch (err) {
      message.error(err?.message);
    }
    setSubmitting(false);
  }, []);

  const onNextClick = useCallback(() => {
    if (!isNextButtonDisabled) {
      if (current === 0) {
        setCurrent(1);
      } else {
        form.submit();
      }
    }
  }, [isNextButtonDisabled, current]);

  const onClientEdit = useCallback((e, client) => {
    e.stopPropagation();
    setClientToEdit(client);
  }, []);

  return {
    isSubmitting,
    search,
    setSearch,
    clients,
    refetchClients: fetchPetOwners,
    loading,
    selectedClient,
    setSelectedClient,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    form,
    total,
    handleSignUp,
    fetchPetOwners,
    nonAppPets: petsToCreate,
    setPetsToCreate
  };
};

export default useNewAppointmentModal;
