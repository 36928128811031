import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 5.1rem 2.8rem 2.8rem 2.8rem;
  width: 41.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: anywhere;
  ${({ theme }) => theme.mobile`
    padding: 1.7rem;
    padding-top: 6.9rem;
    width: 89vw;
  `}
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const IconBackground = styled.div`
  width: 13.3rem;
  height: 13.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.7rem;
  background-color: #fcf4f2;
  ${({ theme }) => theme.mobile`
    width: 9rem;
    height: 9rem;
  `}
`;

export const IconWrapper = styled.div`
  height: 6.8rem;
  width: auto;
  ${({ theme }) => theme.mobile`
    height: 3.9rem;
  `};

  & svg {
    width: 100%;
    height: 100%;
    fill: #ff9a05;
  }
`;

export const Caption = styled.h2`
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.11;
  text-align: center;
  ${({ theme }) => theme.mobile`
    font-size: 1.6rem;
  `}
`;

export const SubCaption = styled.h3`
  color: #9094ac;
  font-size: 1.4rem;
  line-height: 1.29;
  font-family: inter, assistant;
  font-weight: 400;
  margin-top: 1.2rem;
  text-align: center;
  ${({ theme }) => theme.mobile`
    margin-top: 0.8rem;
    font-size: 1.2rem;
  `}
`;

export const PetNameCaption = styled.h2`
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.11;
  text-align: center;
  margin-top: 1rem;
  ${({ theme }) => theme.mobile`
    font-size: 1.6rem;
  `};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.4rem;
  margin-top: 3.2rem;
  width: 100%;
  ${({ theme }) => theme.mobile`
    height: 3.4rem;
    margin-top: 2.4rem;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 48%;
  height: 100%;
`;
