import React from 'react';
import moment from 'moment';
import { EyeTwoTone, EditTwoTone, MoreOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import css from './Customers.module.css';

import './customers-styles.css';
import useActAsUser from './useActAsUser';
import { Dropdown } from '_fsd/shared';

import { petAvatars } from 'constants/pet';
import { UserContactInfoPopover } from '_fsd/features/user-contact-info-popover/ui/UserContactInfoPopover.jsx';

import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { IntegrationErrorPopover } from '_fsd/entities/integration/ui/error-popover/IntegrationErrorPopover';
import { isAnyEntityIntegrationError, isEntityIntegrationError } from '_fsd/entities/integration';

const ActionColumn = ({ record, actAsHandler, editPetOwnerHandler }) => {
  const items = [
    {
      key: 2,
      label: (
        <div className={css.actionItem} onClick={() => editPetOwnerHandler(record)}>
          <EditTwoTone twoToneColor={'#112950'} />
          <span>Edit client</span>
        </div>
      )
    },
    {
      key: 1,
      label: (
        <div className={css.actionItem} onClick={() => actAsHandler(record.userId)}>
          <EyeTwoTone twoToneColor={'#112950'} />
          <span>View as client</span>
        </div>
      )
    }
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      getPopupContainer={() => {
        return document.getElementById('ID_LAYOUT_SCROLLABLE');
      }}>
      <MoreOutlined />
    </Dropdown>
  );
};

export const useColumns = (sortBy, setEditClient) => {
  const { handleActAsUser } = useActAsUser();
  const { t } = useTranslation();

  const editPetOwnerHandler = (user) => {
    setEditClient(user);
  };

  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  return [
    {
      title: t('vet_admin.clients_table.registration_date'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      showSorterTooltip: false,
      sorter: true
    },
    {
      title: <span className={css.fullNameTitle}>{t('vet_admin.clients_table.full_name')}</span>,
      dataIndex: 'fullName',
      key: 'fullName',
      showSorterTooltip: false,
      sorter: true,
      render: (text, record) => {
        const isPetOwnerIntegrationError = isAnyEntityIntegrationError(
          record.vetAdmin,
          record.petOwnerIntegrations
        );

        const integrationErrors = record.petOwnerIntegrations
          ?.filter((i) => isEntityIntegrationError(i) && i?.errorDetails?.message)
          .map((i) => <span key={i.uid}>{i?.errorDetails?.message}</span>);

        return (
          <div className={css.fullNameRow}>
            <IntegrationErrorPopover
              content={
                integrationErrors.length > 0 ? (
                  integrationErrors
                ) : (
                  <span>{t('integration.error_popover.owner_error_message')}</span>
                )
              }
              entity={record}
              fixItHandler={() => setEditClient(record)}>
              <ExclamationCircleFilled
                className={cls(css.warningIntegrationIcon, {
                  [css.hiddenIntegrationIcon]: !isPetOwnerIntegrationError
                })}
              />
            </IntegrationErrorPopover>
            {record.fullName}
          </div>
        );
      }
    },
    {
      title: t('vet_admin.clients_table.contacts'),
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      render: (text, record) => (
        <UserContactInfoPopover
          phonePrefix={record.phone}
          phoneNumber={''}
          email={record.email}
          city={record.city}
          street={record.street}>
          @
        </UserContactInfoPopover>
      )
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('vet_admin.clients_table.pets_number'),
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      render: (text, record) => {
        const isPetIntegrationError = record?.pets?.some((pet) =>
          isAnyEntityIntegrationError(record.vetAdmin, pet.petIntegrations)
        );

        return (
          <div className={css.petsRow}>
            {!isPetIntegrationError && (
              <ExclamationCircleFilled
                className={cls(css.warningIntegrationIcon, css.hiddenIntegrationIcon)}
              />
            )}
            <IntegrationErrorPopover
              showTitle={false}
              disabled={record.petAmount <= 0}
              content={
                record?.pets?.length && (
                  <div className={css.errorPopoverContent}>
                    {record?.pets?.map((pet) => (
                      <div key={pet.uid} className={css.errorPopoverPetsContent}>
                        <div className={css.errorPetIconContainer}>
                          {isPetIntegrationError &&
                            (pet.petIntegrations.length <= 0 ||
                              isAnyEntityIntegrationError(
                                record.vetAdmin,
                                pet.petIntegrations
                              )) && <ExclamationCircleFilled className={css.waringPetIcon} />}
                          <img
                            className={css.petIcon}
                            src={pet?.avatarUrl || petAvatars[pet?.type?.name || 'other']}
                          />
                        </div>
                        <span>{pet.name}</span>
                      </div>
                    ))}
                  </div>
                )
              }
              entity={record}>
              <div className={css.petIntegrationCell}>
                {isPetIntegrationError && (
                  <ExclamationCircleFilled className={cls(css.warningIntegrationIcon)} />
                )}
                <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
              </div>
            </IntegrationErrorPopover>
          </div>
        );
      }
    },
    {
      showSorterTooltip: false,
      sorter: false,
      title: t('vet_admin.clients_table.next_appointment_date'),
      dataIndex: 'nextAppointmentDate',
      align: 'center',
      key: 'nextAppointmentDate',
      render: (text, record) => (
        <div>
          {record?.userNextAppointmentDate
            ? moment(record.userNextAppointmentDate).format('DD/MM/YYYY')
            : ''}
        </div>
      )
    },
    {
      title: t('vet_admin.clients_table.action'),
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      render: (text, record) => (
        <ActionColumn
          actAsHandler={actAsHandler}
          editPetOwnerHandler={editPetOwnerHandler}
          record={record}
        />
      )
    }
  ].filter((item) => item?.visible !== false);
};

export default useColumns;
