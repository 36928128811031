import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { SliderImg, Wrapper, SlideWrapper } from './components';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CustomCarousel = ({ sliderData, autoPlay = false, infiniteLoop = true }) => {
  return (
    <Wrapper>
      <Carousel
        autoPlay={autoPlay}
        infiniteLoop={infiniteLoop}
        showStatus={false}
        showArrows={false}
        emulateTouch
        showThumbs={false}>
        {sliderData?.map((item, index) => {
          const { imgUrl } = item;
          return (
            <SlideWrapper key={index}>
              <SliderImg src={imgUrl} alt="background image" />
            </SlideWrapper>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default CustomCarousel;
