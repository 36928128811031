import React from 'react';
import css from './Timeslot.module.css';
import { cssSpace as space, cssColor as color, cssText as text } from '_fsd/shared';
import cls from 'classnames';
import { Label } from '_fsd/shared';

import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as ClockCircleIcon } from 'assets/icons/clock-circle.svg';
import { ReactComponent as FirstAidKitIcon } from 'assets/icons/firstaidkit.svg';

import { useTranslation } from 'react-i18next';

import { routeStatus as RouteStatusEnum } from 'components/timeline/appointments/useTimelineAppointments';

export const TimeslotRouteSummary = ({ routeSummaryData }) => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={cls(css.row, space.mb4)}>
        <Label className={cls(text.s14w5l22, color.cWhite)}>
        {routeSummaryData.housecallsCnt == 1 ?
             `${routeSummaryData.housecallsCnt} ${t('Household')}`:
            `${routeSummaryData.housecallsCnt} ${t('Households')}`}
        </Label>
      </div>
      <div className={cls(css.row, space.mb4)}>
        <div className={css.iconWithText}>
          <CarIcon className={space.mr4} />
          <Label className={cls(text.s12w4l22, color.cWhite, css.row)}>
            {t('route-summary.routing')}
            {routeSummaryData.routeStatus?.toLowerCase() === RouteStatusEnum.REGISTER ? (
              <>
                <Label className={cls(text.s12w4l22, color.cWhite)}>
                  {' ('}
                  {t('route-summary.estimatedTime')}
                </Label>
                <Label className={cls(text.s12w4l22, color.cWhite)}>{')'}</Label>
              </>
            ) : null}
            {':'}
          </Label>
        </div>
        <Label className={cls(text.s14w5l22, color.cWhite)}>
          {routeSummaryData.routingTime} {t('route-summary.min')}
        </Label>
      </div>
      <div className={cls(css.row, space.mb4)}>
        <div className={css.iconWithText}>
          <FirstAidKitIcon className={space.mr4} />
          <Label className={cls(text.s12w4l22, color.cWhite)}>
            {t('route-summary.appointments')}
          </Label>
        </div>
        <Label className={cls(text.s14w5l22, color.cWhite)}>
          {routeSummaryData.appointmentsTime} {t('route-summary.min')}
        </Label>
      </div>
      <div className={css.row}>
        <div className={css.iconWithText}>
          <ClockCircleIcon className={space.mr4} />
          <Label className={cls(text.s12w4l22, color.cWhite)}>
            {routeSummaryData.freeTime < 0 ? t('route-summary.overTime') : t('route-summary.free')}
            {':'}
          </Label>
        </div>
        <Label className={cls(text.s14w5l22, color.cWhite)}>
          {Math.abs(routeSummaryData.freeTime)} {t('route-summary.min')}
        </Label>
      </div>
    </div>
  );
};
