import dayjs from 'dayjs';

const useDateFormat = () => {
  const isHeb = localStorage.getItem('lang') === 'heb';
  const dateFormat = (date) => {
    if (isHeb) {
      return dayjs(date).format('DD/MM/YYYY');
    } else {
      return dayjs(date).format('MM/DD/YYYY');
    }
  };
  const reverseDateFormat = (date) => {
    if (isHeb) {
      return dayjs(date).format('YYYY/MM/DD');
    } else {
      return dayjs(date).format('YYYY/DD/MM');
    }
  };
  const monthFormat = (date) => {
    if (isHeb) {
      return dayjs(date).format('DD / MM');
    } else {
      return dayjs(date).format('MM / DD');
    }
  };
  return { dateFormat, monthFormat, reverseDateFormat };
};

export default useDateFormat;
