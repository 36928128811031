import media, {
  screenSizes,
  maxHeightMedia,
  minWidthMedia,
  screenHeights
} from 'common_repo/styles/media';

const theme = {
  colors: {
    darkGray: '#acbcd4',
    gray: '#d0d9e7',
    ash: '#dbe2ee',
    lightGray: '#D9D9D9',
    snowWhite: '#fafafa',
    white: '#ffffff',
    red: '#ff563f',
    orange: '#FF9A05',
    black: '#323b4b',
    green: '#26acb9',
    darkGreen: '#22a3c3',
    errorRed: '#E44E61'
  },
  minWidth: {
    ...minWidthMedia
  },
  screenSizes,
  screenHeights,
  ...maxHeightMedia,
  ...media
};

export default theme;
