import React, { useMemo, useRef, useState } from 'react';
import { getUserSettingsByUserQuery, updateUserSettingMutation } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { Typography, Switch, Button, message } from 'antd';

import insertTextAtCursor from 'insert-text-at-cursor';

import {
  clientCommunicationConstants,
  preferenceTitlesAndDescriptions,
  reminderKeys
} from 'constants/preferences';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import Modal from 'components/common/modal/Modal';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const PreferenceNotification = ({
  description,
  uid,
  itemValue,
  isFirstChild,
  handleUpdateUserSetting,
  reminder
}) => {
  const [updateUserSetting] = useMutation(updateUserSettingMutation, {
    refetchQueries: [{ query: getUserSettingsByUserQuery }]
  });

  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();

  const textareaRef = useRef(null);

  const [localValue, setLocalValue] = useState(itemValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminderText, setReminderText] = useState(
    reminder?.itemValue || reminder?.defaultValue || ''
  );

  const closeModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const openModal = () => setIsModalOpen(true);

  const saveChanges = (e) => {
    closeModal(e);
    handleUpdateUserSetting(reminder?.uid, reminderText, reminder?.description);
  };

  const handleUpdateSwitch = async (checked, e) => {
    e?.stopPropagation();
    setLocalValue(String(checked));
    try {
      await updateUserSetting({
        variables: { record: { uid, itemValue: String(checked) } },
        onError: (error) => {
          setLocalValue(itemValue);
          message.error(error.message);
        },
        onCompleted: () => {
          message.success(t('updated.successfully'));
        }
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const addKeyToText = (key) => {
    insertTextAtCursor(textareaRef.current, key + ' ');
    setReminderText(textareaRef.current?.value);
  };

  const titleComponent = useMemo(() => {
    return (
      <Title level={3} className="settings-text notification-title">
        {preferenceTitlesAndDescriptions[description]?.title}
      </Title>
    );
  }, [description]);

  const subTitleComponent = useMemo(() => {
    return (
      <Text className="settings-text notification-description">
        {preferenceTitlesAndDescriptions[description]?.description}
      </Text>
    );
  }, [description]);

  return (
    <div
      className={`notification-wrapper ${isFirstChild ? 'first-child' : ''} hoverable`}
      onClick={openModal}>
      <div className="notification-content cursor-pointer">
        {titleComponent}
        {subTitleComponent}
      </div>
      {description !== clientCommunicationConstants.ENABLE_SMS_WELCOME && (
        <Switch checked={localValue === 'true'} onChange={handleUpdateSwitch} />
      )}
      {isModalOpen && reminder && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLaptop
          isMobile={isMobile}
          autoHeight
          scrollable>
          <div className="modal-wrapper notification-text-wrapper">
            <div className="modal-titles-wrapper">
              {titleComponent}
              {subTitleComponent}
            </div>
            <textarea
              rows={8}
              className="notification-text-area"
              ref={textareaRef}
              onChange={(e) => setReminderText(e.target.value)}>
              {reminderText}
            </textarea>
            <Text level={3} className="optional-parameters-title">
              {t('preferences.optional_parameters')}
            </Text>
            <div className="keys-wrapper">
              {reminderKeys[reminder?.description]?.map((key, i) => (
                <Button key={i} className="key-btn" onClick={() => addKeyToText(key)}>
                  {key}
                </Button>
              ))}
            </div>

            <div className="modal-footer">
              <Button type="default" className="btn-default" onClick={closeModal}>
                {t('preferences.cancel')}
              </Button>
              <Button type="primary" className="btn-primary" onClick={saveChanges}>
                {t('preferences.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PreferenceNotification;
