import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 41.3rem;
  height: 31.6rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MessageImage = styled.img`
  margin-bottom: 2.8rem;
  margin-top: -0.6rem;
`;

export const Caption = styled.p`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  color: #2d3363;
  margin-bottom: 1.2rem;
`;

export const SubCaption = styled.p`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #9094ac;
`;

export const Exit = styled.img`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  ${({ theme }) => theme.mobile`
    margin-left: 27rem;
  `}
`;
