import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';

import { getPetsByOwnerQuery, removePetMutation } from 'gql';

const UseMyPets = () => {
  const history = useHistory();
  const [petToRemove, setPetToRemove] = useState(null);

  const { data, loading } = useQuery(getPetsByOwnerQuery);
  const [removePet] = useMutation(removePetMutation, {
    refetchQueries: [{ query: getPetsByOwnerQuery }]
  });

  const { petFormOpen, petId } = qs.parse(history.location.search.substr(1));

  const handleRemovePet = async (uid) => {
    try {
      await removePet({
        variables: { record: { uid } }
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleEdit = (uid) => {
    history.replace({ ...history, search: `petFormOpen=1&petId=${uid}` });
  };

  const handleCloseModal = () => {
    history.replace({ ...history, search: '' });
  };

  return {
    data,
    loading,
    handleRemovePet,
    handleEdit,
    history,
    petFormOpen,
    petId,
    handleCloseModal,
    petToRemove,
    setPetToRemove
  };
};

export default UseMyPets;
