import React, { useRef } from 'react';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

import 'swiper/swiper.min.css';

import * as S from './components';

import { IconWrapper } from '../go-back/components';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { useLocalizationContext } from 'common_repo';

SwiperCore.use([Navigation]);

const Swiper = ({ children }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { rtl: isRtl } = useLocalizationContext();

  return (
    <S.Wrapper>
      <S.Prev ref={prevRef}>
        <IconWrapper rtl={isRtl}>
          <ArrowLeft />
        </IconWrapper>
      </S.Prev>
      <S.Next ref={nextRef} className="swiper-button-disabled">
        <IconWrapper rtl={!isRtl}>
          <ArrowLeft />
        </IconWrapper>
      </S.Next>
      <ReactSwiper
        spaceBetween={10}
        slidesPerView="auto"
        navigation={{
          prevEl: prevRef.current ? prevRef.current : undefined,
          nextEl: nextRef.current ? nextRef.current : undefined
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.update();
        }}>
        {children.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </ReactSwiper>
    </S.Wrapper>
  );
};

export default Swiper;
