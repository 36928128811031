import React from 'react';
import { useTranslation } from 'react-i18next';

import CalendarImg from 'assets/images/calendar-colored.svg';

import * as S from './components';

const NoMeetings = () => {
  const { t } = useTranslation();

  return (
    <S.TimelineEmptyState>
      <S.CalendarIcon src={CalendarImg} />
      <S.NoMeetingsYetText>{t('no.meetings.yet')}</S.NoMeetingsYetText>
      <S.ChooseSubscriptionText>
        {t('choose.subscription.plan.or.schedule.a.visit')}
      </S.ChooseSubscriptionText>
    </S.TimelineEmptyState>
  );
};

export default NoMeetings;
