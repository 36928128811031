import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  height: 1.2rem;
  margin-right: 0.8rem;
  display: inline-flex;
  ${({ rtl }) =>
    rtl &&
    css`
      transform: rotate(180deg);
    `}
  > svg {
    fill: #323b4b;
  }
  ${({ theme }) => theme.mobile`
      width: 0.6rem;
      height: 1rem;
    > svg {
      width: 0.6rem;
      height: 1rem;
    }
    `}
`;

export const Label = styled.h4`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.6;
  opacity: 0.6;
  margin-bottom: 0;

  ${({ theme }) => theme.mobile`
    font-size: 1.4rem;
    opacity: 1;

  `}
`;
