import { useQuery, useMutation } from '@apollo/client';
import {
  getAppointmentRoutesQuery,
  getPetsByOwnerQuery,
  getTimeQuery,
  rescheduleAppointmentsMutation
} from 'gql';

const useTimeline = (year, petIds) => {
  const pets = useQuery(getPetsByOwnerQuery, {
    fetchPolicy: 'no-cache'
  });

  const time = useQuery(getTimeQuery, {
    fetchPolicy: 'no-cache'
  });

  const { data, loading, refetch } = useQuery(getAppointmentRoutesQuery, {
    fetchPolicy: 'network-only',
    variables: {
      record: {
        date: year || 0,
        petIds: !!petIds ? petIds.map((p) => p.uid) : []
      }
    }
  });

  const submitHandler = async (timeSlotId, appDate, appointmentRouteId) => {
    const date = `${appDate.getFullYear()}-${appDate.getMonth() + 1}-${appDate.getDate()}`;
    try {
      await rescheduleAppointment({
        variables: {
          record: {
            timeSlotId,
            appointmentDate: date,
            appointmentRouteId
          }
        }
      });
      refetch();
    } catch (err) {
      throw err;
    }
  };

  const [rescheduleAppointment] = useMutation(rescheduleAppointmentsMutation);

  return {
    timeline: data?.getAppointmentRoutes && !loading ? data.getAppointmentRoutes : [],
    pets: pets?.data?.getPetsByOwner ? pets.data.getPetsByOwner : [],
    loading,
    time: time?.getTime || 14,
    submitHandler
  };
};

export default useTimeline;
