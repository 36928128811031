import { useQuery, gql } from '@apollo/client';

export const useTimeslotsByVets = (filters) => {
  const { data: { getTimeSlots } = {}, ...rest } = useQuery(queryGetTimeSlots, {
    variables: { record: filters },
    fetchPolicy: 'no-cache',
    skip: !filters.vetIds?.length
  });

  return { data: getTimeSlots, ...rest };
};

export const queryGetTimeSlots = gql`
  query query($record: GetTimeSlotsRecord!) {
    getTimeSlots(record: $record) {
      uid
      startTime
      endTime
      date
      weekDay
      rule
      maxAppointments
      name
      type
      isSingle
      isLocked
      maxAppointments
      routes {
        uid
        status
        appointmentRoutes {
          uid
          duration
        }
      }
      vet {
        uid
        user {
          uid
          firstName
          lastName
          avatarUrl
          userSettingStartPoint
          userSettings {
            uid
            itemValue
            setting {
              description
            }
          }
          address {
            lat
            lng
            placeId
            zipCode
            placeId
            uid
          }
        }
      }
      workingAreas {
        type
        uid
        name
        zipCodes {
          uid
          zip
        }
        polygons {
          name
          uid
          area
        }
        address {
          zipCode
          city
          lat
          lng
          country {
            name
            code
          }
        }
      }
      appointments {
        appointmentRouteId
        appointmentIntegrations {
          uid
          status
          errorDetails
          integration {
            status
          }
        }
      }
      updatedAt
    }
  }
`;
