import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { useFormik } from 'formik';
import style from './SignUpFormVetAdmin.module.css';

import Input from '../common/input/Input';
import * as Yup from 'yup';
import { authAdminMutation } from '../../gql';
import { useMutation } from '@apollo/client';
import AuthContext from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { VET_ADMIN_ROUTES } from '../../constants/client';

const SignInFormVetAdmin = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { setToken } = useContext(AuthContext);
  const [authAdmin] = useMutation(authAdminMutation);
  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
      common: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required()
    }),
    onSubmit: (values, actions) => {
      authAdmin({
        variables: {
          record: {
            email: values.email,
            password: values.password
          }
        }
      })
        .then((data) => {
          setToken(data?.data?.authAdmin?.accessToken);
          push(VET_ADMIN_ROUTES.SCHEDULE);
        })
        .catch((err) => formik.setErrors({ common: err.message }))
        .finally(() => actions.setSubmitting(false));
    }
  });

  useEffect(() => {
    const handleEnter = (e) => {
      if (e?.key === 'Enter') {
        formik.handleSubmit();
      }
    };
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <div className={style.input}>
        <Input
          type="email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
          label={t('form.label.email', 'Email')}
        />
      </div>
      <div className={style.input}>
        <Input
          label={t('form.label.password', 'Password')}
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />
      </div>
      <span className={style.commonError}>{formik.errors.common}</span>
      <div className={style.buttonWrapper}>
        <Button type="submit" color="primary" disabled={formik.isSubmitting}>
          {t('sign.in')}
        </Button>
      </div>
    </form>
  );
};

export default SignInFormVetAdmin;
