import React from 'react';
import { ReactComponent as ac } from 'assets/icons/countries/AC.svg';
import { ReactComponent as ad } from 'assets/icons/countries/ad.svg';
import { ReactComponent as ae } from 'assets/icons/countries/ae.svg';
import { ReactComponent as af } from 'assets/icons/countries/af.svg';
import { ReactComponent as ag } from 'assets/icons/countries/ag.svg';
import { ReactComponent as ai } from 'assets/icons/countries/ai.svg';
import { ReactComponent as al } from 'assets/icons/countries/al.svg';
import { ReactComponent as am } from 'assets/icons/countries/am.svg';
import { ReactComponent as ao } from 'assets/icons/countries/ao.svg';
import { ReactComponent as aq } from 'assets/icons/countries/aq.svg';
import { ReactComponent as ar } from 'assets/icons/countries/ar.svg';
import { ReactComponent as as } from 'assets/icons/countries/as.svg';
import { ReactComponent as at } from 'assets/icons/countries/at.svg';
import { ReactComponent as au } from 'assets/icons/countries/au.svg';
import { ReactComponent as aw } from 'assets/icons/countries/aw.svg';
import { ReactComponent as ax } from 'assets/icons/countries/ax.svg';
import { ReactComponent as az } from 'assets/icons/countries/az.svg';
import { ReactComponent as ba } from 'assets/icons/countries/ba.svg';
import { ReactComponent as bb } from 'assets/icons/countries/bb.svg';
import { ReactComponent as bd } from 'assets/icons/countries/bd.svg';
import { ReactComponent as be } from 'assets/icons/countries/be.svg';
import { ReactComponent as bf } from 'assets/icons/countries/bf.svg';
import { ReactComponent as bg } from 'assets/icons/countries/bg.svg';
import { ReactComponent as bh } from 'assets/icons/countries/bh.svg';
import { ReactComponent as bi } from 'assets/icons/countries/bi.svg';
import { ReactComponent as bj } from 'assets/icons/countries/bj.svg';
import { ReactComponent as bl } from 'assets/icons/countries/bl.svg';
import { ReactComponent as bm } from 'assets/icons/countries/bm.svg';
import { ReactComponent as bn } from 'assets/icons/countries/bn.svg';
import { ReactComponent as bo } from 'assets/icons/countries/bo.svg';
import { ReactComponent as bq } from 'assets/icons/countries/bq.svg';
import { ReactComponent as br } from 'assets/icons/countries/br.svg';
import { ReactComponent as bs } from 'assets/icons/countries/bs.svg';
import { ReactComponent as bt } from 'assets/icons/countries/bt.svg';
import { ReactComponent as bv } from 'assets/icons/countries/bv.svg';
import { ReactComponent as bw } from 'assets/icons/countries/bw.svg';
import { ReactComponent as by } from 'assets/icons/countries/by.svg';
import { ReactComponent as bz } from 'assets/icons/countries/bz.svg';
import { ReactComponent as ca } from 'assets/icons/countries/ca.svg';
import { ReactComponent as cc } from 'assets/icons/countries/cc.svg';
import { ReactComponent as cd } from 'assets/icons/countries/cd.svg';
import { ReactComponent as cf } from 'assets/icons/countries/cf.svg';
import { ReactComponent as cg } from 'assets/icons/countries/cg.svg';
import { ReactComponent as ch } from 'assets/icons/countries/ch.svg';
import { ReactComponent as ci } from 'assets/icons/countries/ci.svg';
import { ReactComponent as ck } from 'assets/icons/countries/ck.svg';
import { ReactComponent as cl } from 'assets/icons/countries/cl.svg';
import { ReactComponent as cm } from 'assets/icons/countries/cm.svg';
import { ReactComponent as cn } from 'assets/icons/countries/cn.svg';
import { ReactComponent as co } from 'assets/icons/countries/co.svg';
import { ReactComponent as cr } from 'assets/icons/countries/cr.svg';
import { ReactComponent as cu } from 'assets/icons/countries/cu.svg';
import { ReactComponent as cv } from 'assets/icons/countries/cv.svg';
import { ReactComponent as cw } from 'assets/icons/countries/cw.svg';
import { ReactComponent as cx } from 'assets/icons/countries/cx.svg';
import { ReactComponent as cy } from 'assets/icons/countries/cy.svg';
import { ReactComponent as cz } from 'assets/icons/countries/cz.svg';
import { ReactComponent as de } from 'assets/icons/countries/de.svg';
import { ReactComponent as dj } from 'assets/icons/countries/dj.svg';
import { ReactComponent as dk } from 'assets/icons/countries/dk.svg';
import { ReactComponent as dm } from 'assets/icons/countries/dm.svg';
import { ReactComponent as doIcon } from 'assets/icons/countries/do.svg';
import { ReactComponent as dz } from 'assets/icons/countries/dz.svg';
import { ReactComponent as ec } from 'assets/icons/countries/ec.svg';
import { ReactComponent as ee } from 'assets/icons/countries/ee.svg';
import { ReactComponent as eg } from 'assets/icons/countries/eg.svg';
import { ReactComponent as eh } from 'assets/icons/countries/eh.svg';
import { ReactComponent as er } from 'assets/icons/countries/er.svg';
import { ReactComponent as es } from 'assets/icons/countries/es.svg';
import { ReactComponent as et } from 'assets/icons/countries/et.svg';
import { ReactComponent as eu } from 'assets/icons/countries/eu.svg';
import { ReactComponent as fi } from 'assets/icons/countries/fi.svg';
import { ReactComponent as fj } from 'assets/icons/countries/fj.svg';
import { ReactComponent as fk } from 'assets/icons/countries/fk.svg';
import { ReactComponent as fm } from 'assets/icons/countries/fm.svg';
import { ReactComponent as fo } from 'assets/icons/countries/fo.svg';
import { ReactComponent as fr } from 'assets/icons/countries/fr.svg';
import { ReactComponent as ga } from 'assets/icons/countries/ga.svg';
import { ReactComponent as gb } from 'assets/icons/countries/gb.svg';
import { ReactComponent as gd } from 'assets/icons/countries/gd.svg';
import { ReactComponent as ge } from 'assets/icons/countries/ge.svg';
import { ReactComponent as gf } from 'assets/icons/countries/gf.svg';
import { ReactComponent as gg } from 'assets/icons/countries/gg.svg';
import { ReactComponent as gh } from 'assets/icons/countries/gh.svg';
import { ReactComponent as gi } from 'assets/icons/countries/gi.svg';
import { ReactComponent as gl } from 'assets/icons/countries/gl.svg';
import { ReactComponent as gm } from 'assets/icons/countries/gm.svg';
import { ReactComponent as gn } from 'assets/icons/countries/gn.svg';
import { ReactComponent as gp } from 'assets/icons/countries/gp.svg';
import { ReactComponent as gq } from 'assets/icons/countries/gq.svg';
import { ReactComponent as gr } from 'assets/icons/countries/gr.svg';
import { ReactComponent as gs } from 'assets/icons/countries/gs.svg';
import { ReactComponent as gt } from 'assets/icons/countries/gt.svg';
import { ReactComponent as gu } from 'assets/icons/countries/gu.svg';
import { ReactComponent as gw } from 'assets/icons/countries/gw.svg';
import { ReactComponent as gy } from 'assets/icons/countries/gy.svg';
import { ReactComponent as hk } from 'assets/icons/countries/hk.svg';
import { ReactComponent as hm } from 'assets/icons/countries/hm.svg';
import { ReactComponent as hn } from 'assets/icons/countries/hn.svg';
import { ReactComponent as hr } from 'assets/icons/countries/hr.svg';
import { ReactComponent as ht } from 'assets/icons/countries/ht.svg';
import { ReactComponent as hu } from 'assets/icons/countries/hu.svg';
import { ReactComponent as IC } from 'assets/icons/countries/IC.svg';
import { ReactComponent as id } from 'assets/icons/countries/id.svg';
import { ReactComponent as ie } from 'assets/icons/countries/ie.svg';
import { ReactComponent as il } from 'assets/icons/countries/il.svg';
import { ReactComponent as im } from 'assets/icons/countries/im.svg';
import { ReactComponent as inIcon } from 'assets/icons/countries/in.svg';
import { ReactComponent as io } from 'assets/icons/countries/io.svg';
import { ReactComponent as iq } from 'assets/icons/countries/iq.svg';
import { ReactComponent as ir } from 'assets/icons/countries/ir.svg';
import { ReactComponent as is } from 'assets/icons/countries/is.svg';
import { ReactComponent as it } from 'assets/icons/countries/it.svg';
import { ReactComponent as je } from 'assets/icons/countries/je.svg';
import { ReactComponent as jm } from 'assets/icons/countries/jm.svg';
import { ReactComponent as jo } from 'assets/icons/countries/jo.svg';
import { ReactComponent as jp } from 'assets/icons/countries/jp.svg';
import { ReactComponent as ke } from 'assets/icons/countries/ke.svg';
import { ReactComponent as kg } from 'assets/icons/countries/kg.svg';
import { ReactComponent as kh } from 'assets/icons/countries/kh.svg';
import { ReactComponent as ki } from 'assets/icons/countries/ki.svg';
import { ReactComponent as km } from 'assets/icons/countries/km.svg';
import { ReactComponent as kn } from 'assets/icons/countries/kn.svg';
import { ReactComponent as kp } from 'assets/icons/countries/kp.svg';
import { ReactComponent as kr } from 'assets/icons/countries/kr.svg';
import { ReactComponent as kw } from 'assets/icons/countries/kw.svg';
import { ReactComponent as ky } from 'assets/icons/countries/ky.svg';
import { ReactComponent as kz } from 'assets/icons/countries/kz.svg';
import { ReactComponent as la } from 'assets/icons/countries/la.svg';
import { ReactComponent as lb } from 'assets/icons/countries/lb.svg';
import { ReactComponent as lc } from 'assets/icons/countries/lc.svg';
import { ReactComponent as li } from 'assets/icons/countries/li.svg';
import { ReactComponent as lk } from 'assets/icons/countries/lk.svg';
import { ReactComponent as lr } from 'assets/icons/countries/lr.svg';
import { ReactComponent as ls } from 'assets/icons/countries/ls.svg';
import { ReactComponent as lt } from 'assets/icons/countries/lt.svg';
import { ReactComponent as lu } from 'assets/icons/countries/lu.svg';
import { ReactComponent as lv } from 'assets/icons/countries/lv.svg';
import { ReactComponent as ly } from 'assets/icons/countries/ly.svg';
import { ReactComponent as ma } from 'assets/icons/countries/ma.svg';
import { ReactComponent as mc } from 'assets/icons/countries/mc.svg';
import { ReactComponent as md } from 'assets/icons/countries/md.svg';
import { ReactComponent as me } from 'assets/icons/countries/me.svg';
import { ReactComponent as mf } from 'assets/icons/countries/mf.svg';
import { ReactComponent as mg } from 'assets/icons/countries/mg.svg';
import { ReactComponent as mh } from 'assets/icons/countries/mh.svg';
import { ReactComponent as mk } from 'assets/icons/countries/mk.svg';
import { ReactComponent as ml } from 'assets/icons/countries/ml.svg';
import { ReactComponent as mm } from 'assets/icons/countries/mm.svg';
import { ReactComponent as mn } from 'assets/icons/countries/mn.svg';
import { ReactComponent as mo } from 'assets/icons/countries/mo.svg';
import { ReactComponent as mp } from 'assets/icons/countries/mp.svg';
import { ReactComponent as mq } from 'assets/icons/countries/mq.svg';
import { ReactComponent as mr } from 'assets/icons/countries/mr.svg';
import { ReactComponent as ms } from 'assets/icons/countries/ms.svg';
import { ReactComponent as mt } from 'assets/icons/countries/mt.svg';
import { ReactComponent as mu } from 'assets/icons/countries/mu.svg';
import { ReactComponent as mv } from 'assets/icons/countries/mv.svg';
import { ReactComponent as mw } from 'assets/icons/countries/mw.svg';
import { ReactComponent as mx } from 'assets/icons/countries/mx.svg';
import { ReactComponent as my } from 'assets/icons/countries/my.svg';
import { ReactComponent as mz } from 'assets/icons/countries/mz.svg';
import { ReactComponent as na } from 'assets/icons/countries/na.svg';
import { ReactComponent as nc } from 'assets/icons/countries/nc.svg';
import { ReactComponent as ne } from 'assets/icons/countries/ne.svg';
import { ReactComponent as nf } from 'assets/icons/countries/nf.svg';
import { ReactComponent as ng } from 'assets/icons/countries/ng.svg';
import { ReactComponent as ni } from 'assets/icons/countries/ni.svg';
import { ReactComponent as nl } from 'assets/icons/countries/nl.svg';
import { ReactComponent as no } from 'assets/icons/countries/no.svg';
import { ReactComponent as np } from 'assets/icons/countries/np.svg';
import { ReactComponent as nr } from 'assets/icons/countries/nr.svg';
import { ReactComponent as nu } from 'assets/icons/countries/nu.svg';
import { ReactComponent as nz } from 'assets/icons/countries/nz.svg';
import { ReactComponent as om } from 'assets/icons/countries/om.svg';
import { ReactComponent as pa } from 'assets/icons/countries/pa.svg';
import { ReactComponent as pe } from 'assets/icons/countries/pe.svg';
import { ReactComponent as pf } from 'assets/icons/countries/pf.svg';
import { ReactComponent as pg } from 'assets/icons/countries/pg.svg';
import { ReactComponent as ph } from 'assets/icons/countries/ph.svg';
import { ReactComponent as pk } from 'assets/icons/countries/pk.svg';
import { ReactComponent as pl } from 'assets/icons/countries/pl.svg';
import { ReactComponent as pm } from 'assets/icons/countries/pm.svg';
import { ReactComponent as pn } from 'assets/icons/countries/pn.svg';
import { ReactComponent as pr } from 'assets/icons/countries/pr.svg';
import { ReactComponent as ps } from 'assets/icons/countries/ps.svg';
import { ReactComponent as pt } from 'assets/icons/countries/pt.svg';
import { ReactComponent as pw } from 'assets/icons/countries/pw.svg';
import { ReactComponent as py } from 'assets/icons/countries/py.svg';
import { ReactComponent as qa } from 'assets/icons/countries/qa.svg';
import { ReactComponent as re } from 'assets/icons/countries/re.svg';
import { ReactComponent as ro } from 'assets/icons/countries/ro.svg';
import { ReactComponent as rs } from 'assets/icons/countries/rs.svg';
import { ReactComponent as ru } from 'assets/icons/countries/ru.svg';
import { ReactComponent as rw } from 'assets/icons/countries/rw.svg';
import { ReactComponent as sa } from 'assets/icons/countries/sa.svg';
import { ReactComponent as sb } from 'assets/icons/countries/sb.svg';
import { ReactComponent as sc } from 'assets/icons/countries/sc.svg';
import { ReactComponent as sd } from 'assets/icons/countries/sd.svg';
import { ReactComponent as se } from 'assets/icons/countries/se.svg';
import { ReactComponent as sg } from 'assets/icons/countries/sg.svg';
import { ReactComponent as sh } from 'assets/icons/countries/sh.svg';
import { ReactComponent as si } from 'assets/icons/countries/si.svg';
import { ReactComponent as sj } from 'assets/icons/countries/sj.svg';
import { ReactComponent as sk } from 'assets/icons/countries/sk.svg';
import { ReactComponent as sl } from 'assets/icons/countries/sl.svg';
import { ReactComponent as sm } from 'assets/icons/countries/sm.svg';
import { ReactComponent as sn } from 'assets/icons/countries/sn.svg';
import { ReactComponent as so } from 'assets/icons/countries/so.svg';
import { ReactComponent as sr } from 'assets/icons/countries/sr.svg';
import { ReactComponent as ss } from 'assets/icons/countries/ss.svg';
import { ReactComponent as st } from 'assets/icons/countries/st.svg';
import { ReactComponent as sv } from 'assets/icons/countries/sv.svg';
import { ReactComponent as sx } from 'assets/icons/countries/sx.svg';
import { ReactComponent as sy } from 'assets/icons/countries/sy.svg';
import { ReactComponent as sz } from 'assets/icons/countries/sz.svg';
import { ReactComponent as TA } from 'assets/icons/countries/TA.svg';
import { ReactComponent as tc } from 'assets/icons/countries/tc.svg';
import { ReactComponent as td } from 'assets/icons/countries/td.svg';
import { ReactComponent as tf } from 'assets/icons/countries/tf.svg';
import { ReactComponent as tg } from 'assets/icons/countries/tg.svg';
import { ReactComponent as th } from 'assets/icons/countries/th.svg';
import { ReactComponent as tj } from 'assets/icons/countries/tj.svg';
import { ReactComponent as tk } from 'assets/icons/countries/tk.svg';
import { ReactComponent as tl } from 'assets/icons/countries/tl.svg';
import { ReactComponent as tm } from 'assets/icons/countries/tm.svg';
import { ReactComponent as tn } from 'assets/icons/countries/tn.svg';
import { ReactComponent as to } from 'assets/icons/countries/to.svg';
import { ReactComponent as tr } from 'assets/icons/countries/tr.svg';
import { ReactComponent as tt } from 'assets/icons/countries/tt.svg';
import { ReactComponent as tv } from 'assets/icons/countries/tv.svg';
import { ReactComponent as tw } from 'assets/icons/countries/tw.svg';
import { ReactComponent as tz } from 'assets/icons/countries/tz.svg';
import { ReactComponent as ua } from 'assets/icons/countries/ua.svg';
import { ReactComponent as ug } from 'assets/icons/countries/ug.svg';
import { ReactComponent as um } from 'assets/icons/countries/um.svg';
import { ReactComponent as us } from 'assets/icons/countries/us.svg';
import { ReactComponent as uy } from 'assets/icons/countries/uy.svg';
import { ReactComponent as uz } from 'assets/icons/countries/uz.svg';
import { ReactComponent as va } from 'assets/icons/countries/va.svg';
import { ReactComponent as vc } from 'assets/icons/countries/vc.svg';
import { ReactComponent as ve } from 'assets/icons/countries/ve.svg';
import { ReactComponent as vg } from 'assets/icons/countries/vg.svg';
import { ReactComponent as vi } from 'assets/icons/countries/vi.svg';
import { ReactComponent as vn } from 'assets/icons/countries/vn.svg';
import { ReactComponent as vu } from 'assets/icons/countries/vu.svg';
import { ReactComponent as wf } from 'assets/icons/countries/wf.svg';
import { ReactComponent as ws } from 'assets/icons/countries/ws.svg';
import { ReactComponent as xk } from 'assets/icons/countries/xk.svg';
import { ReactComponent as ye } from 'assets/icons/countries/ye.svg';
import { ReactComponent as yt } from 'assets/icons/countries/yt.svg';
import { ReactComponent as za } from 'assets/icons/countries/za.svg';
import { ReactComponent as zm } from 'assets/icons/countries/zm.svg';
import { ReactComponent as zw } from 'assets/icons/countries/zw.svg';

const map = {
  ac: ac,
  ad: ad,
  ae: ae,
  af: af,
  ag: ag,
  ai: ai,
  al: al,
  am: am,
  ao: ao,
  aq: aq,
  ar: ar,
  as: as,
  at: at,
  au: au,
  aw: aw,
  ax: ax,
  az: az,
  ba: ba,
  bb: bb,
  bd: bd,
  be: be,
  bf: bf,
  bg: bg,
  bh: bh,
  bi: bi,
  bj: bj,
  bl: bl,
  bm: bm,
  bn: bn,
  bo: bo,
  bq: bq,
  br: br,
  bs: bs,
  bt: bt,
  bv: bv,
  bw: bw,
  by: by,
  bz: bz,
  ca: ca,
  cc: cc,
  cd: cd,
  cf: cf,
  cg: cg,
  ch: ch,
  ci: ci,
  ck: ck,
  cl: cl,
  cm: cm,
  cn: cn,
  co: co,
  cr: cr,
  cu: cu,
  cv: cv,
  cw: cw,
  cx: cx,
  cy: cy,
  cz: cz,
  de: de,
  dj: dj,
  dk: dk,
  dm: dm,
  do: doIcon,
  dz: dz,
  ec: ec,
  ee: ee,
  eg: eg,
  eh: eh,
  er: er,
  es: es,
  et: et,
  eu: eu,
  fi: fi,
  fj: fj,
  fk: fk,
  fm: fm,
  fo: fo,
  fr: fr,
  ga: ga,
  gb: gb,
  gd: gd,
  ge: ge,
  gf: gf,
  gg: gg,
  gh: gh,
  gi: gi,
  gl: gl,
  gm: gm,
  gn: gn,
  gp: gp,
  gq: gq,
  gr: gr,
  gs: gs,
  gt: gt,
  gu: gu,
  gw: gw,
  gy: gy,
  hk: hk,
  hm: hm,
  hn: hn,
  hr: hr,
  ht: ht,
  hu: hu,
  IC: IC,
  id: id,
  ie: ie,
  il: il,
  im: im,
  in: inIcon,
  io: io,
  iq: iq,
  ir: ir,
  is: is,
  it: it,
  je: je,
  jm: jm,
  jo: jo,
  jp: jp,
  ke: ke,
  kg: kg,
  kh: kh,
  ki: ki,
  km: km,
  kn: kn,
  kp: kp,
  kr: kr,
  kw: kw,
  ky: ky,
  kz: kz,
  la: la,
  lb: lb,
  lc: lc,
  li: li,
  lk: lk,
  lr: lr,
  ls: ls,
  lt: lt,
  lu: lu,
  lv: lv,
  ly: ly,
  ma: ma,
  mc: mc,
  md: md,
  me: me,
  mf: mf,
  mg: mg,
  mh: mh,
  mk: mk,
  ml: ml,
  mm: mm,
  mn: mn,
  mo: mo,
  mp: mp,
  mq: mq,
  mr: mr,
  ms: ms,
  mt: mt,
  mu: mu,
  mv: mv,
  mw: mw,
  mx: mx,
  my: my,
  mz: mz,
  na: na,
  nc: nc,
  ne: ne,
  nf: nf,
  ng: ng,
  ni: ni,
  nl: nl,
  no: no,
  np: np,
  nr: nr,
  nu: nu,
  nz: nz,
  om: om,
  pa: pa,
  pe: pe,
  pf: pf,
  pg: pg,
  ph: ph,
  pk: pk,
  pl: pl,
  pm: pm,
  pn: pn,
  pr: pr,
  ps: ps,
  pt: pt,
  pw: pw,
  py: py,
  qa: qa,
  re: re,
  ro: ro,
  rs: rs,
  ru: ru,
  rw: rw,
  sa: sa,
  sb: sb,
  sc: sc,
  sd: sd,
  se: se,
  sg: sg,
  sh: sh,
  si: si,
  sj: sj,
  sk: sk,
  sl: sl,
  sm: sm,
  sn: sn,
  so: so,
  sr: sr,
  ss: ss,
  st: st,
  sv: sv,
  sx: sx,
  sy: sy,
  sz: sz,
  TA: TA,
  tc: tc,
  td: td,
  tf: tf,
  tg: tg,
  th: th,
  tj: tj,
  tk: tk,
  tl: tl,
  tm: tm,
  tn: tn,
  to: to,
  tr: tr,
  tt: tt,
  tv: tv,
  tw: tw,
  tz: tz,
  ua: ua,
  ug: ug,
  um: um,
  us: us,
  uy: uy,
  uz: uz,
  va: va,
  vc: vc,
  ve: ve,
  vg: vg,
  vi: vi,
  vn: vn,
  vu: vu,
  wf: wf,
  ws: ws,
  xk: xk,
  ye: ye,
  yt: yt,
  za: za,
  zm: zm,
  zw: zw
};

const CountryIcon = ({ countryCode, ...rest }) => {
  const Icon = map[countryCode.toLowerCase()];
  if (!Icon) return null;
  return <Icon {...rest} width={16} height={16} />;
};

export default CountryIcon;
