import React from 'react';
import { Wrapper, TextareaComponent, Label } from './components';
import { ErrorMessage } from '../error-message/components';

const TextArea = ({ value, label, error, maxLength = '300', ...props }) => {
  return (
    <Wrapper>
      <TextareaComponent value={value} {...props} error={error} maxLength={maxLength} />
      <Label value={value} error={error}>
        {label}
      </Label>
      <ErrorMessage>{error}</ErrorMessage>
    </Wrapper>
  );
};

export default TextArea;
