import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { getCurrencyIcon } from 'utils/helpers';
import { ReactComponent as Paw } from 'assets/icons/paw.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon-gray.svg';

import theme from 'styles/theme';
import * as S from './components';

const PlanCard = ({ chooseMembership, isSelected, plan }) => {
  const { t } = useTranslation();
  return (
    <S.Plan isSelected={isSelected} onClick={() => chooseMembership(plan.uid)}>
      <S.PlanHeader isSelected={isSelected}>
        <S.PlanTiTle isSelected={isSelected}>{plan.title}</S.PlanTiTle>
        <S.ChoosePlan isSelected={isSelected}>{isSelected && <S.Circle />}</S.ChoosePlan>
      </S.PlanHeader>
      <S.Info>
        {plan.features?.map((feat) => (
          <S.Feature key={feat}>
            <Paw />
            <S.FeatureTitle isSelected={isSelected}>{feat}</S.FeatureTitle>
          </S.Feature>
        ))}
      </S.Info>

      <S.OneTimeText isVisible={!!plan?.additionalFee}>
        {`+${getCurrencyIcon(plan?.currency)}${plan?.additionalFee}`}
        <S.FontNormal> {t('one.time.payment')} </S.FontNormal>
        <S.ToolTopWrapper>
          <a data-tip data-for="infoText">
            <InfoIcon fill="#8A94A6" />
          </a>
          <ReactTooltip
            id="infoText"
            type="warning"
            backgroundColor={theme.colors.black}
            multiline
            textColor={theme.colors.white}
            effect="solid">
            {plan?.name === 'kitten' || plan?.name === 'cat' ? (
              <span>{t('kittens.need.procedures')}</span>
            ) : (
              <span>{t('puppies.need.procedures')}</span>
            )}
            <br />
            <br />
            <span>{t('additional.payment.info')}</span>
          </ReactTooltip>
        </S.ToolTopWrapper>
      </S.OneTimeText>

      <S.Price>
        {getCurrencyIcon(plan?.currency)}
        {plan.price}
        <S.FontNormal small>/{t('per.month')}</S.FontNormal>
      </S.Price>
    </S.Plan>
  );
};

export default PlanCard;
