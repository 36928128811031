import React, { useEffect, useState } from 'react';
// import css from './CreateClient.module.css';
// import {SelectClinicVet} from '_fsd/features/admin-select-vet';
import { CreateClient as CreateClientForVet } from '_fsd/features/create-client-for-vet';
import { useClinicVets } from '_fsd/entities/vet-admin';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

export const CreateClient = ({ onSubmit }) => {
  const { data, loading } = useClinicVets();
  const [vet, setVet] = useState();

  useEffect(() => {
    if (data && data[0]) {
      setVet(data[0]);
    }
  }, [data]);

  return (
    <LoaderWrapper isLoading={loading}>
      {/* <div className={css.header}>*/}
      {/*  <span>* New client will be created for: </span>*/}
      {/*  <SelectClinicVet value={vet} onSelect={setVet} vets={data || []}/>*/}
      {/* </div>*/}
      <CreateClientForVet onSubmit={onSubmit} vetId={vet?.uid} />
    </LoaderWrapper>
  );
};
