import PetsHeart from 'assets/images/pets-heart.jpg';
import AddPetBackground from 'assets/images/add-background.jpg';
import LogInBackground from 'assets/images/log_In_background.jpg';
import MobPetsHeart from 'assets/images/mob-heart.png';
import MobAddPetBackground from 'assets/images/mob-add-background.png';
import MobLogInBackground from 'assets/images/mob-log_In-background.png';

import theme from 'styles/theme';
import { useMediaQuery } from 'react-responsive';

const useOnboardingLayout = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  return [
    {
      imgUrl: isMobile ? MobLogInBackground : LogInBackground
    },
    {
      imgUrl: isMobile ? MobPetsHeart : PetsHeart
    },
    {
      imgUrl: isMobile ? MobAddPetBackground : AddPetBackground
    }
  ];
};

export default useOnboardingLayout;
