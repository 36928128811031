import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 41.3rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2.8rem;

  ${({ theme }) => theme.mobile`
    width: 31.2rem;
  `}
`;

export const MessageImage = styled.img`
  margin-bottom: 2.8rem;
  width: 20rem;
`;

export const Exit = styled.img`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  ${({ theme }) => theme.mobile`
    margin-left: 27rem;
  `}
`;

export const Caption = styled.p`
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.6rem;
  color: #2d3363;
  margin-bottom: 0.8rem;
  text-align: center;
`;

export const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3.2rem;
`;

export const Button = styled.div`
  height: 4.4rem;
  flex: 1 10rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ active }) => (active ? '#E44E61' : '#FDFDFD')};
  color: ${({ active, theme }) => (active ? theme.colors.white : '#36475F')};
`;
