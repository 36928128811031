import styled from 'styled-components';
import bulletPoint from 'assets/icons/bullet-point-blue.svg';

export const Recommendations = styled.div`
  margin-bottom: 2rem;
`;

export const Heading = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.darkGreen};
  font-weight: 400;
  cursor: pointer;
`;

export const DropdownImg = styled.img`
  margin-left: 0.5rem;
  transition: all 0.3s ease-in-out;
  user-select: none;

  ${({ isRecommendationsOpen }) => isRecommendationsOpen && `transform: rotate(180deg);`}
`;

export const UL = styled.ul`
  list-style-image: url(${bulletPoint});
  padding-top: 1rem;
  max-height: 100rem;
  transition: 0.3s ease-in-out;

  ${({ isRecommendationsOpen }) => !isRecommendationsOpen && `max-height: 0rem; opacity: 0;`};
`;

export const SingleReccomendation = styled.li`
  font-size: 1.2rem;
  color: #000000d9;
  font-weight: 400;
  margin-left: 1.3rem;
`;
