import React from 'react';
import css from './AddressInput.module.css';
import AddressAutocomplete from '../../../pages/vet-schedule/ui/outdate/modals/edit-client-address-modal/address-autocomplete/AddressAutocomplete';

export const AddressInput = (props) => {
  const { onChange, onSelect, address, addressError, isForVet } = props;
  return (
    <AddressAutocomplete
      onChange={onChange}
      onSelect={onSelect}
      address={address}
      isForZipCode
      isForCalendar
      error={address && !!Object.keys(address).length && addressError}
      isForCompleteProfile={isForVet}
    />
  );
};
