import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import theme from 'styles/theme';
import { Row, Col } from 'antd';

import Input from 'components/vet-components/vet-web-inputs/input/Input';
import Select from 'components/vet-components/vet-web-inputs/select-input/Select';
import DatePicker from 'components/vet-components/vet-web-inputs/date-picker/DatePicker';
import VetPhoneInput from 'components/vet-components/phone-input/VetPhoneInput';
import UploadImage from 'components/vet-components/vet-web-inputs/upload/UploadImage';

import { GenderOptions } from 'constants/enums';
import dropdownIcon from 'assets/icons/vet-profile-dropdown.svg';

import * as S from './components';

const VetPersonalInfo = ({ form, formInitialValues, vetImageUrl, setVetImageUrl }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`
  });
  const rowStyles = { gutter: 24 };
  const columnStyles = { xs: 24, lg: 8 };

  useEffect(() => {
    setIsDropdownOpen(true);
  }, [isTablet]);

  return (
    <S.Wrapper isDropdownOpen={isDropdownOpen}>
      <S.Header>
        <S.Heading>{t('personal.info')}</S.Heading>
        <S.DropdownIcon
          src={dropdownIcon}
          alt="dropdown"
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          isDropdownOpen={isDropdownOpen}
        />
      </S.Header>

      <S.Content isDropdownOpen={isDropdownOpen}>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={12} lg={4}>
            <UploadImage
              form={form}
              name="avatarUrl"
              label={t('profile.picture')}
              formInitialValues={formInitialValues}
              hasVisibilitySign
              imageUrl={vetImageUrl}
              setImageUrl={setVetImageUrl}
            />
          </Col>

          <Col xs={24} lg={20}>
            <Row {...rowStyles}>
              <Col {...columnStyles}>
                <Input
                  name="firstName"
                  label={t('first.name.required')}
                  placeholder={t('firstName')}
                  required
                  hasVisibilitySign
                />
              </Col>
              <Col {...columnStyles}>
                <Input
                  name="lastName"
                  label={t('lastName.required')}
                  placeholder={t('lastName')}
                  hasVisibilitySign
                  required
                />
              </Col>
              <Col {...columnStyles}>
                <Input
                  name="email"
                  label={t('email.required')}
                  placeholder={t('email')}
                  required
                  type="email"
                />
              </Col>
            </Row>

            <Row {...rowStyles}>
              <Col {...columnStyles}>
                <Select
                  name="gender"
                  label={t('gender')}
                  placeholder="Male/Female"
                  options={GenderOptions}
                />
              </Col>
              <Col {...columnStyles}>
                <DatePicker name="birthDate" label={t('birth.date')} />
              </Col>
              <Col {...columnStyles}>
                <VetPhoneInput disabled name="phoneNumberDisplay" form={form} />
              </Col>
            </Row>
          </Col>
        </Row>
      </S.Content>
    </S.Wrapper>
  );
};

export default VetPersonalInfo;
