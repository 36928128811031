import { useMutation } from '@apollo/client';
import { chooseVetForSubscriptionMutation } from 'gql';

const useTimelineContentBox = (id) => {
  const [chooseVet] = useMutation(chooseVetForSubscriptionMutation);

  const submitHandler = async (timeSlotId, appointmentDate) => {
    try {
      await chooseVet({
        variables: {
          record: {
            timeSlotId,
            appointmentDate,
            subscriptionId: id
          }
        }
      });
    } catch (err) {
      throw err;
    }
  };

  return {
    submitHandler
  };
};

export default useTimelineContentBox;
