import React, { useCallback } from 'react';
import CalendarSteps from '../calendar/steps/CalendarSteps';
import { message } from 'antd';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Modal } from '_fsd/shared';
import moment from 'moment';

const NewClientModal = ({
  onRequestClose,
  vet,
  allVets,
  sendRequestCode,
  checkExistingUser,
  selectedWorkingArea,
  goBack,
  setCalendarDate
}) => {
  const { t } = useTranslation();
  const onFinishNewClient = useCallback((timeslotId, date) => {
    setCalendarDate(moment(date));
    onRequestClose();
    message.success(t('admin.recommender.new_appointment.success_message'));
  }, []);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onRequestClose}
        customHeader={() => null}
        customFooter={() => null}>
        <CalendarSteps
          vet={vet}
          allVets={allVets}
          isForVet={true}
          sendRequestCode={sendRequestCode}
          checkExistingUser={checkExistingUser}
          onFinishNewClient={onFinishNewClient}
          selectedWorkingArea={selectedWorkingArea}
          onRequestClose={onRequestClose}
          goBack={goBack}
        />
      </Modal>
    </>
  );
};

export default NewClientModal;
