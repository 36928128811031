import React, { useState, memo, useEffect } from 'react';
import { Form, Row, Col, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

import UploadImage from 'components/vet-components/vet-web-inputs/upload/UploadImage';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import Select from 'components/vet-components/vet-web-inputs/select-input/Select';
import DatePicker from 'components/vet-components/vet-web-inputs/date-picker/DatePicker';
import PetWeightInput from 'components/vet-components/vet-web-inputs/pet-weight-input/PetWeightInput';
import TextArea from 'components/vet-components/vet-web-inputs/text-area/TextArea';
import SubmitButton from 'components/vet-components/vet-web-inputs/submit-button/SubmitBtn';
import SelectTags from 'components/vet-components/vet-web-inputs/select-tags/SelectTags';

import useAddPetModal from './useAddPetModal';
import { GenderOptions, YesOrNoOptions } from 'constants/enums';
import arrow from 'assets/icons/arrow-down.svg';

import './styles.css';
import css from './PetModal.module.css';
import { cssText } from '_fsd/shared';
import { Modal } from '_fsd/shared';

import { ExclamationCircleFilled, EditOutlined } from '@ant-design/icons';
import { EditExternalIdWarningModal } from '_fsd/features/update-pet-external-id';
import { EditExternalIdModal } from '_fsd/features/update-pet-external-id';

import {
  INTEGRATION_ENTITY_LINK_ACTION,
  isAnyEntityIntegrationError,
  isEntityIntegrationError
} from '_fsd/entities';
import { isVetAdminHasIntegrations } from '_fsd/entities/integration';
import { PetIntegrationLink } from '_fsd/entities/pet-integration/ui/PetIntegrationIcon';

const PetModal = ({
  isPetModalOpen,
  closeModal,
  petIdToUpdate,
  customerUserUid,
  petIntegrations
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { petTypes, handleSubmit, petFormInitialValues, petIntegration } = useAddPetModal(
    petIdToUpdate,
    closeModal
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [petImageUrl, setPetImageUrl] = useState('');
  const [isExternalIdWarningModalOpen, setIsExternalIdWarningModalOpen] = useState(false);
  const [isEditExternalIdModalOpen, setIsEditExternalIdModalOpen] = useState(false);

  const columnStyles = { xs: 24, lg: 12 };
  const rowStyles = { gutter: 24 };

  useEffect(() => {
    form.resetFields();
  }, [petFormInitialValues]);

  const handleSubmitForm = (values) => {
    const formValues = { ...values };
    if (petIdToUpdate) {
      formValues.uid = petIdToUpdate;
    }
    formValues.userId = customerUserUid;
    formValues.avatarUrl = petImageUrl;
    formValues.birthDate = formValues.birthDate?.format('YYYY-MM-DD');
    handleSubmit(formValues);
  };

  const vetAdminHasIntegrations = isVetAdminHasIntegrations(isPetModalOpen.vetAdmin);
  const [integration] = isPetModalOpen.vetAdmin?.integrations ?? [];

  const isIntegrationError = isAnyEntityIntegrationError(isPetModalOpen.vetAdmin, petIntegrations);

  const integrationErrors = petIntegrations
    ?.filter(isEntityIntegrationError)
    .map((i) => i?.errorDetails?.message);

  return (
    <Modal
      title={
        <div>
          {isIntegrationError && (
            <div className={css.petModalIntegrationError}>
              <ExclamationCircleFilled className={css.petModalWarningIcon} />
              <div>
                <p className={cssText.s16w4l20}>
                  {integrationErrors?.length > 0
                    ? integrationErrors
                    : t('integration.error_popover.pet_error_message')}
                </p>
              </div>
            </div>
          )}
          {t(petIdToUpdate ? 'edit.pet.info' : 'add.new.pet')}
        </div>
      }
      isOpen={isPetModalOpen || petIdToUpdate}
      customFooter={() => null}
      onClose={closeModal}>
      <Form
        form={form}
        className="pet-modal-form"
        layout="vertical"
        requiredMark={false}
        initialValues={petFormInitialValues}
        onFinish={(values) => {
          handleSubmitForm(values);
        }}>
        <Row>
          <Col id="fill-info-text">{t('fill.pet.info')}</Col>
        </Row>

        <Row {...rowStyles}>
          <Col {...columnStyles}>
            <UploadImage
              name="avatarUrl"
              form={form}
              formInitialValues={petFormInitialValues}
              imageUrl={petImageUrl}
              setImageUrl={setPetImageUrl}
            />
          </Col>
          <Col {...columnStyles}>
            {vetAdminHasIntegrations && (
              <Row>
                <Col span={24}>
                  <div>
                    <div className={css.externalIdTitle}>
                      <span>{t('pet.external_id.title')}</span>
                      <div className={css.externalIdActions}>
                        <EditOutlined
                          className={css.actionButton}
                          onClick={() => setIsExternalIdWarningModalOpen(true)}
                        />
                        {petIntegration?.link && (
                          <a
                            href={
                              petIntegration.link.action ===
                                INTEGRATION_ENTITY_LINK_ACTION.OPEN_URL && petIntegration.link.url
                            }
                            className={css.directLink}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                              if (
                                petIntegration.link.action ===
                                INTEGRATION_ENTITY_LINK_ACTION.COPY_URL
                              ) {
                                navigator.clipboard.writeText(petIntegration.link.url);
                                message.success(t('pet.link.clipboard.message'));
                              }
                            }}>
                            <PetIntegrationLink className={css.actionButton} />
                          </a>
                        )}
                      </div>
                      {isExternalIdWarningModalOpen && (
                        <EditExternalIdWarningModal
                          isOpen={isExternalIdWarningModalOpen}
                          onClose={() => setIsExternalIdWarningModalOpen(false)}
                          onConfirm={() => {
                            setIsExternalIdWarningModalOpen(false);
                            setIsEditExternalIdModalOpen(true);
                          }}
                        />
                      )}
                      {isEditExternalIdModalOpen && (
                        <EditExternalIdModal
                          isOpen={isEditExternalIdModalOpen}
                          onClose={() => setIsEditExternalIdModalOpen(false)}
                          petIntegration={petIntegration}
                          petId={petIdToUpdate}
                          integration={integration}
                        />
                      )}
                    </div>
                    <p>{petFormInitialValues.externalId ?? t('pet.blank.external_id')}</p>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Input
                  label={t('pet.name.input.placeholder')}
                  name="name"
                  placeholder="Rex"
                  required
                  whitespace={true}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  label={t('pet.type.input.placeholder')}
                  name="type"
                  placeholder={t('select')}
                  options={petTypes}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row {...rowStyles}>
          <Col {...columnStyles}>
            <Select
              name="gender"
              label={t('gender.required')}
              placeholder={t('male.or.female')}
              options={GenderOptions}
            />
          </Col>
          <Col {...columnStyles}>
            <DatePicker name="birthDate" label={t('birth.date.required')} />
          </Col>
        </Row>

        <Row>
          <Col id="advanced-info-text" onClick={() => setIsDropdownOpen((prev) => !prev)}>
            {t('advanced.information')}
            <img
              src={arrow}
              alt="arrow"
              id={isDropdownOpen ? 'arrow-icon-rotated' : 'arrow-icon'}
            />
          </Col>
        </Row>

        <div className={isDropdownOpen ? 'advanced-info-wrapper' : 'advanced-info-wrapper--hidden'}>
          <Row {...rowStyles}>
            <Col {...columnStyles}>
              <Select
                name="isSterilized"
                label={t('pet.sterilized.input.placeholder')}
                placeholder={t('yes.or.no')}
                options={YesOrNoOptions}
              />
            </Col>

            <Col {...columnStyles}>
              <PetWeightInput
                label={t('pet.weight.input.placeholder')}
                name="weight"
                form={form}
                placeholder={t('pet.weight.input.placeholder')}
              />
            </Col>
          </Row>

          <Row {...rowStyles}>
            <Col {...columnStyles}>
              <Input label={t('chip.number')} name="chipNumber" placeholder="GA12383YP" />
            </Col>
            <Col {...columnStyles}>
              <Input label={t('breed')} name="breed" placeholder="Labrador" />
            </Col>
          </Row>

          <Row {...rowStyles}>
            <Col {...columnStyles}>
              <Input
                label={t('medical.alerts')}
                name="medicalAlerts"
                placeholder={t('medical.alerts')}
              />
            </Col>
            <Col {...columnStyles}>
              <Input
                label={t('food.type')}
                name="foodType"
                placeholder={t('food.type.placeholder')}
              />
            </Col>
          </Row>

          <Row {...rowStyles}>
            <Col span={24}>
              <TextArea
                label={t('pet.behavioral.notes.input.placeholder')}
                placeholder={t('describe.pet')}
                name="behavioralNotes"
              />
            </Col>
          </Row>

          <Row {...rowStyles}>
            <Col span={24}>
              <SelectTags
                name="allergies"
                label={t('allergies')}
                placeholder={t('allergies.input.placeholder')}
              />
            </Col>
          </Row>
        </div>

        <Row gutter={12} justify="end">
          <Col>
            <Button className="cancel-button" onClick={closeModal}>
              {t('cancel')}{' '}
            </Button>
          </Col>
          <Col>
            <SubmitButton onClick={form.submit} value={t('save')} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(PetModal);
