import React from 'react';
import './style.scss';
import { ReactComponent as CustomerIcon } from 'assets/images/empty-state.svg';

const EmptyState = ({ title }) => {
  return (
    <div className="empty-state-wrapper">
      <div className="content-wrapper">
        <CustomerIcon />
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default EmptyState;
