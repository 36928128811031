import { useState, useEffect } from 'react';
import { AppointmentRouteStatus } from 'constants/client';

import dayjs from 'dayjs';

export const routeStatus = {
  REGISTER: 'register',
  ADJUSTMENT: 'adjustment',
  READY: 'ready',
  UPCOMING: 'upcoming',
  LOCKED: 'locked',
  STARTED: 'started',
  FINISHED: 'finished'
};

const useTimelineAppointments = (timeline, year, submitCalendar) => {
  const [futureTimeline, setFutureTimeline] = useState([]);
  const [pastTimeline, setPastTimeline] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  useEffect(() => {
    if (!!timeline.length) {
      const futureAppointments = [];
      const pastAppointments = [];
      timeline?.forEach((appointment) => {
        if (
          appointment?.status !== AppointmentRouteStatus.FINISHED &&
          appointment?.status !== AppointmentRouteStatus.MISSED &&
          (dayjs(appointment?.route?.date).isSame(dayjs(), 'day') ||
            dayjs(appointment?.route?.date).isAfter(dayjs(), 'day'))
        ) {
          const futureAppointmentsObj = { ...appointment };
          futureAppointments.push(futureAppointmentsObj);
        } else {
          const pastAppointmentsObj = { ...appointment };
          pastAppointments.push(pastAppointmentsObj);
        }
      });
      if (futureAppointments.length) setFutureTimeline(futureAppointments);

      setPastTimeline(pastAppointments);

      if (!!futureAppointments.length) {
        setSelectedTimeSlot(futureAppointments[futureAppointments.length - 1]);
      } else if (!!pastAppointments.length) {
        setSelectedTimeSlot(pastAppointments[0]);
      }

      setTimeout(() => {
        const target = document.getElementById('scrollElement');
        if (target) {
          target.parentNode.scrollTop = target.offsetTop;
        }
      }, 0);
    }
  }, [timeline]);

  const isFiltered = () => {
    return !!year.value;
  };

  const isFutureTimeSlot = (i, route) => {
    return route && route?.status === routeStatus.REGISTER.toUpperCase();
  };

  const isRescheduleAvailable = (routeDate, time = 14) => {
    if (!routeDate) {
      return false;
    }

    const diff = dayjs(routeDate).diff(new Date(), 'days');

    const currentTime = new Date();

    return !(diff === 0 && currentTime.getHours() >= time);
  };

  const handleSubmitCalendar = (timeSlotId, appointmentDate, id, appointmentRouteId) => {
    submitCalendar(timeSlotId, appointmentDate, id, appointmentRouteId);
  };

  return {
    futureTimeline,
    pastTimeline,
    selectedTimeSlot,
    setSelectedTimeSlot,
    isFiltered,
    isFutureTimeSlot,
    handleSubmitCalendar,
    isRescheduleAvailable
  };
};

export default useTimelineAppointments;
