import React, { useMemo, useCallback, useState } from 'react';

import Button from 'components/common/button/Button';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

const ModalFooter = ({
  className,
  isNextButtonDisabled,
  onNextClick,
  current,
  steps,
  onRequestClose,
  changeStep,
  isSubmitting,
  informClient,
  setInformClient,
  form
}) => {
  const { t } = useTranslation();
  const [isInformClient, setIsInformClient] = useState(true);

  const getNextButtonLabel = useMemo(() => {
    if (current === steps.length - 1) {
      return t('finish');
    }
    return t('next');
  }, [current, steps]);

  const getBackButtonLabel = useMemo(() => {
    if (current != 0) {
      return t('back');
    }
    return t('admin.general.cancel');
  }, [current, steps]);

  const handleSubmitBackBtn = useCallback(() => {
    if (current != 0) changeStep(current - 1);
    else onRequestClose();
  }, [current, steps]);


  return (
    <div className={`appointments-footer-wrapper ${className}`}>
      {current === steps.length - 1 ? (
        <div
          onClick={() => {
            form.setFieldsValue({ isInformClient: !isInformClient });
            setIsInformClient(!isInformClient);
          }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginRight: 20,
            fontSize: 14
          }}>
          <Checkbox checked={isInformClient} />
          <span>{t('inform_client_by_email')}</span>
        </div>
      ) : null}
      <Button
        label={getBackButtonLabel}
        disabled={isSubmitting}
        className="cancel-btn"
        onClick={handleSubmitBackBtn}
      />
      <Button
        label={getNextButtonLabel}
        color="primary"
        className={`next-btn ${isNextButtonDisabled ? 'disabled' : ''}`}
        disabled={isNextButtonDisabled || isSubmitting}
        onClick={onNextClick}
      />
    </div>
  );
};

export default ModalFooter;
