import styled from 'styled-components';

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const LabelText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 3rem;
  color: #8a94a6;
  margin-bottom: 1rem;
  ${({ theme }) => theme.mobile`
    line-height: 2rem;
  `}
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ isForSelect }) => (isForSelect ? '1.6rem' : '2.4rem')};
  width: ${({ isForSelect }) => (isForSelect ? '1.6rem' : '2.4rem')};
  background-color: #eee;
  border-radius: ${({ isForSelect }) => (isForSelect ? '.4rem' : '.8rem')};
  ::after {
    content: '';
    position: absolute;
    display: none;
    left: ${({ isForSelect, isRtl }) => (isForSelect ? '.3rem' : isRtl ? '0.65rem' : '.8rem')};
    top: ${({ isForSelect, isRtl }) => (isForSelect ? '.3rem' : isRtl ? '0.45rem' : '.4rem')};
    width: ${({ isForSelect, isRtl }) =>
      isRtl ? (isForSelect ? '.8rem' : '1rem') : isForSelect ? '.4rem' : '.6rem'};
    height: ${({ isForSelect, isRtl }) =>
      isRtl ? (isForSelect ? '.4rem' : '.6rem') : isForSelect ? '.8rem' : '1rem'};
    border: solid white;
    border-width: ${({ isForSelect }) => (isForSelect ? '0 .2rem .2rem 0' : '0 .25rem .25rem 0')};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: ${({ isRtl }) => (isRtl ? 'rotate(-45deg)' : 'rotate(45deg)')};
  }
`;

export const Label = styled.div`
  height: ${({ isForSelect }) => (isForSelect ? '1.6rem' : '3.2rem')};
  display: flex;
  align-items: center;
  position: relative;
  padding-left: ${({ isForSelect }) => (isForSelect ? '0' : '3.5rem')};
  margin-bottom: ${({ isForSelect }) => (isForSelect ? '0' : '1.2rem')};
  cursor: pointer;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ theme }) => theme.mobile`
    margin-bottom: 3rem;
  `}
  &:hover input ~ ${Checkmark} {
    background-color: #ccc;
    border-radius: ${({ isForSelect }) => (isForSelect ? '.4rem' : '.8rem')};
  }
  input:checked ~ ${Checkmark} {
    background-color: ${({ isForSelect }) => (isForSelect ? '#22A3C3' : '#f9b057')};
    border-radius: ${({ isForSelect }) => (isForSelect ? '.4rem' : '.8rem')};
  }
  input:checked ~ ${Checkmark}:after {
    display: block;
  }
`;
