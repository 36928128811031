import styled from 'styled-components';

export const OneTimeText = styled.div`
  position: absolute;
  bottom: -1.7rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkGreen};
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 600;
  ${({ isVisible }) => !isVisible && 'display: none'}

  ${({ theme }) => theme.mobile`
    margin-top: 0.6rem;
  `}
`;

export const PetNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  & svg {
    width: 1.4rem;
    height: 1.4rem;
    display: none;
    cursor: pointer;
    fill: white;

    ${({ theme }) => theme.mobile`
     display : block;
  `}
  }

  ${({ theme }) => theme.mobile`
    justify-content: center;
    margin-top: 2rem;
  `}

  &  div {
    ${({ theme }) => theme.mobile`
    display: flex;
  `}
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.darkGray};
  border: 0.2rem solid #fcfcfd;
  box-sizing: border-box;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: none;

  ${({ theme }) => theme.mobile`
    display: block;
    background: unset;
    right: 1.9rem;
    top: 1.9rem;
    border: unset;
   `}
`;

export const Wrapper = styled.div`
  width: ${({ isCheckout }) => (isCheckout ? 'auto' : '53.6rem')};
  max-width: ${({ isCheckout }) => (isCheckout ? '62.2rem' : 'unset')};

  background: #ffffff;
  border: 0.1rem solid #dbe2ee;
  box-sizing: border-box;
  border-radius: 1.2rem;
  height: ${({ isCheckout }) => (isCheckout ? 'auto' : '18.1rem')};
  position: relative;
  padding: 1.6rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  box-sizing: border-box;

  ${({ theme }) => theme.mobile`
    width: 100%;
    border: 0.1rem solid #DBE2EE;
    height: auto;
    position : relative;
    padding : 2.4rem 1.6rem 3.5rem 1.6rem;
    margin-bottom : 2.4rem;
    :nth-child(odd) {
      margin-right: unset;
    }
  `}

  ${({ isCheckout }) =>
    isCheckout &&
    `
     max-width: 62.2rem;
  `}

  &:hover ${PetNameWrapper} {
    & svg {
      display: block;
    }
    & div {
      display: flex;
    }

    ${OneTimeText} {
      display: unset;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 16.4rem;
  height: 14.9rem;
  border-radius: 0.8rem;

  ${({ theme }) => theme.mobile`
      width: 6.56rem;
      height: 6.6rem;
      position: absolute;
  `}
`;

export const Avatar = styled.img`
  height: 14.9rem;
  object-fit: ${({ hasAvatar }) => (hasAvatar ? 'fill' : 'cover')};
  width: 16.4rem;
  border-radius: 0.8rem;
  border: none;
  background-color: rgba(219, 226, 238, 1);
  padding: ${({ hasAvatar }) => (hasAvatar ? '4.3rem 4.1rem 4.2rem 4.0rem' : '0')};

  ${({ hasAvatar, theme }) => theme.mobile`
      width: 6.56rem;
      height: 6.6rem;
      padding : ${hasAvatar ? '.7rem' : 0};
  `}
`;

export const PetName = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 2rem;
  max-width: 30rem;
  height: 2.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => theme.mobile`
    padding-left : 8rem;
    width : 100%;
    max-width: 25rem;
  `}
`;

export const SelectedPlanWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  ${({ isSubscriptionOneTime }) => isSubscriptionOneTime && 'visibility: hidden'};
  & svg {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
  }
  ${({ theme }) => theme.mobile`
    margin-top: 3rem;
    margin-bottom: 1rem;
  `}
`;
export const ToolTopWrapper = styled.div`
  & div {
    max-width: 20.2rem;
    font-family: inter, assistant;
    font-weight: 300;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    border-radius: 0.8rem;
  }
  & a {
    display: flex;
    align-items: center;
  }
`;

export const SelectedPlan = styled.h2`
  color: #8a94a6;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  margin-top: 0.4rem;
  & svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.4rem;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 16.4rem - 2rem);
  ${({ theme }) => theme.mobile`
    width: auto;
  `}
`;

export const PlanWrapper = styled.div`
  width: 100%;
  height: 3.6rem;
  padding: 0.8rem 1.6rem;
  background: rgba(255, 154, 5, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlanText = styled.h2`
  color: ${({ theme }) => theme.colors.orange};
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-transform: capitalize;
`;

export const ChangeText = styled.h4`
  font-size: 1.2rem;
  color: #8a94a6;
  line-height: 1.5rem;
  font-weight: 500;
  margin-right: 0.5rem;
  font-family: inter, assistant;
  font-weight: 600;
`;

export const Edit = styled.img`
  width: 1.167rem;
  height: 1.167rem;
  color: #8a94a6;
`;

export const PlanEditWrapper = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1.167rem;
    height: 1.167rem;
    fill: #8a94a6;
  }
  &:hover * {
    color: ${({ theme }) => theme.colors.black};
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.8rem;
  height: 5.7rem;
  gap: 0.6rem;

  ${({ theme }) => theme.mobile`
    height: auto;
  `}
`;

export const FeatureItem = styled.div`
  width: calc(50% - 0.6rem);
  display: flex;
  align-items: center;
  height: 1.5rem;
  & svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.colors.orange};
  }

  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-top : 0.4rem;
    margin-bottom: 0.4rem;
  `}
`;

export const FeatureTitle = styled.h5`
  font-size: 1.2rem;
  color: #8a94a6;
  margin-left: 0.8rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: gilrov-l, assistant;

  ${({ theme }) => theme.laptop`
    margin-left: 0.8rem;
  `}
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -0.5rem;
  max-width: 10rem;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  ${({ theme }) => theme.mobile`
    max-width: unset;
    align-items: center;
  `}
`;

export const PriceWrapper = styled.div`
  background: rgba(34, 163, 195, 0.1);
  border-radius: 8px;
  padding: 0.1rem 1.2rem;
  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-top: 1rem;
  `}
`;
export const PriceText = styled.h5`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #22a3c3;
  white-space: nowrap;
  font-family: gilrov-l, assistant;
  ${({ theme }) => theme.laptopSm`
    display: flex;
    align-items: center;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
    text-align: center;
    height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const FontSmall = styled.span`
  font-size: 1.2rem;
  font-weight: 300;
  margin-left: 0.4rem;
  margin-top: 0.2rem;
`;

export const FontNormal = styled.span`
  font-weight: 400;
`;
