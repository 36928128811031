import React from 'react';
import cn from 'classnames';
import { Row, Popover } from 'antd';

import { Col, cssText, cssSpace, Button } from '_fsd/shared';
import css from './AppointmentMapTooltip.module.css';
import { ETAView } from '../vet-appointments-row/AppointmentRow';
import { PetOwnerShortRow } from '../../../../entities';
import { petImages } from '../../../../../constants/pet';

// widget
export const AppointmentMapTooltip = ({ data, openModal }) => {
  const {
    appointmentRoute: { address, duration },
    pets = [],
    user
  } = data;
  const { petsIcons, petsCount } = React.useMemo(
    () => ({
      petsIcons: pets.slice(0, 4),
      petsCount: pets.length - 4
    }),
    [pets]
  );
  return (
    <Col className={css.container}>
      <ETAView data={data.appointmentRoute} />
      <Row className={cn(cssSpace.jcSB)}>
        <PetOwnerShortRow
          viewMode={true}
          name={`${user.firstName} ${user.lastName}`}
          address={address.description}
          user={user}
        />
        <span className={cn(cssText.s14h22w5, css.duration)}>{`${duration} min`}</span>
      </Row>
      <Row className={cn(cssSpace.w100, cssSpace.mt8)}>
        {petsIcons.map((appPet) => {
          const pet = appPet.pet;
          return (
            <div title={pet.name} key={pet.uid} className={cn(css.avatarWrapper, cssSpace.mr4)}>
              <Popover showArrow={false} content={pet.name} openClassName={css.cl}>
                <img
                  className={css.avatar}
                  src={pet?.avatarUrl || petImages[pet?.type?.name || 'other']}
                  alt="avatar"
                />
              </Popover>
            </div>
          );
        })}
        {petsCount > 0 && <div className={cn(cssText.s14h22w4, css.petCount)}>+{petsCount}</div>}
      </Row>
      <Row className={cn(cssSpace.w100, cssSpace.mt12)}>
        <Button
          type="primary"
          colorScheme="blue"
          onClick={() => openModal(data)}
          className={cn(cssSpace.w100)}>
          View details
        </Button>
      </Row>
    </Col>
  );
};
