import { useMutation } from '@apollo/client';
import { removeVetMutation } from 'gql';

export const useRemoveVet = (refetchVets) => {
  const [removeVet] = useMutation(removeVetMutation);

  const handleDeleteVet = async (uid) => {
    try {
      await removeVet({
        variables: {
          record: {
            uid
          }
        }
      });
      refetchVets();
    } catch (e) {
      throw Error('Can not delete vet');
    }
  };

  return { handleDeleteVet };
};
