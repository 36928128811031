import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: 36rem;
  max-height: calc(100vh - 27.5rem);
  padding: 2rem 1.6rem;

  box-shadow: 0px 17px 45px -31px rgba(45, 28, 28, 0.25);
  border-radius: 0.8rem;
  height: auto;

  ${({ theme }) => theme.minWidth.desktopS`
    width: 53.6rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
  `}
  ${({ theme }) => theme.laptopS`
    width: 30rem;
  `}
  ${({ theme }) => theme.laptop`
    height: calc(100vh - 23rem);
    max-height: 79.6rem;
  `}
  ${({ theme }) => theme.laptopHeightS`
    height: calc(100vh - 27.5rem);
    max-height: 79.5rem;
  `}
  ${({ theme }) => theme.laptopSm`
    width: 22rem;
  `}

  ${({ theme }) => theme.mobile`
    height: 100%;
    width: 100%;
    display : ${({ isActiveTab }) => (isActiveTab ? 'flex' : 'none')};
  `}
`;

export const Header = styled.div`
  height: 6.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2rem solid #f5f6f9;
  //padding-bottom: 2rem;

  ${({ theme }) => theme.laptop`
    height: 5.6rem;
    padding-bottom: 0rem;
  `}
  ${({ theme }) => theme.laptopSm`
    flex-wrap: wrap;
    justify-content: flex-start;
  `}
  ${({ theme }) => theme.mobile`
    flex-wrap: no-wrap;
    justify-content: space-between;
  `}
`;

export const Title = styled.p`
  font-family: inter, assistant;
  font-size: 1.8rem;
  font-weight: 600;
  color: #323b4b;
  margin-bottom: 0;
  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
  `}
`;

export const MarkReadButton = styled.p`
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ disabled, theme }) => (disabled ? '#8a94a6' : theme.colors.orange)};
  user-select: none;
  padding: 0 1.6rem;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  margin-bottom: 0;
  ${({ theme }) => theme.laptop`
    font-size: 1.2rem;
  `};
  ${({ theme }) => theme.laptopSm`
    padding-left: 0;
    margin : 1rem 0;
  `}
  ${({ theme }) => theme.mobile`
    margin : 0;
  `}
`;

export const Period = styled.p`
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: 500;
  color: #323b4ba3;
  margin: ${({ hasBiggerMargin }) => (hasBiggerMargin ? '2.5rem 1.6rem' : '2rem 1.6rem')};

  ${({ theme }) => theme.laptop`
    margin: 1.6rem 0.4rem;
  `}
`;

export const Body = styled.div`
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8a94a640;
    border-radius: 1rem;
  }
`;

export const NotificationsIcon = styled.img`
  margin-right: 1.7rem;
  //margin-bottom: 1.6rem;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;
