import React from 'react';

import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

import './style.scss';

const { Title } = Typography;

const SignInModal = ({ values, onRequestClose, handleSendRequestCode }) => {
  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();

  const sendRequestCode = async () => {
    await handleSendRequestCode(values);
    // onRequestClose();
  };

  return (
    <Modal isOpen={true} onRequestClose={onRequestClose} isMobile={isMobile} isLaptop={!isMobile}>
      <div className="sign-in-modal">
        <div className="sign-in-modal__header">
          <Title level={1}> {t('sign_in_modal.title')} </Title>
          <CloseIcon onClick={onRequestClose} />
        </div>
        <div className="sign-in-modal__content">
          <Title level={3}>
            {t('sign_in_modal.user_with')}{' '}
            <span>{values?.phonePrefix + ' ' + values?.phoneNumber}</span>{' '}
            {t('sign_in_modal.already_exists')}
          </Title>
          <Title level={3}>{t('sign_in_modal.CTA_lable')}</Title>
          <Button
            label={t('sign_in_modal.btn_text')}
            color="primary"
            textColor="#fff"
            isForModal
            onClick={sendRequestCode}
          />
        </div>
        <div className="sign-in-modal__footer">
          <Title level={4} onClick={onRequestClose}>
            {t('sign_in_modal.footer_text')}
          </Title>
        </div>
      </div>
    </Modal>
  );
};

export default SignInModal;
