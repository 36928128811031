import React from 'react';
import css from './User.module.css';
import cls from 'classnames';
import { cssText as text, UserDynamicAvatar, getName } from '_fsd/shared';

export const User = ({ user, size }) => (
  <div className={css.user}>
    <div className={css.icon}>
      <UserDynamicAvatar user={user} size={size} />
    </div>
    <span className={cls(css.userName, text.s14w6l18)}>{getName(user)}</span>
  </div>
);
