import React, { memo } from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

const Stepper = ({ current, changeStep, me, isForVet, onRequestClose }) => {
  const { t } = useTranslation();
  const { Step } = Steps;

  return (
    <div className="new-user-stepper-wrapper">
      <Steps current={current}>
        <Step
          title={isForVet ? t('scheduling_stepper.vet_third_step_title') : t('personal.details')}
          description=""
          onClick={() => changeStep(0)}
        />
        <Step
          title={
            isForVet ? t('scheduling_stepper.vet_first_step_title') : t('address.and.date.time')
          }
          description=""
          onClick={() => changeStep(1)}
        />
        <Step
          title={t('patient.details')}
          subTitle=""
          description=""
          onClick={() => changeStep(2)}
        />
      </Steps>
      {isForVet && <CloseIcon onClick={onRequestClose} />}
    </div>
  );
};

export default memo(Stepper);
