import { useMutation } from '@apollo/react-hooks';
import { updateAppointmentUserComment, updateAppointmentVetCommentMutation } from 'gql';

const useAppointments = () => {
  const [updateUserComment] = useMutation(updateAppointmentUserComment);
  const [updateVetComment] = useMutation(updateAppointmentVetCommentMutation);

  const updateAppUserComment = async (appointmentId, comment) => {
    return await updateUserComment({
      variables: {
        record: {
          appointmentId,
          comment
        }
      }
    });
  };

  const updateAppVetComment = async (appointmentId, vetComment) => {
    return await updateVetComment({
      variables: {
        record: {
          appointmentId,
          vetComment
        }
      }
    });
  };

  return {
    updateAppUserComment,
    updateAppVetComment
  };
};

export default useAppointments;
