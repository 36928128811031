import { useMutation, gql } from '@apollo/client';

const query = gql`
  mutation UpsertPetIntegrationExternalId($record: UpsertPetIntegrationExternalIdRecord!) {
    upsertPetIntegrationExternalId(record: $record) {
      uid
    }
  }
`;

export const useUpsertExternalId = (options = {}) => {
  return useMutation(query, { ...options, refetchQueries: ['getPet'] });
};
