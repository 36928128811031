import React from 'react';
// import LanguageSwitcher from 'components/common/language-switcher/LanguageSwitcher';
import Logo from 'components/common/logo/Logo';
import * as S from './components';
import Carousel from 'components/common/carousel/Carousel';

import useOnboardingLayout from './useOnboardingLayout';

const OnboardingLayout = ({ children }) => {
  const sliderData = useOnboardingLayout();

  return (
    <S.Container>
      <Logo />
      {/* <S.LanguageSwitcherWrapper>*/}
      {/*  <LanguageSwitcher />*/}
      {/* </S.LanguageSwitcherWrapper>*/}
      <S.SliderWrapper>
        <Carousel sliderData={sliderData} />
      </S.SliderWrapper>
      <S.Line />
      <S.FormsContainer>{children}</S.FormsContainer>
    </S.Container>
  );
};

export default OnboardingLayout;
