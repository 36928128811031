import moment from 'moment/moment';

export const timeSlotsFilterHelper = (tsArray) => {
  return tsArray.filter(
    (ts) => !ts.routes.length || !timeSlotIsFull(ts, ts.routes[0].appointmentRoutes)
  );
};

export const timeSlotIsFull = (timeSlot, appointmentRoutes) => {
  const lastAppointmentTime = 40;
  const workingMins = moment(timeSlot.endTime, 'h:mm:ss').diff(
    moment(timeSlot.startTime, 'h:mm:ss'),
    'minutes'
  );

  const occupiedTime = appointmentRoutes.reduce((num, b) => num + b.duration + 20, 0);

  const isFullTimeslot =
    lastAppointmentTime + occupiedTime >= workingMins ||
    !!(
      appointmentRoutes.length &&
      timeSlot.maxAppointments &&
      timeSlot.maxAppointments <= appointmentRoutes.length
    );
  return isFullTimeslot;
};
