import styled from 'styled-components';

export const LogoWrapper = styled.h1`
  position: absolute;
  top: 3.2rem;
  left: 3.2rem;
  z-index: 1;
  margin: 0;
  ${({ theme }) => theme.mobile`
    top: 2rem;
    left: 2.4rem;
  `}
`;

export const LogoImg = styled.img`
  width: 10.2rem;
  height: 4rem;
  object-fit: contain;
  ${({ theme }) => theme.mobile`
    height: 3.5rem;
    width: 7.4rem;
  `}
`;
