import React, { memo, useContext } from 'react';
import Modal from './Modal';
import VerifyCodeForm from '../../../components/verify-code-form/VerifyCodeForm';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';
import { useTranslation } from 'react-i18next';
import style from './ModalVerifyPhone.module.css';

const ModalVerifyPhone = ({ phone, prefix, successHandler, closeModal, vet }) => {
  const { t } = useTranslation();

  return (
    <Modal title={'Please verify your Phone number'}>
      <span className={style.description}>
        {t('verify_code.description')}
        &nbsp;
        <span className={style.phone}>{prefix + ' ' + phone}</span>
      </span>
      <VerifyCodeForm
        vet={vet}
        successHandler={successHandler}
        isForModal
        isPhoneNumberExists
        pPrefix={prefix}
        pNumber={phone}
        setIsVerificationCodeSent={() => {}}
        onRequestClose={closeModal}
      />
    </Modal>
  );
};

const Memoized = memo(ModalVerifyPhone);
const Container = ({ vet }) => {
  const { phone, prefix, verifyPhoneSuccessHandler, closeModal } = useContext(
    ScheduleAppointmentContext
  );
  return (
    <Memoized
      vet={vet}
      phone={phone}
      prefix={prefix}
      closeModal={closeModal}
      successHandler={verifyPhoneSuccessHandler}
    />
  );
};

export default Container;
