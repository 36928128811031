import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  padding: 3.9rem 4.8rem 4.8rem 4.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
  background-color: rgba(245, 246, 249, 0.2);

  ${({ theme }) => theme.laptop`
    padding: 2.4rem 1.8rem 1.6rem 2.4rem;
  `}

  ${({ theme }) => theme.mobile`
    padding-left : 2.4rem;
    padding-right : 2.4rem;
  `}
`;

export const Main = styled.main`
  background: rgba(245, 246, 249, 0.2);
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledText = styled.b`
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.11;
  text-align: center;
  margin-top: 1rem;
  ${({ theme }) => theme.mobile`
    font-size: 1.6rem;
  `};
`;

export const Title = styled.h2`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 3.2rem;
  color: #323b4b;
  margin-bottom: 0.8rem;
`;

export const SubTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #8a94a6;
  margin-bottom: 2.4rem;
  ${({ isSubscriptionOneTime }) => isSubscriptionOneTime && 'visibility: hidden'};

  ${({ theme }) => theme.mobile`
  margin-bottom: 4.8rem`}
`;

export const PetsWrapperShadow = styled.div`
  width: 100%;
  height: 7.2rem;
  position: absolute;
  background: linear-gradient(180deg, rgba(252, 252, 253, 0) 0%, #fcfcfd 67.36%);
  bottom: 12.3rem;
  left: 0;

  ${({ theme }) => theme.laptop`
    bottom: 6rem;
  `}
`;
export const PetsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
`;
export const PetsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  ${({ theme }) => theme.laptop`
    gap: 2.4rem;
  `}
  ${({ theme }) => theme.mobile`
    padding : 0;
  `}
`;

export const DiscountContainer = styled.div`
  background: #fff7eb;
  border-radius: 8px;
  height: 4rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  width: 38.2rem;
  & svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }

  ${({ theme }) => theme.laptop`
    height: 100%;
    padding: 0.8rem 0.8rem 0.7rem 0.8rem;
    
  `}

  ${({ theme }) => theme.mobile`
    width : 100%;
    height: 5.6rem;
    padding: 1.3rem 2.2rem;
  `}

  ${({ theme }) => theme.mobileS`
     padding: 0.5rem;  
  `}
`;

export const TextGray = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #9094ac;
  white-space: nowrap;
  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    font-weight: 400;
`}
  ${({ theme }) =>
    theme.mobileS`
    font-size: 1.4rem;
  `};
`;

export const TextOrange = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.orange};
  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    font-weight: 600;
`}

  ${({ theme }) =>
    theme.mobileS`
    font-size: 1.4rem;
  `}
`;

export const TotalPriceBox = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.laptop`
    margin-left: 1.6rem;
  `}

  ${({ theme, discount }) => theme.mobile`
    justify-content: flex-end;
    margin-left: unset;    
  `}
`;

export const PriceBox = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.laptop`
    width: 8.8rem;
    margin-right: 1.6rem;
    justify-content: flex-end;
  `}

  ${({ theme, discount }) => theme.mobile`
    justify-content: space-between;
    ${discount && 'margin-right: unset; width: 100%; '}
  `}
`;

export const Dot = styled.span`
  ${({ theme }) => theme.laptop`
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background: #5b626f;
    ${({ discount }) =>
      !discount &&
      css`
        display: none;
      `}
  `}
`;

export const Price = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  width: 9.5rem;
  color: rgba(50, 59, 75, 0.8);

  ${({ theme }) => theme.laptop`
    width: 3.8rem;
    margin-right: 0.8rem;
  `}

  ${({ theme }) => theme.mobile`
   padding-left: 0.3rem;
  `}
`;

export const OldPriceText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: line-through;
  line-height: 3rem;
  margin-left: 1rem;
  color: rgba(50, 59, 75, 0.8);
  ${({ theme }) => theme.laptop`
    margin-left: 0;
  `}
`;

export const TotalPrice = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  width: 9.5rem;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.laptop`
    width: 8.6rem;
    line-height: 1.9rem;
  `}
`;

export const PriceText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.laptop`
    line-height: 2.4rem;
  `}
`;

export const BoldName = styled.span`
  font-weight: 600;
`;

export const GrayLine = styled.div`
  width: 15.4rem;
  height: 0.2rem;
  background: #d8dfea;
  border-radius: 0.2rem;
  margin-bottom: 2.1rem;
  display: none;

  ${({ theme }) => theme.mobile`
  display:block
  `}
`;

export const Footer = styled.footer`
  display: block;
  justify-content: end;
  ${({ theme }) => theme.laptop`
    display: flex;
    position: relative;
    ${({ discount }) =>
      !discount &&
      css`
        padding-top: 1.6rem;
      `}
  `}

  ${({ theme }) => theme.mobile`
  flex-direction: column;
  padding: 0rem 2.4rem;
  padding-top: 2.4rem;
  margin-bottom: 3rem;
  `}
`;
