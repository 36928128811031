import styled from 'styled-components';

export const StepCircle = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: ${({ status, theme }) =>
    status === 'done' ? theme.colors.orange : status === 'active' ? theme.colors.white : '#ACBCD4'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ status, theme }) => status === 'active' && `border: 0.2rem solid ${theme.colors.orange}`};
  font-size: 1.6rem;
  font-family: inter, assistant;
  ${({ status, theme }) =>
    status === 'active' ? ' color: #323B4B' : `color: ${theme.colors.white}`};
  font-weight: ${({ status }) => (status === 'active' ? '500' : '400')};
  ${({ status }) => status === 'active' && ' box-shadow: 0 0 0 0.5rem rgba(255, 155, 5, 0.2);'}
  ${({ status }) => status === 'active' && ' margin: 0.5rem;'}
`;

export const SpaceLine = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: ${({ status, theme }) =>
    status === 'passive' ? '#d8dfea' : theme.colors.orange};
  margin: 0 3rem;

  ${({ theme }) => theme.mobile`
    max-width: 3rem;
    margin: 0 1.6rem;
    `}
`;

export const TextWrapper = styled.div`
  margin-left: 1.2rem;
  font-family: inter, assistant;
  font-size: 1.4rem;
  white-space: nowrap;
  font-weight: ${({ status }) => (status === 'active' ? '500' : '400')};

  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

export const CircleAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ status }) => (status === 'passive' ? 'default' : 'pointer')};
`;
