import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, message, Tooltip } from 'antd';
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';

import { getVetCalendarUrl } from 'utils/helpers';

import EmptyStateIcon from 'assets/icons/clients-empty-state.svg';

const ClientsEmptyState = ({ vet }) => {
  const { t } = useTranslation();
  const vetCalendarLink = getVetCalendarUrl(vet);

  const onCopy = () => {
    navigator.clipboard.writeText(vetCalendarLink);
    message.success(t('copied.to.clipboard'));
  };

  return (
    <div className="clients-empty-state">
      <h3>{t('new_appointment.empty_state_title')}</h3>
      <p>{t('new_appointment.empty_state_description')}</p>
      <img src={EmptyStateIcon} alt="" />
      <Input
        className="copy-url-input"
        disabled
        addonAfter={
          <Tooltip title={t('vet_routes.copy_link')}>
            <div className="copy-icons-wrapper">
              <CopyOutlined onClick={onCopy} fill="#585CE5" className="copy-svg" />
              <span className="line-between-icons" />
              <ShareAltOutlined onClick={onCopy} fill="#585CE5" className="share-svg" />
            </div>
          </Tooltip>
        }
        value={vetCalendarLink?.replace('https://', '')?.replace('http://', '')}
      />
    </div>
  );
};

export default ClientsEmptyState;
