import React, { useCallback, useEffect, useState } from 'react';
import css from './IntegrationsPage.module.css';
import { useTranslation } from 'react-i18next';
import { Modal, Section } from '_fsd/shared';
import { INTEGRATION_MODAL, INTEGRATION_STATUS, useIntegrations } from '_fsd/entities/integration';
import { IntegrationItem } from '_fsd/features/integration-item';
import { IntegrationCredentialsEditor } from '_fsd/widgets';
import { Skeleton } from 'antd';
import { Pim, usePims } from '_fsd/entities/pim';
import { IntegrationSuccessModal } from '../../../features';

const getIntegrations = (data = []) => {
  return {
    active: data.filter((i) => i.status !== INTEGRATION_STATUS.DISABLED),
    disabled: data.filter((i) => i.status === INTEGRATION_STATUS.DISABLED)
  };
};

export const IntegrationsPage = () => {
  const { t } = useTranslation();
  const { data, loading } = useIntegrations();
  const { data: pimsData, loading: pimsLoading } = usePims();

  const integrations = getIntegrations(data);

  const [mapModal, setMapModal] = useState(null);
  const [modal, setModal] = useState(null);
  const closeMapModal = useCallback(() => setMapModal(null), []);
  const closeModal = useCallback(() => setModal(null), []);
  const openModal = useCallback(({ type, context }) => setModal({ type, context }), []);

  return (
    <Skeleton loading={loading || pimsLoading} active>
      <div className={css.container}>
        <Section label={t('integration.section.active.title')} visible={!mapModal}>
          {integrations?.active.length
            ? integrations?.active.map((i) => (
                <IntegrationItem key={i.uid} data={i} disabled openModal={openModal} />
              ))
            : t('integration.section.active.empty_list')}
        </Section>
        <Section label={t('integration.section.integrations.title')} visible={!mapModal}>
          {pimsData?.length ? (
            pimsData.map((i) => <Pim key={i.uid} data={i} openModal={openModal} />)
          ) : (
            <span className={css.empty}>
              {t('integration.section.integrations.empty_list')}
              <a href={'https://kumba.pet/#Form'} target="_blank" rel="noreferrer">
                {t('contact us.')}
              </a>
            </span>
          )}
        </Section>
        {modal?.type === INTEGRATION_MODAL.CREDENTIALS_EDITOR && (
          <IntegrationCredentialsEditor
            openModal={openModal}
            closeModal={closeModal}
            modal={modal}
            data={modal?.context}
          />
        )}
        {modal?.type === INTEGRATION_MODAL.SUCCESS && (
          <IntegrationSuccessModal closeModal={closeModal} modal={modal} data={modal?.context} />
        )}
      </div>
    </Skeleton>
  );
};
