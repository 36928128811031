import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 2.4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e4e8;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
  h2 {
    margin-bottom: 0;
    font-size: 2rem;
  }
  ${({ theme }) =>
    theme.mobile`
      padding: 0rem 2.4rem 5rem 2.3rem;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: unset;
    `}
`;

export const Main = styled.main`
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;

  ${({ theme }) => theme.mobile`
    flex-direction: column-reverse;
  `}
`;

export const CheckoutInfoWrapper = styled.div`
  9 + & button {
    display: none;
  }
  ${({ theme }) =>
    theme.mobile`
      width: 100%;
      & button {
        width: calc(100% - 4.8rem);
        margin-top: 5rem;
        display: block;
        position: fixed;
        bottom: 4rem;
        z-index: 6;
        left: 2.4rem;
      }
    `}
`;

export const CheckoutButtonWrapper = styled.div`
  ${({ theme }) => theme.mobile`
    background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -9.28%,
    ${({ theme }) => theme.colors.white} 33.23%
    );
    width: 100%;
    position: fixed;
    z-index: 6;
    bottom: 0;
    left: 0;
    height: 15.1rem;
    `}
`;

export const GoBackWrapper = styled.div`
  margin-bottom: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.mobile`
    margin-bottom: 1.6rem;
  `}
`;

export const StyledText = styled.b`
  color: #2d3363;
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.11;
  text-align: center;
  margin-top: 1rem;
  ${({ theme }) => theme.mobile`
    font-size: 1.6rem;
  `};
`;

export const IconWrapper = styled.div`
  width: 0.8rem;
  height: 1.2rem;
  margin-right: 0.8rem;
  svg {
    fill: ${({ theme }) => theme.colors.black};
  }
  ${({ rtl }) =>
    rtl &&
    css`
      transform: rotate(180deg);
    `}

  ${({ theme }) => theme.mobile`
    display:none;
    `}
`;

export const PersonalInfoWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mobile`
    padding: 2.1rem 1.6rem 2.1rem 1.6rem;
    border: 0.1rem solid rgba(172, 188, 213, 0.56);
    box-sizing: border-box;
    box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.02);
    border-radius: 0.8rem;
  `}
`;

export const PersonalInfoHead = styled.div`
  > svg {
    display: none;
  }
  ${({ theme }) =>
    theme.mobile`
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      & svg {
        display:block;
        transition: 0.3s ease-in-out;
      }
      `}
  ${({ personalInfo }) =>
    personalInfo &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `}
`;

export const PersonalInfo = styled.div`
  ${({ theme }) => theme.mobile`
    margin-top: 1.9rem;
    padding-bottom: 1.1rem;
  `}
`;

export const FullWidth = styled.div`
  width: 100%;
  div,
  input {
    width: 100%;
    max-width: 100%;
  }
`;

export const PageSubTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.9rem;
  ${({ theme, checkout }) => theme.mobile`
    margin-bottom: ${checkout ? '1.9rem' : '0'}
  `}
`;

export const PetsWrapper = styled.div`
  width: 100%;
`;

export const CheckoutAbsoluteWrapper = styled.div`
  width: 41rem;
  max-height: 52.8rem;
  margin-left: 6.8rem;

  ${({ theme }) => theme.minWidth.desktop`
    width: unset;
  `}

  ${({ theme }) => theme.laptopS`
   margin-left: 2.8rem;
   width: 32.8rem;
  `}

  ${({ theme }) => theme.laptopSm`
   margin-left: 2.8rem;
   width: 30.2rem;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-left: unset;
    position: initial;
  `}
`;
export const CheckoutDetailsWrapper = styled.div`
  width: 45.8rem;
  height: auto;
  padding: 3.6rem 4.8rem;
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid rgba(172, 188, 213, 0.56);
  box-sizing: border-box;
  box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.02);
  border-radius: 0.8rem;
  ${({ theme }) => theme.desktop`
    width : 41rem;
    padding: 1.6rem;
  `}

  ${({ theme }) => theme.laptopS`
      width: 32.8rem;
    `}

${({ theme }) => theme.laptopSm`
      width: 30.2rem;
    `}

  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-top: 2rem;
    border: unset;
    position: initial;
    & button {
      display: none;
    }
  `}
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
`;

export const ExpirationInputWrapper = styled.div`
  width: 100%;
`;

export const CvcWrapper = styled.div`
  width: 35%;
  margin-right: 1.6rem;
`;

export const PriceText = styled.h3`
  font-family: inter, assistant;
  font-weight: ${({ colored, bold }) => (colored ? '600' : bold ? '600' : '400')};
  font-size: ${({ colored }) => (colored ? '1.6rem' : '1.4rem')};
  line-height: 1.8rem;
  color: ${({ colored, bold, theme }) =>
    colored ? '#FF9A05' : bold ? theme.colors.black : '#9094ac'};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => (margin ? '1.9rem 0' : 0)};
  margin-left: ${({ colored }) => (colored ? '.5rem' : '0')};
  opacity: ${({ colored }) => colored && '0.8'};
`;

export const PriceValueText = styled.h3`
  font-family: inter, assistant;
  font-weight: ${({ bold, orange }) => (bold ? '700' : orange ? '500' : '600')};
  font-size: ${({ total }) => (total ? '1.6rem' : '1.4rem')};
  line-height: ${({ bold }) => (bold ? '3.0rem' : '1.8rem')};
  color: ${({ theme, orange }) => (orange ? theme.colors.orange : theme.colors.black)};
  opacity: ${({ bold }) => (bold ? '1' : '0.8')};
`;

export const Hr = styled.div`
  background: #d5dde9;
  width: 100%;
  height: 0.1rem;
  margin-bottom: ${({ top }) => (top ? '1.3rem' : '2.1rem')};
`;

export const CheckoutButton = styled.button`
  background: ${({ theme, loading, finishedSuccessfully, isFinished }) =>
    loading
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), ${theme.colors.orange}`
      : isFinished && !finishedSuccessfully
        ? theme.colors.errorRed
        : isFinished
          ? '#41D77D'
          : theme.colors.orange};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
    `}
  margin-bottom: 2.1rem;
  width: 100%;
  height: 5.8rem;
  border-radius: 0.8rem;
  border: none;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-left: 1.2rem;
  }
  ${({ theme, isDesktop }) =>
    isDesktop &&
    theme.mobile`
    display: none;
    `}
  ${({ theme }) => theme.desktop`
    margin-bottom : 0;
    `}
`;

export const Loader = styled.div`
  border: 0.3rem solid rgba(251, 209, 157, 0.72);
  border-top: 0.3rem solid white;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  animation: spin 2s linear infinite;
  margin-left: 1.2rem;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CheckoutSubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Prev = styled.div`
  user-select: none;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid #dbe2ee;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin: 0;
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 5.5rem;
  svg {
    fill: #687a9e;
  }
  & ${Prev}:active svg {
    fill: #22a3c3;
  }
`;

export const SelectedPetsWrapper = styled.div`
  ${({ theme }) => theme.mobile`
   padding: 2.1rem 1.6rem 2.1rem 1.6rem;
   border: 0.1rem solid rgba(172, 188, 213, 0.56);
   box-sizing: border-box;
   box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.02);
   border-radius: 0.8rem;
   margin-top: 2rem;
 `}
`;

export const SelectedPets = styled.div`
  ${({ theme }) => theme.mobile`
    margin-top: 1.9rem;
    padding-bottom: 1.1rem;
  `}
`;

export const SelectedPetsHead = styled.div`
  > svg {
    display: none;
  }

  ${({ theme }) =>
    theme.mobile`
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      & svg {
          display:block;
          transition: 0.3s ease-in-out;
        }
      `}
  ${({ selectedPets }) =>
    selectedPets &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `}
`;

export const SelectedVetsWrapper = styled.div`
  ${({ theme }) => theme.mobile`
   display: flex;
   flex-direction: column;
   padding: 2.1rem 1.6rem 2.1rem 1.6rem;
   border: 0.1rem solid rgba(172, 188, 213, 0.56);
   box-sizing: border-box;
   box-shadow: 0 1.2rem 2.3rem rgba(0, 0, 0, 0.02);
   border-radius: 0.8rem;
   margin-top: 2rem;
   margin-bottom: 5rem;
`}
`;

export const SelectedVets = styled.div`
  ${({ theme }) => theme.mobile`
    margin-top: 1.9rem;
    padding-bottom: 1.1rem;
  `}
`;

export const Vets = styled.div`
  ${({ theme }) => theme.mobile`
    display: flex;
    flex-direction: column;
  `}
`;

export const SelectedVetsHead = styled.div`
  > svg {
    display: none;
  }

  ${({ theme }) =>
    theme.mobile`
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      & svg {
          display: block;
          transition: 0.3s ease-in-out;
        }
      `}
  ${({ selectVet }) =>
    selectVet &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `}
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 5.1rem;
  margin-bottom: 2.4rem;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(172, 188, 212, 0.56);
  padding: 0 2.4rem;
  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: #ff9a05;
    `}
  ${({ theme }) => theme.desktop`
    margin-bottom: 1.2rem;
  `}
  ${({ theme }) => theme.laptopS`
    padding: 0 1.5rem;
  `}
`;

export const CVCWrapper = styled.div`
  width: 35%;
  margin-right: 1.6rem;

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 3.2rem;
  color: rgba(228, 78, 97, 1);
  background: #fbe9ec;
  border-radius: 0.4rem;
  padding: 0 2rem;
  font-size: 1.2rem;
  font-family: inter, assistant;
  font-weight: 400;
  margin-bottom: 2.4rem;
`;

export const ErrorText = styled.span`
  margin-left: 1rem;
`;

export const Label = styled.div`
  position: absolute;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ isFocused, theme }) => (isFocused ? theme.colors.orange : '#b0b7c3')};
  top: 50%;
  padding: 0 0.4rem;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 99;

  ${({ isFocused, notEmpty }) =>
    (isFocused || notEmpty) &&
    css`
      transform: translateY(0);
      top: -1rem;
    `}
  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: 0;
    background-color: #fbfcfd;
    z-index: -1;
    left: 0;
  }
`;

export const MembershipPetCardWrapper = styled.div`
  margin-bottom: 1.2rem;
`;

export const CvvAndDateWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.mobile`
    flex-direction: column;
  `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${({ theme }) => theme.tabletL`
  flex-direction: column;
  `}

  ${({ theme }) => theme.mobile`
  flex-direction: column;
 `}
`;
