import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import useDigitInput from 'react-digit-input';

import { reassignUserPhoneMutation, requestCodeOnEmailMutation } from 'gql';

import { CodeValidationScheme } from 'validations/validations';
import AuthContext from '../../contexts/AuthContext';

const useVerifyCodeForm = (
  email,
  setIsVerificationCodeSent,
  onRequestClose,
  isPhoneNumberExists,
  successHandler
) => {
  const { user, setToken, refetch } = useContext(AuthContext);
  const [resendCodeIn, setResendCodeIn] = useState(0);
  const [codeResendSuccess, setCodeResendSuccess] = useState(false);

  const [reassignUserPhone] = useMutation(reassignUserPhoneMutation);
  const [resendCode, { loading: requestingCode }] = useMutation(requestCodeOnEmailMutation);

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      const { data: verifyData } = await reassignUserPhone({
        variables: {
          record: {
            email,
            code: Number(values.code)
          }
        }
      });
      const { accessToken } = verifyData.reassignUserPhone;
      if (successHandler && accessToken) {
        setToken(accessToken);
        successHandler();
        return;
      }
    } catch (e) {
      resetForm();
      setFieldError('serverError', 'Invalid code');
    }
  };

  const handleResendCode = async () => {
    try {
      await resendCode({
        variables: {
          record: {
            email
          }
        }
      });
      await refetch();
      setResendCodeIn(1);
      setCodeResendSuccess(true);
    } catch (e) {
      setFieldError('serverError', e.graphQLErrors?.[0]?.message);
    }
  };

  useEffect(() => {
    if (codeResendSuccess) {
      const timeOut = setTimeout(() => {
        setCodeResendSuccess(false);
      }, 5000);
      return () => clearTimeout(timeOut);
    }
  }, [codeResendSuccess]);

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    handleSubmit,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: { code: '' },
    onSubmit,
    validationSchema: CodeValidationScheme
  });

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: values.code,
    onChange: (val) => setFieldValue('code', val)
  });

  useEffect(() => {
    if (user?.codeOnEmailSentAt && resendCodeIn >= 0) {
      const interval = setInterval(() => {
        setResendCodeIn(
          Math.round(
            (new Date(user?.codeOnEmailSentAt).getTime() +
              parseInt(process.env.REACT_APP_REQUEST_CODE_PERIOD) -
              Date.now()) /
              1000
          )
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [user?.codeOnEmailSentAt, codeResendSuccess, resendCodeIn]);

  return {
    digits,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resendCodeIn,
    handleResendCode,
    requestingCode,
    codeResendSuccess
  };
};

export default useVerifyCodeForm;
