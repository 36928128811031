import { PropertyType } from 'utils/enums';
import { prepareAppPets } from '../../../serializers/signUpFlow.serializer';
import moment from 'moment';
import dayjs from 'dayjs';

const statusMap = {
  unknown: null,
  neutered: true,
  no_neutered: false
};

const serializePets = (petList = []) => {
  const res = petList?.map((pet) => ({
    uid: pet?.uid,
    name: pet.name,
    type: pet.type?.uid,
    birthDate: pet.birthDate,
    isSterilized: statusMap[pet.isSterilized] || null,
    gender: pet.gender
  }));
  return res;
};

export const processDate = (date) => {
  if (date instanceof dayjs) {
    return date.format('YYYY-MM-DD');
  }
  if (date instanceof moment) {
    return date.format('YYYY-MM-DD');
  }
  if (date instanceof Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  if (typeof date === 'string') {
    return date.slice(0, 10);
  }
};

const serializeAppointment = ({ concern, condition }, date, vet, selectedTimeslot) => {
  return {
    vetComment: condition,
    date: processDate(date),
    vetId: vet?.uid,
    timeSlotId: selectedTimeslot?.uid || selectedTimeslot
  };
};

const serializeAddress = (address) => {
  return {
    apartment: address?.apartment,
    city: address?.city,
    comment: address?.comment,
    countryCode: address?.countryCode || address?.country?.code,
    description: address?.description,
    floor: Number(address?.floor) || 0,
    houseNumber: Number(address?.houseNumber) || 0,
    propertyType: PropertyType[address?.propertyType],
    street: address?.street,
    uid: address?.uid,
    zipCode: address?.zipCode,
    lat: address?.lat,
    lng: address?.lng
  };
};

const serializeCustomer = ({
  uid,
  firstName,
  lastName,
  email,
  phoneNumber,
  phonePrefix,
  address
}) => {
  return {
    uid,
    firstName,
    lastName,
    email,
    phonePrefix,
    phoneNumber,
    address: serializeAddress(address)
  };
};

export const serializeSignUpFlow = (
  appointment,
  customer,
  pets,
  date,
  vet,
  selectedTimeslot,
  nonAppPets
) => {
  const res = {
    ...serializeAppointment(appointment, date, vet, selectedTimeslot),
    user: serializeCustomer(customer),
    pets: serializePets(nonAppPets, []),
    appointmentPets: prepareAppPets(pets, true),
    isFirstTime: appointment?.isFirstTime || false,
    firstTimePetsCount: appointment?.firstTimePetsCount || null,
    sendEmail: !!appointment.isInformClient
  };
  return res;
};

export const processAddress = (address) => {
  if (!address) return {};
  return {
    countryCode: address.country.code,
    zipCode: address.zipCode,
    city: address.city,
    houseNumber: address.houseNumber,
    floor: address.floor || 0,
    apartment: address.appartment || null,
    lat: address.lat,
    lng: address.lng,
    description: address.description
  };
};
