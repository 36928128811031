import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 41.3rem;
  height: 37.6rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: inter, assistant;

  ${({ theme }) => theme.mobile`
    width: unset;
  `}
`;

export const MessageImage = styled.img`
  margin-bottom: 2.8rem;
  margin-top: -0.6rem;
`;

export const Caption = styled.p`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  color: #2d3363;
  margin-bottom: 1.2rem;
`;

export const SubCaption = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  color: #9094ac;
`;

export const Exit = styled.img`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  ${({ theme }) => theme.mobile`
    margin-left: 27rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const Button = styled.button`
  width: 17.3rem;
  height: 4.4rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ isError }) => (isError ? '#E44E61' : '#41D77D')};
  border-radius: 0.8rem;
  outline: none;
  border: none;
  margin-top: 3.2rem;
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`;
