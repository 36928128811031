import styled from 'styled-components';

export const FilterModal = styled.div`
  width: 31.2rem;
  height: 29.9rem;
`;
export const FilterHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.4rem;
  & svg {
    fill: #9094ac;
    width: 2.8rem;
    height: 2.8rem;
    padding: 0.5rem;
  }
`;

export const FilterText = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const SelectorWrapper = styled.div`
  padding: 2.1rem 2.6rem 2.6rem;
  background: #f5f6f9;
  border-radius: 0rem 0rem 1.2rem 1.2rem;
  & .css-tj5bde-Svg {
    display: block;
  }
  & .css-tlfecz-indicatorContainer {
    display: none;
  }
  & .css-1gtu0rj-indicatorContainer {
    display: none;
  }
`;
export const ApplyButton = styled.div`
  width: 100%;
  height: 4.4rem;
  background: #22a3c3;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-top: 5.4rem;
`;
