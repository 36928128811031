import styled, { css } from 'styled-components';

export const Form = styled.form``;
export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;
  ${({ theme }) => theme.mobile`
    flex-direction: column;
    margin: 0;
  `}
`;
export const InputWrapper = styled.div`
  display: flex;
  max-width: ${(props) => (props.max ? '48.6%' : '30.1rem')};
  width: 48.6%;
  ${({ full }) =>
    full &&
    css`
      max-width: 100%;
      width: 100%;
    `}
  ${({ theme }) => theme.mobile`
      width: 100%;
      max-width: 100%;
      margin-bottom: 1.4rem;
  `}
`;

export const Input = styled.input`
  height: 4rem;
  margin-left: 2rem;
  :first-child {
    margin: 0;
  }
`;
