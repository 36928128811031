import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import CustomModal from 'components/common/modal/Modal';

import * as S from './components';

import { ReactComponent as ExitIcon } from 'assets/icons/close-gray.svg';
import { ReactComponent as Paw } from 'assets/icons/paw-red.svg';

const RemovePetModal = ({
  isOpen,
  onRequestClose,
  petName,
  handleRemovePet,
  uid,
  isForAppointment,
  appointmentRouteId,
  removeVisit
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  const handleConfirm = () => {
    isForAppointment ? removeVisit(appointmentRouteId, comment) : handleRemovePet(uid);
    onRequestClose();
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Wrapper isForAppointment={isForAppointment}>
        <S.Header>
          <S.ImageWrapper isForAppointment={isForAppointment}>
            <Paw />
          </S.ImageWrapper>
          <S.Exit isForAppointment={isForAppointment} onClick={onRequestClose}>
            <ExitIcon fill="#9094AC" />
          </S.Exit>
        </S.Header>
        <S.Main isForAppointment={isForAppointment}>
          {isForAppointment ? (
            <>
              <S.MainText isForAppointment={isForAppointment}>
                {t('are.you.sure.want.to')}
              </S.MainText>
              <S.MainTitle isForAppointment={isForAppointment}>
                {t('cancel.the.appointment')}
              </S.MainTitle>
            </>
          ) : (
            <>
              <S.MainTitle>
                {t('are.you.sure.remove.pet')}
                <S.HighlightedPetName> {petName}</S.HighlightedPetName>?
              </S.MainTitle>
              <S.MainText>
                {t('deleting.your.pet.will.remove.all.pets.data.permanently.this.cannot.be.undone')}
              </S.MainText>
            </>
          )}
        </S.Main>
        <S.Fotter isForAppointment={isForAppointment}>
          <S.Input
            placeholder={t('add.comment.optional')}
            onChange={(e) => setComment(e?.target?.value)}
          />
          <S.Confirm isForAppointment={isForAppointment} onClick={handleConfirm}>
            {t('confirm')}
          </S.Confirm>
        </S.Fotter>
      </S.Wrapper>
    </CustomModal>
  );
};

export default RemovePetModal;
