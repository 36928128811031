import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import Modal from 'components/common/modal/Modal';
import Button from '../common/button/Button';

import { ReactComponent as Clock } from 'assets/icons/clock-blue.svg';

import './style.scss';

const AppointmentDurationModal = ({ onclose, onConfirm, duration }) => {
  const [appDuration, setAppDuration] = useState(duration);
  const { t } = useTranslation();
  return (
    <Modal zIndex={1001} isOpen={true} onRequestClose={onclose}>
      <div className="appointment-duration-modal">
        <h3>
          <Clock /> {t('new_appointment.set_duration_title')}
        </h3>
        <p>
          {' '}
          Changing the appointment duration is affecting the <strong>ETA</strong> of the next
          appointments{' '}
        </p>
        <div className="duration-input-wrapper">
          <Input
            value={appDuration || 0}
            onChange={(e) => setAppDuration(e.target.value)}
            suffix={<span className="suffix">{t('new_appointment.input_suffix')}</span>}
            style={{ height: 48, borderColor: '#BFD7DA' }}
          />
        </div>
        <div className="footer-wrapper">
          <Button label={t('cancel')} className="cancel-btn" onClick={onclose} />
          <Button
            label={t('done')}
            color="primary"
            className="next-btn"
            onClick={() => onConfirm(appDuration)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AppointmentDurationModal;
