import { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  getVetByShareIdQuery,
  requestCodeMutation,
  getVetsForUserQuery,
  checkIfUserExistsQuery
} from 'gql';

const useCalendar = () => {
  const params = useParams();
  const location = useLocation();

  const isBooking = useMemo(() => {
    return location.pathname.includes('book');
  }, [location.pathname]);

  const [currentVet, setCurrentVet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vets, setVets] = useState([]);
  const [placeId, setPlaceId] = useState(null);

  const [requestCode] = useMutation(requestCodeMutation);

  const [getSelectedVet] = useLazyQuery(getVetByShareIdQuery, {
    variables: {
      record: {
        shareId: params?.shareId
      }
    }
  });
  const [getVetsForUser] = useLazyQuery(getVetsForUserQuery);
  const [checkIfUserExists] = useLazyQuery(checkIfUserExistsQuery);

  useEffect(async () => {
    if (!currentVet) {
      if (params?.placeId) setPlaceId(params?.placeId);
      if (params?.shareId) {
        await getSelectedVet().then((res) => {
          const { getVetByShareId } = res?.data || {};
          if (getVetByShareId) {
            setCurrentVet(getVetByShareId);
            setLoading(false);
          }
        });
      } else {
        await getVetsForUser().then(async (res) => {
          const { getVetsForUser } = res.data;
          setVets(getVetsForUser);
          const vet = getVetsForUser?.[0]?.vet;
          if (vet) {
            await getSelectedVet({
              variables: {
                record: {
                  shareId: vet?.shareId
                }
              }
            }).then((res) => {
              const { getVetByShareId } = res?.data || {};
              if (getVetByShareId) {
                setCurrentVet(getVetByShareId);
                setLoading(false);
              }
            });
          }
        });
      }
    }
  }, [params?.shareId, currentVet]);

  const sendRequestCode = async ({ phoneNumber, phonePrefix, role }) => {
    try {
      return await requestCode({
        variables: {
          record: {
            phonePrefix,
            phoneNumber,
            role
          }
        }
      });
    } catch (e) {
      return e;
    }
  };

  return {
    selectedVet: currentVet,
    setCurrentVet,
    loading,
    sendRequestCode,
    isBooking,
    vets,
    placeId,
    checkExistingUser: checkIfUserExists
  };
};

export default useCalendar;
