import React from 'react';

import { useTranslation } from 'react-i18next';
import { routeStatus as Route } from 'components/timeline/appointments/useTimelineAppointments';

import { ReactComponent as Paw } from 'assets/icons/animal-foot.svg';
import {
  FieldTimeOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  CalendarOutlined
} from '@ant-design/icons';

import './styles.css';

const AppointmentPopover = ({
  setSelectedAppointment,
  uid,
  appointment,
  user,
  handleTogglePopover,
  routeStatus,
  pets
}) => {
  const { t } = useTranslation();

  const handleDuration = () => {
    if (handleTogglePopover) handleTogglePopover();
    setSelectedAppointment((prev) => ({
      ...prev,
      duration: appointment
    }));
  };

  const handleETA = () => {
    if (handleTogglePopover) handleTogglePopover();
    setSelectedAppointment((prev) => ({
      ...prev,
      eta: appointment
    }));
  };

  const handleAddPatient = () => {
    if (handleTogglePopover) handleTogglePopover();
    setSelectedAppointment((prev) => ({
      ...prev,
      addPatient: {
        appointment,
        user,
        pets
      }
    }));
  };

  const handleCancel = () => {
    if (handleTogglePopover) handleTogglePopover();
    setSelectedAppointment((prev) => ({
      ...prev,
      cancel: {
        appointment,
        user
      }
    }));
  };

  const handleReschedule = () => {
    if (handleTogglePopover) handleTogglePopover();
    setSelectedAppointment((prev) => ({
      ...prev,
      reschedule: {
        uid,
        appointment
      }
    }));
  };

  return (
    <div className="appointment-popover-wrapper">
      <div className="appointment-popover-item" onClick={handleAddPatient}>
        <Paw className="appointment-popover-item-icon" />
        <h3 className="appointment-popover-item-lbl">
          {t('new_appointment.add_patients_menu_item')}
        </h3>
      </div>
      <div className="appointment-popover-item" onClick={handleReschedule}>
        <CalendarOutlined className="appointment-popover-item-icon" />
        <h3 className="appointment-popover-item-lbl">
          {t('new_appointment.reschedule_appointment')}
        </h3>
      </div>
      <div className="appointment-popover-item" onClick={handleCancel}>
        <DeleteOutlined className="appointment-popover-item-icon" />
        <h3 className="appointment-popover-item-lbl">{t('new_appointment.cancel_appointment')}</h3>
      </div>
      <div className="appointment-popover-item" onClick={handleDuration}>
        <ClockCircleOutlined className="appointment-popover-item-icon" />
        <h3 className="appointment-popover-item-lbl">{t('new_appointment.view_duration')}</h3>
      </div>
      {routeStatus?.toLowerCase() !== Route.REGISTER && (
        <div className="appointment-popover-item" onClick={handleETA}>
          <FieldTimeOutlined className="appointment-popover-item-icon" />
          <h3 className="appointment-popover-item-icon">{t('new_appointment.edit_eta')}</h3>
        </div>
      )}
    </div>
  );
};

export default AppointmentPopover;
