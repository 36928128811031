import { useMutation } from '@apollo/client';

import { updateNotificationSeenMutation } from 'gql';

const useNotificationCard = () => {
  const [updateSeen] = useMutation(updateNotificationSeenMutation);

  const handleUpdateSeen = (uid, seen) => {
    updateSeen({
      variables: {
        record: {
          notificationId: uid,
          seen
        }
      }
    });
  };

  return { handleUpdateSeen };
};

export default useNotificationCard;
