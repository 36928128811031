import React, { memo, useContext, useEffect, useState } from 'react';
import style from './SectionUserLogin.module.css';
import { Button } from 'antd';
import PhoneInputNew from '../../../components/PhoneInput/PhoneInput';
import { useTranslation } from 'react-i18next';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';
import { phoneValidationScheme } from '../schemes';

const SectionUserLogin = ({ checkPhoneNumber, phone, prefix }) => {
  const [number, setNumber] = useState({ phone, prefix });
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const submitPhone = () =>
    phoneValidationScheme
      .validate(number)
      .then((data) => {
        setError('');
        checkPhoneNumber(data);
      })
      .catch((err) => {
        setError(t(err.message));
      });

  useEffect(() => {
    setNumber({ phone, prefix });
  }, [phone, prefix]);

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [number.phone, number.prefix]);

  return (
    <div className={style.section}>
      <h2>Let’s bring up your details</h2>
      <h3>Enter Phone number</h3>
      <span>Hint: Try your phone number as well as other family members number</span>
      <div className={style.input}>
        <PhoneInputNew
          code={number.prefix || '+1'}
          number={number.phone}
          onChange={(pr, ph) => setNumber({ prefix: pr, phone: ph })}
          error={error}
        />
        <span className={style.inputError}>{error}</span>
      </div>
      <div className={style.buttonContainer}>
        <Button type="primary" onClick={submitPhone}>
          Check number
        </Button>
      </div>
    </div>
  );
};

const Memoized = memo(SectionUserLogin);
const Container = () => {
  const { checkPhoneNumber, phone, prefix } = useContext(ScheduleAppointmentContext);
  return <Memoized checkPhoneNumber={checkPhoneNumber} phone={phone} prefix={prefix} />;
};

export default Container;
