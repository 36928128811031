import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SIGN_IN_VET_ADMIN } from 'constants/client';
import { Roles } from 'constants/enums';

require('components/vet-components/layout/layout.css');

const VetRoute = ({
  component: Component,
  user,
  isHome,
  layout: Layout,
  isCustomers,
  disableMobilePadding,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return user && user.role === Roles.VET_ADMIN ? (
          <WrapWithLayout
            Layout={Layout}
            isHome={isHome}
            isCustomers={isCustomers}
            disableMobilePadding={disableMobilePadding}
          >
            <Component {...props} />
          </WrapWithLayout>
        ) : (
          <Redirect to={SIGN_IN_VET_ADMIN} />
        );
      }}
    />
  );
};

const WrapWithLayout = ({ children, Layout, isHome, isCustomers, disableMobilePadding }) => {
  if (Layout) {
    return (
      <Layout
        isAdmin
        isHome={isHome}
        isCustomers={isCustomers}
        disableMobilePadding={disableMobilePadding}
      >
        {children}
      </Layout>
    );
  }
  return children;
};

export default VetRoute;
