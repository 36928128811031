import React from 'react';
import useChoosePet from './useChoosePet';
import * as S from './components';
import { ChoosePetModal } from 'components/choose-pet-modal/ChoosePetModal';
import { Caption, Description } from 'components/membership/commonComponents';
import { useTranslation } from 'react-i18next';
import AddPetButton from 'components/membership/add-pet-button/AddPetButton';
import PetModal from 'components/pet-modal/PetModal';
import MembershipPetList from 'components/membership/membership-pets-list/MembershipPetList';
import NavigationButtons from '../../../components/membership/navigation-button/NavigationButtons';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

const ChoosePet = () => {
  const {
    data,
    loading,
    discountModalOpen,
    congratsModalOpen,
    selectedPets,
    setSelectedPets,
    isAddPetModalOpen,
    setIsAddPetModalOpen,
    closeModal,
    submitHandler,
    goBackHandler,
    continueClickHandler
  } = useChoosePet();
  const { t } = useTranslation();

  const petsExist = data?.getPetsByOwner?.length > 0;

  return (
    <S.Wrapper>
      {loading && <LoaderWrapper isLoading={loading} />}
      <S.Main>
        {petsExist && (
          <>
            <S.ChoosePetsHeader>
              <S.ChoosePetsTitlesWrapper>
                <Caption>{t('select.pets')}</Caption>
                <S.ChoosePetsTextWrapper>
                  <Description>{t('choose.your.pets.to.continue')}</Description>
                </S.ChoosePetsTextWrapper>
              </S.ChoosePetsTitlesWrapper>
              <S.AddButtonWrapper>
                <AddPetButton
                  handleClick={() => {
                    setIsAddPetModalOpen(true);
                  }}
                />
              </S.AddButtonWrapper>
            </S.ChoosePetsHeader>
            <MembershipPetList
              data={data.getPetsByOwner}
              selectedPets={selectedPets}
              setSelectedPets={setSelectedPets}
            />
          </>
        )}
        <PetModal
          isOpen={isAddPetModalOpen}
          handleCloseModal={() => {
            setIsAddPetModalOpen(false);
          }}
        />
        <ChoosePetModal
          discountModalOpen={discountModalOpen}
          closeModal={closeModal}
          congratsModalOpen={congratsModalOpen}
          continueClickHandler={continueClickHandler}
        />
      </S.Main>
      <NavigationButtons
        disabled={!Object.keys(selectedPets).length}
        submitHandler={data?.getPetsByOwner.length === 1 ? continueClickHandler : submitHandler}
        goBackHandler={goBackHandler}
      />
    </S.Wrapper>
  );
};

export default ChoosePet;
