import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 20rem;
  border-radius: 0.8rem;
  padding: 1.6rem;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${({ theme }) => theme.desktop`
    width: 100%;
    max-width: unset; 
    `}
  ${({ theme }) => theme.mobile`
    border: 0.1rem solid #DBE2EE;
    height: 13.2rem;
    :nth-child(odd) {
      margin-right: unset;
    }
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 80%;
`;

export const Avatar = styled.img`
  height: 100%;
  object-fit: cover;
  width: 60%;
  min-width: 60%;
  max-width: 15.9rem;
  margin-right: 8%;
  border-radius: 0.8rem;
  ${({ hasAvatar }) =>
    !hasAvatar &&
    css`
      background-color: #dbe2ee;
      padding: 3.6rem 0;
      object-fit: contain;
    `}

  border: none;
  ${({ theme }) => theme.mobile`
    width: 10rem;
  `}
`;

export const PetName = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 2rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const PetAge = styled.h3`
  color: #8a94a6;
  font-size: 1.6rem;
  line-height: 1.88;
  font-family: inter, assistant;
  font-weight: 400;
  white-space: nowrap;
`;

export const NameAndAgeWrapper = styled.div`
  width: 48%;
`;

export const ShowMoreBtn = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const ShowMoreIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ExpandedActionsWrapper = styled.div`
  position: absolute;
  top: 5rem;
  right: ${({ index }) => (!(+index % 2) ? '-17rem' : '2rem')};
  background-color: #ffffff;
  padding: 0.8rem;
  box-shadow: 0 0.8rem 4.5rem rgba(5, 40, 72, 0.08);
  width: 21.3rem;
  z-index: 2;
  border-radius: 0.8rem;
  ${({ theme }) => theme.mobile`
    right: -2rem;
    top: -8rem;
  `}
  ::after {
    content: '';
    position: absolute;
    top: -0.4rem;
    ${({ index }) => (!(+index % 2) ? 'left: 1rem' : 'right 1rem')};
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    ${({ theme }) => theme.mobile`
      display: none;
    `}
  }
`;

const ExpandedActionsBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  :first-child {
    margin-bottom: 0.4rem;
  }
`;

const ExpandedActionsBtnIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.2rem;
`;

const ExpandedActionsBtnLabel = styled.h3`
  font-family: inter, assistant;
  font-weight: 500;
  color: #5c6874;
  font-size: 1.4rem;
`;

export const ActionButton = ({ icon, label, submitHandler }) => (
  <ExpandedActionsBtnWrapper onClick={submitHandler}>
    <ExpandedActionsBtnIcon alt={label} src={icon} />
    <ExpandedActionsBtnLabel>{label}</ExpandedActionsBtnLabel>
  </ExpandedActionsBtnWrapper>
);
