import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';

import { meQuery } from 'gql';
import { Roles } from '../../constants/enums';
import { SIGN_IN } from '../../constants/client';
import { useHistory } from 'react-router-dom';
import { SCHEDULE } from '../../../constants/client';

const useAuth = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [hasToken, setHasToken] = useState(
    () => !!sessionStorage.getItem('token') || !!localStorage.getItem('token')
  );

  const { data, loading, refetch, error } = useQuery(meQuery, {
    fetchPolicy: 'no-cache',
    skip: !hasToken
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  const setToken = (token, setSessionTokenOnly = false) => {
    if (setSessionTokenOnly) {
      sessionStorage.setItem('token', token);
    } else {
      localStorage.setItem('token', token);
    }

    setHasToken(true);
  };

  const getToken = () => {
    let token = sessionStorage.getItem('token');
    if (!token) {
      token = localStorage.getItem('token');
    }
    return token;
  };

  const hierarchalTokenRemoval = (
    removeFromSessionStorage = true,
    removeFromLocalStorage = true
  ) => {
    if (removeFromSessionStorage) {
      sessionStorage.removeItem('token');
    }

    if (removeFromLocalStorage) {
      localStorage.removeItem('token');
    }
  };

  const removeToken = (isFromVetRoute, isFromReschedule) => {
    hierarchalTokenRemoval();
    setHasToken(false);
    client.clearStore();
    if (isFromReschedule) {
      window.location.reload();
    } else {
      if (!isFromVetRoute) {
        if (data?.me && data.me.role !== Roles.VET) window.location = SIGN_IN;
        else history.replace(SIGN_IN);
      }
    }
  };

  const logout = (isFromVetRoute, isFromReschedule) => {
    removeToken(isFromVetRoute, isFromReschedule);
  };

  useEffect(() => {
    if (error && !loading) {
      console.log(error);
    }
  }, [error, loading]);

  const Roles = {
    VetsAdmin: 'VETS_ADMIN',
    Admin: 'ADMIN',
    Vet: 'VET'
  };

  return {
    setToken,
    removeToken,
    me: data && data.me ? data.me : user || undefined,
    meLoading: loading,
    meRefetch: refetch,
    setUser: (user) => setUser(user),
    hasToken,
    logout
  };
};

export default useAuth;
