import React, { memo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DragAndDrop = ({ data, setData, isDragDisabled, ChildComponent, ...rest }) => {
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "#f8f9fb",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);

    setData(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {data.map((item, index) => (
              <Draggable
                isDragDisabled={isDragDisabled}
                key={item.uid}
                draggableId={item.uid}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    <ChildComponent
                      data={item}
                      {...item}
                      hideHr={index === 0}
                      isDragging={!isDragDisabled}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(DragAndDrop);
