import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form, Tooltip, message, Button } from 'antd';
import { InfoCircleFilled, CopyOutlined } from '@ant-design/icons';

import './styles.css';
import * as S from './components';

const CalendarLink = ({ vet, isMobile }) => {
  const { t } = useTranslation();

  const vetCalendarLink = `${process.env.REACT_APP_PETS_WEB_ROOT_URL}/schedule/${vet?.user?.firstName
    ?.replaceAll('-', '_')
    ?.replaceAll(' ', '_')}-${vet?.user?.lastName
    ?.replaceAll('-', '_')
    ?.replaceAll(' ', '_')}-${vet?.shareId}`;

  return (
    <S.Wrapper isMobile={isMobile}>
      <Form.Item
        labelCol={{ span: 24 }}
        className="calendar-link"
        label={
          <>
            {t('vet_routes.calendar_link')}
            <Tooltip title={t('vet_routes.calendar_tooltip')}>
              <InfoCircleFilled className="address-info-icon" />
            </Tooltip>
          </>
        }>
        <Input.Group compact>
          <Input
            style={{ width: 'calc(100% - 31px)' }}
            defaultValue={vetCalendarLink?.replace('https://', '')?.replace('http://', '')}
            disabled={true}
          />
          <Tooltip title={t('vet_routes.copy_link')}>
            <Button
              style={{ height: '10 px !important' }}
              icon={<CopyOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(vetCalendarLink);
                message.success(t('copied.to.clipboard'));
              }}
            />
          </Tooltip>
        </Input.Group>
      </Form.Item>
    </S.Wrapper>
  );
};

export default memo(CalendarLink);
