import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import AvatarGroup from 'components/avatar-group/AvatarGroup';
import { useTranslation } from 'react-i18next';

const ClientCard = ({ item, onClientSelect, selectedClient, onClientEdit }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`client-card-wrapper ${selectedClient?.user?.uid === item.user?.uid ? 'active' : ''}`}
      onClick={() => onClientSelect(item)}>
      {item.pets?.length > 0 ? (
        <div>
          <AvatarGroup pets={item.pets} />
        </div>
      ) : (
        <div />
      )}
      <div className="client-details-wrapper">
        <h3>
          {item?.user?.firstName} {item?.user?.lastName}
        </h3>
        <div className="address-wrapper">
          <h4 onClick={(e) => onClientEdit(e, item?.user)}>
            {t('admin.recommender.new_appointment.address')}
          </h4>
          <h5 onClick={(e) => onClientEdit(e, item?.user)}>{item?.user?.address?.description}</h5>
        </div>
        <h5>
          {item?.user?.address?.city}, {item?.user?.address?.country?.name}{' '}
          <EditOutlined onClick={(e) => onClientEdit(e, item?.user)} />
        </h5>
      </div>
    </div>
  );
};

export default ClientCard;
