import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import useVerifyCodeForm from './useVerifyCodeForm';
import { BottomSection, Question } from 'pages/auth/sign-up/components';
import {
  DigitsWrapper,
  DigitInput,
  Form,
  ResendCodeButtonWrapper,
  ResendCodeButton,
  BottomSectionContent,
  RemainingTime,
  Error,
  CodeSent,
  QuestionButtonWrapper,
  ResentText,
  CodeSentMobile
} from './components';
import { formatTime } from 'utils/helpers';
import { ReactComponent as CheckboxSuccess } from 'assets/icons/checkbox-green.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle-green.svg';

const VerifyCodeForm = ({
  isForModal,
  email,
  setIsVerificationCodeSent,
  onRequestClose,
  isPhoneNumberExists,
  successHandler
}) => {
  const {
    digits,
    handleSubmit,
    isSubmitting,
    errors,
    resendCodeIn,
    handleResendCode,
    requestingCode,
    touched,
    codeResendSuccess
  } = useVerifyCodeForm(
    email,
    setIsVerificationCodeSent,
    onRequestClose,
    isPhoneNumberExists,
    successHandler
  );

  const { t } = useTranslation();
  const hasError = (touched.code && errors.code) || errors.serverError;
  const { isMobile } = mediaQueriesList();

  const [isFirstTimeCodeSend, setIsFirstTimeCodeSent] = useState(true);
  useEffect(() => {
    requestingCode && setIsFirstTimeCodeSent(false);
  }, [requestingCode]);

  const isDisabled = useMemo(() => {
    return digits.filter((digit) => digit.value !== '').length !== digits.length;
  }, [digits]);

  return (
    <Form onSubmit={handleSubmit}>
      <DigitsWrapper dir="ltr">
        <DigitInput
          inputMode="decimal"
          autoFocus
          {...digits[0]}
          error={hasError}
          isForModal={isForModal}
        />
        <DigitInput inputMode="decimal" {...digits[1]} error={hasError} isForModal={isForModal} />
        <DigitInput inputMode="decimal" {...digits[2]} error={hasError} isForModal={isForModal} />
        <DigitInput inputMode="decimal" {...digits[3]} error={hasError} isForModal={isForModal} />
        <DigitInput inputMode="decimal" {...digits[4]} error={hasError} isForModal={isForModal} />
        <DigitInput inputMode="decimal" {...digits[5]} error={hasError} isForModal={isForModal} />
      </DigitsWrapper>

      {touched.code && errors.code && <Error>{errors.code}</Error>}
      {errors.serverError && <Error>{t('please.provide.right.code')}</Error>}
      <Button
        isForModal={isForModal}
        type="submit"
        disabled={isSubmitting || isDisabled}
        color="primary"
        style={{ marginTop: '2.4rem' }}>
        {isForModal ? t('submit') : t('verify.code')}
      </Button>
      <BottomSection isForModal={isForModal}>
        <BottomSectionContent>
          <QuestionButtonWrapper>
            {(resendCodeIn <= 0 || isFirstTimeCodeSend || codeResendSuccess) && (
              <Question isForModal={isForModal}>{t("Haven't received it?")}</Question>
            )}
            <ResendCodeButtonWrapper>
              {isMobile && (
                <CodeSentMobile visible={codeResendSuccess}>
                  <div style={{ display: 'flex' }}>
                    <CheckCircle />
                  </div>
                  {t('code.sent')}
                </CodeSentMobile>
              )}
              {(resendCodeIn <= 0 || isFirstTimeCodeSend || codeResendSuccess) && (
                <ResendCodeButton
                  onClick={handleResendCode}
                  disabled={resendCodeIn >= 0 || requestingCode}
                  isForModal={isForModal}>
                  {isMobile && !isForModal ? t('resend') : t('resend.new.code')}
                </ResendCodeButton>
              )}
              {resendCodeIn > 0 && !isFirstTimeCodeSend && !codeResendSuccess && (
                <ResentText>{t('you.will.be.able.to.resend.code.in')}</ResentText>
              )}
            </ResendCodeButtonWrapper>
          </QuestionButtonWrapper>
          {!isMobile && (
            <CodeSent visible={codeResendSuccess}>
              <div style={{ marginRight: 10 }}>
                <CheckboxSuccess />
              </div>
              {t('code.sent')}
            </CodeSent>
          )}
          {resendCodeIn > 0 && !codeResendSuccess && (
            <RemainingTime>{formatTime(resendCodeIn)} </RemainingTime>
          )}
        </BottomSectionContent>
      </BottomSection>
    </Form>
  );
};

export default VerifyCodeForm;
