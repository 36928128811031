import React from 'react';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import CheckoutDetailsPetCard from 'components/membership/checkout-details-pet-card/CheckoutDetailsPetCard';

const CheckoutDetailsPetsSlider = ({ pets, prevRef, nextRef }) => {
  return (
    <ReactSwiper
      slidesPerView={5}
      spaceBetween={20}
      navigation={{
        prevEl: prevRef.current ? prevRef.current : undefined,
        nextEl: nextRef.current ? nextRef.current : undefined
      }}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.update();
      }}>
      {pets?.map((pet) => {
        return (
          <SwiperSlide key={pet.uid}>
            <CheckoutDetailsPetCard
              image={pet?.pet?.avatarUrl}
              name={pet?.pet?.name}
              price={pet?.plan?.price}
              currency={pet?.plan?.currency}
              type={pet?.pet?.type?.name}
              plan={pet?.plan}
              isForSlider={true}
            />
          </SwiperSlide>
        );
      })}
    </ReactSwiper>
  );
};

export default CheckoutDetailsPetsSlider;
