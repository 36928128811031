import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 5.6rem;
  width: 9.5rem;
  border: 1px solid #acbcd4;
  border-radius: 0.8rem;
  background: ${({ theme, isActive, disabled }) =>
    isActive ? theme.colors.orange : disabled ? theme.colors.gray : 'rgba(219, 226, 238, 0.12)'};
  padding: 1rem 0;
  border-color: ${({ theme, isActive }) => isActive && theme.colors.orange};
  :hover {
    border-color: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.orange)};
  }
  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Date = styled.span`
  margin-top: 0.1rem;
  font-size: 1rem;
  font-family: inter-r, assistant;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : '#acbcd4')};
  align-self: flex-start;
`;
export const Hours = styled.span`
  font-family: inter-m, assistant;
  font-size: 1.2rem;
  margin-bottom: 0.1rem;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.black)};
`;
