import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { createPetMutation, getPetQuery, getPetTypesQuery } from 'gql';

const useAddPetModal = (petIdToUpdate, closePetModal) => {
  const { t } = useTranslation();
  const [createPet] = useMutation(createPetMutation);
  const { data: petTypesData } = useQuery(getPetTypesQuery);

  const handleSubmit = async (values) => {
    createPet({
      variables: {
        record: values
      },
      refetchQueries: ['me', 'getPetsByUser', 'getPetOwners', 'getPetOwnersTotals']
    })
      .then(() => {
        message.success(t('updated.successfully'));
        closePetModal();
      })
      .catch((err) => {
        message.error(err?.message || t('failed.to.update'));
      });
  };

  const { data: singlePetData } = useQuery(getPetQuery, {
    variables: {
      record: {
        uid: petIdToUpdate
      }
    },
    skip: !petIdToUpdate
  });

  const [petIntegration] = singlePetData?.pet?.petIntegrations ?? [];

  const petFormInitialValues = useMemo(() => {
    return {
      avatarUrl: singlePetData?.pet?.avatarUrl,
      name: singlePetData?.pet?.name,
      type: singlePetData?.pet?.type?.uid,
      gender: singlePetData?.pet?.gender,
      birthDate: !singlePetData?.pet?.birthDate
        ? null
        : moment(singlePetData?.pet?.birthDate, 'YYYY-MM-DD'),
      isSterilized: singlePetData?.pet?.isSterilized,
      weight: singlePetData?.pet?.weight,
      chipNumber: singlePetData?.pet?.chipNumber,
      breed: singlePetData?.pet?.breed,
      medicalAlerts: singlePetData?.pet?.medicalAlerts,
      foodType: singlePetData?.pet?.foodType,
      behavioralNotes: singlePetData?.pet?.behavioralNotes,
      allergies: singlePetData?.pet?.allergies || [],
      externalId: petIntegration?.externalId
    };
  }, [singlePetData]);

  return {
    petTypes: petTypesData?.petTypes?.map((item) => {
      return { value: item.uid, displayValue: item.label };
    }),
    handleSubmit,
    petFormInitialValues,
    petIntegration
  };
};

export default useAddPetModal;
