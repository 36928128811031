/* eslint-disable max-len */
import React from 'react';

const locationIcon = (
  <svg width="18" height="20" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.6C4.67452 3.6 3.6 4.67452 3.6 6C3.6 7.32548 4.67452 8.4 6 8.4C7.32548 8.4 8.4 7.32548 8.4 6C8.4 4.67452 7.32548 3.6 6 3.6ZM4.8 6C4.8 5.33726 5.33726 4.8 6 4.8C6.66274 4.8 7.2 5.33726 7.2 6C7.2 6.66274 6.66274 7.2 6 7.2C5.33726 7.2 4.8 6.66274 4.8 6Z"
      fill="#22A3C3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.69907 0 0 2.61993 0 5.88C0 8.08005 1.31777 10.1994 2.61977 11.7159C3.28096 12.4859 3.96317 13.1299 4.52532 13.5855C4.80563 13.8127 5.06404 13.9995 5.28142 14.1332C5.38942 14.1996 5.49708 14.259 5.59884 14.3037C5.68132 14.34 5.83124 14.4 6 14.4C6.16876 14.4 6.31868 14.34 6.40117 14.3037C6.50292 14.259 6.61058 14.1996 6.71858 14.1332C6.93596 13.9995 7.19437 13.8127 7.47468 13.5855C8.03683 13.1299 8.71904 12.4859 9.38023 11.7159C10.6822 10.1994 12 8.08005 12 5.88C12 2.61993 9.30093 0 6 0ZM1.2 5.88C1.2 3.30795 3.33625 1.2 6 1.2C8.66375 1.2 10.8 3.30795 10.8 5.88C10.8 7.63995 9.71778 9.48059 8.46977 10.9341C7.85597 11.649 7.22567 12.2426 6.71907 12.6533C6.465 12.8592 6.24998 13.0126 6.09002 13.111C6.05577 13.1321 6.02579 13.1495 6 13.1638C5.9742 13.1495 5.94423 13.1321 5.90998 13.111C5.75002 13.0126 5.535 12.8592 5.28093 12.6533C4.77433 12.2426 4.14404 11.649 3.53023 10.9341C2.28223 9.48059 1.2 7.63995 1.2 5.88Z"
      fill="#22A3C3"
    />
  </svg>
);

export default locationIcon;
