import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';

import { useLocalizationContext } from 'common_repo';
import { getUserByPhoneQuery, requestCodeMutation } from 'gql';

import { SignUpValidationScheme } from 'validations/validations';

import options from '../../country-phones.json';
import { VERIFY_CODE } from 'constants/client';
import { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { useLazyQuery } from '@apollo/client';
import { Roles } from '../../constants/enums';

const useSignUpForm = (redirectUrl, isVet) => {
  const history = useHistory();
  const { setCodeSendAt } = useContext(AuthContext);
  const [requestCode] = useMutation(requestCodeMutation);
  const { countryCode } = useLocalizationContext();
  const initialValues = {
    phonePrefix: options.find((o) => o.code === countryCode)?.dial_code || '',
    phoneNumber: ''
  };
  const [getUserByPhone] = useLazyQuery(getUserByPhoneQuery);

  const onSubmit = async ({ phoneNumber, phonePrefix }, { setSubmitting }) => {
    try {
      setSubmitting(true);
      if (!isVet) {
        const result = await getUserByPhone({
          variables: {
            record: {
              phonePrefix: values.phonePrefix,
              phoneNumber: values.phoneNumber
            }
          },
          errorPolicy: 'ignore',
          fetchPolicy: 'network-only'
        });
        if (result.data.getUserByPhone?.role !== Roles.PET_OWNER) {
          setFieldError(
            'serverError',
            "Pet's owner doesn't exist. To Sign up, please contact your Vet"
          );
          setSubmitting(false);
          return;
        }
      }
      const res = await requestCode({
        variables: {
          record: {
            phonePrefix,
            phoneNumber
          }
        }
      });

      setSubmitting(false);
      setCodeSendAt(res?.data?.requestCode?.codeSentAt);
      if (redirectUrl) {
        localStorage.setItem('redirectUrl', JSON.stringify(redirectUrl));
      }
      history.replace(
        `${VERIFY_CODE}?prefix=${encodeURIComponent(phonePrefix)}&number=${
          res?.data?.requestCode?.phoneNumber
        }`
      );
    } catch (e) {
      setFieldError('serverError', e.graphQLErrors?.[0]?.message);
      setSubmitting(false);
    }
  };

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    touched,
    errors,
    isSubmitting,
    setFieldError
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: SignUpValidationScheme
  });

  return {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    isSubmitting
  };
};
export default useSignUpForm;
