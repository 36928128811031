import React from 'react';
import cls from 'classnames';
import css from './SingleOption.module.css';
import { cssText, cssColor } from '_fsd/shared';

export const SingleOption = ({ active, icon, className, children, colorScheme, ...props }) => {
  return (
    <div
      {...props}
      className={cls(
        css.option,
        {
          [css.active]: active
        },
        className
      )}>
      {icon && <div className={css.icon}>{icon}</div>}
      <span
        className={cls({
          [cssText.s20h24w6]: active,
          [cssText.s18h24w5]: !active,
          [cssColor.c323B4B]: active
        })}>
        {children}
      </span>
    </div>
  );
};
