import dayjs from 'dayjs';

export const parseTimeSlotHour = (hour) => {
  let res = '';
  try {
    if (hour != null) {
      res = dayjs()
        .set('hour', Number(hour?.split(':')[0]))
        .set('minute', Number(hour?.split(':')[1]))
        .format('h:mm a')
        .toUpperCase();
    }
  } catch {
    return res;
  }
  return res;
};
