import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const useGetVetIdsByUserConcernUid = (userConcernId) => {
  const { data, ...rest } = useQuery(query, {
    variables: {
      record: {
        userConcernId
      }
    },
    fetchPolicy: 'network-only',
    skip: !userConcernId
  });

  return { data: data?.getVetsByUserConcernId, ...rest };
};

const query = gql`
  query GetVetsByUserConcernId($record: GetVetsByUserConcernId!) {
    getVetsByUserConcernId(record: $record) {
      uid
    }
  }
`;
