import { useQuery } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom';

import { calculateSubscriptionPriceQuery, getSubscriptionQuery } from 'gql';

const useChoosePetModal = () => {
  const match = useRouteMatch();
  const { id } = match.params;

  const { data: discountData } = useQuery(calculateSubscriptionPriceQuery, {
    variables: { record: { subscriptionId: id } }
  });
  const { data } = useQuery(getSubscriptionQuery, {
    variables: {
      record: {
        subscriptionId: id
      }
    }
  });

  const discount = discountData?.calculateSubscriptionPrice;
  const percentage = `${Math.round(discount?.discountAmount * 100)}${
    discount?.additionalDiscountAmount > 0
      ? '% + ' + Math.round(discount?.additionalDiscountAmount * 100)
      : ''
  }`;

  return {
    discount: `${percentage}% discount`,
    subscriptionType: data?.getSubscription?.subscriptionType?.name
  };
};

export default useChoosePetModal;
