import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
`;

export const GrayIcon = styled.img`
  width: ${({ large }) => (large ? '2.8rem' : '2.4rem')};
  height: ${({ large }) => (large ? '2.8rem' : '2.4rem')};
  background-color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 0.6rem;
  padding: ${({ large }) => (large ? '0.6rem' : '0.5rem')};
  margin-right: 1.2rem;
`;

export const VetImage = styled.img`
  width: 8rem;
  height: 8rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  border-radius: 0.8rem;
  object-fit: cover;
`;

export const VetDefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  background-color: rgba(219, 226, 238, 1);
  font-size: 2rem;
  color: #ffffff;
  border-radius: 0.8rem;
  ${({ theme }) => theme.mobile`
    width: 5.2rem;
    height: 5.6rem;
    font-size: 1.5rem;
  `}
`;

export const VetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VetName = styled.p`
  color: #323b4bb8;
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.4rem;
  height: 2.1rem;
  text-transform: capitalize;
`;

export const LogoAndDetails = styled.div`
  display: flex;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-size: ${({ large }) => (large ? '1.4rem' : '1.3rem')};
  font-weight: ${({ large }) => (large ? '600' : '500')};
  margin-bottom: 0.2rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  > div {
    margin-left: 1.3rem;
    cursor: pointer;
  }
  ${({ large }) => large && 'height: 2.4rem'};
`;

export const Date = styled.p`
  color: #59616e;
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  height: 1.8rem;
`;

export const PlanInfo = styled.div`
  background-color: rgba(34, 163, 195, 0.12);
  height: 2.8rem;
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colors.darkGreen};
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
  ${({ theme, isMonthlySubscription }) =>
    isMonthlySubscription &&
    css`
      color: ${theme.colors.orange};
      background: rgba(255, 155, 5, 0.12);
    `}
`;

export const SingleDetail = styled.div`
  display: flex;
  margin-bottom: ${({ isMedications }) => (isMedications ? 'unset' : '1.6rem')};
  ${({ marginTop }) => marginTop && 'margin-top: 2.8rem'}
`;

export const DetailImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  background: #ffffff;
  border: 0.04rem solid #dbe2ee;
  box-sizing: border-box;
  box-shadow: 0rem 0.4rem 2.4rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  padding: 0.4rem;
  margin-right: 1.6rem;
`;

export const DetailsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsHeading = styled.p`
  color: #323b4b8f;
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  height: 1.8rem;
`;

export const DetailsText = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.1rem;
  max-width: 20rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.gray};
  opacity: 0.4;
  margin-top: 2rem;
`;

export const VisitDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isVisitDetailsOpen }) => (isVisitDetailsOpen ? 'unset' : '2rem')};
  max-height: 100rem;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
`;

export const VisitDetails = styled.div`
  padding-top: 2rem;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Arrow = styled.img`
  width: ${({ large }) => (large ? '1.6rem' : '1.12rem')};
  height: ${({ large }) => (large ? '1.6rem' : '0.72rem')};
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;
  ${({ isVisitDetailsOpen }) => isVisitDetailsOpen && 'transform: rotate(180deg);'};
`;

export const VaccinesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

export const SingleVaccine = styled.div`
  background-color: #dbe2ee1f;
  border-radius: 0.8rem;
  border: 0.05rem solid ${({ theme }) => theme.colors.darkGray};
  color: #323b4bcc;
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1rem;
  max-width: 20.3rem;
  line-height: 1.8rem;
`;

export const ExtraCosts = styled.div`
  padding-top: 2rem;
`;

export const TotalPrice = styled.div`
  height: 3.2rem;
  width: 100%;
  background-color: #f5f6f9;
  border-radius: 0.8rem;
  color: #323b4b;
  font-family: inter, assistant;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.2rem;
  padding: 1.2rem 1.6rem;
`;

export const TotalPriceNumber = styled.p`
  margin-left: 1.9rem;
  font-weight: 600;
`;
