import React, { useEffect, useRef } from 'react';

import {
  InputComponent,
  InputWrapper,
  InputContainer,
  Label,
  ToolTipText,
  IconWrapper,
  CalendarWrapper
} from './components';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ErrorMessage } from '../error-message/components';
import { useLocalizationContext } from 'common_repo';

const Input = ({
  value,
  label,
  error,
  disabled,
  toolTipText,
  isCalendarInput,
  flatBottom,
  hideKeyboardOnFocus,
  isNumeric,
  isWeightInput,
  isChipNumber,
  maxLength,
  isForVet,
  ...props
}) => {
  const ref = useRef();
  const { rtl: isRtl } = useLocalizationContext();

  const blur = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  useEffect(() => {
    if (ref.current && hideKeyboardOnFocus) {
      ref.current.addEventListener('focus', blur);
    }
    return () => ref.current?.removeEventListener('focus', blur);
  }, []);

  return (
    <InputWrapper>
      <InputContainer error={error} flatBottom={flatBottom}>
        {!isCalendarInput ? (
          <>
            <InputComponent
              isRtl={isRtl}
              isForVet={isForVet}
              value={value}
              error={error}
              disabled={disabled}
              {...props}
              ref={ref}
              inputMode={isNumeric || isWeightInput ? 'numeric' : 'text'}
              hasIcon={toolTipText || isCalendarInput}
              isNumeric={isNumeric}
              isWeightInput={isWeightInput}
              isChipNumber={isChipNumber}
              maxLength={maxLength}
            />
            <Label
              value={value}
              error={error}
              isNumeric={isNumeric}
              disabled={disabled}
              isCalendarInput={isCalendarInput}>
              {label}
            </Label>
            {toolTipText && (
              <IconWrapper error={error}>
                <InfoIcon fill="#CFD8E6" />
                {!error && <ToolTipText>{toolTipText}</ToolTipText>}
              </IconWrapper>
            )}
          </>
        ) : (
          <CalendarWrapper error={error} disabled={disabled} {...props} ref={ref} hasIcon>
            <Label
              value={value}
              error={error}
              disabled={disabled}
              isCalendarInput={isCalendarInput}>
              {label}
            </Label>
            <InputComponent
              value={value}
              isForVet={isForVet}
              disabled={isCalendarInput}
              isCalendarInput={isCalendarInput}
            />
            <IconWrapper error={error}>
              <CalendarIcon />
            </IconWrapper>
          </CalendarWrapper>
        )}
      </InputContainer>
      <ErrorMessage>{error}</ErrorMessage>
    </InputWrapper>
  );
};

export default Input;
