import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import moment from 'moment';

const TimePicker = ({ name, form, initialValues, placeholder, onChange, onSelect, value }) => {
  return (
    <AntTimePicker
      style={{ width: '100%' }}
      use12Hours
      minuteStep={15}
      format="h:mm a"
      defaultValue={initialValues[name] ? moment(initialValues[name], 'HH:mm') : null}
      value={value ? moment(value, 'HH:mm') : null}
      inputReadOnly={true}
      placeholder={placeholder}
      onChange={(time, string) => {
        if (onChange) {
          onChange();
        }
        form.setFieldsValue({
          [name]: string
        });
      }}
      onSelect={(time) => {
        if (onChange) {
          onChange();
        }
        const timeStr = moment(time).format('hh:mm a');
        form.setFieldsValue({
          [name]: timeStr
        });
        if (onSelect) {
          onSelect(timeStr);
        }
      }}
    />
  );
};

export default TimePicker;
