import styled from 'styled-components';

import removePetIcon from 'assets/icons/remove-pet-from-filter.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 6.8rem;
  background: #fff;
  box-shadow: 0px 17px 45px -31px rgba(45, 28, 28, 0.25);
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1.9rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    cursor: pointer;
    ${({ theme }) => theme.mobile`
    display : block;
  `}
  }

  ${({ theme }) => theme.laptopM`
    font-size: 1.6rem;
    padding-right: 1.2rem;
    padding-left: 1.8rem;
    height: 5.6rem;
  `}

  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    padding-right: 1rem;
    padding-left: 1.8rem;
    height: 5.6rem;
  `}

  ${({ theme }) => theme.mobile`
    padding: 1.2rem 1.6rem;
    border-bottom: 0.2rem solid #F5F6F9;
  `}
`;
export const TimelineHeaderTitle = styled.h2`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
  `}
`;
export const TimelineFilterWrapper = styled.div`
  width: 57.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.laptopM`
    width : 45rem;
  `}
  ${({ theme }) => theme.laptop`
    justify-content: flex-end;
    width: 57.2rem;
  `}

  ${({ theme }) => theme.mobile`
    display : none;
  `}
`;
export const FilterByText = styled.span`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  margin-right: 1rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    margin-right: 2rem;
  `}
`;
export const YearSelectorWrapper = styled.div`
  width: 12rem;
  height: 4rem;

  ${({ theme }) => theme.laptop`
    height: 3.6rem;
    width: 10.6rem;
    margin-right: 1.6rem;
  `}
`;
export const PetSelectorWrapper = styled.div`
  max-width: 36.4rem;
  width: 100%;
  height: 4rem;
  ${({ theme }) => theme.laptopM`
    width: 26.6rem;
  `}
  ${({ theme }) => theme.laptop`
    height: 3.6rem;
    width: 26.6rem;
  `}
`;
export const OptionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-right: 1.8rem;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  ${({ theme }) => theme.mobile`
    width: calc(100% - 1.8rem);
  `}
`;

export const CheckboxMobile = styled.div`
  ${({ theme }) => theme.mobile`
    margin-top: 3rem;
`}
`;

export const Value = styled.div`
  margin-right: 0.6rem;
  cursor: pointer;
`;
export const ValueWrapper = styled.div`
  display: flex;
  max-width: 6.4rem;
  align-items: center;
  z-index: 0;
  background: #f3f5f9;
  border-radius: 0.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  position: relative;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
    padding: 0.1rem;
  }

  &::after {
    content: url(${removePetIcon});
    position: absolute;
    right: -0.3rem;
    top: -0.55rem;
    width: 1.2rem;
    height: 1.2rem;
    display: none;
  }

  &:hover::after {
    display: block;
    ${({ theme }) => theme.mobile`
      display: none;
    `}
  }
`;
export const ValueLabelBox = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #59616e;
  max-width: 3.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const OptionLabelBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.8rem;
`;
export const OptionName = styled.h3`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 22rem;
  white-space: nowrap;
`;

export const Avatar = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.4rem;
  background: #dbe2ee;
  padding: 0.4rem;
  margin-right: 1.2rem;
`;

export const FilterIcon = styled.div`
  cursor: pointer;
  display: none;
  ${({ theme }) => theme.mobile`
    display: block;
  `}
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const TimelineIcon = styled.img`
  width: 2.2rem;
  height: 2.1rem;

  ${({ theme }) => theme.laptop`
   width: 1.8rem;
   height: 1.75rem;
  `}
  ${({ theme }) => theme.mobile`
    display: none;
  `}

  margin-right: 1.7rem;
`;
