import React from 'react';
import { Popover } from 'antd';
import { Button, cssText } from '_fsd/shared';
import css from './IntegrationErrorPopover.module.css';
import { useTranslation } from 'react-i18next';

export const IntegrationErrorPopover = ({
  content,
  fixItHandler,
  entity,
  children,
  showTitle = true,
  disabled = false
}) => {
  const { t } = useTranslation();

  return disabled ? (
    <div>{children}</div>
  ) : (
    <Popover
      zIndex={999}
      overlayClassName={css.errorPopover}
      content={
        <div className={css.errorPopoverContent}>
          {showTitle && (
            <div className={cssText.s14w6l18}>{t('integration.error_popover.title')}</div>
          )}
          {content}
          {fixItHandler && (
            <Button
              className={css.errorPopoverButton}
              onClick={() => {
                fixItHandler(entity);
              }}>
              {t('integration.error_popover.button.fix_it')}
            </Button>
          )}
        </div>
      }>
      {children}
    </Popover>
  );
};
