import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.7rem;
  border: 0.1rem solid #acbcd4;
  width: fixed;
  padding: 0.6rem 0.8rem;
  border-radius: 0.6rem;
`;

export const Name = styled.span`
  font-size: 1.2rem;
  font-family: inter, assistant;
  font-weight: 400;
  color: #8a94a6;
  margin-left: 0.5rem;
`;
