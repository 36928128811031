import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import apolloClient from 'utils/client';
import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';
// import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
const root = document.getElementById('root');
import './styles/styles.css';
import FSD from './_fsd/app';

if (root) {
  Modal.setAppElement(root);
}

ReactDOM.render(
  <React.StrictMode>
    <FSD />
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading...">
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <Router>
              <AuthContextProvider>
                <App />
              </AuthContextProvider>
            </Router>
          </ApolloProvider>
        </ThemeProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
