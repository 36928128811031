import styled from 'styled-components';

export const SliderImg = styled.img`
  object-fit: cover;
  height: 100vh;
  user-select: none;
  ${({ theme }) => theme.mobile`
    height: 100%;
  `}
`;

export const Wrapper = styled.div`
  direction: initial;
  .carousel-root {
  }

  .control-dots {
    ${({ theme }) => theme.mobile`
      text-align: unset;
      left: 1.4rem;
      bottom: 2.5rem;
    `}
  }
`;

export const SlideInfoContainer = styled.div`
  position: absolute;
  bottom: 6.6rem;
  padding: 0 3.2rem;
  ${({ theme }) => theme.mobile`
    padding: 0 1.7rem;
  `}
`;

export const SlideCaption = styled.h2`
  color: #ffffff;
  font-size: 2.4rem;
  font-family: inter, assistant;
  font-weight: 700;
  text-align: left;
  line-height: 1.33;
  ${({ theme }) => theme.mobile`
    font-size: 1.8rem;
  `}
`;

export const SlideDescription = styled.p`
  color: #ffffff;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 1.1rem;
  line-height: 1.5;
  text-align: left;
  ${({ theme }) => theme.mobile`
    margin-top: 0.8rem;
    font-size: 1.4rem;
  `}
`;

export const SlideWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  ${({ theme }) => theme.mobile`
    height: 34.3rem;
  `}
`;
