import React, { memo } from 'react';
import { Select, Form } from 'antd';

import './styles.css';

const SelectTags = ({ placeholder, name, label, required }) => {
  return (
    <Form.Item name={name} label={label} required={required}>
      <Select mode="tags" placeholder={placeholder} dropdownStyle={{ display: 'none' }}></Select>
    </Form.Item>
  );
};

export default memo(SelectTags);
