import React, { memo } from 'react';
import { INTEGRATION_MODAL, IntegrationIcon } from '_fsd/entities/integration';
import css from './Pim.module.css';

const PimContent = ({ data, openModal }) => {
  const { name, icon, bgColor } = data;
  return (
    <div
      className={css.item}
      onClick={() =>
        openModal({
          type: INTEGRATION_MODAL.CREDENTIALS_EDITOR,
          context: { pim: data }
        })
      }>
      <div className={css.itemDisabled}>
        <IntegrationIcon icon={icon} bgColor={bgColor} disabled />
        <span>{name}</span>
      </div>
    </div>
  );
};

const MemoizedItem = memo(PimContent);

export const Pim = MemoizedItem;
