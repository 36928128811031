import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.css';

const VetPhoneInput = ({ name, disabled, form }) => {
  const { t } = useTranslation();

  return (
    <div className="vet-phone-input-wrapper">
      <Form.Item name="phonePrefix" hidden></Form.Item>
      <Form.Item name="phoneNumber" hidden></Form.Item>

      <Form.Item name={name} label={t('phone.number.not.required')}>
        <PInput
          placeholder={t('phone.number.not.required')}
          // prefix={form.getFieldValue('phonePrefix')}
          disabled={disabled}
          onChange={(value, data) => {
            form.setFieldsValue({
              phonePrefix: '+' + data?.dialCode,
              phoneNumber: value.slice(data?.dialCode?.length)
            });
          }}
        />
      </Form.Item>
    </div>
  );
};

export default VetPhoneInput;
