import React from 'react';
import { ButtonColors, Modal } from '_fsd/shared';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import css from './EditExternalId.module.css';

export const EditExternalIdWarningModal = ({ onConfirm, onClose, isOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      onConfirm={() => onConfirm()}
      title={
        <div className={css.editExternalIdWarningTitle}>
          <ExclamationCircleOutlined className={css.editExternalIdWarningIcon} />
          <span>{t('integration.edit_pet_form.edit_external_id_warning_modal.title')}</span>
        </div>
      }
      footerProps={{
        titleConfirm: t('integration.edit_pet_form.edit_external_id_warning_modal.confirm_button'),
        buttonConfirmProps: { colorScheme: ButtonColors.ORANGE }
      }}>
      <p className={css.editExternalIdWarningContent}>
        {t('integration.edit_pet_form.edit_external_id_warning_modal.content')}
      </p>
    </Modal>
  );
};
