import React from 'react';
import { Table } from 'antd';
import { useColumns } from '../lib';
import css from './AppointmentHistoryTable.module.css';
import { useTranslation } from 'react-i18next';

export const AppointmentHistoryTable = ({ data = [], loading }) => {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div className={css.list}>
      <p className={css.titleText}>{t('admin_dashboard.list_appointments')}</p>
      <div className={css.container}>
        <Table
          locale={{ emptyText: t('empty', { ns: 'stats' }) }}
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </div>
    </div>
  );
};
