import { useMutation } from '@apollo/client';
import { cancelVisitMutation } from 'gql';

const useCancelAppointment = () => {
  const [cancelVisit] = useMutation(cancelVisitMutation);

  const removeVisit = async (appointmentRouteId, user) => {
    try {
      await cancelVisit({
        variables: {
          record: {
            appointmentRouteId: appointmentRouteId,
            userId: user?.uid
          }
        }
      });
    } catch (err) {
      console.log(err, 'error');
    }
  };
  return {
    removeVisit
  };
};

export default useCancelAppointment;
