import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  padding: 1.6rem 3.2rem;
  max-height: 100rem;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0rem 0.4rem 4.5rem -3.1rem rgba(45, 28, 28, 0.08);

  ${({ theme }) => theme.tabletL`
    padding: 2rem;
  `};

  ${({ isDropdownOpen }) =>
    !isDropdownOpen && `max-height: 6.4rem; display:flex; flex-direction: column`};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
  width: 100%;
`;

export const Heading = styled.h3`
  font-size: 1.6rem;
  color: #000000d9;
  margin: 0;
`;

export const Content = styled.div`
  ${({ isDropdownOpen }) => !isDropdownOpen && `visibility: hidden`};
`;

export const DropdownIcon = styled.img`
  cursor: pointer;
  display: none;
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg) ${({ isDropdownOpen }) => isDropdownOpen && `transform: rotate(0deg);`};

  ${({ theme }) => theme.tabletL`
    display: block;
  `}
`;

export const Group = styled.div`
  display: flex;
  gap: 2.4rem;
`;
