import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 57.9rem;
  background-color: rgba(245, 246, 249, 1);
  box-shadow: 0 0.4rem 6rem -2rem rgba(5, 40, 72, 0.15);
  border-radius: 1.6rem;

  ${({ theme }) => theme.laptop`
    width: fit-content;
    height: fit-content;
  `}

  ${({ theme }) => theme.mobile`
    border-radius : 0;
    width : 100%;
    height : 100%;
    background : ${theme.colors.white};
  `}
`;

export const Header = styled.header`
  width: 100%;
  height: 6.4rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1.6rem 1.6rem 0 0;

  ${({ theme }) => theme.mobile`
    border-radius : 0;
    height : auto;
    position : relative;
    &::after{
      content : "";
      position : absolute;
      bottom : -2.6rem;
      left: 2.4rem;
      width : 15.4rem;
      height : .2rem;
      background : #D8DFEA;
      border-radius : 0.2rem; 
    }
  `}
`;

export const Title = styled.h2`
  font-family: inter, assistant;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  color: rgba(50, 59, 75, 1);
  padding: 2.1rem 0 1.9rem 3.6rem;

  ${({ theme }) => theme.laptop`
    padding: 2rem 0 2rem 3.6rem;
  `}

  ${({ theme }) => theme.mobile`
    padding : 1.9rem;
    max-width : 29rem;
  `}
`;

export const PlansWrapper = styled.ul`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  border-radius: 1.6rem;
  margin-left: 3.6rem;
  margin-right: 3.6rem;
  margin-top: 3.6rem;

  ${({ theme }) => theme.laptop`
    margin: 2.4rem 2.4rem 0 2.4rem;
    gap: 1.6rem;
    justify-content: unset;
  `}

  ${({ theme }) => theme.mobile`
    margin : 5.5rem 2.4rem 0;
    flex-wrap : wrap;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6.4rem 3.6rem 3.6rem 3.6rem;

  ${({ theme }) => theme.laptop`
    width: 100%;
    justify-content: flex-end;
    padding: 2.4rem 1.4rem 2.4rem 0;
  `}

  ${({ theme }) => theme.mobile`
    padding: 3.6rem 2.4rem 6rem;
  `}
`;

export const Button = styled.button`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.178rem;
  color: rgba(255, 255, 255, 1);
  border: 0;
  border-radius: 0.8rem;
  width: 17.3rem;
  height: 5.8rem;
  background: ${({ save }) => (save ? '#FF9A05' : '#8a94a6')};
  cursor: pointer;
  margin-left: ${({ save }) => (save ? '1.2rem' : '0')};
  ${({ theme }) => theme.laptop`
    margin: 0 1rem;
  `}
  ${({ theme }) => theme.mobile`
    width : 15rem;
    height : 5.2rem;
  `};
`;
