import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { getInitialLetters } from 'utils/helpers';

import Language from 'components/language/Language';

import theme from 'styles/theme';

import * as S from './components';

const CheckoutVetCard = ({ image, name, title, languages, user }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  return (
    <S.VetWrapper>
      {!isMobile ? (
        image ? (
          <S.VetImage src={image} />
        ) : (
          <S.AvatarWithText> {getInitialLetters(name)} </S.AvatarWithText>
        )
      ) : (
        <S.VetMainInfo>
          {image ? (
            <S.VetImage src={image} />
          ) : (
            <S.AvatarWithText> {getInitialLetters(name)} </S.AvatarWithText>
          )}
          <S.VetName>{name}</S.VetName>
        </S.VetMainInfo>
      )}
      <S.VetInfo>
        {!isMobile && (
          <div>
            <S.VetName>{name}</S.VetName>
          </div>
        )}
        <S.LanguagesWrapper>
          {languages?.map((l) => (
            <S.LanguageWrapper key={l.code}>
              <Language language={l} />
            </S.LanguageWrapper>
          ))}
        </S.LanguagesWrapper>
        <S.About>{user?.bio}</S.About>
      </S.VetInfo>
    </S.VetWrapper>
  );
};

export default CheckoutVetCard;
