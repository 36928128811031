import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.6rem 2.4rem;
  border-radius: 10px;
  height: auto;
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
`;

export const Heading = styled.div`
  font-size: 1.6rem;
  white-space: nowrap;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

export const DateText = styled.span`
  font-size: 1.4rem;
  white-space: nowrap;
  font-weight: 400;
  color: #22a3c3;
  margin-left: 2rem;
  ${({ theme }) => theme.mobile`
    margin-left : 0;
  `}
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mobile`
    flex-wrap : wrap;
  `}
`;

export const NoTimeslotsWrapper = styled.div`
  background: #fafafa;
  width: 100%;
  height: 32rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoTimeslotsImg = styled.img`
  margin-bottom: 1.6rem;
`;
