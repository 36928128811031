export const INTEGRATION_STATUS = {
  NEW: 'NEW',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED'
};
export const INTEGRATION_TYPE = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};
export const INTEGRATION_MODAL = {
  CAREGIVERS_ATTENTION: 'CAREGIVERS_ATTENTION',
  CONNECT: 'CONNECT',
  CREDENTIALS_EDITOR: 'CREDENTIALS_EDITOR',
  DISABLE: 'DISABLE',
  EXTENSION: 'EXTENSION',
  SUCCESS: 'SUCCESS'
};

export const INTEGRATION_ENTITY_STATUS = {
  INITIALIZED: 'INITIALIZED',
  PROCESSING: 'PROCESSING',
  SYNCED: 'SYNCED',
  ERROR: 'ERROR'
};

export const INTEGRATION_ENTITY_LINK_ACTION = {
  OPEN_URL: 'OPEN_URL',
  COPY_URL: 'COPY_URL'
};
