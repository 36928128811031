import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompleteProfileForm from 'components/complete-profile-form/CompleteProfileForm';
import { PageTitle, PageSubTitle } from 'components/common/texts/components';
import GoBack from 'components/common/go-back/GoBack';
import WarningModal from 'components/common/warning-modal/WarningModal';
import { Container } from './components';
import AuthContext from '../../../contexts/AuthContext';

const CompleteProfile = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [isWarningMOdalOpen, setIsWarningMOdalOpen] = useState(false);

  return (
    <Container>
      <div
        style={{ marginBottom: 16 }}
        onClick={() => {
          setIsWarningMOdalOpen(true);
        }}>
        <GoBack
          clickHandler={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <PageTitle>{t('get.started')}</PageTitle>
      <PageSubTitle>{t('fill.personal.info')}</PageSubTitle>
      <CompleteProfileForm />

      <WarningModal
        isOpen={isWarningMOdalOpen}
        handleCloseModal={() => {
          setIsWarningMOdalOpen(false);
        }}
        handleClickLeave={() => logout()}
      />
    </Container>
  );
};

export default CompleteProfile;
