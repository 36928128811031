import React from 'react';
import { Checkbox, cssText as text } from '_fsd/shared';
import css from './Reschedule.module.css';

export const FilterOption = ({ disabled, setValue, value, label }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      setValue(!value);
    }}
    className={css.checkBox}>
    <Checkbox disabled={disabled} checked={value} />
    <span className={text.s14w4l18fI}>{label}</span>
  </div>
);
