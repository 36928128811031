import React, { memo } from 'react';
import { Form, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.css';

const { Option } = Select;

const PetWeightInput = ({ label, name, placeholder, required, form }) => {
  const { t } = useTranslation();

  const selectAfter = (
    <Select
      onChange={(value) => {
        form.setFieldsValue({
          weightUnit: value
        });
      }}>
      <Option value="kg">{t('kg')}</Option>
      <Option value="lb">{t('lb')}</Option>
    </Select>
  );

  return (
    <>
      <Form.Item name="weightUnit" hidden />

      <Form.Item label={label} name={name} required={required}>
        <InputNumber
          size="middle"
          type="number"
          placeholder={placeholder}
          addonAfter={selectAfter}
          controls={false}
          className="weight-input"
        />
      </Form.Item>
    </>
  );
};

export default memo(PetWeightInput);
