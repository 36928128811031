import styled from 'styled-components';

export const CloseIconWrapper = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  padding: 0.5rem;

  & svg {
    fill: #9094ac;
  }
`;
