import React, { useMemo, useState } from 'react';
import { Slider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { preferenceTitlesAndDescriptions } from 'constants/preferences';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';

const { Title, Text } = Typography;

const PreferenceRangeInput = ({
  uid,
  description,
  itemValue,
  isFirstChild,
  minValue,
  maxValue,
  handleUpdateUserSetting,
  step
}) => {
  const formatter = (value) => `${value}`;

  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localValue, setLocalValue] = useState(Number(itemValue));

  const closeModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const openModal = () => setIsModalOpen(true);

  const handleChange = (value) => {
    setLocalValue(value);
  };

  const saveChanges = (e) => {
    e.stopPropagation();
    if (itemValue != localValue) handleUpdateUserSetting(uid, localValue);
    setIsModalOpen(false);
  };

  const sliderComponent = useMemo(() => {
    return (
      <>
        <Slider
          onAfterChange={(value) => handleChange(String(value))}
          defaultValue={Number(itemValue)}
          trackStyle={{
            backgroundColor: '#22A3C3'
          }}
          handleStyle={{
            backgroundColor: '#fff',
            boxShadow: 'none',
            borderColor: '#22A3C3'
          }}
          className="range-slider"
          tipFormatter={formatter}
          min={minValue || 0}
          max={maxValue || 50}
          step={step}
        />
        <div className="slider-details-wrapper">
          <Text>
            {minValue} {t('min')}
          </Text>
          <Text>
            {maxValue} {t('min')}
          </Text>
        </div>
      </>
    );
  }, [itemValue, minValue, maxValue, handleUpdateUserSetting, uid]);

  const titleComponent = useMemo(() => {
    return (
      <Title level={3} className="settings-text notification-title">
        {preferenceTitlesAndDescriptions[description]?.title}
      </Title>
    );
  }, []);

  const subTitleComponent = useMemo(() => {
    return (
      <Text className="settings-text notification-description">
        {preferenceTitlesAndDescriptions[description]?.description}
      </Text>
    );
  }, []);

  return (
    <div
      className={`notification-wrapper flex-wrap cursor-pointer ${
        isFirstChild ? 'first-child' : ''
      }`}
      onClick={openModal}>
      <div className="notification-content range-input-content">
        {titleComponent}
        {subTitleComponent}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isMobile={isMobile}
          autoHeight
          scrollable>
          <div className="range-input-modal">
            {titleComponent}
            {subTitleComponent}
            {sliderComponent}
            <div className="modal-footer">
              <Button
                color="white"
                fontColor="rgba(0, 0, 0, 0.85)"
                height="3.2rem"
                width="8rem"
                border="1px solid #D9D9D9"
                isForModal
                onClick={closeModal}>
                {t('preferences.cancel')}
              </Button>
              <Button color="primary" width="8rem" height="3.2rem" isForModal onClick={saveChanges}>
                {t('preferences.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PreferenceRangeInput;
