import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Checkbox from 'assets/icons/checkCircle.svg';
import Profile from 'assets/images/profile-pic.png';
import Logo from 'common_repo/assets/icons/logo-blue-beta.png';

import { DASHBOARD } from '../../constants/client';

import * as S from './components';

const CalendarSuccessBox = ({ data, isBooking, getTime }) => {
  const { appointment, pets, user, vet, timeSlot } = data;
  const { t } = useTranslation();

  return (
    <S.PageWrapper isBooking={isBooking}>
      <S.ContentWrapper>
        <S.MessageWrapper>
          <S.CheckboxImg src={Checkbox} />
          <S.MessageTitle>{t('appointment.has.been.scheduled.with')}</S.MessageTitle>
          <S.ProfileImg src={vet?.user?.avatarUrl || Profile} />
          <S.VetName>{vet?.user?.firstName + ' ' + vet?.user?.lastName}</S.VetName>
          <S.MessageDetailsWrapper>
            <S.MessageRow>
              <S.MessageKey>{t('date')}</S.MessageKey>
              <S.MessageValue>{dayjs(appointment?.date).format('MM/DD/YYYY')}</S.MessageValue>
            </S.MessageRow>
            <S.MessageRow>
              <S.MessageKey>{t('time.slot')}</S.MessageKey>
              <S.MessageValue>
                {getTime(timeSlot?.startTime)}-{getTime(timeSlot?.endTime)}
              </S.MessageValue>
            </S.MessageRow>
            <S.MessageRow>
              <S.MessageKey>{t('pets')}</S.MessageKey>
              <S.PetsWrapper>
                {pets?.map((pet, i) => {
                  return (
                    <S.PetItem key={i}>
                      <S.PetName>{pet?.name}</S.PetName>
                      <S.PetType>{pet?.type?.name}</S.PetType>
                    </S.PetItem>
                  );
                })}
              </S.PetsWrapper>
            </S.MessageRow>
          </S.MessageDetailsWrapper>
          <S.Button href={DASHBOARD}>
            <S.ButtonText>{t('go.to.home.page')}</S.ButtonText>
          </S.Button>
          <S.MessageText>
            {t('appointment.details.has.been.sent.to.your.email')}
            <S.Email>{user?.email}</S.Email>
          </S.MessageText>
        </S.MessageWrapper>
        <S.Footer>
          <S.FooterText>{t('powered.by')}</S.FooterText>
          <S.FooterImg src={Logo} />
        </S.Footer>
      </S.ContentWrapper>
    </S.PageWrapper>
  );
};

export default CalendarSuccessBox;
