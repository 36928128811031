import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import { useFormik } from 'formik';
import useDigitInput from 'react-digit-input';

import { verifyCodeMutation, requestCodeMutation } from 'gql';

import { CodeValidationScheme } from 'validations/validations';
import { ADD_PET, COMPLETE_PROFILE, DASHBOARD, SCHEDULE, VET_ROUTES } from 'constants/client';
import { Roles } from 'constants/enums';
import AuthContext from '../../contexts/AuthContext';

const useVerifyCodeForm = (
  pPrefix,
  pNumber,
  setIsVerificationCodeSent,
  onRequestClose,
  isPhoneNumberExists,
  successHandler,
  vet
) => {
  const { codeSentAt, setCodeSendAt, setToken } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const { prefix: phonePrefix, number: phoneNumber } = pPrefix
    ? {
        prefix: pPrefix,
        number: pNumber
      }
    : qs.parse(location.search.substr(1));

  const [resendCodeIn, setResendCodeIn] = useState(0);
  const [codeResendSuccess, setCodeResendSuccess] = useState(false);

  const [verifyCode] = useMutation(verifyCodeMutation);
  const [resendCode, { loading: requestingCode }] = useMutation(requestCodeMutation);

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      const { data: verifyData } = await verifyCode({
        variables: {
          record: {
            phonePrefix,
            phoneNumber,
            code: Number(values.code),
            vetId: vet?.uid
          }
        }
      });
      const { accessToken, user } = verifyData.verifyCode;
      if (successHandler && isPhoneNumberExists && accessToken) {
        setToken(accessToken);
        successHandler();
        return;
      }
      if (pPrefix) {
        setIsVerificationCodeSent(true);
        if (isPhoneNumberExists) {
          setToken(accessToken);
        }
        return onRequestClose(false);
      }
      setToken(accessToken);
      setSubmitting(false);
      resetForm();

      let redirect;
      const redirectUrl = localStorage.getItem('redirectUrl');

      if (!user?.firstName) {
        redirect = COMPLETE_PROFILE;
      } else {
        if (redirectUrl) {
          const { firstName, lastName, shareId } = JSON.parse(redirectUrl);
          redirect = `/${SCHEDULE}/${firstName}-${lastName}-${shareId}`;
        } else {
          if (user?.role === Roles.VET) {
            redirect = VET_ROUTES.ROUTES;
          } else {
            if (!user?.hasPet) {
              redirect = ADD_PET;
            } else {
              redirect = DASHBOARD;
            }
          }
        }
      }

      if (redirectUrl) {
        localStorage.removeItem('redirectUrl');
      }

      history.replace(redirect);
    } catch (e) {
      resetForm();
      setFieldError('serverError', 'Invalid code');
    }
  };

  const handleResendCode = async () => {
    try {
      const data = await resendCode({
        variables: {
          record: {
            phonePrefix,
            phoneNumber
          }
        }
      });
      setCodeSendAt(data.data?.requestCode?.codeSentAt);
      setResendCodeIn(1);
      setCodeResendSuccess(true);
    } catch (e) {
      setFieldError('serverError', e.graphQLErrors?.[0]?.message);
    }
  };

  useEffect(() => {
    if (codeResendSuccess) {
      const timeOut = setTimeout(() => {
        setCodeResendSuccess(false);
      }, 5000);
      return () => clearTimeout(timeOut);
    }
  }, [codeResendSuccess]);

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    handleSubmit,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: { code: '' },
    onSubmit,
    validationSchema: CodeValidationScheme
  });

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: values.code,
    onChange: (val) => setFieldValue('code', val)
  });

  useEffect(() => {
    if (codeSentAt && resendCodeIn >= 0) {
      const interval = setInterval(() => {
        setResendCodeIn(
          Math.round(
            (new Date(codeSentAt).getTime() +
              parseInt(process.env.REACT_APP_REQUEST_CODE_PERIOD) -
              Date.now()) /
              1000
          )
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [codeSentAt, codeResendSuccess, resendCodeIn]);

  return {
    digits,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resendCodeIn,
    handleResendCode,
    requestingCode,
    codeResendSuccess
  };
};

export default useVerifyCodeForm;
