import styled, { css } from 'styled-components';

export const TimelinePetsContainer = styled.div`
  padding: 1.8rem 2rem;
  height: 19.1rem;
  box-shadow: 0px 17px 45px -31px rgba(45, 28, 28, 0.25);
  border-radius: 0rem 0rem 0.8rem 0.8rem;
  border-top: 0.1rem solid #f5f6f9;
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.minWidth.desktopS`
    height: 27.7rem;
    padding: 2rem 3.2rem;
  `}

  ${({ theme }) => theme.mobile`
    display : ${({ isActiveTab }) => (isActiveTab ? 'block' : 'none')};
    padding: 2rem 1.6rem;
    border-radius: 1.6rem;
    height: 100%;
  `}
`;
export const PetsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${({ theme }) => theme.minWidth.desktopS`
    margin-bottom: 1.9rem;
  `}

  ${({ theme }) => theme.mobile`
  border-bottom: 0.2rem solid #f5f6f9;
  padding-bottom: 0.6rem;
  margin-bottom: 3.5rem;
  `}
`;

export const TitleAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PetsIcon = styled.img`
  height: 2.2rem;
  width: 2.1rem;

  margin-right: 1.7rem;

  ${({ theme }) => theme.minWidth.desktopS`
      height: 2.2rem;
      width: 2.1rem;
    `}
`;

export const PetsHeadTitle = styled.h3`
  font-family: inter, assistant;
  font-style: 400;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.minWidth.desktopS`
    font-size: 1.8rem;
  `}
`;
export const NewPetTitle = styled.h3`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #26acb9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  cursor: pointer;
  ${({ theme }) => theme.minWidth.desktopS`
    font-size: 1.6rem;
  `}
`;
export const Prev = styled.div`
  user-select: none;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid #f2f3f7;
  border-radius: 0.8rem;
  background-color: #f2f3f7;
  opacity: 0.64;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${({ rtl }) => (rtl ? 'initial' : 0)};
  right: ${({ rtl }) => (rtl ? 0 : 'initial')};
  top: calc(50% - 1.2rem);
  div {
    margin: 0;
  }
`;
export const PetsSlider = styled.div`
  position: relative;
  padding: 0 3.2rem;
  height: unset;

  display: flex;
  align-items: center;
  .swiper-container {
    width: 100% !important;
  }
  .swiper-wrapper {
    ${({ isRtl }) =>
      isRtl &&
      css`
        direction: rtl;
      `}
  }
  .swiper-slide {
    width: auto !important;
  }
`;

export const MobilePetsWrapper = styled.div`
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 35rem);
  height: 85%;
  &::-webkit-scrollbar {
    width: 0.8rem;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8a94a640;
    border-radius: 0.8rem;
  }
`;
